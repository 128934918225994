import PageContent from "components/PageContent";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Create from "./Create";
import Preview from "./Preview";
import Table from "./Table";

function Insert() {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [preview, setPreview] = useState();
  const [refresh, setRefresh] = useState();

  return (
    <PageContent
      header={t(`Labels.${data ? t("Submit Data") : t("Manage Submissions")}`)}
      subheader={data ? data.custom_id_label + ": " + data.submission.custom_id : ""}
    >
      {data ? (
        <Create data={data} resetData={() => setData()} setPreview={setPreview} />
      ) : (
        <Table refresh={refresh} setData={setData} setPreview={setPreview} />
      )}
      <Preview
        title={t("Labels.Preview")}
        handleEdit={() =>
          setPreview(_ => {
            setData(_);
            return null;
          })
        }
        handleClose={completed => () => {
          !completed && setRefresh(_ => !_);
          setPreview();
        }}
        open={preview}
        id={preview?.submission?.id}
      />
    </PageContent>
  );
}

export default Insert;
