import { LineChart, Line } from "recharts";
import React from "react";

function SmallChart({ data }) {
  return (
    <LineChart width={100} height={50} data={data}>
      <Line dataKey="value" stroke="#5c5c5c" dot={false} />
    </LineChart>
  );
}

export default SmallChart;
