import { Grid, MenuItem, Typography } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import FormSubmissionApi from "api/formSubmission";
import Buttons from "components/Button";
import Select from "components/Select";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: "100%",
  },
  textfield: {
    margin: 0,
  },
  buttonGroup: {
    marginTop: 10,
    marginBottom: 4,
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 10,
  },
  details: {
    marginLeft: 12,
  },
  container: {
    border: `solid 1px ${palette.background.default}`,
    borderRadius: 4,
  },
}));

function Fields({ handleClose, forms, data }) {
  const classes = useStyles();
  const [date, setDate] = useState(data.date ? new Date(data.date) : new Date());
  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit, control, watch, setValue, errors } = useForm();
  const { t } = useTranslation();
  const { demo } = useSelector(_ => _.user);

  useEffect(() => {
    data.formId && setValue("formId", data.formId);
  }, [data.formId, setValue]);

  const saveForm = async formData => {
    data.id
      ? await FormSubmissionApi.update(data.id, { ...formData, date: new Date(date) })
          .then(res => {
            enqueueSnackbar(res.status);
          })
          .catch(e => {
            enqueueSnackbar(e, {
              variant: "error",
            });
          })
      : await FormSubmissionApi.create({ ...formData, date: new Date(date) })
          .then(res => {
            enqueueSnackbar(res.status);
          })
          .catch(e => {
            enqueueSnackbar(e, {
              variant: "error",
            });
          });
    handleClose();
  };

  return (
    <form className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.details}>
            {t("Labels.Details")}
          </Typography>
          <TextField
            labelPlacement="left"
            name="date"
            variant="h3"
            required
            label={t("Labels.Date")}
            helperText={t("HelperText.Please fill in Date")}
            type="datetime"
            className={classes.textfield}
            inputRef={register({ required: true })}
            value={date}
            onChange={e => setDate(e)}
            placeholder={new Date()
              .toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit", hour12: false })
              .replace(",", "")}
          />

          <Select
            name="formId"
            defaultValue={data.formId}
            control={control}
            disabled={data.formId}
            rules={{ required: true }}
            labelPlacement="left"
            variant="h3"
            error={errors.formId}
            helperText={t("HelperText.Please pick a form template")}
            inputRef={register}
            required
            label={t("Labels.Form")}
            fullWidth
          >
            {forms.map((template, i) => (
              <MenuItem key={i + 1} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
          {watch("formId", 0) > 0 && (
            <TextField
              labelPlacement="left"
              variant="h3"
              name="custom_id"
              defaultValue={data.custom_id}
              label={forms.find(_ => _.id === watch("formId", "")).custom_id_label}
              className={classes.textfield}
              error={errors.custom_id}
              helperText={t("Please fill in") + " " + forms.find(_ => _.id === watch("formId", "")).custom_id_label}
              inputRef={register({ required: true })}
            />
          )}
        </Grid>
      </Grid>
      <Grid container justify="flex-end" spacing={1} className={classes.buttonGroup}>
        <Grid item>
          <Buttons variant="outlined" buttonText={t("Labels.Cancel")} className={classes.button} onClick={handleClose} />
        </Grid>
        <Grid item>
          <Buttons
            buttonText={data.id ? t("Save") : t("Labels.Create")}
            type="submit"
            disabled={demo}
            className={classes.button}
            onClick={handleSubmit(saveForm)}
            endIcon={<ChevronRightRounded />}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default Fields;
