import { Card, CardContent, CardHeader, IconButton, InputAdornment, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import UserApi from "api/user";
import cardHeaderStyle from "assets/jss/components/cardHeader";
import landingStyle from "assets/jss/views/landing";
import classNames from "classnames";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const useLandingStyles = makeStyles(landingStyle);
const useCardHeaderStyles = makeStyles(cardHeaderStyle);

const useStyles = makeStyles({
  root: {
    margin: 150,
    padding: "18px 8px",
  },
  subheader: {
    paddingBottom: 22,
  },
  button: {
    marginTop: 20,
  },
  textfield: {
    margin: "5px 0px",
  },
});

function ResetPassword() {
  const { register, errors, handleSubmit, watch } = useForm();
  const password = useRef({});
  password.current = watch("password", "");
  const { t } = useTranslation();
  const code = new URLSearchParams(window.location.search).get("code");
  const [loading, setLoading] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const landingClasses = useLandingStyles();
  const cardHeaderClasses = useCardHeaderStyles();
  const classes = useStyles();
  const history = useHistory();

  const saveForm = async data => {
    setLoading(true);
    try {
      await UserApi.resetPassword({ ...data, code });
      enqueueSnackbar(t("Messages.Congratulations, your password has been changed successfully!"));
      history.push("/");
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form>
      <Card variant="outlined" className={classNames(landingClasses.card, classes.root)}>
        <CardHeader
          title={t("Labels.BrandTag")}
          className={landingClasses.title}
          titleTypographyProps={{
            variant: "h4",
          }}
          classes={cardHeaderClasses}
        />
        <CardContent>
          <Typography className={classes.subheader}>
            {t("HelperText.A strong password helps prevent unauthorised access to your email account")}
          </Typography>
          <TextField
            className={classes.textfield}
            inputRef={register({ required: true })}
            name="password"
            type={showPassword ? "text" : "password"}
            label={t("Labels.Enter new password")}
            error={errors.password}
            helperText={t("HelperText.Please fill in new password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
              </InputAdornment>
            }
          />
          <TextField
            className={classes.textfield}
            inputRef={register({ required: true, validate: value => value === password.current || t("Messages.The passwords do not match") })}
            name="passwordConfirmation"
            type="password"
            label={t("Labels.Re-Enter new password")}
            error={errors.passwordConfirmation}
            helperText={errors.passwordConfirmation?.message || t("HelperText.Please fill in retype password")}
          />
          <Button
            className={classes.button}
            buttonText={t("Labels.Update Password")}
            loading={loading}
            loadingText={t("Messages.Updating password")}
            fullWidth
            type="submit"
            onClick={handleSubmit(saveForm)}
          />
        </CardContent>
      </Card>
    </form>
  );
}

export default ResetPassword;
