import { CssBaseline, Grow, IconButton, makeStyles, MuiThemeProvider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import { light } from "assets/jss";
import defaultTheme from "assets/theme";
import { SnackbarProvider } from "notistack";
import React from "react";
import { hot } from "react-hot-loader/root";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "routes";
import { createRoutes } from "routes/utils";
import { decodeJWTPayload } from "utils";

const UIThemeProvider = ({ children }) => {
  const { theme, primaryColor, secondaryColor, language } = useSelector(_ => _.environment);
  return (
    <MuiThemeProvider theme={defaultTheme(theme, primaryColor, secondaryColor, language)}>
      <ThemeProvider theme={defaultTheme(theme, primaryColor, secondaryColor, language)}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
};

const dashboardRoutes = () => createRoutes(routes);
const adminRoutes = () => createRoutes(routes.filter(i => !i.private));
const landingRoutes = () => {
  let landingRouteList = routes.filter(i => !i.private);
  const rootPath = landingRouteList.findIndex(i => i.path === "/");
  landingRouteList[rootPath] = { ...landingRouteList[rootPath], component: require("views/Landing").default, exact: true };
  return createRoutes(landingRouteList);
};

const useStyles = makeStyles(({ palette }) => ({
  success: {
    backgroundColor: light.primary,
  },
  dismiss: {
    color: palette.background.paper,
  },
}));

const App = () => {
  const jwtToken = useSelector(_ => _.user.jwtToken);
  const isAdmin = decodeJWTPayload(jwtToken)?.isAdmin;
  const classes = useStyles();
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => notistackRef.current.closeSnackbar(key);

  return (
    <Router basename="/">
      <UIThemeProvider>
        <SnackbarProvider
          classes={{
            variantSuccess: classes.success,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          variant="success"
          TransitionComponent={Grow}
          preventDuplicate
          autoHideDuration={3000}
          ref={notistackRef}
          action={key => (
            <IconButton className={classes.dismiss} onClick={onClickDismiss(key)}>
              <Close />
            </IconButton>
          )}
        >
          <CssBaseline />
          <Switch>{jwtToken ? (isAdmin ? adminRoutes() : dashboardRoutes()) : landingRoutes()}</Switch>
        </SnackbarProvider>
      </UIThemeProvider>
    </Router>
  );
};

export default process.env.NODE_ENV === "development" ? hot(App) : App;
