const divider = true;
const routes = [
  {
    path: "/business",
    label: "Labels.Manage Businesses",
    component: require("views/Admin/Business").default,
  },
  {
    path: "/brand",
    label: "Labels.Manage Trademarks",
    component: require("views/Admin/Brand").default,
  },
  {
    path: "/sku",
    label: "Manage SKU Certificates",
    component: require("views/Admin/SKU").default,
  },
  {
    path: "/user",
    label: "Labels.Manage Users",
    component: require("views/Admin/User").default,
  },
  {
    path: "/plan",
    label: "Labels.Manage Plans",
    component: require("views/Admin/Plan").default,
  },
  {
    divider,
  },
  {
    path: "/",
    label: "Labels.General Settings",
    component: require("views/Settings").default,
  },
  {
    path: "/preferences",
    label: "Labels.Preferences",
    component: require("views/Preferences").default,
  },
  {
    divider,
  },
  {
    path: "/signout",
    label: "Labels.Sign Out",
    component: require("views/SignOut").default,
  },
  {
    redirect: true,
    path: "*",
    to: "/error",
  },
];

const updateRoute = r => {
  return r.map(route => {
    return {
      ...route,
      children: route.children && updateRoute(route.children),
      private: true,
      admin: true,
      exact: true,
    };
  });
};

export default updateRoute(routes);
