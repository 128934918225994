import { Grid, IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { MoreHoriz, ThumbDownRounded, ThumbsUpDownRounded, ThumbUpRounded, WarningRounded } from "@material-ui/icons";
import { apiURL } from "api";
import TrademarkApi from "api/trademark";
import A from "components/A";
import PageContent from "components/PageContent";
import MaterialTable from "material-table";
import Image from "material-ui-image";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: 24,
  },
  row: {
    paddingBottom: 12,
  },
  detailImage: {
    maxWidth: 150,
    maxHeight: 150,
    borderRadius: 3,
    border: `solid 3px ${palette.background.default}`,
    padding: spacing(1),
    margin: spacing(1),
  },
  detailRoot: {
    padding: spacing(1),
    margin: spacing(1),
  },
  detailKey: {
    fontWeight: "bold",
  },
  itemIcon: {
    minWidth: 40,
  },
  status: {
    border: `2px solid ${palette.background.default}`,
    textAlign: "center",
    padding: 10,
  },
}));

const keyToDisplayMap = {
  applicant_name: "Labels.Applicant's Name",
  class: "Class",
  applicant_address: "Applicant Address",
  postcode: "Postcode",
  country: "Country",
  verified_by: "Verified By",
};

const Container = props => <Paper elevation={0} {...props} />;

function Business() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState();
  const [business, setBusiness] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const { palette } = useTheme();

  useEffect(() => {
    TrademarkApi.getAll().then(res => {
      setBusiness(res);
    });
  }, []);

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData);
    event.stopPropagation();
  };

  const handleClose = () => setAnchorEl();

  const handleApprove = status => () => {
    const { id } = selectedRowData;
    TrademarkApi.updateStatus(id, { status })
      .then(res => {
        enqueueSnackbar(`Trademark ${selectedRowData.registration_name} status updated!`);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => {
        setAnchorEl();
        TrademarkApi.getAll().then(res => {
          setBusiness(res);
        });
      });
  };

  return (
    <PageContent header={t("Manage Trademarks")}>
      <Grid container>
        <Grid item className={classes.row} xs={12}>
          <MaterialTable
            columns={[
              { title: t("Labels.Business Name"), field: "registration_name", defaultGroupOrder: 1 },
              { title: t("Labels.Registration Number"), field: "registration_no" },
              { title: t("Labels.Status"), field: "status" },
              {
                title: t("Labels.Document"),
                field: "details",
                render: ({ details }) => {
                  if (details?.file) {
                    const { hash, ext, name } = details?.file;
                    return <A href={`${apiURL}/files/${hash}${ext}`}>{name}</A>;
                  } else {
                    return "";
                  }
                },
              },
              {
                title: t("Labels.Action"),
                field: "action",
                sorting: false,
                render: rowData => (
                  <IconButton onClick={e => handleClick(e, rowData)}>
                    <MoreHoriz />
                  </IconButton>
                ),
              },
            ]}
            data={business}
            title=""
            options={{
              toolbar: true,
              draggable: false,
              detailPanelColumnAlignment: "left",
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: palette.background.default,
              },
            }}
            components={{
              Container,
            }}
            detailPanel={[
              {
                render: rowData => {
                  let fileSrc = "";
                  if (rowData?.details?.file) {
                    const { hash, ext } = rowData.details.file;
                    fileSrc = `${apiURL}/files/${hash}${ext}`;
                  }
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        {fileSrc !== "" &&
                          (fileSrc.substr(fileSrc.length - 4) === ".pdf" ? (
                            <embed src={fileSrc} width="200px" height="140px" />
                          ) : (
                            <Image src={fileSrc} className={classes.detailImage} />
                          ))}
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.detailRoot}>
                          {rowData &&
                            Object.keys(rowData).map(
                              (d, i) =>
                                keyToDisplayMap.hasOwnProperty(d) && (
                                  <Grid container key={i}>
                                    <Grid item xs={4}>
                                      <Typography className={classes.detailKey}>{t(keyToDisplayMap[d])}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>{rowData[d]}</Typography>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                          {rowData.details &&
                            Object.keys(rowData.details).map(
                              (d, i) =>
                                keyToDisplayMap.hasOwnProperty(d) && (
                                  <Grid container key={i}>
                                    <Grid item xs={4}>
                                      <Typography className={classes.detailKey}>{t(keyToDisplayMap[d])}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>{rowData.details[d]}</Typography>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                },
              },
            ]}
          />
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
          >
            {selectedRowData.status !== "Pending Approval" && (
              <MenuItem onClick={handleApprove("Pending Approval")}>
                <ListItemIcon className={classes.itemIcon}>
                  <ThumbsUpDownRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Labels.Pending Approval")} />
              </MenuItem>
            )}
            {selectedRowData.status !== "Approved" && (
              <MenuItem onClick={handleApprove("Approved")}>
                <ListItemIcon className={classes.itemIcon}>
                  <ThumbUpRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Labels.Approved")} />
              </MenuItem>
            )}
            {selectedRowData.status !== "Rejected" && (
              <MenuItem onClick={handleApprove("Rejected")}>
                <ListItemIcon className={classes.itemIcon}>
                  <ThumbDownRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Labels.Reject")} />
              </MenuItem>
            )}
            {selectedRowData.status !== "Request Changes" && (
              <MenuItem onClick={handleApprove("Request Changes")}>
                <ListItemIcon className={classes.itemIcon}>
                  <WarningRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Labels.Request Change")} />
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default Business;
