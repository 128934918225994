import axios from "axios";
import { signOut } from "redux/actions/user";
import store from "../redux/store";

export const apiURL = process.env.REACT_APP_API_URL || "http://localhost:8081";

const noTokenPaths = ["/auth/local", "/merchant/register", "/auth/send-email-confirmation", "/auth/email-confirmation"];

const service = axios.create({
  baseURL: apiURL,
});

const isHtml = item => {
  return /<\/?[a-z][\s\S]*>/i.test(item);
};

const parseErrorMessage = error => {
  let errorMessage = "";
  try {
    if (isHtml(error.response.data)) {
      errorMessage = "Undefined error";
    } else if (error.response.data.message) {
      if (error.response.data.message[0]?.messages?.length > 0) {
        errorMessage =
          error.response.data.message[0].messages[0].message ||
          error.response.data.message[0].messages[0].id ||
          error.response.data.message[0].messages[0].message;
      } else {
        errorMessage = error.response.data.message;
      }
    } else if (error.response.data.error) {
      errorMessage = error.response.data.error;
    } else {
      errorMessage = error.response.data;
    }
  } catch (error) {
    errorMessage = "Oops! Something went wrong. If the problem persists, please contact support at info@brandtag.io";
  }
  return errorMessage;
};

service.interceptors.request.use(
  config => {
    const { jwtToken, demo } = store.getState().user;
    if (jwtToken && !noTokenPaths.includes(config.url.split("?")[0])) {
      config.headers["Authorization"] = "Bearer " + jwtToken;
    }
    if (demo) {
      config.headers["Mode"] = "demo";
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// response
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    // Sentry.captureException(error.response.data) // for debug
    if ([401, 403].includes(error?.response?.status)) {
      //signout
      store.dispatch(signOut());
      return Promise.reject("Your session has expired. Please sign in again to continue.");
    }
    return Promise.reject(parseErrorMessage(error));
  }
);

export default service;
