import { Card, CardActions, CardContent, Grid, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BillingApi from "api/billing";
import OrderApi from "api/order";
import classNames from "classnames";
import Button from "components/Button";
import { default as ConfirmationDialog, default as ErrorDialog, default as ExpiredDialog } from "components/Dialog/confirmation";
import SuccessDialog from "components/Dialog/info";
import Select from "components/Select";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCredit } from "redux/actions/user";
import { formatNumber } from "utils";

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
  },
  actionButton: {
    justifyContent: "flex-end",
    paddingRight: 25,
  },
  progressWrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: `-${theme.typography.fontSize}px`,
    marginLeft: `-${theme.typography.fontSize}px`,
  },
  cardstyle: {
    height: "100%",
  },
}));

function Create({ loading, setLoading, skuList }) {
  const classes = useStyles();
  const [successDialog, setSuccessDialog] = useState();
  const [confirmationDialog, setConfirmationDialog] = useState();
  const [errorDialog, setErrorDialog] = useState();
  const [expiredDialog, setExpiredDialog] = useState();
  const [quantity, setQuantity] = useState(0);
  const [newBalance, setNewBalance] = useState(0);
  const { demo } = useSelector(_ => _.user);
  const [formData, setFormData] = useState({});
  const [res, setRes] = useState();
  const [expired, setExpired] = useState();
  const [planPeriod, setPlanPeriod] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLetsTalk = useCallback(() => history.push("/settings/credit"), [history]);
  const { register, errors, handleSubmit, control, reset } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const progress = useSelector(_ => _.user.progress);

  useEffect(() => {
    try {
      progress?.emailConfirmed &&
        BillingApi.getBalance().then(res => {
          if (res.remainingDays > 0) {
            setRes(res);
          } else {
            setExpiredDialog(true);
            setExpired(true);
          }
          setPlanPeriod(res.validity);
        });
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, progress]);

  const handleOnClick = async data => {
    if ((res?.remainingCodes || 0) >= data.quantity) {
      setNewBalance(res.remainingCodes - data.quantity);
      setQuantity(data.quantity);
      setSuccessDialog();
      setConfirmationDialog(true);
      setErrorDialog();
      setLoading(true);
      setFormData(data);
    } else {
      setSuccessDialog();
      setConfirmationDialog();
      setErrorDialog(true);
    }
  };

  const saveForm = async () => {
    setConfirmationDialog();
    setSuccessDialog(true);
    setLoading(true);
    const { skuId, note, quantity } = formData;
    try {
      await OrderApi.create({
        skuId,
        note,
        quantity,
      });
      reset();
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading();
      dispatch(setCredit(newBalance));
    }
  };

  const handleClose = () => {
    setLoading();
    setSuccessDialog();
    setConfirmationDialog();
    setErrorDialog();
    setExpiredDialog();
  };

  return (
    <>
      <Card className={classes.cardstyle}>
        <form className={classes.cardstyle} onSubmit={handleSubmit(handleOnClick)}>
          <CardContent>
            <Grid container>
              <Grid xs={12} md={12}>
                <Select
                  name="skuId"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={new URLSearchParams(window.location.search).get("sku")}
                  labelPlacement="left"
                  required
                  className={classes.root}
                  label={t("Labels.SKU Name")}
                  error={errors.skuId}
                  helperText={t("HelperText.Please select SKU")}
                  fullWidth
                  tooltipText={t("HelperText.A unique number to identify and track your orders")}
                >
                  {skuList.map(sku => {
                    return <MenuItem key={`sku_name_${sku.id}`} value={sku.id}>{`${sku.name} - ${sku.sku}`}</MenuItem>;
                  })}
                </Select>
                <TextField
                  labelPlacement="left"
                  required
                  inputRef={register({ required: true })}
                  name="quantity"
                  label={t("Labels.Quantity")}
                  type="number"
                  error={errors.quantity}
                  helperText={t("HelperText.Please fill in quantity")}
                  tooltipText={t("Tooltips.The amount of codes you wish to generate")}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions className={classNames(classes.root, classes.actionButton)}>
            <Button type="submit" disabled={loading || expired || demo} loading={loading} buttonText={t("Labels.Generate Codes")} />
          </CardActions>
        </form>
      </Card>

      <SuccessDialog
        header={t("Labels.Awesome!")}
        image={require("assets/img/codesBeingGenerated.png")}
        descriptions={[t("Messages.Your codes are currently being generated"), t("Messages.You can download them once they are ready")]}
        buttonText={t("Labels.Continue")}
        handleClose={handleClose}
        open={successDialog}
      />

      <ConfirmationDialog
        header={t("Labels.Generating Codes")}
        image={require("assets/img/generatingCodes.png")}
        descriptions={[
          `${quantity} ` + t("Messages.codes will be deducted from your wallet"),
          t("Messages.Balance will be") + ` ${formatNumber(newBalance)} ` + t("Messages.codes, Do you wish to continue?"),
        ]}
        submitText={t("Labels.Yes")}
        cancelText={t("Labels.Cancel")}
        handleClose={handleClose}
        onClick={saveForm}
        open={confirmationDialog}
      />

      <ErrorDialog
        header={t("Labels.Oh, No")}
        image={require("assets/img/insufficientBalance.png")}
        descriptions={[t("Messages.Insufficient Balance!"), t("Messages.Upgrade your plan to continue generating codes")]}
        submitText={t("Labels.Let's Talk")}
        cancelText={t("Labels.Cancel")}
        handleClose={handleClose}
        open={errorDialog}
        onClick={handleLetsTalk}
      />

      <ExpiredDialog
        header={t("Labels.Your Free Trial Has Expired")}
        image={require("assets/img/codesExpired.png")}
        descriptions={[
          t("Messages.It looks like your code balance has already passed the") + ` ${planPeriod || 0} ` + t("Messages.days trial"),
          t("Messages.Contact our Customer Experience Executive for more information"),
        ]}
        submitText={t("Labels.Let's Talk")}
        cancelText={t("Labels.Cancel")}
        handleClose={handleClose}
        onClick={handleLetsTalk}
        open={expiredDialog}
      />
    </>
  );
}

export default Create;
