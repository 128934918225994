import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import SKUApi from "api/sku";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ palette }) => ({
  content: {
    border: `solid 1px ${palette.background.default}`,
    borderRadius: 4,
    padding: 0,
    margin: "8px 16px",
  },
  actions: {
    marginRight: 8,
  },
}));

function VerifyUrlForm({ open, data, setOpen }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { errors, register, handleSubmit, formState } = useForm({ reValidateMode: "onChange" });

  const onClose = () => {
    setOpen(false);
  };

  const saveForm = async ({ verify_url }, skipValidate = false) => {
    try {
      if (formState.isValid || skipValidate) {
        await SKUApi.update(data.id, { ...data, details: { ...data.details, verify_url: verify_url } });

        enqueueSnackbar(`${t("Labels.Trademark")} ${data.name} (${data.sku}) updated.`);
        onClose();
      }
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{t("Labels.Custom Verify URL")}</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(saveForm)}>
        <DialogContent className={classes.content}>
          <TextField
            label={t("Labels.URL")}
            name="verify_url"
            inputRef={register({
              required: true,
              pattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
            })}
            defaultValue={data?.details?.verify_url}
            labelPlacement="left"
            required
            error={errors.verify_url}
            helperText={t("Messages.Invalid URL format")}
            tooltipText={`${t("Tooltips.Example")}: https://${t("Tooltips.my-custom-sku-landing-page")}.${t("Tooltips.com")}`}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            variant="outlined"
            buttonText={t("Labels.Use Default")}
            onClick={e => {
              saveForm({}, true);
              e.stopPropagation();
            }}
          />
          <Button type="submit" />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default VerifyUrlForm;
