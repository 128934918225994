export default ({ palette }) => ({
  paper: {
    paddingTop: 34,
    paddingLeft: 20,
    marginRight: 6,
    height: 137,
    borderRadius: 3,
    border: `solid 3px ${palette.background.default}`,
    position: "relative",
    transition: "border-color 1s",
    "&:hover": {
      borderColor: palette.primary.main,
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 1.63,
  },
  title: {
    fontSize: 34,
    lineHeight: 1.53,
  },
  details: {
    display: "flex",
  },
  icon: {
    position: "absolute",
    right: 15.2,
    bottom: 13,
  },
});
