export default ({ palette }) => ({
  card: {
    marginBottom: 12,
    marginTop: 12,
  },
  cardContent: {
    border: `solid 1px ${palette.background.default}`,
    borderRadius: 4,
  },
  cardHeader: {
    backgroundColor: palette.background.default,
    padding: 0,
    cursor: "pointer",
  },
  action: {
    margin: 0,
  },
  hide: {
    visibility: "hidden",
  },
});
