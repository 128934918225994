import { IconButton, makeStyles, Slide } from "@material-ui/core";
import { CheckRounded, ClearRounded, KeyboardArrowRightRounded } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LogisticAPI from "api/logistic";

const useStyles = makeStyles(() => ({
  alert: {
    alignItems: "center",
    textAlign: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  pointer: {
    cursor: "pointer",
  },
}));

function Notification({ delivered, logEnded, verifiedBusiness, verifiedTrademark }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = useState();
  const [show, setShow] = useState();
  const [itemDelivered, setItemDelivered] = useState(delivered);
  const [ended, setEnded] = useState(logEnded);

  const onConfirmed = () => {
    setShow();
    const query = new URLSearchParams(window.location.search);
    const address = query.get("a") || query.get("address");
    if (!itemDelivered) {
      LogisticAPI.arrived(address);
      setTimeout(() => {
        setItemDelivered(true);
        setConfirmation();
      }, 1000);
    } else if (!ended) {
      LogisticAPI.endSensorLogging(address);
      setTimeout(() => {
        setEnded(true);
        setConfirmation();
      }, 1000);
    }
  };

  useEffect(() => {
    setTimeout(() => setShow(!itemDelivered || !ended || !verifiedBusiness || !verifiedTrademark), 1000);
  }, [itemDelivered, ended, verifiedBusiness, verifiedTrademark]);

  return show ? (
    <div id="sticky">
      <Slide in={show} direction={show ? "right" : "left"}>
        <Alert
          classes={{ root: classNames(classes.alert, { [classes.pointer]: !confirmation && (!itemDelivered || !ended) }) }}
          variant="filled"
          severity={confirmation ? "warning" : !itemDelivered || !ended ? "info" : "error"}
          action={
            confirmation ? (
              <>
                <IconButton color="inherit" size="small" onClick={onConfirmed}>
                  <CheckRounded />
                </IconButton>
                <IconButton color="inherit" size="small" onClick={() => setConfirmation()}>
                  <ClearRounded />
                </IconButton>
              </>
            ) : (
              (!itemDelivered || !ended) && (
                <IconButton color="inherit" size="small" onClick={() => setConfirmation(true)}>
                  <KeyboardArrowRightRounded />
                </IconButton>
              )
            )
          }
          onClick={() => !confirmation && (!itemDelivered || !ended) && setConfirmation(true)}
        >
          {confirmation
            ? t("Are you sure?")
            : !itemDelivered
            ? t("Click here to mark item as arrived")
            : !ended
            ? t("Click here to end sensor logging")
            : t(`This product's ${!verifiedBusiness ? "business" : "trademark"} is currently under the verification process`)}
        </Alert>
      </Slide>
    </div>
  ) : null;
}

export default Notification;
