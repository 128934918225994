import api from ".";

const path = "/tag";

const download = (id, params) => api.get(`${path}/download/${id}`, { params });

const preview = a => api.get(`${path}/preview`, { params: { a } });

const activate = data => api.post(`${path}/activate`, data);

const redirect = a => api.get(`${path}/redirect`, { params: { a } });

const verify = (a, p) => api.get(`${path}/verify`, { params: { a, p } });

const getCustomization = a => api.get(`${path}/customization`, { params: { a } });

export default {
  download,
  preview,
  activate,
  redirect,
  verify,
  getCustomization,
};
