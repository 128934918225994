import { Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PayloadApi from "api/payload";
import mtoolbarStyle from "assets/jss/components/MTableToolbar";
import Button from "components/Button";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import DownloadDialog from "./DownloadDialog";
import PayloadDialog from "components/Dialog/PayloadTable";

const useToolbarStyle = makeStyles(mtoolbarStyle);
const Container = props => <Paper elevation={0} {...props} />;

function Table({ data, skuList }) {
  const { t } = useTranslation();

  const [codeList, setCodeList] = useState([]);
  const [startDownload, setStartDownload] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openDownloadDialog, setOpenDownloadDialog] = useState();
  const [csvFileName, setCsvFileName] = useState("codes.csv");
  const [activatedList, setActivatedList] = useState([]);
  const csvRef = useRef();
  const { palette } = useTheme();
  const toolbarClasses = useToolbarStyle();
  const [payloadFK, setPayloadFK] = useState({});

  const Title = () => <Typography variant="h2">{t("Labels.Order History")}</Typography>;

  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  const handleOpen = (event, rowData) => {
    setSelectedRowData(rowData);
    setOpenDownloadDialog(true);
  };

  const handleClose = () => setOpenDownloadDialog();

  useEffect(() => {
    if (startDownload) {
      setStartDownload();
      csvRef.current.link.click();
    }
  }, [codeList, startDownload]);

  useEffect(() => {
    PayloadApi.activated().then(res => {
      setActivatedList(res);
    });
  }, []);

  useEffect(() => {
    if (activatedList?.map(({ activated, total }) => total > 0 && total !== activated)?.reduce((a, b) => a && b, true)) {
      const interval = setInterval(() => {
        PayloadApi.activated().then(res => {
          setActivatedList(res);
        });
      }, 5000);
      return () => {
        //DISCONNECT
        clearInterval(interval);
      };
    }
  }, [activatedList]);

  return (
    <React.Fragment>
      <MaterialTable
        title={Title()}
        options={{
          toolbar: true,
          draggable: false,
          detailPanelColumnAlignment: "left",
          selection: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
            border: "3px solid",
            borderColor: palette.background.default,
          },
          rowStyle: {
            border: "3px solid",
            borderColor: palette.background.default,
          },
          cellStyle: {
            borderBottom: 0,
          },
        }}
        components={{
          Toolbar,
          Container,
        }}
        columns={[
          {
            title: t("Labels.Date Created"),
            align: "center",
            field: "createdAt",
            grouping: false,
            defaultSort: "desc",
            render: rowData =>
              new Date(rowData.createdAt).toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
          },
          {
            title: t("Labels.Order ID"),
            align: "center",
            field: "order_id",
            grouping: false,
          },
          {
            title: t("Labels.SKU"),
            align: "center",
            field: "skuId",
            lookup: skuList.reduce((obj, sku) => {
              obj[sku.id] = `${sku.name} - ${sku.sku}`;
              return obj;
            }, {}),
          },
          {
            title: t("Labels.Quantity"),
            align: "center",
            field: "quantity",
            grouping: false,
          },
          {
            title: t("Labels.Activated"),
            align: "center",
            field: "id",
            searchable: false,
            grouping: false,
            render: ({ id: orderId, quantity }) => (
              <Button
                variant="outlined"
                buttonText={`${activatedList.find(i => i.orderId === orderId)?.activated || 0}/${quantity || 0}`}
                loading={!quantity}
                loadingText={t("Messages.Fetching data")}
                onClick={e => setPayloadFK({ orderId })}
              />
            ),
          },
          {
            title: t("Labels.Download Codes"),
            align: "center",
            field: "action",
            sorting: false,
            render: rowData => <Button variant="outlined" onClick={e => handleOpen(e, rowData)} buttonText={t("Labels.Download Codes")} />,
          },
        ]}
        data={data}
      />
      <PayloadDialog open={Object.keys(payloadFK)?.length} handleClose={() => setPayloadFK({})} {...payloadFK} />
      <DownloadDialog
        open={openDownloadDialog}
        handleClose={handleClose}
        data={selectedRowData}
        activated={activatedList.find(i => i.orderId === selectedRowData?.id)?.total}
        setCodeList={setCodeList}
        setCsvFileName={setCsvFileName}
        setStartDownload={setStartDownload}
      />
      <CSVLink data={codeList} filename={csvFileName} ref={csvRef} target="_blank" rel="noopener noreferrer" />
    </React.Fragment>
  );
}

export default Table;
