import PrivateRoute from "components/PrivateRoute";
import React from "react";
import { Redirect, Route } from "react-router-dom";

export const createRoutes = routes => {
  return routes.map(({ divider, redirect, path, to, component, layout, private: priv, admin, exact, disableRoute, children }, key) => {
    if (divider) return null;
    if (redirect) return <Redirect from={path} to={to} key={key} />;

    const Component = createComponent(component, layout);
    let route = disableRoute ? null : priv ? (
      <PrivateRoute exact={exact} path={path} component={Component} key={key} admin={admin} />
    ) : (
      <Route exact={exact} path={path} component={Component} key={key} />
    );
    if (children && children.length) {
      return [createRoutes(children), route];
    }
    return route;
  });
};

const createComponent = (Component, Layout) => {
  return props =>
    Layout ? (
      <Layout>
        <Component {...props} />
      </Layout>
    ) : (
      <Component {...props} />
    );
};
