import { Card, CardContent, CardHeader, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import UserApi from "api/user";
import cardHeaderStyle from "assets/jss/components/cardHeader";
import landingStyle from "assets/jss/views/landing";
import A from "components/A";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setToken } from "redux/actions/user";
import RegExpPattern, { toSnakeCase } from "utils";

const useLandingStyles = makeStyles(landingStyle);
const useCardHeaderStyles = makeStyles(cardHeaderStyle);

function SignUp({ history }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const landingClasses = useLandingStyles();
  const cardHeaderClasses = useCardHeaderStyles();
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState();

  const onSubmit = data => {
    setLoading(true);
    data.code = toSnakeCase(data.businessName);
    data.country = "my";
    UserApi.registerMerchant(data)
      .then(res => {
        dispatch(setToken(res.jwt));
        history.push("/onboarding");
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => setLoading());
  };

  return (
    <form>
      <Card variant="outlined" className={landingClasses.card}>
        <CardHeader
          title={t("Labels.Register")}
          className={landingClasses.title}
          titleTypographyProps={{
            variant: "h4",
          }}
          classes={cardHeaderClasses}
        />
        <CardContent>
          <TextField
            inputRef={register({ required: true, pattern: RegExpPattern.email() })}
            name="email"
            label={t("Labels.Email")}
            className={landingClasses.textfield}
          />
          <TextField
            inputRef={register({ required: true })}
            name="password"
            label={t("Labels.Password")}
            className={landingClasses.textfield}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(showPassword => !showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <TextField
            inputRef={register({ required: true })}
            name="passwordConfirm"
            label={t("Labels.Re-Enter password")}
            className={landingClasses.textfield}
            type="password"
          />
          <TextField
            inputRef={register({ required: true })}
            name="businessName"
            label={t("Labels.Company Name")}
            className={landingClasses.textfield}
          />
          <Button
            loading={loading}
            loadingText={t("Messages.Signing you up")}
            buttonText={t("Labels.Register")}
            type="submit"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          />
          {t("HelperText.By clicking register, you agree to our")}&nbsp;
          <NavLink to="/terms-of-service" className={landingClasses.link}>
            {t("Labels.Terms of Service")}
          </NavLink>
          &nbsp;{t("HelperText.and that you have read our")}&nbsp;
          <A href="https://www.luxtag.io/privacy-policy/">{t("Labels.Privacy Policy")}</A>
        </CardContent>
      </Card>
    </form>
  );
}

export default SignUp;
