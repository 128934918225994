import { Fab, makeStyles } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import sidebarStyle from "assets/jss/components/sidebarStyle";
import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles(sidebarStyle);

const CollapseButton = ({ onDrawerOpen, open }) => {
  const classes = useStyles();
  return (
    <Fab size="small" className={classes.menuButton} onClick={onDrawerOpen}>
      <ChevronLeft
        color="primary"
        fontSize="large"
        className={classNames(classes.menuButtonIcon, {
          [classes.menuButtonIconClosed]: !open,
        })}
      />
    </Fab>
  );
};

export default withRouter(CollapseButton);
