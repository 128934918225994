import { Dialog, DialogActions, DialogContent, Grid, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import UserApi from "api/user";
import Button from "components/Button";
import Select from "components/Select";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import PapaParse from "papaparse";
import React, { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RegExpPattern, { toSnakeCase } from "utils";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: 0,
  },
  button: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  dragndrop: {
    height: "100%",
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: palette.color.primary,
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  bold: {
    fontWeight: 600,
  },
  semiTrans: {
    opacity: 0.5,
  },
  dropzoneText: {
    padding: 6,
  },
}));

function AddUser({ onUpdate }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, errors, control, handleSubmit, watch } = useForm();

  const [editDialogOpen, setEditDialogOpen] = useState();
  const [csvDialogOpen, setCsvDialogOpen] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const password = useRef({});
  password.current = watch("password", "");
  const [submittingForm, setSubmittingForm] = useState();

  const submitForm = async data => {
    setSubmittingForm(true);

    const companyCode = toSnakeCase(data.businessName);
    var parameters = Object.assign(data, {
      code: companyCode,
      country: "my",
    });

    //Api Call to save data
    try {
      await UserApi.register(parameters.role, parameters);
      enqueueSnackbar("User Added!");
      setSubmittingForm();
      closeEditForm();

      //reload page and update the table with new data
      onUpdate();
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      setSubmittingForm();
    }
  };

  const createBatchUser = useCallback(
    async data => {
      //Loop API call
      for (var i = 0; i < data.length; i++) {
        var parameters = data[i];
        try {
          await UserApi.register(parameters.role, parameters);
          enqueueSnackbar(`User ${parameters.name} added!`);
        } catch (error) {
          enqueueSnackbar(`Error at row ${i + 2} : ${error}`, {
            variant: "error",
          });
          break;
        }

        setSubmittingForm();
        closeCsvForm();
        //reload page and update the table with new data
        onUpdate();
      }
    },
    [enqueueSnackbar, onUpdate]
  );

  function openEditForm() {
    setEditDialogOpen(true);
  }

  function closeEditForm() {
    setEditDialogOpen();
  }

  function openCsvForm() {
    setCsvDialogOpen(true);
  }

  function closeCsvForm() {
    setCsvDialogOpen();
    setUploadedFiles([]);
  }

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        PapaParse.parse(acceptedFiles[0], {
          header: true,
          skipEmptyLines: "greedy",
          complete: results => {
            const resultData = results.data;
            var validKeys = false;

            //Preliminary check if required keys are available before uploading
            if (
              resultData[0].hasOwnProperty("name") &&
              resultData[0].hasOwnProperty("role") &&
              resultData[0].hasOwnProperty("email") &&
              resultData[0].hasOwnProperty("businessName") &&
              resultData[0].hasOwnProperty("password")
            ) {
              validKeys = true;
            }

            if (validKeys) {
              //Append company code and country to the parameter in the array
              var finalData = resultData.map(obj => {
                var companyCode = toSnakeCase(obj.businessName);
                var parameter = Object.assign(obj, {
                  code: companyCode,
                  country: "my",
                });

                return parameter;
              });
              createBatchUser(finalData);
            } else {
              //Keys are invalid
              enqueueSnackbar("Expected column is missing! Please check header column names", {
                variant: "error",
              });
            }
          },
        });
        setUploadedFiles(acceptedFiles);
      }
    },
    [createBatchUser, enqueueSnackbar]
  );

  const { getRootProps, getInputProps, fileRejections, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
    multiple: false,
  });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={2} xl={2}>
          <Button className={classes.button} buttonText={t("Labels.Add User")} fullWidth onClick={openEditForm} />
        </Grid>

        <Grid item lg={2} xl={2}>
          <Button className={classes.button} buttonText={t("Labels.Add CSV")} variant="outlined" fullWidth onClick={openCsvForm} />
        </Grid>
      </Grid>

      <Dialog fullWidth open={editDialogOpen}>
        <DialogContent>
          <Typography variant="h1" className={classes.cardNo}>
            {t("Labels.Add User Details")}
          </Typography>

          <form>
            <TextField
              labelPlacement="left"
              name="name"
              required
              inputRef={register({ required: true })}
              label={t("Labels.Name")}
              error={errors.name}
              helperText={t("HelperText.User.Please fill in name")}
            />

            <Select
              name="role"
              control={control}
              rules={{ required: true }}
              defaultValue="merchant"
              labelPlacement="left"
              inputRef={register}
              label={t("Labels.Role")}
              fullWidth
            >
              <MenuItem key={1} value="merchant">
                Merchant
              </MenuItem>
              <MenuItem key={2} value="dealer">
                Dealer
              </MenuItem>
              <MenuItem key={3} value="customerservice">
                Customer Service
              </MenuItem>
            </Select>

            <TextField
              labelPlacement="left"
              name="email"
              type="email"
              required
              inputRef={register({
                required: true,
                pattern: RegExpPattern.email(),
              })}
              label={t("Labels.Email")}
              error={errors.email}
              helperText={t("HelperText.User.Please fill in valid email")}
            />

            <TextField
              labelPlacement="left"
              name="businessName"
              required
              className={classes.textfield}
              inputRef={register({ required: true })}
              label={t("Labels.Company")}
              error={errors.company}
              helperText={t("HelperText.User.Please fill in Company Name")}
            />

            <TextField
              labelPlacement="left"
              name="password"
              type="password"
              required
              inputRef={register({ required: true })}
              label={t("Labels.Password")}
              error={errors.password}
              helperText={t("HelperText.User.Please fill in Password")}
            />

            <TextField
              labelPlacement="left"
              name="retypePassword"
              type="password"
              required
              inputRef={register({
                required: true,
                validate: value => value === password.current || t("Messages.The passwords do not match"),
              })}
              label={t("Labels.Re-type Password")}
              error={errors.retypePassword}
              helperText={errors.retypePassword?.message || t("HelperText.User.Please retype password")}
            />

            <DialogActions>
              <Button buttonText={t("Labels.Cancel")} onClick={closeEditForm} variant="outlined" />

              <Button loading={submittingForm} buttonText={t("Labels.User.Submit")} type="submit" onClick={handleSubmit(submitForm)} />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={csvDialogOpen}>
        <DialogContent>
          <Typography variant="h1" className={classes.cardNo}>
            {t("Labels.Add User Details CSV")}
          </Typography>

          <div className={classes.dragndrop} {...getRootProps()}>
            <input {...getInputProps()} />
            <Typography variant="h3" className={(classes.bold, classes.dropzoneText)}>
              {isDragActive ? t("Instructions.Drop it like it's hot!") : t("HelperText.Drag and Drop CSV file here")}
            </Typography>
            <Typography variant="h3" className={(classes.bold, classes.dropzoneText)}>
              {t("Labels.OR")}
            </Typography>
            <Button component="span" buttonText={t("Labels.Upload")} />
            <Typography variant="h3" className={(classes.semiTrans, classes.dropzoneText)}>
              {uploadedFiles.length > 0
                ? uploadedFiles.map(acceptedFile => t("Labels.File selected") + ": " + acceptedFile.name)
                : t("HelperText.Max File 10MB")}
            </Typography>
            {fileRejections.length > 0 && <Typography text={fileRejections[0].errors[0].message} />}
          </div>

          <DialogActions>
            <Button loading={submittingForm} buttonText={t("Labels.Cancel")} onClick={closeCsvForm} variant="outlined" />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddUser;
