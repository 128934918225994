import { FilledInput, FormHelperText, Select, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/textfield";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
  },
  left: {
    display: "flex",
  },
  leftLabel: {
    width: 150,
    alignSelf: "center",
  },
  topLabel: {
    height: 23,
    lineHeight: 1.63,
  },
  fields: {
    overflow: "hidden",
    width: "100%",
    marginBottom: 6,
  },
  help: {
    marginLeft: 4,
  },
}));

const useTextFieldStyles = makeStyles(textfieldStyles);

const TextField = ({
  label,
  value,
  onChange,
  required,
  tooltipText,
  labelPlacement,
  helperText,
  name,
  control,
  rules,
  defaultValue,
  children,
  labelProps,
  variant,
  ...props
}) => {
  const classes = useStyles();
  const textfieldClasses = useTextFieldStyles();
  const [selected, setSelected] = useState(value);

  const onSelectChange = event => {
    setSelected(event.target.value);
    if (onChange) onChange(event);
  };

  return (
    <div
      className={classNames({
        [classes.root]: labelPlacement,
        [classes.left]: labelPlacement === "left",
      })}
    >
      {labelPlacement && (
        <Typography
          variant={variant || "h5"}
          gutterBottom
          className={classNames(classes.label, {
            [classes.leftLabel]: labelPlacement === "left",
            [classes.topLabel]: labelPlacement === "top",
          })}
          {...labelProps}
        >
          {label}
          {required && "*"}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Help fontSize="small" className={classes.help} />
            </Tooltip>
          )}
        </Typography>
      )}
      <div className={classes.fields}>
        {control ? (
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            as={
              <Select value={selected} onChange={onSelectChange} variant="filled" input={<FilledInput classes={textfieldClasses} />} {...props}>
                {children}
              </Select>
            }
          />
        ) : (
          <Select
            name={name}
            value={value}
            onChange={onChange}
            variant="filled"
            input={<FilledInput classes={textfieldClasses} />}
            defaultValue={defaultValue}
            {...props}
          >
            {children}
          </Select>
        )}
        {props.error && <FormHelperText {...props}>{helperText}</FormHelperText>}
      </div>
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["top", "left"]),
};

export default TextField;
