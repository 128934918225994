import api from ".";

const path = "/business";

const get = () => api.get(`${path}`);

const getProgress = () => api.get(`${path}/progress`);

const create = data => api.post(`${path}`, data);

const update = data => api.put(`${path}`, data);

const remove = id => api.delete(`${path}/${id}`);

const getAll = () => api.get(`${path}/all`);

const updateAdmin = (id, data) => api.put(`${path}/${id}`, data);

export default {
  get,
  getProgress,
  create,
  update,
  remove,
  getAll,
  updateAdmin,
};
