import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import BillingTable from "components/BillingTable";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";

function Billing({ open, merchant, handleClose, plans, onOpen, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth={"md"} open={onOpen}>
      <DialogTitle>
        <Typography variant="h1">{t("Business Account Billing Settings and History")}</Typography>
      </DialogTitle>
      <DialogContent fullWidth maxWidth="md" open={open} scroll="body" onClose={handleClose}>
        <BillingTable merchant={merchant} plans={plans} />
      </DialogContent>
      <DialogActions style={{ paddingRight: 24 }}>
        <Button loadingText={t("Messages.Loading")} buttonText={t("Labels.Close")} onClick={onClose} variant="outlined" />
      </DialogActions>
    </Dialog>
  );
}

export default Billing;
