import {
  Box,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Check, Clear, EventNoteOutlined, MoreHoriz, Refresh } from "@material-ui/icons";
import { apiURL } from "api";
import BusinessApi from "api/business";
import PlanApi from "api/plan";
import A from "components/A";
import PageContent from "components/PageContent";
import MaterialTable, { MTableToolbar } from "material-table";
import Image from "material-ui-image";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BillingDialog from "./Billing";
import CustomButton from "components/Button";

const useStyles = makeStyles(({ spacing, palette }) => ({
  row: {
    paddingBottom: 12,
  },
  detailImage: {
    maxWidth: 150,
    maxHeight: 150,
    borderRadius: 3,
    border: `solid 3px ${palette.background.default}`,
    padding: spacing(1),
    margin: spacing(1),
  },
  detailRoot: {
    padding: spacing(1),
    margin: spacing(1),
  },
  detailKey: {
    fontWeight: "bold",
  },
  itemIcon: {
    minWidth: 40,
  },
  billing: {
    width: 700,
  },
}));

const keyToDisplayMap = {
  address: "Company Address",
  contactNo: "Contact Number",
  email: "Company Email",
  country: "Country",
  postcode: "Postcode",
  verified_by: "Verified By",
};

const Container = props => <Paper elevation={0} {...props} />;

function Business() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState();
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [business, setBusiness] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [billingDialogOpen, setBillingDialogOpen] = useState();
  const [plans, setPlans] = useState([]);
  const { palette, breakpoints } = useTheme();
  const isSM = useMediaQuery(breakpoints.up("sm"));

  useEffect(() => {
    BusinessApi.getAll().then(res => {
      setBusiness(res);
    });
    PlanApi.getAll().then(res => {
      setPlans(res);
    });
  }, []);

  const onHandleReloadTable = async () => {
    setRefreshLoading(true);
    try {
      const res = await BusinessApi.getAll();
      setBusiness(res);
      enqueueSnackbar("Table Reloaded", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setRefreshLoading(false);
    }
  };

  function openBillingDialog() {
    handleClose();
    setBillingDialogOpen(true);
  }

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData);
    event.stopPropagation();
  };

  const handleClose = () => setAnchorEl();

  const handleApprove = () => {
    const { id, registration_no, details } = selectedRowData;
    details.verified = !details.verified;
    BusinessApi.updateAdmin(id, { registration_no, details })
      .then(res => {
        enqueueSnackbar(res.status);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => {
        setAnchorEl();
        BusinessApi.getAll().then(res => {
          setBusiness(res);
        });
      });
  };

  return (
    <PageContent header={t("Labels.Manage Businesses")}>
      <Grid container>
        <Grid item className={classes.row} xs={12}>
          <MaterialTable
            columns={[
              {
                title: t("Labels.Business Name"),
                field: "name",
                render: ({ name, merchant }) => name || merchant,
              },
              { title: t("Labels.Registration Number"), field: "registration_no" },
              {
                title: t("Labels.Approved"),
                field: "details",
                render: rowData => (rowData?.details?.verified ? <Check fontSize="small" /> : <Clear fontSize="small" />),
              },
              {
                title: t("Billing Status"),
                field: "billing_status",
                render: rowData => {
                  if (rowData.isActive) {
                    return (
                      <Chip
                        label={t("Active")}
                        color="success"
                        size="small"
                        style={{ paddingInline: "1rem", background: "rgb(102, 187, 106)", color: "#FFF", fontWeight: 600 }}
                      />
                    );
                  }
                  return (
                    <Chip
                      label={t("Inactive")}
                      color="secondary"
                      size="small"
                      style={{ paddingInline: "1rem", background: "red", color: "#FFF", fontWeight: 600 }}
                    />
                  );
                },
              },
              {
                title: t("Labels.Document"),
                field: "details",
                render: ({ details }) => {
                  if (details?.documents) {
                    const { hash, ext, name } = details?.documents;
                    return <A href={`${apiURL}/files/${hash}${ext}`}>{name}</A>;
                  } else {
                    return "";
                  }
                },
              },
              {
                title: t("Labels.Action"),
                field: "action",
                sorting: false,
                render: rowData => (
                  <IconButton onClick={e => handleClick(e, rowData)}>
                    <MoreHoriz />
                  </IconButton>
                ),
              },
            ]}
            data={business}
            title=""
            options={{
              toolbar: true,
              draggable: false,
              detailPanelColumnAlignment: "left",
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: palette.background.default,
              },
            }}
            components={{
              Container,
              Toolbar: props => {
                return (
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box>
                      <CustomButton
                        loading={refreshLoading}
                        loadingText=""
                        startIcon={!refreshLoading && isSM ? <Refresh /> : null}
                        buttonText={isSM ? "Reload Table" : <Refresh />}
                        onClick={onHandleReloadTable}
                      />
                    </Box>
                    <MTableToolbar {...props} />
                  </Box>
                );
              },
            }}
            detailPanel={[
              {
                render: rowData => {
                  let fileSrc = "";
                  if (rowData?.details?.documents) {
                    const { hash, ext } = rowData.details.documents;
                    fileSrc = `${apiURL}/files/${hash}${ext}`;
                  }
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={2}>
                        {fileSrc !== "" &&
                          (fileSrc.endsWith(".pdf") ? (
                            <embed src={fileSrc} width="200px" height="140px" />
                          ) : (
                            <Image src={fileSrc} className={classes.detailImage} />
                          ))}
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.detailRoot}>
                          {rowData.details &&
                            Object.keys(rowData.details).map(
                              (d, i) =>
                                keyToDisplayMap.hasOwnProperty(d) && (
                                  <Grid container key={i}>
                                    <Grid item xs={4}>
                                      <Typography className={classes.detailKey}>{keyToDisplayMap[d]}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>{rowData.details[d]}</Typography>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                        </div>
                      </Grid>
                    </Grid>
                  );
                },
              },
            ]}
          />
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
          >
            {selectedRowData?.details && (
              <MenuItem onClick={handleApprove}>
                <ListItemIcon className={classes.itemIcon}>
                  {selectedRowData?.details?.verified ? <Clear fontSize="small" /> : <Check fontSize="small" />}
                </ListItemIcon>
                <ListItemText primary={selectedRowData?.details?.verified ? t("Labels.Unapprove") : t("Labels.Approve")} />
              </MenuItem>
            )}
            <MenuItem onClick={openBillingDialog}>
              <ListItemIcon className={classes.itemIcon}>
                <EventNoteOutlined />
              </ListItemIcon>
              <ListItemText>{t("Account & Billing Control")}</ListItemText>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <BillingDialog
        className={classes.billing}
        merchant={selectedRowData.merchant}
        plans={plans}
        onClose={() => setBillingDialogOpen()}
        onOpen={billingDialogOpen}
      />
    </PageContent>
  );
}

export default Business;
