import Button from "components/Button";
import PageContent from "components/PageContent";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Create from "./Create";
import Table from "./Table";

function Forms() {
  const { t } = useTranslation();
  const [data, setData] = useState();

  return (
    <PageContent header={t(`Labels.${data ? (data.id ? t("Edit Form") : t("Create Form")) : t("Manage Forms")}`)}>
      {data ? (
        <Create data={data} resetData={() => setData()} />
      ) : (
        <>
          <Button onClick={() => setData([])} buttonText={t("Labels.Create Form")} />
          <Table setData={setData} />
        </>
      )}
    </PageContent>
  );
}

export default Forms;
