import { Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PayloadApi from "api/payload";
import mtoolbarStyle from "assets/jss/components/MTableToolbar";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import PayloadDialog from "components/Dialog/PayloadTable";

const useToolbarStyle = makeStyles(mtoolbarStyle);
const Container = props => <Paper elevation={0} {...props} />;

function Table({ refresh }) {
  const { t } = useTranslation();

  const [activatedList, setActivatedList] = useState([]);
  const [data, setData] = useState({});

  const { palette } = useTheme();
  const toolbarClasses = useToolbarStyle();

  const Title = () => <Typography variant="h2">{t("Labels.Activation History")}</Typography>;

  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  useEffect(() => {
    PayloadApi.activateBatch().then(res => {
      setActivatedList(res);
    });
  }, [refresh]);

  useEffect(() => {
    if (activatedList.find(i => i.status === "Activating")) {
      const interval = setInterval(() => {
        PayloadApi.activateBatch().then(res => {
          setActivatedList(res);
        });
      }, 5000);
      return () => {
        //DISCONNECT
        clearInterval(interval);
      };
    }
  }, [activatedList]);

  return (
    <>
      <MaterialTable
        title={Title()}
        options={{
          toolbar: true,
          draggable: false,
          detailPanelColumnAlignment: "left",
          selection: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
            border: "3px solid",
            borderColor: palette.background.default,
          },
          rowStyle: {
            border: "3px solid",
            borderColor: palette.background.default,
          },
          cellStyle: {
            borderBottom: 0,
          },
        }}
        components={{
          Toolbar,
          Container,
        }}
        columns={[
          {
            title: t("Labels.Date Created"),
            align: "center",
            field: "createdAt",
            grouping: false,
            defaultSort: "desc",
            render: rowData =>
              new Date(rowData.createdAt).toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
          },
          {
            title: t("Labels.Batch ID"),
            align: "center",
            field: "identifier",
            grouping: false,
          },
          {
            title: t("Labels.Quantity"),
            align: "center",
            field: "quantity",
            grouping: false,
            render: ({ quantity, id: activeBatchId }) => (
              <Button variant="outlined" buttonText={quantity} onClick={e => setData({ activeBatchId })} />
            ),
          },
          {
            title: t("Labels.Status"),
            align: "center",
            field: "status",
            grouping: false,
          },
          {
            title: t("Labels.Submission"),
            align: "center",
            render: ({ blockchain_payload }) => {
              if (blockchain_payload) {
                const custom_id_label = Object.keys(blockchain_payload).find(i => typeof blockchain_payload[i] === "object");
                const custom_id =
                  custom_id_label && Object.keys(custom_id_label).length > 0 ? Object.keys(blockchain_payload[custom_id_label])[0] : "";
                return custom_id_label + ": " + custom_id;
              } else return "-";
            },
          },
        ]}
        data={activatedList}
      />
      <PayloadDialog open={Object.keys(data)?.length} handleClose={() => setData({})} {...data} />
    </>
  );
}

export default Table;
