import { Paper } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
  },
}));

function Announcement() {
  const list = useSelector(_ => _.announcement.list);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      {list.length > 0 && (
        <Paper elevation={1}>
          {list.map((announcement, idx) => (
            <Alert key={`announcement_${idx}`} className={classes.root} variant="filled" severity={announcement.severity || "error"}>
              <AlertTitle>{t("Labels.Generate Code")}</AlertTitle>
              {t("Messages.You have missing fields")}
            </Alert>
          ))}
        </Paper>
      )}
    </div>
  );
}

export default Announcement;
