/* eslint-disable no-throw-literal */
import { Divider, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { ChevronRightRounded, DeleteRounded, EditRounded, FileCopyRounded, Language, MoreHoriz } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import SKUApi from "api/sku";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import A from "components/A";
import Button from "components/Button";
import Carousel from "components/Carousel";
import PageContent from "components/PageContent";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BrandCreate from "./Create";
import VerifyUrlForm from "./VerifyUrlDialog";
import { CSVLink } from "react-csv";
import InfoDialog from "components/Dialog/info";
import errorAnimation from "assets/json/no_idea.json";
import uploadedAnimation from "assets/json/file_uploaded.json";
import PapaParse from "papaparse";
import { certificates } from "constants.js";

const dynamicPropCount = 5;
const arrayOfCount = Array.from({ length: dynamicPropCount }, (_, i) => i + 1);

const headers = [
  { label: "Name" },
  { label: "SKU" },
  { label: "Trademark" },
  ...arrayOfCount.flatMap(i => [{ label: `Custom_Field_Key_${i}` }, { label: `Custom_Field_Value_${i}` }]),
  ...arrayOfCount.map(i => ({ label: `Image_Name_${i}` })),
  ...arrayOfCount.flatMap(i => [{ label: `Certificate_Name_${i}` }, { label: `Certificate_Type_${i}` }]),
];

const csvReport = {
  filename: "Bulk_SKU.csv",
  headers,
  data: [],
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: 24,
  },
  row: {
    paddingBottom: 12,
  },
  detail: {
    border: `3px solid ${palette.background.default}`,
    borderTop: "unset",
  },
  detailRoot: {
    padding: spacing(2),
  },
  detailKey: {
    fontWeight: 600,
    marginRight: 16,
  },
  subtitle: {
    paddingBottom: 10,
  },
}));

const useToolBarStyles = makeStyles(mtoolbarstyle);

const Container = props => <Paper elevation={0} {...props} />;

function ManageSKU() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState();
  const [openCreate, setOpenCreate] = useState();
  const [openVerifyUrl, setOpenVerifyUrl] = useState();
  const [skuList, setSkuList] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openInfoDialog, setOpenInfoDialog] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();
  const toolbarClasses = useToolBarStyles();
  const { demo } = useSelector(_ => _.user);
  const csvRef = useRef();
  const uploadCsv = useRef();

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData);
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpenInfoDialog();
    setAnchorEl();
    setSuccess();
  };

  const handleEdit = () => {
    setOpenCreate(true);
    handleClose();
  };

  const handleActivation = async () => {
    handleClose();
    try {
      const active = !selectedRowData.active;
      await SKUApi.update(selectedRowData.id, { ...selectedRowData, active });
      enqueueSnackbar(`${t("Labels.Trademark")} ${selectedRowData.name} (${selectedRowData.sku}) ${active ? "activated" : "deactivated"}.`);
      skuList[skuList.findIndex(i => i.id === selectedRowData.id)].active = active;
      setSkuList(skuList);
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
  };

  const handleVerifyUrl = e => {
    handleClose();
    setOpenVerifyUrl(true);
    e.stopPropagation();
  };

  useEffect(() => {
    if (openCreate || openVerifyUrl) return;

    fetchSkuList();
  }, [openCreate, openVerifyUrl, demo]);

  const fetchSkuList = async () => {
    SKUApi.getAll().then(res => {
      setSkuList(res);
    });
  };

  const Title = () => <Typography variant="h2">{t("Labels.List of SKUs")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;
  const getArray = (details, order) => order || Object.keys(details);

  const handleDownloadTemplate = () => {
    csvRef.current.link.click();
  };

  const handleOnChange = e => {
    if (e.target.files[0]) {
      PapaParse.parse(e.target.files[0], {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          if (results.data.length > 0) {
            try {
              const skuList = results.data.map(({ Name, SKU, Trademark, ...rest }, i) => {
                const cfk = Object.entries(rest)
                  .filter(i => i[0].startsWith("Custom_Field_Key_") && i[1])
                  .map(i => [i[0].replace("Custom_Field_Key_", ""), i[1]]);
                const cfv = Object.entries(rest)
                  .filter(i => i[0].startsWith("Custom_Field_Value_") && i[1])
                  .map(i => [i[0].replace("Custom_Field_Value_", ""), i[1]]);
                const cfvwk = cfv.filter(i => cfk.findIndex(j => j[0] === i[0]) < 0);
                if (cfvwk.length) {
                  throw `[Row ${i + 2}] ${cfvwk.map(i => `Custom_Field_Value_${i[0]} is missing its Custom_Field_Key_${i[0]}`).join(". ")}`;
                }

                const cn = Object.entries(rest)
                  .filter(i => i[0].startsWith("Certificate_Name_") && i[1])
                  .map(i => [i[0].replace("Certificate_Name_", ""), i[1]]);
                const ct = Object.entries(rest)
                  .filter(i => i[0].startsWith("Certificate_Type_") && i[1])
                  .map(i => [i[0].replace("Certificate_Type_", ""), i[1]]);

                const nec = [...new Set(ct.map(i => i[1]).filter(i => !certificates.includes(i)))];
                if (nec.length) {
                  throw `Certificate Type ${nec.join(", ")} is not supported`;
                }
                const ctwn = ct.filter(i => cn.findIndex(j => j[0] === i[0]) < 0);
                if (ctwn.length) {
                  throw `[Row ${i + 2}] ${ctwn.map(i => `Certificate_Type_${i[0]} is missing its Certificate_Name_${i[0]}`).join(". ")}`;
                }
                return {
                  name: Name,
                  sku: SKU,
                  trademark: Trademark,
                  images: Object.entries(rest)
                    .filter(i => i[0].startsWith("Image_Name") && i[1])
                    .map(i => i[1]),
                  certificates: cn
                    .map(i => {
                      const extra = {};
                      const cert = ct.find(j => j[0] === i[0]);
                      if (cert) {
                        extra.type = cert[1];
                      }
                      return { name: i[1], ...extra };
                    })
                    .reduce((a, c) => [...a, c], []),
                  details: {
                    sku_details: cfk.map(i => ({ [i[1]]: cfv.find(j => j[0] === i[0])[1] })).reduce((a, c) => ({ ...a, ...c }), {}),
                  },
                };
              });

              if (skuList[0]) {
                SKUApi.createBulk(skuList)
                  .then(res => {
                    fetchSkuList();
                    setSuccess(true);
                  })
                  .catch(e => {
                    setErrorMessage(e);
                  })
                  .finally(() => {
                    setOpenInfoDialog(true);
                  });
              }
            } catch (e) {
              setErrorMessage(t(e));
              setOpenInfoDialog(true);
            }
          }
        },
      });
      e.currentTarget.value = null;
    }
  };

  const handleCsvUpload = () => {
    uploadCsv.current.click();
  };

  return (
    <PageContent header={t("Labels.Manage SKUs")} subheader={t("Labels.Add your product line here")}>
      <Grid container>
        <Grid item className={classes.row} xs={12}>
          <Button
            onClick={() => {
              setSelectedRowData({});
              setOpenCreate(true);
            }}
            buttonText={t("Labels.Add SKU")}
            endIcon={<ChevronRightRounded />}
          />
          <form style={{ display: "inline-block", margin: "0 8px" }}>
            <input accept=".csv" className={classes.input} style={{ display: "none" }} ref={uploadCsv} type="file" onChange={handleOnChange} />
            <Button variant="outlined" component="span" onClick={handleCsvUpload} buttonText={t("Labels.Upload CSV")} />
          </form>
          <Button variant="outlined" buttonText={t("Labels.Download CSV Template")} onClick={handleDownloadTemplate} />
          <CSVLink {...csvReport} ref={csvRef} target="_blank" rel="noopener noreferrer" />
        </Grid>
        <Grid item className={classes.row} xs={12}>
          <MaterialTable
            columns={[
              { title: t("Labels.Name"), field: "name" },
              { title: t("Labels.SKU"), field: "sku" },
              { title: t("Labels.Trademark Name"), field: "trademark.registration_name" },
              {
                title: t("Labels.Generate Code"),
                field: "generate_code",
                render: rowData => (
                  <Link
                    to={{
                      pathname: "generatecode",
                      search: `sku=${rowData.id}`,
                    }}
                  >
                    <Button variant="outlined" buttonText={t("Labels.Generate Codes")} />
                  </Link>
                ),
              },
              {
                title: t("Labels.Action"),
                field: "action",
                sorting: false,
                render: rowData => (
                  <IconButton onClick={e => handleClick(e, rowData)}>
                    <MoreHoriz></MoreHoriz>
                  </IconButton>
                ),
              },
            ]}
            data={skuList}
            title={Title()}
            options={{
              toolbar: true,
              draggable: false,
              detailPanelColumnAlignment: "left",
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: palette.background.default,
                border: "3px solid",
                borderColor: palette.background.default,
              },
              rowStyle: {
                border: "3px solid",
                borderColor: palette.background.default,
              },
              cellStyle: {
                borderBottom: 0,
              },
            }}
            components={{
              Container,
              Toolbar,
            }}
            detailPanel={[
              {
                render: rowData => {
                  let imagesSrc = rowData?.details?.images?.map(file => `${apiURL}/files/${file.hash}${file.ext}`) || [];
                  return (
                    <Grid className={classes.detail} container spacing={0}>
                      <Grid item xs={2}>
                        {!!imagesSrc.length && <Carousel data={imagesSrc} />}
                      </Grid>
                      <Grid className={classes.detailRoot} item xs={5}>
                        <Typography className={classes.detailKey}>
                          {Object.keys(rowData.details.sku_details).length ? t("Labels.Specifications") : ""}
                        </Typography>
                        <table>
                          <tbody>
                            {getArray(rowData.details.sku_details, rowData.details.sku_details_order).map(d => (
                              <tr>
                                <td>
                                  <Typography className={classes.detailKey}>{d}</Typography>
                                </td>
                                <td>
                                  <Typography>{rowData.details.sku_details[d]}</Typography>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Grid>
                      {!!rowData.certificates?.length && (
                        <Grid className={classes.detailRoot} item xs={5}>
                          <Typography className={classes.detailKey}>{rowData.certificates?.length ? t("Labels.Certificates") : ""}</Typography>

                          {rowData.certificates.map(({ name, type, hash, ext, status }, i) => (
                            <div style={{ display: "flex" }}>
                              {i + 1}.&nbsp;&nbsp;
                              <A href={`${apiURL}/files/${hash}${ext}`}>
                                {name} {type ? `(${type})` : ""}
                              </A>
                              &nbsp;
                              <Typography color="textSecondary">
                                -&nbsp;
                                {t(status ? `Labels.${status}` : "Labels.Pending Approval")}
                              </Typography>
                            </div>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  );
                },
              },
            ]}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <EditRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("Labels.Edit")} />
            </MenuItem>
            <Divider />
            <MenuItem disabled={demo || selectedRowData?.details?.customization?.hideDefaultButton} onClick={handleVerifyUrl}>
              <ListItemIcon>
                <Language fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("Labels.Verify URL")} />
            </MenuItem>
            <Divider />
            <MenuItem disabled onClick={handleClose}>
              <ListItemIcon>
                <FileCopyRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={t("Labels.Clone")} />
            </MenuItem>
            <Divider />
            <MenuItem disabled onClick={handleActivation}>
              <ListItemIcon>
                <DeleteRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={!selectedRowData.active ? t("Labels.Deactivate") : t("Labels.Activate")} />
            </MenuItem>
          </Menu>
          <BrandCreate
            title={selectedRowData.name ? t("Labels.Edit SKU") : t("Labels.Add SKU")}
            handleClose={() => setOpenCreate()}
            open={openCreate}
            data={selectedRowData}
          />
          <VerifyUrlForm open={openVerifyUrl} setOpen={setOpenVerifyUrl} data={selectedRowData} />
        </Grid>
      </Grid>
      <InfoDialog
        header={success ? t("Labels.Success") : t("Labels.Oh, No")}
        lottieAnimationData={success ? uploadedAnimation : errorAnimation}
        descriptions={
          success
            ? [t("Messages.Your data has been created succesfully!")]
            : [errorMessage || t("Messages.Something went wrong"), t("Messages.Please check your data again")]
        }
        buttonText={t("Labels.Close")}
        handleClose={handleClose}
        open={openInfoDialog}
      />
    </PageContent>
  );
}

export default ManageSKU;
