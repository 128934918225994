import PageContent from "components/PageContent";
import React, { useState } from "react";
import Customize from "./Customize";
import Table from "./Table";
import SKUApi from "api/sku";
import { useSnackbar } from "notistack";

function Customization() {
  const [data, setData] = useState();
  const [skus, setSKUs] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const refetch = async () => {
    try {
      const skus = await SKUApi.getAll();
      setData(skus.find(sku => sku.id === data.id));
    } catch (err) {
      enqueueSnackbar(err, {
        variant: "error",
      });
    }
  };
  return (
    <PageContent>
      {data ? <Customize data={data} skus={skus} refetch={refetch} resetData={() => setData()} /> : <Table setData={setData} setSKUs={setSKUs} />}
    </PageContent>
  );
}

export default Customization;
