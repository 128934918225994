import { Step, StepConnector, StepLabel, Stepper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React from "react";

const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
  },
  active: {
    "& $line": {
      borderColor: "#418dcb",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#418dcb",
    },
  },
  line: {
    borderColor: "#9e9e9e",
    borderWidth: 2,
  },
  vertical: {
    padding: 0,
    marginLeft: 9,
  },
})(StepConnector);

function Points({ data, sections = [] }) {
  return (
    <Stepper
      activeStep={sections.findIndex(i => i.id === data.currentSection)}
      alternativeLabel={!data.extend}
      orientation={data.extend ? "vertical" : "horizontal"}
      connector={<CustomConnector />}
    >
      {sections.map((form, i) =>
        !data.extend && sections?.length > 5 && i > 1 && i < sections.length - 2 ? (
          i === 2 && (
            <Step key={i}>
              <StepLabel>...</StepLabel>
            </Step>
          )
        ) : (
          <Step key={i}>
            <StepLabel>{form.name}</StepLabel>
          </Step>
        )
      )}
    </Stepper>
  );
}

export default Points;
