import { useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function SensorLineChart({ data }) {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();
  const { t } = useTranslation();
  const thresholds = data.map(i => i.threshold);
  const thresholdIndexes = [...new Set(thresholds)].map(t => {
    return { t, i: thresholds.lastIndexOf(t) };
  });
  const distribution = thresholds.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={data}>
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          interval={Object.values(distribution).reduce((a, b) => a + b, 0) / Object.values(distribution).length}
          tick={{ fontSize: 11 }}
        />
        <YAxis unit={data[0]?.unit} width={30} axisLine={false} tickLine={false} />
        <Tooltip />
        {thresholdIndexes.map(({ t, i }, index) => (
          <ReferenceArea
            y1={t}
            x1={index > 0 && thresholdIndexes[index - 1].i + 1}
            x2={index < thresholdIndexes.length - 1 && i + 1}
            label={{ value: t + data[0].unit + " Max", position: "insideBottomLeft" }}
            fill="red"
            fillOpacity={0.2}
          />
        ))}
        {thresholdIndexes.map(({ t, i }, index) => (
          <ReferenceArea
            y2={t}
            x1={index > 0 && thresholdIndexes[index - 1].i + 1}
            x2={index < thresholdIndexes.length - 1 && i + 1}
            fill={main}
            label={{ value: "Normal", position: "insideTop" }}
            fillOpacity={0.1}
          />
        ))}
        <Line dataKey="value" stroke={main} unit={data[0]?.unit} dot={false} name={t(data[0]?.type)} />
        <Line dataKey="threshold" strokeWidth={0} stroke={"red"} dot={false} activeDot={false} name={t("Threshold")} />
      </LineChart>
    </ResponsiveContainer>
  );
}
