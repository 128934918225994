import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import { Facebook, Instagram, Public, Telegram, Twitter, WhatsApp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import headerStyle from "assets/jss/components/verificationHeaderStyle";
import A from "components/A";
import Logo from "components/BrandTagLogo";
import HalalLogo from "assets/img/halal-my.png";
import React from "react";
import { useSelector } from "react-redux";
import { convertToExternalLink } from "utils";
import { badges } from "./badges";
import Notification from "./Notification";

const Icon = ({ variant }) => {
  switch (variant) {
    case "Facebook":
      return <Facebook />;
    case "Instagram":
      return <Instagram />;
    case "Telegram":
      return <Telegram />;
    case "WhatsApp":
      return <WhatsApp />;
    case "Twitter":
      return <Twitter />;
    default:
      return <Public />;
  }
};

const useStyles = makeStyles(headerStyle);
function Header({ customization, verified, expired, ...props }) {
  const classes = useStyles();
  const toolbarColor = useSelector(_ => _.environment.toolbarColor);

  const { badge = "BrandTag", social_link, social_icon = "other" } = customization;
  const tag = badge.split("Tag")[0];

  return (
    <>
      <AppBar position="sticky" color={toolbarColor ?? "inherit"} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <A href={badges[badge]?.link && convertToExternalLink(badges[badge]?.link)} className={classes.logoDiv}>
            {tag !== "Halal" ? (
              <>
                <Logo className={classes.logo} color={badges[badge].color} />
                &nbsp;
                <span className={classes.brand}>{tag}</span>
                <span className={classes.tag}>Tag</span>
              </>
            ) : (
              <img className={classes.logo} src={HalalLogo} alt="Logo" />
            )}
          </A>
          <div className={classes.grow} />
          {social_link && (
            <IconButton href={convertToExternalLink(social_link)} color="inherit">
              <Icon variant={social_icon} />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <AppBar position="sticky" color="transparent" className={classes.appBar}>
        {!window.location.pathname.startsWith("/experience") && verified && !expired && <Notification {...props} />}
      </AppBar>
    </>
  );
}

export default Header;
