import UserApi from "api/user";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { signOut } from "redux/actions/user";

function EmailConfirmation() {
  const confirmation = new URLSearchParams(window.location.search).get("confirmation");
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    UserApi.confirmEmail(confirmation)
      .then(res => {
        dispatch(signOut());
        enqueueSnackbar(t("Messages.Congratulations, your email has been confirmed"), t("Messages.Please sign in to continue"));
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      });
  });

  return <Redirect to="/" />;
}

export default EmailConfirmation;
