import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Close, Launch } from "@material-ui/icons";
import Button from "components/Button";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PayloadApi from "api/payload";
import { toVerifyUrl } from "utils";

function PayloadTableDialog({ open, orderId, skuId, activeBatchId, logisticId, handleClose }) {
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const fullScreen = useMediaQuery(breakpoints.down("sm"));
  const [data, setData] = useState([]);

  const Container = props => <Paper elevation={0} {...props} />;

  useEffect(() => {
    open &&
      PayloadApi.getAll({ orderId, skuId, activeBatchId, logisticId })
        .then(res => {
          setData(
            res.map(c => {
              return { ...c, Codes: toVerifyUrl(c.address) };
            })
          );
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });
  }, [activeBatchId, enqueueSnackbar, logisticId, open, orderId, skuId]);

  return (
    <Dialog maxWidth="md" fullScreen={fullScreen} open={open} scroll="body" onClose={handleClose}>
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h2">{t("Labels.Code List")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton disableRipple disableFocusRipple size="small" onClick={handleClose}>
              <Close></Close>
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <MaterialTable
          options={{
            showTitle: false,
            filtering: true,
            toolbar: false,
            emptyRowsWhenPaging: false,
          }}
          components={{
            Container,
          }}
          columns={[
            {
              title: t("Labels.Order ID"),
              field: "orderId",
              align: "center",
              filtering: false,
              render: ({ order }) => order.order_id,
            },
            {
              title: t("Labels.SKU"),
              field: "skuId",
              align: "center",

              filtering: false,
              render: ({ sku }) => `${sku.name} - ${sku.sku}`,
            },
            {
              title: t("Labels.Serial No"),
              field: "serial_no",
              align: "center",
            },
            {
              title: t("Labels.Activated"),
              field: "type",
              align: "center",
              lookup: { Active: "Active", Inactive: "Inactive", Pending: "Pending" },
            },
            {
              title: t("Labels.Verify Page"),
              field: "Codes",
              align: "center",

              filtering: false,
              render: rowData => (
                <Button variant="outlined" href={rowData.Codes + "&p=1"} target="_blank" buttonText={t("Labels.Preview")} endIcon={<Launch />} />
              ),
            },
          ]}
          data={data}
        />
      </DialogContent>
    </Dialog>
  );
}

export default PayloadTableDialog;
