import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(({ typography }) => ({
  button: {
    marginBottom: 8,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: `-${typography.fontSize}px`,
    marginLeft: `-${typography.fontSize}px`,
  },
  endIcon: {
    border: "unset",
  },
}));

const CustomButton = ({
  loading,
  variant = "contained",
  loadingText = "Submitting...",
  buttonText = "Submit",
  endIcon,
  noCircleEndIcon,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button
      disabled={loading}
      variant={variant}
      color="primary"
      className={classes.button}
      endIcon={loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : endIcon}
      classes={noCircleEndIcon && classes}
      {...props}
    >
      {loading ? loadingText : buttonText}
    </Button>
  );
};

CustomButton.propTypes = {
  loading: PropTypes.any,
  loadingText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
};

export default CustomButton;
