import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PageContent from "components/PageContent";
import React, { useState } from "react";
import HubspotForm from "react-hubspot-form";
import { useTranslation } from "react-i18next";
import Faq from "./FAQ";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: 24,
  },
  faqThumbnail: {
    margin: 10,
    borderRadius: 3,
    boxShadow: " 1.5px 2.6px 10px 0 rgba(119, 119, 119, 0.1)",
    borderStyle: "none",
    cursor: "pointer",
  },
  backgroundWhite: {
    backgroundColor: palette.background.paper,
  },
  backgroundGrey: {
    backgroundColor: palette.background.default,
  },
  supportForm: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
  },
  title: {
    margin: 10,
    fontWeight: "bolder",
  },
}));

function Support() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [content, setContent] = useState("FAQ");

  return (
    <PageContent header={content}>
      <Grid container>
        <Grid item xs={12} md={3}>
          <Card
            className={`${classes.faqThumbnail} ${content === "FAQ" ? classes.backgroundGrey : classes.backgroundWhite}`}
            name={t("Labels.FAQ")}
            onClick={() => setContent("FAQ")}
          >
            <CardContent>
              <Typography className={classes.title}>{t("Labels.FAQ")}</Typography>
            </CardContent>
          </Card>
          <Card
            className={`${classes.faqThumbnail} ${content === "Contact" ? classes.backgroundGrey : classes.backgroundWhite}`}
            name={"Contact"}
            onClick={() => setContent("Contact")}
          >
            <CardContent>
              <Typography className={classes.title}>{t("Labels.Contact Support")}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={9}>
          {content === "FAQ" ? (
            <Faq />
          ) : (
            <div className={classes.supportForm}>
              <HubspotForm portalId="6182373" formId="ae369a68-968c-4b1f-82b3-f89585d1a5cd" loading={<div>{t("Messages.Loading")}</div>} />
            </div>
          )}
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default Support;
