import { Dialog, Grid, Typography } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Button from "components/Button";
import React from "react";
import Lottie from "react-lottie";

const usestyles = makeStyles(() => ({
  titleBottom: {
    textAlign: "center",
    paddingTop: 30,
  },
  titleTop: {
    textAlign: "center",
    paddingTop: 30,
  },
  img: {
    height: 240,
    padding: 20,
  },
  root: {
    padding: "24px 224px",
    textAlign: "center",
  },
  message: {
    lineHeight: "1.25",
    textAlign: "center",
    marginBottom: 10,
    paddingBottom: 10,
  },
  button: {
    height: 37,
    width: 212,
  },
}));

function Information({ header, image, descriptions, buttonText, handleClose, lottieAnimationData, ...props }) {
  const classes = usestyles();

  return (
    <Dialog maxWidth={"md"} PaperProps={{ className: classes.root }} {...props}>
      <Grid justify="center">
        <Typography variant="h1" className={classes.titleTop}>
          {header}
        </Typography>
        {lottieAnimationData ? (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: lottieAnimationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height="75%"
            autoplay
            loop
            width="75%"
          />
        ) : (
          <img alt={header} className={classes.img} src={image} />
        )}

        <Typography variant="subtitle1" className={classes.message}>
          {descriptions
            .reduce((a, b) => a.concat(b).concat(<br />), [])
            .slice(0, -1)
            .map(_ => _)}
        </Typography>
      </Grid>
      <Grid container justify="center">
        <Button color="primary" className={classes.button} buttonText={buttonText} onClick={handleClose} endIcon={<ChevronRightRounded />} />
      </Grid>
    </Dialog>
  );
}

export default Information;
