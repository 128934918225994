export default {
  root: {
    textAlign: "center",
    margin: "auto",
    maxWidth: "90%",
  },
  paper: {
    marginTop: 5,
    borderRadius: 18,
    padding: 20,
    border: "unset",
  },
  img: {
    width: 47,
    height: 47,
  },
  title: {
    fontWeight: 400,
  },
  customId: {
    fontWeight: 600,
  },
  container: {
    padding: 24,
  },
  headerInfo: {
    padding: 12,
    textAlign: "left",
  },
  sort: {
    textAlign: "end",
  },
};
