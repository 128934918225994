import {
  AccountBalanceOutlined,
  DashboardOutlined,
  DescriptionOutlined,
  DeveloperBoardOutlined,
  FlashOnOutlined,
  ImageOutlined,
  InfoOutlined,
  InsertDriveFileOutlined,
  LocalShipping,
  PostAddOutlined,
  ReceiptOutlined,
  Remove,
  SettingsOutlined,
  TrendingUpOutlined,
  Tune,
} from "@material-ui/icons";

const routes = [
  {
    disableRoute: true,
    label: "Labels.Data Insights",
    icon: Remove,
    children: [
      {
        path: "/",
        label: "Labels.Dashboard",
        icon: DashboardOutlined,
        component: require("views/Dashboard").default,
      },
      {
        path: "/insights",
        label: "Labels.Data Insights",
        icon: TrendingUpOutlined,
        component: require("views/Insight").default,
      },
    ],
  },
  {
    disableRoute: true,
    label: "Labels.Product Management",
    icon: Remove,
    children: [
      {
        path: "/brand",
        label: "Labels.Manage Trademarks",
        component: require("views/Brand").default,
        icon: ReceiptOutlined,
      },
      {
        path: "/sku",
        label: "Labels.Manage SKUs",
        icon: AccountBalanceOutlined,
        component: require("views/SKU").default,
      },
      {
        path: "/media",
        label: "Labels.Media",
        icon: ImageOutlined,
        component: require("views/Media").default,
      },
    ],
  },
  {
    disableRoute: true,
    label: "Labels.Code Management",
    icon: Remove,
    children: [
      {
        path: "/generatecode",
        label: "Labels.Generate Codes",
        icon: PostAddOutlined,
        component: require("views/CodeGeneration").default,
      },
      {
        path: "/activatecode",
        label: "Labels.Activate Codes",
        icon: FlashOnOutlined,
        component: require("views/CodeActivation").default,
      },
      {
        path: "/customization",
        label: "Labels.Customization",
        icon: Tune,
        component: require("views/Customization").default,
      },
    ],
  },
  {
    disableRoute: true,
    label: "Labels.Temperature Tagging",
    icon: Remove,
    children: [
      {
        path: "/sensor",
        label: "Labels.View Sensors",
        icon: DeveloperBoardOutlined,
        component: require("views/Sensor").default,
      },
      {
        path: "/form",
        label: "Labels.Manage Forms",
        icon: InsertDriveFileOutlined,
        component: require("views/Form").default,
      },
      {
        path: "/submission",
        label: "Labels.Manage Submissions",
        icon: DescriptionOutlined,
        component: require("views/Submission").default,
      },
      {
        path: "/logistics",
        label: "Labels.Logistics",
        icon: LocalShipping,
        component: require("views/Logistics").default,
      },
    ],
  },
  {
    disableRoute: true,
    label: "Labels.Settings",
    icon: Remove,
    children: [
      {
        path: "/settings",
        label: "Labels.Settings",
        icon: SettingsOutlined,
      },
      {
        path: "/support",
        label: "Labels.FAQ",
        component: require("views/Support").default,
        icon: InfoOutlined,
      },
    ],
  },
  {
    redirect: true,
    path: "*",
    to: "/error",
  },
];

const updateRoute = r => {
  return r.map(route => {
    return {
      ...route,
      children: route.children && updateRoute(route.children),
      private: true,
      exact: true,
    };
  });
};

export default updateRoute(routes);
