import axios from "axios";
import { setLogisticSensorToken } from "redux/actions/user";
import store from "redux/store";
import TagSensorApi from "./logistic";

export const baseURL = process.env.REACT_APP_TAG_SENSOR_URL || "https://tagapi.tag-sensors.com";

const service = axios.create({
  baseURL,
});

const refreshToken = () =>
  new Promise(resolve =>
    TagSensorApi.getAccessToken().then(({ token }) => {
      store.dispatch(setLogisticSensorToken(token));
      resolve(token);
    })
  );

service.interceptors.request.use(async req => {
  let token = store.getState()?.user?.logisticSensorToken || (await refreshToken());
  req.params = { ...req.params, token };
  return req;
});

service.interceptors.response.use(
  res => res.data,
  async error => {
    if ([401, 403].includes(error?.response?.status)) {
      const req = error.config;
      req.params.token = await refreshToken();
      return new Promise((resolve, reject) =>
        axios(req)
          .then(({ data }) => resolve(data))
          .catch(err => reject(err))
      );
    }
    return Promise.reject(error);
  }
);

const getAllActivated = () => service.get(`/taglist/search`, { params: { statuses: [40, 50].join(",") } });

const getById = id => service.get(`/tag/${id}`);

export default { getAllActivated, getById };
