import { Button, CircularProgress, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { CloseRounded, InsertDriveFileRounded } from "@material-ui/icons";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { apiURL } from "api";
import SkuAPI from "api/sku";
import verifyStyle from "assets/jss/components/verifyStyle";
import classNames from "classnames";
import A from "components/A";
import Carousel from "components/Carousel";
import ProductDetail from "components/Table/ProductDetail";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import HTMLReactParser from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { convertToExternalLink } from "utils";
const blockExplorerUrl = process.env.REACT_APP_BLOCKEXPLORER_URL || "https://blox.staging.brandtag.io/";
const useStyles = makeStyles(verifyStyle);

function Default({ customization, details, documents, expired, redirectUrl, verified, images, txId, productDetailCustomization }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hover, setHover] = useState();
  const [showButton, setShowButton] = useState();
  const [redirect, setRedirect] = useState(redirectUrl);
  const [redirectLink, setRedirectLink] = useState(redirectUrl);
  const [productDetails, setProductDetails] = useState({
    Name: "_________",
    SKU: "_________",
    "Serial No.": "_________",
    "Manufactured Date": "_________",
    "Expiry Date": "_________",
  });
  const [imgs, setImgs] = useState([require(`assets/img/sampleSKU.png`)]);

  const [progress, setProgress] = useState(0);
  const {
    additions,
    background_color,
    button_text: bt,
    certificates,
    description: d,
    logo,
    verified_text: vt,
    website: w,
    hideDefaultButton,
    hideLink,
    product_details,
  } = customization;
  const button_text = bt || "Find out more at";
  const description = d || "This product is a genuine product";
  const verified_text = vt || "Pass!";
  const website = w || "brandtag.io";

  const handleCancelRedirect = () => {
    setShowButton();
    let timer = setTimeout(() => {
      setRedirect(false);
      setShowButton(true);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    (verified || expired || !details) && setShowButton(true);

    if (redirectUrl) {
      setRedirectLink(convertToExternalLink(redirectUrl));
      let timer =
        verified &&
        setTimeout(() => {
          window.location.href = redirectLink;
        }, 10000);
      if (!redirect) {
        clearTimeout(timer);
      }
      const progress =
        verified &&
        setInterval(() => {
          setProgress(prevProgress => (prevProgress >= 100 ? 100 : prevProgress + 1));
        }, 100);
      return () => {
        clearTimeout(timer);
        clearTimeout(progress);
      };
    }
  }, [redirectUrl, redirect, verified, redirectLink, expired, details]);

  useEffect(() => {
    const sku = new URLSearchParams(window.location.search).get("sku");

    sku &&
      SkuAPI.getDemo({ sku }).then(res => {
        setProductDetails({
          Name: res.name,
          SKU: res.sku,
          ...res.details.sku_details,
        });
        res.details.images.length && setImgs(res.details.images);
      });
  }, []);

  return (
    <Grid item>
      <Grid container justify="center">
        <Grid container justify="center" className={classes.logoSection}>
          {!expired ? (
            <div className={classes.logoBorder} style={{ backgroundColor: background_color }}>
              <div className={classes.logoDiv}>
                <div
                  className={classes.logo}
                  style={{
                    backgroundImage: `url('${logo || require("assets/svg/BrandTagLogo.svg")}')`,
                    backgroundSize: logo && "contain",
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Paper className={classNames(classes.paper, { [classes.verificationPaper]: verified && !expired })}>
        {verified && !expired ? (
          <>
            <A href={`${blockExplorerUrl}#/transfer/${txId}`} className={classes.a}>
              <Typography variant="h2" className={classes.result}>
                {verified_text}
              </Typography>
            </A>
            <Typography variant="body2">{description}</Typography>
            <Grid container justify="center">
              <div className={classes.carousel} style={{ borderColor: background_color }}>
                <Carousel data={images || imgs} />
              </div>
            </Grid>
            <Grid container justify="center" className={classes.details}>
              {certificates?.map(certificate => {
                return (
                  <A href={`${apiURL}/files/${certificate.hash}${certificate.ext}`}>
                    <img
                      alt="certificate-logo"
                      className={classes.certificateLogo}
                      src={
                        certificate.type === "Halal"
                          ? require("assets/img/halal-my.png")
                          : certificate.type === "ISO 9001"
                          ? "http://www.sysnovate.com.my/wp-content/uploads/2019/12/iso2015-1024x395-1024x395.png"
                          : "https://www.freepngimg.com/thumb/youtube/76561-badge-verified-youtube-logo-free-frame.png"
                      }
                    />
                  </A>
                );
              })}
            </Grid>
            <Typography variant="subtitle2" className={classes.detailLabel} style={{ fontWeight: "bold", textAlign: "left", paddingLeft: "20px" }}>
              {product_details || t("Labels.Product Details")}
            </Typography>
            <Grid container justify="center" className={classes.details}>
              <ProductDetail
                details={Object.values(details).filter(i => i).length ? details : productDetails}
                document={documents}
                customization={productDetailCustomization}
              />
            </Grid>
          </>
        ) : (
          <>
            <img className={classes.img} src={require("assets/svg/expiredplan.svg")} alt="plan-expired" />
            <Typography variant="h2" className={classes.result}>
              {t("Labels.Oops!")}
            </Typography>
            <Typography>
              {expired
                ? t("Messages.We can't verify the product")
                : details
                ? t("Messages.This code is not yet activated")
                : t("Messages.This code is not valid")}
            </Typography>
            <Typography>{t("Messages.Visit us to find out more")}</Typography>
          </>
        )}
      </Paper>
      {!hideDefaultButton && (
        <>
          {showButton && (expired || !details) ? (
            <Button variant="contained" href={convertToExternalLink(website)} fullWidth className={classes.button}>
              <Grid item>
                <Typography variant="subtitle2" className={classes.buttonLink}>
                  {t("Visit BrandTag")}
                </Typography>
              </Grid>
            </Button>
          ) : !redirect ? (
            <Button
              variant="contained"
              href={redirectUrl ? redirectLink : convertToExternalLink(website)}
              fullWidth
              rel="noopener noreferrer"
              className={classes.button}
            >
              <Grid item>
                <Typography variant="h6" className={classes.buttonText}>
                  {button_text}
                </Typography>
                {!hideLink && (
                  <Typography variant="subtitle2" className={classes.buttonLink}>
                    {redirectUrl ? new URL((redirectUrl.startsWith("http") ? "" : "http://") + redirectUrl)?.host : website}
                  </Typography>
                )}
              </Grid>
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              className={classNames(classes.button, classes.redirectButton)}
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover()}
              onClick={handleCancelRedirect}
            >
              <CircularProgress variant="static" color="inherit" value={progress} size={18} thickness={22} className={classes.circularProgress} />
              <Typography variant="h6" className={classes.buttonText}>
                {hover ? t("Cancel redirect") : t("You will be redirected")}
              </Typography>
              <CloseRounded />
            </Button>
          )}
        </>
      )}
      {verified &&
        !expired &&
        !!additions?.length &&
        additions.map(
          (
            {
              type,
              button_border_color,
              button_color,
              button_text_color,
              button_text,
              description,
              website,
              title,
              images,
              text,
              timeline_color,
              events,
              link,
              height,
              enableBorder,
              title: videoTitle,
              description: videoDescription,
              videoUrl,
            },
            i
          ) =>
            type === "button" ? (
              <Button
                variant="contained"
                target="_blank"
                href={website ? (website.includes("//") ? "" : "//") + website : "https://brandtag.io/"}
                fullWidth
                className={classes.button}
                rel="noopener noreferrer"
                style={{ border: `solid 4px ${button_border_color}`, backgroundColor: button_color }}
              >
                <Grid item>
                  <Typography variant="subtitle2" className={classes.buttonLink} style={{ color: button_text_color }}>
                    {button_text || t("Find Out More")}
                  </Typography>
                </Grid>
              </Button>
            ) : (
              <Paper
                className={classNames(classes.defaultPaper, { [classes.text]: type === "text", [classes.eventPaper]: type === "events" })}
                style={{
                  ...(type === "embed" &&
                    !enableBorder && {
                      padding: 0,
                      background: "transparent",
                    }),
                }}
              >
                {type === "image" ? (
                  <>
                    <Typography variant="subtitle1">{description}</Typography>
                    <Grid container justify="center">
                      <div className={classes.carousel} style={{ borderColor: background_color }}>
                        <Carousel data={images || imgs} />
                      </div>
                    </Grid>
                  </>
                ) : type === "text" ? (
                  text && HTMLReactParser(draftToHtml(convertToRaw(text.getCurrentContent())))
                ) : type === "embed" ? (
                  link && (
                    <iframe
                      title={i}
                      src={link}
                      width="100%"
                      height={height || "100%"}
                      style={{ borderColor: "transparent", borderRadius: "12px", borderWidth: 0 }}
                    />
                  )
                ) : type === "video" ? (
                  <>
                    {videoTitle && (
                      <Typography variant="h2" className={classes.eventsTitle}>
                        {videoTitle}
                      </Typography>
                    )}
                    <Grid container>
                      {videoUrl?.endsWith(".mp4") ? (
                        <video autoPlay playsInline muted controls width="100%" style={{ borderRadius: "12px" }}>
                          <source src={videoUrl}></source>
                        </video>
                      ) : (
                        <ReactPlayer url={videoUrl} playing controls volume={0} muted width="100%" height="100%" />
                      )}
                    </Grid>
                    {videoDescription && <Typography className={classes.description}>{videoDescription}</Typography>}
                  </>
                ) : (
                  type === "events" && (
                    <>
                      <Typography variant="h2" className={classes.eventsTitle}>
                        {title}
                      </Typography>

                      <Timeline>
                        {events?.map((event, i) => (
                          <TimelineItem key={i}>
                            <TimelineOppositeContent className={classes.timelineOppositeContent} />
                            <TimelineSeparator>
                              <TimelineDot className={classes.defaultVerifyColor} style={{ backgroundColor: timeline_color }} />
                              {events.length !== i + 1 && (
                                <TimelineConnector className={classes.defaultVerifyColor} style={{ backgroundColor: timeline_color }} />
                              )}
                            </TimelineSeparator>

                            <TimelineContent className={classes.timelineContent}>
                              <div className={classes.eventsMargin}>
                                <Typography variant="h3" className={classes.eventTitle}>
                                  {event.title}
                                </Typography>

                                {event.media ? (
                                  <Grid container justify="center">
                                    {event.media.length && !event.media.startsWith("blob:") ? (
                                      event.media.endsWith(".mp4") ? (
                                        <video autoPlay playsInline muted controls width="100%">
                                          <source src={event.media}></source>
                                        </video>
                                      ) : (
                                        <ReactPlayer url={event.media} playing controls volume={0} muted width="100%" height="100%" />
                                      )
                                    ) : (
                                      <img
                                        alt="media"
                                        src={
                                          event.media.hash && event.media.ext
                                            ? `${apiURL}/files/${event.media.hash}${event.media.ext}`
                                            : URL.createObjectURL(event.media)
                                        }
                                        width="100%"
                                      />
                                    )}
                                  </Grid>
                                ) : (
                                  ""
                                )}

                                <Typography className={classes.description}>{event.description}</Typography>
                                {event.documents && (
                                  <div className={classes.description}>
                                    {event.documents?.length > 0 && <Typography className={classes.description}>{t("See More")}</Typography>}
                                    {event.documents?.map((document, i) => (
                                      <Typography className={classes.links}>
                                        {`${document.description || `${t("Labels.Document")} #${i + 1}`}: `}
                                        <A
                                          className={classes.links}
                                          href={
                                            document.hash && document.ext
                                              ? `${apiURL}/files/${document.hash}${document.ext}`
                                              : document.url || (document.name && convertToExternalLink(document.name))
                                          }
                                        >
                                          {document.name}
                                          &nbsp;
                                          <InsertDriveFileRounded fontSize="small" color="action" />
                                        </A>
                                      </Typography>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </>
                  )
                )}
              </Paper>
            )
        )}
    </Grid>
  );
}

export default Default;
