import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { ChevronRightRounded, Create, KeyboardArrowDown, ListAlt, MoreHoriz, Visibility } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import FormSectionApi from "api/formSection";
import FormSubmissionApi from "api/formSubmission";
import FormTemplateApi from "api/formTemplate";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import Button from "components/Button";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { asyncForEach } from "utils";
import SubmissionCreate from "./CreateDialog";
import Points from "./Points";

const useToolBarStyles = makeStyles(mtoolbarstyle);
const Container = props => <Paper elevation={0} {...props} />;

function Table({ setData, setPreview, refresh }) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openCreate, setOpenCreate] = useState();
  const toolbarClasses = useToolBarStyles();
  const { enqueueSnackbar } = useSnackbar();

  const Title = () => <Typography variant="h2">{t("Labels.List of Submissions")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData);
    event.stopPropagation();
  };

  const handleClose = () => setAnchorEl();

  const [submissions, setSubmissions] = useState([]);
  const [forms, setForms] = useState([]);
  const [sectionGroup, setSectionGroup] = useState({});

  const callData = useCallback(() => {
    FormSubmissionApi.getAll()
      .then(async res => {
        setSubmissions(res);
        await asyncForEach([...new Set(res.map(_ => _.formId))], i => {
          FormSectionApi.getByForm(i)
            .then(res => {
              setSectionGroup(_ => {
                return { ..._, [i]: res };
              });
            })
            .catch(e => {
              enqueueSnackbar(e, {
                variant: "error",
              });
            });
        });
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      });

    FormTemplateApi.getAll()
      .then(res => {
        setForms(res);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      });
  }, [enqueueSnackbar]);

  useEffect(() => {
    callData();
  }, [callData, refresh]);

  const extend = id =>
    setSubmissions(submissions => {
      const newFilledForms = [...submissions];
      const i = newFilledForms.findIndex(_ => _.id === id);
      newFilledForms[i]["extend"] = !newFilledForms[i]["extend"];
      return newFilledForms;
    });

  return (
    <>
      <Button
        onClick={() => {
          setOpenCreate(true);
          setSelectedRowData({});
        }}
        buttonText={t("Labels.Create Submission")}
      />
      <MaterialTable
        options={{
          toolbar: true,
          draggable: false,
          detailPanelColumnAlignment: "left",
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
            border: "3px solid",
            borderColor: palette.background.default,
          },
          rowStyle: {
            border: "3px solid",
            borderColor: palette.background.default,
          },
          cellStyle: {
            borderBottom: 0,
          },
        }}
        components={{
          Container,
          Toolbar,
        }}
        title={Title()}
        columns={[
          {
            title: t("Labels.Date Created"),
            align: "center",
            field: "date",
            render: rowData =>
              new Date(rowData.date).toLocaleString([], {
                weekday: "long",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
              }),
          },
          {
            title: t("Labels.Form Name"),
            align: "center",
            field: "formId",
            lookup: forms.reduce((obj, item) => {
              obj[item.id] = item.name;
              return obj;
            }, {}),
          },
          {
            title: "Identifier",
            align: "center",
            render: ({ custom_id, formId }) => (
              <>
                <Typography color="textSecondary" style={{ fontSize: 12 }}>
                  {forms.find(_ => _.id === formId)?.custom_id_label}
                </Typography>
                {custom_id}
              </>
            ),
          },
          {
            title: t("Labels.Points"),
            align: "center",
            field: "points",
            width: "50%",
            render: rowData => <Points data={rowData} sections={sectionGroup[rowData.formId]} />,
          },
          {
            title: t("Labels.Action"),
            align: "center",
            field: "action",
            searchable: false,
            width: "5%",
            render: rowData => (
              <IconButton onClick={e => handleClick(e, rowData)}>
                <MoreHoriz />
              </IconButton>
            ),
          },
          {
            align: "center",
            field: "action",
            searchable: false,
            width: "5%",
            render: rowData => (
              <IconButton onClick={() => extend(rowData.id)}>{rowData.extend ? <KeyboardArrowDown /> : <ChevronRightRounded />}</IconButton>
            ),
          },
        ]}
        data={submissions}
      />

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setData({
              submission: selectedRowData,
              sections: sectionGroup[selectedRowData.formId],
              custom_id_label: forms.find(_ => _.id === selectedRowData.formId).custom_id_label,
            });
            handleClose();
          }}
        >
          <ListItemIcon>
            <ListAlt fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Labels.Insert Data")} />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenCreate(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Create fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Labels.Edit Submission")} />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setPreview({
              submission: selectedRowData,
              sections: sectionGroup[selectedRowData.formId],
              custom_id_label: forms.find(_ => _.id === selectedRowData.formId).custom_id_label,
            });
            handleClose();
          }}
        >
          <ListItemIcon>
            <Visibility fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Labels.Preview")} />
        </MenuItem>
        <Divider />
        {/* <Link
          to={{
            pathname: "activatecode",
            search: `submissionId=${selectedRowData.id}`,
          }}
        >
          <MenuItem>
            <ListItemIcon>
              <FlashOn fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Labels.Activate")} />
          </MenuItem>
        </Link> */}
      </Menu>
      <SubmissionCreate
        handleClose={() => {
          setOpenCreate();
          callData();
        }}
        open={openCreate}
        data={selectedRowData}
        forms={forms}
      />
    </>
  );
}

export default Table;
