import { Dialog, Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "components/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles({
  welcome: {
    fontSize: 30,
    marginTop: 25,
    marginBottom: 25,
  },
  message: {
    lineHeight: "1.25",
    textAlign: "center",
    marginBottom: 10,
  },
  button: {
    width: 264,
    marginTop: 25,
    marginBottom: 25,
  },
  img: {
    marginTop: 25,
    marginBottom: 25,
  },
  endIcon: {
    fontSize: 14,
  },
  div: {
    padding: 20,
  },
});

function RecoverDialog({ open, email }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const onClose = () => {
    history.push("/");
  };

  return (
    <Dialog maxWidth={"md"} open={open} onClose={onClose}>
      <div className={classes.div}>
        <Grid container justify="center">
          <Typography className={classes.welcome}>{t("Labels.Recovery Link Sent!")}</Typography>
        </Grid>
        <Grid container justify="center" className={classes.img}>
          <img alt="recovery-link" src={require("assets/svg/recoveryLink.svg")} />
        </Grid>
        <Grid container justify="center">
          <Typography className={classes.message}>
            {t("HelperText.We’ve sent an email to")}&nbsp;{email}.&nbsp;
            {t("HelperText.Click the link in the email to reset your password")}
          </Typography>
          <Typography className={classes.message}>
            {t("HelperText.If you don’t see the email, check other places it might be, like your junk, spam, social, or other folders")}
          </Typography>
        </Grid>
        <Grid container justify="center" className={classes.buttonGroup}>
          <Link to="/">
            <Button color="primary" className={classes.button} buttonText={t("Labels.Continue")} />
          </Link>
        </Grid>
      </div>
    </Dialog>
  );
}

export default RecoverDialog;
