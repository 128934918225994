import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { decodeJWTPayload, formatNumber } from "utils";
import { useTranslation } from "react-i18next";
import BillingApi from "api/billing";
import { useSnackbar } from "notistack";
import classNames from "classnames";
import Button from "components/Button";

const useStyles = makeStyles(() => ({
  paper: {
    padding: 24,
    marginTop: 2,
    marginBottom: 6,
    marginLeft: 2,
    marginRight: 2,
  },
  card: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  value: {
    fontWeight: "bolder",
  },
  expired: {
    color: "red",
  },
  active: {
    color: "green",
  },
  inactive: {
    color: "red",
  },
}));

const Detail = ({ title, value, expired, valueProps }) => {
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <Typography color={"inherit"} variant={"subtitle2"}>
        {title}
      </Typography>
      <Typography
        {...valueProps}
        color={"inherit"}
        variant={"subtitle2"}
        className={classNames(classes.value, {
          [classes.expired]: expired,
          ...valueProps?.className,
        })}
      >
        {value}
      </Typography>
    </div>
  );
};

function Summary({ totalCodes = "-", expiryDate = "-", background = "white", merchant, hasSwitch = false }) {
  const classes = useStyles();
  const { credit, jwtToken } = useSelector(_ => _.user);
  const isAdmin = decodeJWTPayload(jwtToken)?.isAdmin;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const [usedCredit, setUsedCredit] = useState(0);
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    isActive: false,
  });
  const [isMerchantActive, setMerchantActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const isSM = useMediaQuery(breakpoints.up("sm"));

  useEffect(() => {
    const getGeneratedCode = async () => {
      try {
        const res = await BillingApi.getBalance(merchant);
        if (res) {
          setUsedCredit(res.usedCodes);
          setMerchantActive(res.isActive);
        }
      } catch (e) {
        enqueueSnackbar(e, {
          variant: "error",
        });
      }
    };

    isAdmin && getGeneratedCode();
  }, [enqueueSnackbar, isAdmin, merchant]);

  // ================== EVENTS
  const onClose = () => setDialogState(prev => ({ ...prev, isOpen: false }));

  const onHandleChange = e => {
    setDialogState(prev => ({ ...prev, isOpen: true, isActive: e.target.checked }));
  };

  const onHandleConfirm = async () => {
    // call api
    setLoading(true);
    try {
      const result = await BillingApi.updateStatus(dialogState.isActive, merchant);
      if (result) {
        setMerchantActive(dialogState.isActive);
        setDialogState(prev => ({ ...prev, isOpen: false }));
        enqueueSnackbar(dialogState.isActive ? "Billing account activated successfully" : "Billing account deactivated successfully", {
          variant: "success",
        });
      }
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // ================== RENDER FUNCTION
  const renderDialogContent = () => {
    if (isMerchantActive) {
      return <Typography>{t("Are you sure you want to deactivate the billing account for this business?")}</Typography>;
    }
    return <Typography>{t("Are you sure you want to activate the billing account for this business?")}</Typography>;
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <Dialog fullWidth maxWidth={"sm"} open={dialogState.isOpen} onClose={onClose}>
        <DialogTitle>
          <Typography variant="h1">{t("Confirmation")}</Typography>
        </DialogTitle>
        <DialogContent fullWidth style={{ paddingBottom: "1.5rem" }}>
          {renderDialogContent()}
        </DialogContent>
        <DialogActions style={{ paddingRight: 24 }}>
          <Button disabled={loading} loadingText={t("Messages.Loading")} buttonText={t("Labels.Close")} onClick={onClose} variant="outlined" />
          <Button
            loading={loading}
            loadingText={t("Messages.Loading")}
            buttonText={t("Labels.Confirm")}
            onClick={onHandleConfirm}
            variant="contained"
          />
        </DialogActions>
      </Dialog>
      <Paper
        className={classes.paper}
        style={{
          background,
        }}
      >
        <div className={classes.card}>
          <Detail title={t("Total Credit")} value={formatNumber(totalCodes)} />
          <Detail title={t("Total Credit Used")} value={formatNumber(isAdmin ? usedCredit : totalCodes - credit)} />
          {isAdmin && <Detail title={t("Total Credit Left")} value={formatNumber(totalCodes - usedCredit)} />}
          {/* <Detail title={"Valid Until"} value={expiryDate} expired={new Date(expiryDate) < new Date()} /> */}
          <Detail
            title={"Billing Status"}
            value={isMerchantActive ? "Active" : "Inactive"}
            valueProps={{
              className: {
                [classes.active]: isMerchantActive,
                [classes.inactive]: !isMerchantActive,
              },
            }}
          />
        </div>
      </Paper>
      {hasSwitch && (
        <Box
          position={{
            sm: "absolute",
          }}
          top="6.75rem"
          left={0}
          zIndex={1}
          display={"flex"}
          justifyContent={"flex-end"}
          paddingRight={{
            xs: 2.5,
            sm: 0,
          }}
        >
          <FormControlLabel
            control={<Switch name="isActive" checked={isMerchantActive} onChange={onHandleChange} />}
            label="Active"
            labelPlacement={isSM ? "end" : "start"}
          />
        </Box>
      )}
    </div>
  );
}

Summary.propTypes = {
  totalCodes: PropTypes.string,
  expiryDate: PropTypes.string,
  background: PropTypes.string,
};

export default Summary;
