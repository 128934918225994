import { fade } from "@material-ui/core/styles/colorManipulator";
import { appbarHeight, bannerHeight, dashboardPaddingTop, demoHeight, drawerWidth } from "../index";
export default ({ palette, zIndex, transitions, spacing, breakpoints, mixins }) => {
  const primaryColor = palette.primary.main;
  const color = palette.color;
  return {
    appBar: {
      zIndex: zIndex.drawer + 1,
      transition: transitions.create(["width", "margin"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      position: "absolute",
      backgroundColor: palette.background.paper,
      right: -20,
      boxShadow: "0px 4px 4px 0px #00000040",
    },
    menuButtonIcon: {
      transform: "rotate(0deg)",
      transition: transitions.create("transform", {
        duration: transitions.duration.shortest,
      }),
    },
    menuButtonIconClosed: {
      transform: "rotate(180deg)",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      overflowY: "visible",
      marginTop: appbarHeight,
    },
    drawerPaper: {
      marginTop: appbarHeight,
      boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.25)",
      paddingTop: dashboardPaddingTop,
      background: palette.primary.light,
      overflow: "visible",
    },
    drawerOpen: {
      width: drawerWidth,
      padding: "0px 20px",
      paddingTop: dashboardPaddingTop,
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
    drawerOpenMobile: {
      width: 240,
      padding: 0,
      paddingTop: dashboardPaddingTop,
      marginTop: 0,
    },
    banner: {
      marginTop: appbarHeight + bannerHeight,
    },
    demo: {
      marginTop: appbarHeight + demoHeight,
    },
    drawerClose: {
      transition: transitions.create("width", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
      width: 73,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...mixins.toolbar,
    },
    listItem: {
      paddingLeft: 24,
      paddingTop: 4,
      paddingBottom: 4,
      color,
      "&>div": {
        color: "inherit",
        minWidth: 38,
      },
      "& span": {
        color: "inherit",
        fontWeight: 700,
      },
    },
    childListItem: {
      "& span": {
        fontWeight: 600,
      },
    },
    settingsListItem: {
      "& span": {
        fontWeight: 500,
      },
    },
    active: {
      // backgroundColor: fade(secondaryColor, 0.2),
      // borderLeft: `5px solid ${secondaryColor}`,
      // paddingLeft: 19,
      color: primaryColor,
      "&:hover": {
        backgroundColor: fade(primaryColor, 0.3),
      },
      "&>div": {
        color: "inherit",
      },
      "& span": {
        color: "inherit",
      },
    },
    activeText: {
      fontWeight: "bold",
    },
    text: {
      fontSize: 12,
    },
    popper: {
      zIndex: zIndex.drawer + 1,
    },
    popperPaper: {
      width: 160,
    },
    typography: {
      padding: spacing(1.6),
    },
    version: {
      alignSelf: "center",
      position: "fixed",
      bottom: 0,
      opacity: 0.5,
    },
    username: {
      padding: "0px 30px",
      fontWeight: "bolder",
      fontSize: 12,
    },
    status: {
      padding: "0px 30px",
      alignItems: "center",
      fontSize: 11,
      display: "flex",
      paddingBottom: 30,
    },
    navlink: {
      color: "inherit",
      display: "flex",
      margin: 34,
    },
    dashboard: {
      padding: 2,
      "&:hover": {
        fontWeight: 500,
      },
    },
    summary: {
      minHeight: 88 + 64,
      position: "relative",
      [breakpoints.down("sm")]: {
        minHeight: 88,
      },
    },
    summaryList: {
      overflowY: "auto",
      marginBottom: 82,
    },
    summaryListDemo: {
      marginBottom: 82 + demoHeight,
    },
    summaryListBanner: {
      marginBottom: 82 + bannerHeight,
    },
    divider: {
      marginLeft: 22,
      marginRight: 22,
    },
    verifyText: {
      minWidth: 157,
      alignSelf: "center",
    },
    link: {
      color: palette.primary.main,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    progressTooltipTitle: {
      fontSize: 10,
    },
    done: {
      textDecoration: "line-through",
    },
    baseProgress: {
      opacity: 0.1,
    },
    truncate: {
      width: 180,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    hidden: {
      visibility: "hidden",
    },
    nothing: {
      display: "none",
    },
  };
};
