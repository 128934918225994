import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
  Tooltip as MuiTooltip,
} from "@material-ui/core";
import { ChevronRightRounded, Info, Stop } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import SKUApi from "api/sku";
import UploadApi from "api/upload";
import mainLogo from "assets/img/iPhoneX.png";
import customisationStyle from "assets/jss/components/customisationStyle";
import classNames from "classnames";
import Button from "components/Button";
import CollapseCard from "components/CollapseCard";
import ColorPickerTextField from "components/ColorPickerTextField";
import Select from "components/Select";
import TextField from "components/TextField";
import UploadTextField from "components/UploadTextField";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Verify from "views/Verify";
import Buttons from "./Button";
import Events from "./Events";
import Image from "./Image";
import Text from "./Text";
import Embed from "./Embed";
import Videos from "./Videos";
import { badges as badgesObj } from "views/Verify/badges";
import { asyncForEach, decodeJWTPayload } from "utils";
import ConfirmationDialog from "components/Dialog/confirmation";
import animationData from "assets/json/clone.json";

const useStyles = makeStyles(customisationStyle);

function Customization({ data, resetData, skus, refetch }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();
  const [certificates, setCertificates] = useState([]);
  const [images, setImages] = useState();
  const [documents, setDocuments] = useState();
  const [confirmationDialog, setConfirmationDialog] = useState({
    type: "clone",
    isOpen: false,
  });
  const { progress, demo, jwtToken } = useSelector(_ => _.user);
  const { merchant } = jwtToken ? decodeJWTPayload(jwtToken) : {};
  const badges = merchant !== "haltrace" ? Object.keys(badgesObj).filter(badge => badge !== "Halal") : Object.keys(badgesObj);
  const socialIcons = ["Facebook", "Instagram", "Telegram", "WhatsApp", "Twitter", /*"WeChat",*/ "Other"];

  const defaultVerificationData = {
    verified_text: t("Pass!"),
    background_color: palette.background.verify,
    description: t("This is a genuine product."),
    button_text: t("Find out more at"),
    website: "brandtag.io",
    name: "Name",
    sku: "SKU",
    product_details: "Product Details",
  };

  const defaultButtonData = {
    type: "button",
    website: "www.example.com",
    button_color: palette.primary.main,
    button_text: "Visit us at",
    button_text_color: "#ffffff",
    button_border_color: palette.primary.main,
  };

  const defaultEventData = {
    title: "Event",
    description: "",
    documents: [],
  };

  const defaultEventsData = {
    type: "events",
    title: "Events",
    timeline_color: defaultVerificationData.background_color,
    events: [defaultEventData],
  };

  const defaultImageData = {
    type: "image",
    description: "View of our Product",
  };

  const defaultEmbedData = {
    type: "embed",
    link: "https://example.com",
    enableBorder: true,
  };

  const defaultTextData = {
    type: "text",
  };

  const defaultVideoData = {
    type: "video",
    title: "Video Title",
  };

  const [values, setValues] = useState({});
  const [logo, setLogo] = useState();
  const [collapse, setCollapse] = useState();
  const [dynamicField, setDynamicField] = useState([]);
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState();

  const handleChange = name => event => setValues({ ...values, [name]: event.target?.value || event.hex });

  const handleCertificateChanges = i => () => {
    let newCertificates = values.certificates;
    values.certificates?.length
      ? values.certificates.find(certificate => certificate.hash === certificates[i].hash)
        ? (newCertificates = values.certificates.filter(certificate => certificate.hash !== certificates[i].hash))
        : newCertificates.push(certificates[i])
      : (newCertificates = [certificates[i]]);
    setValues({ ...values, certificates: newCertificates });
  };

  const handleChanges = i => event => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i][event.target.name] = event.target.value;
    setDynamicField(newDynamicField);
  };

  const handleChecked = i => event => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i][event.target.name] = event.target.checked;
    setDynamicField(newDynamicField);
  };

  const onHandleImagesExternalUrlChange = i => event => {
    setDynamicField(prev => {
      // Create a deep copy of the previous state
      const newState = [...prev];

      // Find the specific image within newState[i]["images"]
      const image = newState[i]["images"].find(image => image.name === event.target.name);

      // Update the imageExternalUrl if the image is found
      if (image) {
        image.imageExternalUrl = event.target.value;
      }

      // Return the updated state
      return newState;
    });
  };

  const handleEventChanges = i => (x, event) => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"][x][event.target.name] = event.target.value;
    setDynamicField(newDynamicField);
  };

  const handleDocumentDescChanges = i => (j, k, event) => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"][j]["documents"][k]["description"] = event.target.value;
    setDynamicField(newDynamicField);
  };

  const handleCheckboxChange = () => setValues({ ...values, hideDefaultButton: !values.hideDefaultButton });

  const onHandleHideLink = () => setValues({ ...values, hideLink: !values.hideLink });

  const onMediaPaste = i => j => event => {
    const newDynamicField = [...dynamicField];
    delete newDynamicField[i]["events"][j]["newMedia"];
    newDynamicField[i]["events"][j]["media"] = event.target.value;
    setDynamicField(newDynamicField);
  };

  const onMediaChange = i => j => data => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"][j]["newMedia"] = data;
    setDynamicField(newDynamicField);
  };

  const onDocumentChange = i => (j, k) => newDocument => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"][j]["documents"][k] = newDocument.target
      ? { description: newDynamicField[i]["events"][j]["documents"][k].description, name: newDocument.target.value }
      : { ...newDynamicField[i]["events"][j]["documents"][k], newDocument };
    setDynamicField(newDynamicField);
  };

  const handleColorChanges = i => title => event => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i][title] = event.hex || event.target.value;
    setDynamicField(newDynamicField);
  };

  const handleEditorChange = i => content => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["text"] = content;
    setDynamicField(newDynamicField);
  };

  const handleLogoChange = _ => setLogo(_);

  const handleImageAdd = i => data => {
    const newDynamicField = [...dynamicField];
    if (newDynamicField[i]["images"]) newDynamicField[i]["images"].push(data);
    else newDynamicField[i]["images"] = [data];
    setDynamicField(newDynamicField);
  };

  const addEvent = i => () => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"] = [...newDynamicField[i]["events"], defaultEventData];
    setDynamicField(newDynamicField);
  };

  const addDocument = i => x => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"][x]["documents"] = [...newDynamicField[i]["events"][x]["documents"], []];
    setDynamicField(newDynamicField);
  };

  const onDrag = {
    onDragEnd(fromIndex, toIndex) {
      const newDynamicField = [...dynamicField];
      const field = newDynamicField.splice(fromIndex, 1)[0];
      newDynamicField.splice(toIndex, 0, field);
      setDynamicField(newDynamicField);
    },
    nodeSelector: "li",
    handleSelector: "button",
  };

  const handleImageRemove = i => j => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["images"] = newDynamicField[i]["images"].filter((_, index) => index !== j);
    setDynamicField(newDynamicField);
  };

  const saveForm = async (formData, isDraft = false, isStartOver = false) => {
    try {
      let customization;
      if (values.cloneId) {
        customization = skus.find(i => i.id === values.cloneId)?.details?.customization;
      } else {
        let tempLogo = values.logo;
        if (logo) {
          setLoading(1);
          const uploadRes = await UploadApi.create(logo);
          const { merchant, url, ...rest } = uploadRes;
          tempLogo = rest;
        }
        await asyncForEach(
          dynamicField.filter(i => i.type === "events"),
          async ({ events }, i) => {
            await asyncForEach(events, async ({ newMedia, documents }, j) => {
              if (newMedia) {
                const { name, hash, ext, mime, size } = await UploadApi.create(newMedia);
                dynamicField.filter(i => i.type === "events")[i].events[j].media = { name, hash, ext, mime, size };
                delete dynamicField.filter(i => i.type === "events")[i].events[j].newMedia;
              }
              if (documents) {
                const uploadRes = await Promise.all(
                  documents.map(async f => {
                    if (f.newDocument) {
                      const { name, hash, ext, mime, size } = await UploadApi.create(f.newDocument);
                      return { description: f.description, name, hash, ext, mime, size };
                    }
                    return f;
                  })
                );
                dynamicField.filter(i => i.type === "events")[i].events[j].documents = uploadRes;
              }
            });
          }
        );
        await asyncForEach(
          dynamicField.filter(i => i.type === "image"),
          async (data, i) => {
            if (!data?.images) {
              return enqueueSnackbar("Please upload an image", { variant: "info" });
            }
            await asyncForEach(data.images, async (file, j) => {
              if (!file.hash) {
                const { name, hash, ext, mime, size } = await UploadApi.create(file);
                dynamicField.filter(i => i.type === "image")[i].images[j] = {
                  name,
                  hash,
                  ext,
                  mime,
                  size,
                  imageExternalUrl: file.imageExternalUrl,
                };
              }
            });
          }
        );
        customization = {
          ...values,
          ...formData,
          logo: tempLogo,
          additions: dynamicField
            .filter(field => field.type)
            .map(i => (i.type === "text" ? { ...i, text: i.text && convertToRaw(i.text.getCurrentContent()) } : i)),
          isDraft,
        };
      }
      setLoading(2);
      if (isStartOver) {
        await SKUApi.removeCustomization(data.id);
      } else {
        await SKUApi.updateCustomization(data.id, customization);
      }

      const toastMessage = isDraft
        ? "Draft saved successfully"
        : isStartOver
        ? "Customization has started over"
        : "Customization published successfully";
      enqueueSnackbar(toastMessage);
      refetch();
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading();
      setConfirmationDialog(prev => ({ ...prev, isOpen: false }));
    }
  };

  const getSKUName = ({ name, sku } = {}) => `${name} (${sku})`;

  const onHandleDialogClose = () => setConfirmationDialog(prev => ({ ...prev, isOpen: false }));

  const customizeObj = data?.details?.customization;

  const confirmationMap = {
    clone: {
      header: t("Labels.Clone Customization"),
      image: require("assets/img/activationLoading.png"),
      description: [
        `${t("Messages.Are you sure to clone customization from")} ${getSKUName(skus.find(i => i.id === values.cloneId))}?`,
        t("All your changes will be overwritten."),
      ],
      submitText: t("Labels.Yes"),
      cancelText: t("Labels.No"),
      handleClose: onHandleDialogClose,
      lottieAnimationData: animationData,
      onClick: handleSubmit(saveForm),
    },
    cancel: {
      header: t("Confirmation"),
      description: ["Are you sure you want to cancel?", "Changes that you made may not be saved."],
      submitText: t("Labels.Yes"),
      cancelText: t("Labels.No"),
      onClick: () => resetData(),
      handleClose: onHandleDialogClose,
      additional: {
        enableImage: false,
        titleProps: {
          style: {
            paddingBottom: 2,
          },
        },
      },
    },
    startOver: {
      header: t("Confirmation"),
      description: ["Are you sure you want to start over?", "All your changes will be lost."],
      submitText: t("Labels.Yes"),
      cancelText: t("Labels.No"),
      handleClose: onHandleDialogClose,
      onClick: handleSubmit(data => saveForm(data, false, true)),
      additional: {
        enableImage: false,
        primaryButtonProps: {
          loading: loading > 0,
          disabled: demo || loading > 0,
          loadingText: loading === 1 ? t("Messages.Uploading image") : t("Messages.Saving"),
        },
        titleProps: {
          style: {
            paddingBottom: 2,
          },
        },
      },
    },
    saveAsDraft: {
      header: t("Confirmation"),
      description: ["Are you sure you want to save as draft?", "You can continue editing later."],
      submitText: t("Labels.Yes"),
      cancelText: t("Labels.No"),
      handleClose: onHandleDialogClose,
      onClick: handleSubmit(data => saveForm(data, true)),
      additional: {
        enableImage: false,
        primaryButtonProps: {
          loading: loading > 0,
          disabled: demo || loading > 0,
          loadingText: loading === 1 ? t("Messages.Uploading image") : t("Messages.Saving"),
        },
        titleProps: {
          style: {
            paddingBottom: 2,
          },
        },
      },
    },
    publish: {
      header: t("Confirmation"),
      description: [
        "Are you sure you want to publish?",
        !customizeObj || customizeObj?.isDraft
          ? "Once you published, you can't no longer save as draft or start over."
          : "This action cannot be undone.",
      ],
      submitText: t("Labels.Yes"),
      cancelText: t("Labels.No"),
      handleClose: onHandleDialogClose,
      onClick: handleSubmit(data => saveForm(data)),
      additional: {
        enableImage: false,
        primaryButtonProps: {
          loading: loading > 0,
          disabled: demo || loading > 0,
          loadingText: loading === 1 ? t("Messages.Uploading image") : t("Messages.Saving"),
        },
      },
    },
  };
  const dialogContent = confirmationMap[confirmationDialog.type];

  useEffect(() => {
    const populateData = data => {
      Object.keys(data).map(i => i !== "logo" && setValue(i, data[i]));
      const { additions, ...values } = data;
      setValues(values);
      additions &&
        setDynamicField(
          additions.map(i => (i.type === "text" ? { ...i, text: i.text && EditorState.createWithContent(convertFromRaw(i.text)) } : i))
        );
    };
    const populateImages = file => {
      if (file) {
        const { hash, ext } = file;
        if (hash) setImages([`${apiURL}/files/${hash}${ext}`]);
        else {
          setImages(file.map(({ hash, ext }) => `${apiURL}/files/${hash}${ext}`));
        }
      }
    };
    const populateDocument = document => {
      if (document) {
        const { hash, ext } = document;
        if (hash) setDocuments([`${apiURL}/files/${hash}${ext}`]);
        else {
          setDocuments(
            document.map(({ hash, ext, name }) => {
              return [name, `${apiURL}/files/${hash}${ext}`];
            })
          );
        }
      }
    };
    setCertificates(data?.certificates);
    if (!data?.details?.customization) {
      setDynamicField([]);
      setValues({});
    } else {
      populateData(data?.details?.customization);
    }
    data?.details?.images && populateImages(data?.details?.images);
    data?.details?.document && populateDocument(data?.details?.document);
  }, [data, setValue]);

  const onDeleteDynamicField = i => () => setDynamicField(dynamicField.filter((e, j) => j !== i && e.type));

  const onDeleteEvent = i => y => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"] = newDynamicField[i]["events"].filter((_, x) => x !== y);
    setDynamicField(newDynamicField);
  };

  const onDeleteDocument = i => (x, y) => {
    const newDynamicField = [...dynamicField];
    newDynamicField[i]["events"][x]["documents"] = newDynamicField[i]["events"][x]["documents"].filter((_, z) => z !== y);
    setDynamicField(newDynamicField);
  };

  const forgeCustomization = cloneId => {
    const values = skus.find(i => i.id === cloneId)?.details?.customization;
    return {
      ...values,
      logo: values?.logo && `${apiURL}/files/${values?.logo?.hash}${values?.logo?.ext}`,
      additions: values?.additions?.map(i =>
        i.type === "text" ? { ...i, text: i.text && EditorState.createWithContent(convertFromRaw(i.text)) } : i
      ),
    };
  };

  const getSKUNameElement = ({ name, sku }) => (
    <>
      {name} (<i>{sku}</i>)
    </>
  );

  return (
    <Grid container>
      <Grid item className={classes.section} md={7} sm={12}>
        <Box display={"flex"} alignItems={"center"} style={{ gap: 4, marginLeft: "-12px" }} mb={1}>
          <Typography variant="h1" className={classes.title}>
            {t("Labels.Customization")}
          </Typography>
          <MuiTooltip
            title={
              <Typography variant="body2">
                {t(
                  "Once you activate your product on the blockchain, the data in this verification section cannot be changed. Any changes you make will only affect the appearance of the page looks here but won't be visible to users when they scan the QR code. The actual data remains secure and unchanged."
                )}
              </Typography>
            }
          >
            <Info style={{ cursor: "pointer", marginTop: "8px" }} />
          </MuiTooltip>
        </Box>
        <Typography>{t("Labels.When scanned, displays detailed information about the product")}</Typography>
        {window.location.pathname.startsWith("/customization") && (
          <Select
            fullWidth
            name="cloneId"
            variant="filled"
            labelPlacement="left"
            label={t("Labels.Clone Customization")}
            value={values.clone}
            tooltipText={t("Clone customization from another SKU")}
            onChange={handleChange("cloneId")}
          >
            <MenuItem value={null}>&nbsp;</MenuItem>
            {skus
              ?.filter(i => i.details?.customization)
              .map(({ id, details, ...sku }, i) => (
                <MenuItem key={i} value={id}>
                  <ListItemIcon>
                    <Stop fontSize="small" style={{ color: details?.customization?.background_color || "transparent" }} />
                  </ListItemIcon>
                  {getSKUNameElement(sku)}
                </MenuItem>
              ))}
          </Select>
        )}
        {values.cloneId ? (
          <>
            {t("Previewing customization for")} {getSKUNameElement(skus.find(i => i.id === values.cloneId))}
          </>
        ) : (
          <>
            <div className={classNames(classes.formDiv, classes.selectorGroup)}>
              <Typography variant="h3" gutterBottom className={classes.buttonLabel}>
                {`${t("Labels.Add")}:`}
              </Typography>

              <Grid container className={classes.formDiv}>
                <Grid item xs={4}>
                  <Button
                    className={classes.addButton}
                    variant="text"
                    buttonText={t("Labels.Image")}
                    onClick={() => setDynamicField([...dynamicField, defaultImageData])}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.addButton}
                    variant="text"
                    buttonText={t("Labels.Text")}
                    onClick={() => setDynamicField([...dynamicField, defaultTextData])}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.addButton}
                    variant="text"
                    buttonText={t("Labels.Button")}
                    onClick={() => setDynamicField([...dynamicField, defaultButtonData])}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.addButton}
                    variant="text"
                    buttonText={t("Labels.Events")}
                    onClick={() => setDynamicField([...dynamicField, defaultEventsData])}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.addButton}
                    variant="text"
                    buttonText={t("Labels.Video")}
                    onClick={() => setDynamicField([...dynamicField, defaultVideoData])}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    className={classes.addButton}
                    variant="text"
                    buttonText={t("Labels.Embed Web Page")}
                    onClick={() => setDynamicField([...dynamicField, defaultEmbedData])}
                  />
                </Grid>
              </Grid>
            </div>

            <CollapseCard collapse={collapse} handleCollapse={() => setCollapse(!collapse)} title={t("Labels.Verification Output")}>
              <Select
                fullWidth
                name="badge"
                variant="filled"
                labelPlacement="left"
                label={t("Labels.Badge")}
                value={values.badge}
                defaultValue={badges[0]}
                tooltipText={t("Badge to be shown in the top bar")}
                onChange={handleChange("badge")}
              >
                {badges?.map((badge, i) => (
                  <MenuItem key={i} value={badge}>
                    {badge} {badge === "BrandTag" && "(Default)"}
                  </MenuItem>
                ))}
              </Select>
              <Select
                fullWidth
                name="social_icon"
                variant="filled"
                labelPlacement="left"
                label={t("Labels.Social Media Icon")}
                value={values.social_icon}
                defaultValue={socialIcons[socialIcons.length - 1]}
                onChange={handleChange("social_icon")}
              >
                {socialIcons?.map((icon, i) => (
                  <MenuItem key={i} value={icon}>
                    {icon}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                labelPlacement="left"
                name="social_link"
                inputRef={register}
                label={t("Labels.Social Media Link")}
                value={values.social_link}
                placeholder={t("Labels.URL")}
                onChange={handleChange("social_link")}
              />
              <UploadTextField
                name="logo"
                inputRef={register}
                label={t("Labels.Upload Brand Logo")}
                onChange={handleLogoChange}
                buttonText={t("Labels.Upload")}
                helperText={t("HelperText.JPG or PNG formats | Size must be 512px X 512px")}
                labelPlacement="left"
                linkPath={`${apiURL}/files/${values?.logo?.hash}${values?.logo?.ext}`}
                documentName={values?.logo?.name}
              />
              <TextField
                labelPlacement="left"
                name="verified_text"
                inputRef={register}
                label={t("Labels.Verification Text")}
                value={values.verified_text}
                placeholder={defaultVerificationData.verified_text}
                onChange={handleChange("verified_text")}
              />
              <TextField
                labelPlacement="left"
                name="description"
                inputRef={register}
                label={t("Labels.Description")}
                value={values.description}
                placeholder={defaultVerificationData.description}
                onChange={handleChange("description")}
              />
              <Select
                fullWidth
                name="certificates"
                labelPlacement="left"
                label={t("Certificates")}
                tooltipText={t("Certificates to be shown for QR code scanners")}
              >
                {certificates?.map(({ status, hash, name, type }, i) => (
                  <MenuItem key={i} disabled={status !== "Approved"} onClick={handleCertificateChanges(i)}>
                    <Checkbox checked={values.certificates?.find(c => c.hash === hash)} onChange={handleCertificateChanges(i)} />
                    <ListItemText
                      primary={name}
                      secondary={(type ? type + " — " : "") + t(status ? `Labels.${status}` : "Labels.Pending Approval")}
                    />
                  </MenuItem>
                ))}
              </Select>

              <ColorPickerTextField
                labelPlacement="left"
                name="background_color"
                inputRef={register}
                label={t("Labels.Color Theme")}
                value={values.background_color || defaultVerificationData.background_color}
                placeholder={defaultVerificationData.background_color}
                onChange={handleChange("background_color")}
              />

              <Divider className={classes.divider} />

              <Typography variant="h3" className={classes.title}>
                {t("Default Button Customization")}
              </Typography>
              <Box>
                <FormControlLabel
                  className={classes.checkbox}
                  control={<Checkbox defaultChecked={values.hideDefaultButton} color="primary" onChange={handleCheckboxChange} />}
                  label={
                    <Typography variant="body2" className={classes.checkboxLabel}>
                      {t("Hide")}
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Box>
              <Box>
                <FormControlLabel
                  className={classes.checkbox}
                  control={<Checkbox defaultChecked={values.hideLink} color="primary" onChange={onHandleHideLink} />}
                  label={
                    <Typography variant="body2" className={classes.checkboxLabel}>
                      {t("Hide Link Text")}
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </Box>
              <TextField
                labelPlacement="left"
                name="button_text"
                inputRef={register}
                label={t("Title")}
                value={values.button_text}
                placeholder={defaultVerificationData.button_text}
                onChange={handleChange("button_text")}
                disabled={values.hideDefaultButton}
              />
              <TextField
                labelPlacement="left"
                name="website"
                inputRef={register}
                label={t("Link")}
                value={values.website}
                placeholder={defaultVerificationData.website}
                onChange={handleChange("website")}
                disabled={values.hideDefaultButton}
              />

              <Divider className={classes.divider} />
              <Typography variant="h3" className={classes.title}>
                {t("Product Details Customization")}
              </Typography>
              <TextField
                labelPlacement="left"
                label={t("Title")}
                value={values.product_details}
                placeholder={defaultVerificationData.product_details}
                onChange={handleChange("product_details")}
              />
              <TextField
                labelPlacement="left"
                label={t("Name")}
                value={values.customSKUName}
                placeholder={defaultVerificationData.name}
                onChange={handleChange("customSKUName")}
              />
              <TextField
                labelPlacement="left"
                label={t("SKU")}
                value={values.customSKU}
                placeholder={defaultVerificationData.sku}
                onChange={handleChange("customSKU")}
              />
              <TextField
                labelPlacement="left"
                label={t("Line Breaking Character")}
                value={values.breakLineChar}
                placeholder={defaultVerificationData.breakLineChar}
                onChange={handleChange("breakLineChar")}
                tooltipText={t("Specify character to break product detail value into multiple line")}
              />
            </CollapseCard>

            <ReactDragListView {...onDrag}>
              {dynamicField?.map((e, i) =>
                e.type === "image" ? (
                  <li key={i} style={{ listStyle: "none" }}>
                    <Image
                      imageValue={e.images || []}
                      descriptionValue={e.description}
                      onTitleChange={handleChanges(i)}
                      onImageAdd={handleImageAdd(i)}
                      onImageRemove={handleImageRemove(i)}
                      onDelete={onDeleteDynamicField(i)}
                      onHandleImagesExternalUrlChange={onHandleImagesExternalUrlChange(i)}
                    />
                  </li>
                ) : e.type === "text" ? (
                  <li key={i} style={{ listStyle: "none" }}>
                    <Text text={e.text} onChange={handleEditorChange(i)} onDelete={onDeleteDynamicField(i)} />
                  </li>
                ) : e.type === "embed" ? (
                  <li key={i} style={{ listStyle: "none" }}>
                    <Embed
                      link={e.link}
                      enableBorder={e.enableBorder}
                      height={e.height}
                      onChange={handleChanges(i)}
                      onChecked={handleChecked(i)}
                      onDelete={onDeleteDynamicField(i)}
                    />
                  </li>
                ) : e.type === "button" ? (
                  <li key={i} style={{ listStyle: "none" }}>
                    <Buttons
                      urlValue={e.website}
                      buttonTextValue={e.button_text}
                      bgColorValue={e.button_color}
                      textColorValue={e.button_text_color}
                      borderColorValue={e.button_border_color}
                      onChange={handleChanges(i)}
                      onColorChange={handleColorChanges(i)}
                      onDelete={onDeleteDynamicField(i)}
                    />
                  </li>
                ) : e.type === "video" ? (
                  <li key={i} style={{ listStyle: "none" }}>
                    <Videos value={e} onChange={handleChanges(i)} onDelete={onDeleteDynamicField(i)} />
                  </li>
                ) : (
                  e.type === "events" && (
                    <li key={i} style={{ listStyle: "none" }}>
                      <Events
                        id={i}
                        title={e.title}
                        timeline_color={e.timeline_color}
                        defaultColor={defaultVerificationData.background_color}
                        events={e.events}
                        onChange={handleChanges(i)}
                        onColorChange={handleColorChanges(i)}
                        onEventChange={handleEventChanges(i)}
                        onDelete={onDeleteDynamicField(i)}
                        onDeleteEvent={onDeleteEvent(i)}
                        onDeleteDocument={onDeleteDocument(i)}
                        addDocument={addDocument(i)}
                        addEvent={addEvent(i)}
                        onMediaChange={onMediaChange(i)}
                        onDocumentChange={onDocumentChange(i)}
                        onDocumentDescChange={handleDocumentDescChanges(i)}
                        onMediaPaste={onMediaPaste(i)}
                      />
                    </li>
                  )
                )
              )}
            </ReactDragListView>
          </>
        )}
        {window.location.pathname.startsWith("/customization") && (
          <Grid container style={{ rowGap: ".5rem", paddingTop: "2rem" }}>
            <Grid item>
              <Button className={classes.button} buttonText={t("Labels.Cancel")} type="submit" onClick={() => resetData()} variant="outlined" />
            </Grid>
            {(!data?.details?.customization || data?.details?.customization?.isDraft) && !values.cloneId && (
              <>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    buttonText="Start Over"
                    onClick={() => setConfirmationDialog({ type: "startOver", isOpen: true })}
                  />
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="outlined"
                    buttonText="Save as Draft"
                    onClick={() => setConfirmationDialog({ type: "saveAsDraft", isOpen: true })}
                  />
                </Grid>
              </>
            )}
            <Grid item>
              <Button
                className={classes.button}
                buttonText={values.cloneId ? t("Labels.Clone Customization") : t("Labels.Publish")}
                // loadingText={loading === 1 ? t("Messages.Uploading image") : t("Messages.Saving")}
                type="submit"
                onClick={() => setConfirmationDialog({ type: values.cloneId ? "clone" : "publish", isOpen: true })}
                // loading={loading > 0}
                endIcon={<ChevronRightRounded />}
                // disabled={demo || loading > 0}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item md={5} sm={12} className={classes.mockup}>
        <img className={classes.phone} src={mainLogo} alt="iPhoneX" />
        <div
          className={classNames(classes.screen, classes.screenBackground)}
          style={{
            background: values.cloneId ? skus.find(i => i.id === values.cloneId)?.details?.customization?.background_color : values.background_color,
          }}
        />
        <div className={classNames(classes.screen, classes.content)}>
          <Verify
            customization={
              values.cloneId
                ? forgeCustomization(values.cloneId)
                : {
                    ...values,
                    additions: dynamicField.map(i =>
                      i.events
                        ? {
                            ...i,
                            events: i.events.map(j => ({
                              ...j,
                              media: j.newMedia || j.media,
                              documents: j.documents.map(k =>
                                k.newDocument ? { description: k.description, name: k.newDocument.name, url: URL.createObjectURL(k.newDocument) } : k
                              ),
                            })),
                          }
                        : i.images
                        ? {
                            ...i,
                            images: i.images.map((j, index) => {
                              return j.hash ? { ...j } : { url: URL.createObjectURL(j), imageExternalUrl: j?.imageExternalUrl || "" };
                            }),
                          }
                        : i
                    ),
                    logo: logo
                      ? URL.createObjectURL(logo)
                      : values?.logo?.hash && values?.logo?.ext && `${apiURL}/files/${values?.logo?.hash}${values?.logo?.ext}`,
                  }
            }
            details={{
              Name: data?.name,
              SKU: data?.sku,
              ...data?.details?.sku_details,
            }}
            productDetailCustomization={{
              order: data?.details?.sku_details_order,
              customSKUName: values.customSKUName,
              customSKU: values.customSKU,
              breakLineChar: values.breakLineChar,
            }}
            documents={documents}
            images={images}
            verified
            verifiedBusiness={progress?.businessApproved}
            verifiedTrademark={data["trademark.status"] === "Approved"}
            delivered
            logEnded
          />
        </div>
        {/* <div className={classes.preview}>
          <div className={classes.link}>
            <Typography variant="h3">{t("Preview")}</Typography>
          </div>
        </div> */}
      </Grid>
      <ConfirmationDialog
        {...dialogContent?.additional}
        header={dialogContent?.header}
        image={dialogContent?.image}
        descriptions={dialogContent?.description}
        submitText={dialogContent?.submitText}
        cancelText={dialogContent?.cancelText}
        handleClose={dialogContent?.handleClose}
        lottieAnimationData={dialogContent?.lottieAnimationData}
        onClick={dialogContent?.onClick}
        open={confirmationDialog.isOpen}
      />
    </Grid>
  );
}

export default Customization;
