export default ({ palette }) => ({
  root: {
    textAlignLast: "center",
    padding: "unset",
    paddingTop: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#000000",
  },
});
