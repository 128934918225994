import { Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "components/Button";
import Map from "components/Map";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "./Table";

const useStyles = makeStyles({
  table: {
    paddingLeft: 10,
  },
  gmap: {
    width: "100%",
  },
  title: {
    fontWeight: 600,
    padding: "13px 0px",
  },
  map: {
    paddingTop: 4,
  },
  mapHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  resetButton: {
    height: "fit-content",
    margin: 12,
  },
});

function TrackTrace({ defaultCenter, defaultZoom, data = [] }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [center, setCenter] = useState(data.length ? { lat: data[0].lat, lng: data[0].lng } : defaultCenter);
  const [zoom, setZoom] = useState(data.length ? 11 : defaultZoom);
  const [filteredData, setFilteredData] = useState([]);

  const onRowClick = c => setCenter(c);

  const onPointClick = ({ lat, lng, Country, Location }) => {
    const t = data.filter(i => i.Country === Country && i.Location === Location);
    setFilteredData(t);
    setZoom(13);
    onRowClick({ lat, lng });
  };

  const onResetFilter = () => {
    setFilteredData([]);
    setZoom(11);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <div className={classes.mapHeader}>
          <Typography variant="h2" className={classes.title}>
            {t("Labels.Location of Scanning Activities")}
          </Typography>
          <Button
            className={classes.resetButton}
            style={{ display: !filteredData.length && "none" }}
            onClick={onResetFilter}
            variant="outlined"
            buttonText={t("Labels.Reset Filter")}
          />
        </div>
        <div className={classes.map}>
          <Map
            center={center}
            zoom={zoom}
            places={data.reduce((a, i) => {
              const e = a.findIndex(j => j.Location === i.Location && j.Country === i.Country && j.lat === i.lat && j.lng === i.lng);
              if (e >= 0) {
                a[e].count++;
              } else {
                a.push({ Location: i.Location, Country: i.Country, lng: i.lng, lat: i.lat, count: 1 });
              }
              return a;
            }, [])}
            onPointClick={onPointClick}
            circleProps={{
              radius: 1000,
              options: {
                strokeOpacity: 0,
                fillColor: "#d27273",
              },
            }}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.table}>
        <Table
          data={filteredData.length ? filteredData : data}
          onRowClick={onRowClick}
          title={() => (
            <Typography variant={"h2"} className={classes.title}>
              {t("Labels.SKU Variation Scanned")}
            </Typography>
          )}
        />
      </Grid>
    </Grid>
  );
}

TrackTrace.defaultProps = {
  defaultCenter: {
    lat: 24.5392382,
    lng: 80.7206842,
  },
  defaultZoom: 2,
};

export default TrackTrace;
