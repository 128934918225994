import { signOut } from "redux/actions/user";
import store from "../redux/store";

export const toSnakeCase = string => string.toLowerCase().trim().replace(/\s/g, "_");

export const dashBetweenLowAndUppCase = l => l.replace(/([a-z])([A-Z])/g, "$1-$2");

export const decodeJWTPayload = jwtToken => {
  try {
    return JSON.parse(atob(jwtToken.split(".")[1]));
  } catch {
    store.dispatch(signOut());
  }
  return {};
};
export default class RegExpPattern {
  static hasNumber = () => /\d/;
  static email = () =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static phone = () => /^(0|\+)(\d+){9,15}$/;
  static password = () => /^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).*$/;
  static postcode = () => /^[0-9]{5,9}$/;
  static website = () => /^(http:\/\/|https:\/\/)?([a-z0-9][a-z0-9-]*\.)+[a-z0-9][a-z0-9-]*$/;
}

export const toVerifyUrl = a => `${window.location.origin}/verify?a=${a}`;

export const capitalizeFirstLetter = s => (s ? s.charAt(0).toUpperCase() + s.slice(1) : s);

export const calculateProgress = (progress = {}) => Object.values(progress).filter(i => i)?.length * 20;

export const asyncForEach = async (array, callback) => {
  for (let i = 0; i < array.length; i++) {
    await callback(array[i], i, array);
  }
};

export const convertToExternalLink = (l = "") => (l.includes("//") ? "" : "//") + l;

export const formatNumber = n => new Intl.NumberFormat(navigator.language).format(n);
