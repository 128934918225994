import { FilledInput, FormControl, FormHelperText, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Delete, Help, Launch } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/textfield";
import classNames from "classnames";
import A from "components/A";
import UploadButton from "components/UploadButton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ palette }) => ({
  button: {
    height: 47,
    width: "100%",
  },
  buttonDiv: {
    marginLeft: 12,
  },
  crossed: {
    textDecoration: "line-through",
  },
  help: {
    marginLeft: 4,
  },
  helperText: {
    display: "flex",
  },
  input: {
    display: "none",
  },
  launch: {
    padding: 3,
  },
  left: {
    display: "flex",
  },
  leftLabel: {
    alignSelf: "center",
    width: 152,
  },
  labelTextField: {
    height: 48,
    width: 140,
    marginRight: 10,
  },
  labelTextFieldInput: {
    padding: "16px 8px",
  },
  root: {
    padding: 12,
  },
  topLabel: {
    height: 23,
    lineHeight: 1.63,
  },
}));

const useTextFieldStyles = makeStyles(textfieldStyles);

const UploadTextField = ({
  buttonText,
  documentName,
  error,
  enable,
  placeholder,
  inputValue,
  onInputChange,
  helperText,
  id = "upload",
  inputRef,
  label,
  labelPlacement,
  linkPath,
  name,
  onChange,
  required,
  // setValue,
  tooltipText,
  onDelete,
  editableLabel,
  onLabelChange,
  labelPlaceholder,
}) => {
  const classes = useStyles();
  const textfieldClasses = useTextFieldStyles();
  const [file, setFile] = useState();
  const { t } = useTranslation();

  const onUploadChange = event => {
    setFile(event.target.files[0].name);
    // setValue && setValue(name, event.target.files);
    if (onChange) onChange(event.target.files[0]);
  };

  const onTextFieldChange = event => {
    setFile();
    if (onInputChange) onInputChange(event);
  };

  return (
    <div
      className={classNames({
        [classes.root]: labelPlacement,
        [classes.left]: labelPlacement === "left",
      })}
    >
      {editableLabel ? (
        <FilledInput
          value={label}
          classes={{ ...textfieldClasses, input: classes.labelTextFieldInput }}
          placeholder={labelPlaceholder}
          className={classes.labelTextField}
          onChange={e => onLabelChange(e)}
        />
      ) : (
        <Typography
          variant="h5"
          gutterBottom
          className={classNames(classes.label, {
            [classes.leftLabel]: labelPlacement === "left",
            [classes.topLabel]: labelPlacement === "top",
          })}
        >
          {label}
          {required && "*"}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Help fontSize="small" className={classes.help} />
            </Tooltip>
          )}
        </Typography>
      )}
      <FormControl fullWidth>
        <Grid container>
          <Grid item md={8} sm={8}>
            <FilledInput
              value={file || documentName || inputValue}
              classes={textfieldClasses}
              fullWidth
              disabled={!enable && (file || documentName)}
              error={error}
              placeholder={placeholder}
              onChange={e => onTextFieldChange(e)}
            />
            {documentName && !linkPath.endsWith("undefinedundefined") ? (
              <FormHelperText style={{ display: "flex" }}>
                {file && t("Labels.Replace document") + `:`}
                &nbsp;
                <A
                  className={classNames({
                    [classes.crossed]: file,
                  })}
                  href={linkPath}
                >
                  {file ? documentName : t("View document")}
                  &nbsp;
                  <Launch fontSize="small" color="primary" className={classes.launch} />
                </A>
              </FormHelperText>
            ) : (
              <FormHelperText error={error}>{helperText}</FormHelperText>
            )}
          </Grid>
          <Grid item md={onDelete ? 3 : 4} sm={onDelete ? 3 : 4}>
            <UploadButton
              buttonText={buttonText}
              documentName={documentName}
              error={error}
              helperText={helperText}
              id={id || "upload" + label}
              inputRef={inputRef}
              name={name}
              onChange={onUploadChange}
            />
          </Grid>
          {onDelete && (
            <Grid item md={1} sm={1}>
              <IconButton color="primary" onClick={onDelete}>
                <Delete />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </FormControl>
    </div>
  );
};

UploadTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["top", "left"]),
};

export default UploadTextField;
