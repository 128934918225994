import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import cardStyle from "assets/jss/components/cardStyle";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import AnimatedNumber from "react-animated-number";
import { formatNumber } from "utils";

const useStyles = makeStyles(cardStyle);

function Card({ count = 0, subtitle, icon, ...props }) {
  const classes = useStyles();
  const [no, setNo] = useState(0);

  useEffect(() => {
    !no && setNo(count);
  }, [count, no]);

  return (
    <Paper className={classes.paper} {...props}>
      <div>
        <Typography className={classes.title} variant={"h1"}>
          <AnimatedNumber value={no} duration={2000} stepPrecision={0} formatValue={n => formatNumber(n)} />
        </Typography>
        <div className={classes.details}>
          <Typography variant={"subtitle1"} className={classes.subtitle}>
            {subtitle}
          </Typography>
          <img src={icon} alt={count} className={classes.icon} />
        </div>
      </div>
    </Paper>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  subtitle: PropTypes.string,
  percentage: PropTypes.number,
  background: PropTypes.string,
};

export default Card;
