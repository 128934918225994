import { Paper, Tooltip, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SensorApi from "api/sensor";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import classNames from "classnames";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SmallChart from "./SmallChart";
import { default as AlertDialog } from "components/Dialog/info";
import { useSnackbar } from "notistack";

const useToolBarStyles = makeStyles(mtoolbarstyle);
const Container = props => <Paper elevation={0} {...props} />;

const useStyles = makeStyles(({ palette }) => ({
  offline: {
    width: 10,
    height: 10,
    marginRight: 5,
    borderRadius: "50%",
    background: palette.background.dark,
  },
  online: {
    background: "#56d364",
  },
  statusGroup: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  link: {
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

const activeMinutes = 5;

function Table({ setData }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [sensorsList, setSensorsList] = useState([]);
  const toolbarClasses = useToolBarStyles();
  const [thresholdData, setThresholdData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const Title = () => <Typography variant="h2">{t("Labels.List of Sensors")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  useEffect(() => {
    SensorApi.getAll()
      .then(res => {
        setSensorsList(res);

        setThresholdData(res.filter(sensor => sensor.average[sensor.average.length - 1].value >= sensor.threshold));
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      });
  }, [enqueueSnackbar]);

  return (
    <>
      <MaterialTable
        options={{
          toolbar: true,
          draggable: false,
          detailPanelColumnAlignment: "left",
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
            border: "3px solid",
            borderColor: palette.background.default,
          },
          rowStyle: {
            border: "3px solid",
            borderColor: palette.background.default,
          },
          cellStyle: {
            borderBottom: 0,
          },
        }}
        components={{
          Container,
          Toolbar,
        }}
        title={Title()}
        columns={[
          {
            title: t("Labels.Sensor Model"),
            align: "center",
            field: "model",
          },
          {
            title: t("Labels.Status"),
            align: "center",
            field: "status",
            render: rowData => {
              const online = new Date(rowData.lastUpdatedAt) > Date.now() - activeMinutes * 60 * 1000;
              return (
                <div className={classes.statusGroup}>
                  <Tooltip placement="top" title={t("Last data at") + " " + new Date(rowData.lastUpdatedAt).toLocaleString()}>
                    <div className={classNames(classes.offline, { [classes.online]: online })} />
                  </Tooltip>
                  {t(`Labels.${online ? "Online" : "Offline"}`)}
                </div>
              );
            },
          },
          {
            title: t("Labels.Location"),
            align: "center",
            field: "location",
          },
          // {
          //   title: t("Type"),
          //   align: "center",
          //   field: "type",
          // },
          {
            title: t("Labels.Threshold"),
            align: "center",
            field: "threshold",
            render: rowData => rowData.threshold + " " + t(rowData.unit),
          },
          {
            title: t("Labels.View"),
            field: "view",
            sorting: false,
            render: rowData => (
              <span className={classes.link} onClick={() => setData(rowData)}>
                {t("Labels.View Log")}
              </span>
            ),
          },
          {
            title: t("Labels.Last 7-Days"),
            field: "chart",
            sorting: false,
            render: rowData => <SmallChart data={rowData.average} />,
          },
        ]}
        data={sensorsList}
      />

      <AlertDialog
        header={t("Alert")}
        image={require("assets/img/insufficientBalance.png")}
        descriptions={[`Your ${thresholdData[0]?.type} in ${thresholdData[0]?.location} is above threshold`]}
        buttonText={t("Labels.Continue")}
        open={thresholdData.length > 0}
        handleClose={() => setThresholdData(thresholdData.slice(1))}
      />
    </>
  );
}

export default Table;
