import CollapseCard from "components/CollapseCard";
import TextField from "components/TextField";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Switch } from "@material-ui/core";

const Embed = ({ link, height, onChange, onDelete, enableBorder, onChecked }) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState();

  const { register } = useForm();

  useEffect(() => {}, []);

  return (
    <CollapseCard onDelete={onDelete} collapse={collapse} handleCollapse={() => setCollapse(!collapse)} draggable title={t("Labels.Embed Web Page")}>
      <TextField labelPlacement="left" name="link" inputRef={register} label={t("Link")} value={link} placeholder={link} onChange={onChange} />
      <TextField
        labelPlacement="left"
        name="height"
        inputRef={register}
        label={t("Height")}
        value={height}
        placeholder={height}
        onChange={onChange}
      />
      <FormControlLabel
        control={<Switch name="enableBorder" className="switch" checked={enableBorder} onChange={onChecked} />}
        label="Enable Border"
      />
    </CollapseCard>
  );
};

Embed.propTypes = {
  onDelete: PropTypes.func,
};

export default Embed;
