import { Button, Fade, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import verifySensorStyle from "assets/jss/components/verifySensorStyle";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LineChart from "./LineChart";
import Timeline from "./Timeline";

const useStyles = makeStyles(verifySensorStyle);

const getSensorId = _ => Number(Object.keys(_).filter(j => j !== "s" && j !== "e")[0]);
const getSectionName = _ => Object.values(_).filter(i => !Number(i))[0];

function Sensor({ custom_id_label, custom_id, sensorAnswers, sensorsData = [], logisticSensorData = {}, delivered = "" }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const flattenSensorsData = sensorsData.flatMap(({ logs, ...s }) => logs.map(l => ({ ...l, ...s }))).sort((a, b) => a.createdAt - b.createdAt);
  const sections = sensorAnswers?.sort((a, b) => a.s - b.s);
  const data = sections
    ? sections
        ?.map(sa =>
          flattenSensorsData
            .filter(i => i.id === getSensorId(sa) && i.createdAt >= sa.s && i.createdAt <= sa.e)
            .map(_ => ({ ..._, name: getSectionName(sa) }))
        )
        .flatMap(i => i)
    : [];
  const { logs, recipient, ...meta } = logisticSensorData;
  const logisticData = logs?.map(({ time, temp: value }) => {
    const createdAt = time * 1000;
    return {
      ...meta,
      name: delivered && Number(delivered) > 1 && createdAt > delivered ? `Delivered${recipient ? " to " + recipient : ""}` : "Out for delivery",
      createdAt,
      value,
    };
  });
  const allData = data.concat(logisticData || []);
  const summary = [...new Set(allData.map(item => item.name))].map(i => allData.find(j => j.name === i));
  const [renderData, setRenderData] = useState(summary);
  const [latestFirst, setLatestFirst] = useState();
  const [expandList, setExpandList] = useState({});
  const handleToggleSection = name => () => {
    const less = expandList[name];
    setExpandList(_ => ({ ..._, [name]: !_[name] }));
    setRenderData(_ => {
      const newData = _;
      const start = newData.findIndex(i => i.name === name);
      less
        ? newData.splice(start + 1, newData.map(i => i.name === name).lastIndexOf(true) - start)
        : newData.splice(start, 1, ...allData.filter(i => i.name === name));
      return newData;
    });
  };

  return (
    <Fade in timeout={2000} mountOnEnter>
      <Paper className={classes.paper}>
        <Typography variant="h2" className={classes.title}>
          {t("Product Journey")}
        </Typography>

        <Grid container justify="center" alignItems="center" className={classes.container}>
          <img className={classes.img} src={require("assets/svg/Lyksvad.svg")} alt="fish" />
          <div className={classes.headerInfo}>
            <div>{t(custom_id_label)}</div>
            <div className={classes.customId}>{custom_id}</div>
          </div>
        </Grid>
        <Typography variant="subtitle2">
          {[...new Set(sensorsData.map(i => i.type))].reduce((acc, cur) => acc + (acc ? " / " : "") + cur, "") + " " + t("Log")}
        </Typography>
        <LineChart data={allData} />
        <Typography variant="subtitle2" color="primary" className={classes.sort}>
          {t("Sort")}:
          <Button
            onClick={() => setLatestFirst(_ => !_)}
            size="small"
            color="primary"
            className={classes.button}
            endIcon={latestFirst ? <ArrowUpward fontSize="inherit" /> : <ArrowDownward fontSize="inherit" />}
          >
            {latestFirst ? t("Latest to Oldest") : t("Oldest to Latest")}
          </Button>
        </Typography>
        <Timeline
          data={renderData.sort((a, b) => (latestFirst ? b.createdAt - a.createdAt : a.createdAt - b.createdAt))}
          onClick={handleToggleSection}
          expanded={expandList}
        />
      </Paper>
    </Fade>
  );
}

export default Sensor;
