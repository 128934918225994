import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import TagSensorApi from "api/TagSensor";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import classNames from "classnames";
import Button from "components/Button";
import LineChart from "components/Charts/LineChart";
import DateRange from "components/Select/DateRange";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  row: {
    paddingBottom: 24,
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 5,
    marginTop: 15,
    marginBottom: 15,
  },
  addButton: {
    width: "100%",
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
  timeline: {
    padding: 0,
    margin: 0,
  },
  threshold: {
    color: "red",
  },
}));

const useToolBarStyles = makeStyles(mtoolbarstyle);

function TempLog({ sensorId, resetTempLog }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [logsList, setLogsList] = useState([]);
  const [sensor, setSensor] = useState();
  const [date, setDate] = useState({ from: "1w" });
  const toolbarClasses = useToolBarStyles();
  const { enqueueSnackbar } = useSnackbar();

  const Container = props => <Paper elevation={0} {...props} />;
  const Title = () => <Typography variant="h2">{t("Logs")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  useEffect(() => {
    // const d = processDates(date?.from, date?.to); // to be used for log date range

    TagSensorApi.getById(sensorId)
      .then(res => {
        setLogsList(
          res.tagdata?.tagdataresult
            ?.map(({ id, time, temp }) => {
              return { id, time: time * 1000, value: temp };
            })
            .reverse()
        );
        setSensor(res.tagprofile);
      })
      .catch(e => {
        enqueueSnackbar("Error calling the temp data", {
          variant: "error",
        });
      });
  }, [sensorId, date, enqueueSnackbar]);

  return (
    <Grid container spacing={0}>
      <Grid item className={classes.row} xs={6} md={3}>
        <Typography variant="h4">
          {t("Date Range")}
          <DateRange
            defaultValue={date?.from}
            onChange={_ => setDate(_)}
            values={[
              { label: t("Labels.Audience.Today"), value: "1d" },
              { label: t("Labels.This Week"), value: "1w" },
              { label: t("Labels.This Month"), value: "1m" },
              { label: t("Labels.This Year"), value: "1y" },
            ]}
          />
        </Typography>
      </Grid>
      <Grid item className={classes.row} xs={12}>
        {logsList?.length ? (
          <LineChart
            data={logsList}
            title={t("Temperature Log")}
            values={[{ value: "value", label: t("Temperature") }]}
            threshold={sensor?.maxlimit}
            unit={sensor?.unit}
          />
        ) : (
          <Typography variant="h4" className={classes.text}>
            {t("No records to display")}
          </Typography>
        )}
      </Grid>

      <Grid item className={classes.row} xs={8}>
        <MaterialTable
          options={{
            toolbar: true,
            draggable: false,
            detailPanelColumnAlignment: "left",
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: palette.background.default,
              border: "3px solid",
              borderColor: palette.background.default,
            },
            rowStyle: {
              border: "3px solid",
              borderColor: palette.background.default,
            },
            cellStyle: {
              borderBottom: 0,
            },
          }}
          components={{
            Container,
            Toolbar,
          }}
          title={Title()}
          columns={[
            {
              title: t("Date"),
              align: "center",
              field: "date",
              render: rowData => new Date(rowData.time).toLocaleDateString([], { day: "numeric", month: "long", year: "numeric" }),
            },
            {
              title: t("Time"),
              align: "center",
              field: "time",
              render: rowData => new Date(rowData.time).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }),
            },
            {
              title: t("Temperature"),
              align: "center",
              field: "value",
              render: rowData => <div className={classNames({ [classes.threshold]: rowData.temp >= sensor?.maxlimit })}>{rowData.value}</div>,
            },
          ]}
          data={logsList}
        />
        <Button className={classes.button} variant="outlined" buttonText={t("Labels.Back")} onClick={resetTempLog} />
      </Grid>
    </Grid>
  );
}

export default TempLog;
