export default ({ palette, typography }) => ({
  addButton: {
    backgroundColor: palette.background.default,
    color: typography.color,
    height: 37,
    width: "95%",
    margin: 5,
  },
  button: {
    height: 37,
    marginLeft: 5,
    width: 212,
  },
  buttonGroup: {
    paddingBottom: 12,
    paddingRight: 12,
    paddingTop: 24,
  },
  buttonLabel: {
    paddingLeft: 7,
    paddingTop: 16,
    width: 50,
  },
  formDiv: {
    display: "flex",
    padding: 5,
  },
  link: {
    color: palette.primary.main,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  mockup: {
    position: "sticky",
    top: 0,
    height: 768,
    display: "grid",
    placeItems: "center",
  },
  phone: {
    width: 392,
    height: 768,
    position: "absolute",
    zIndex: 1,
  },
  preview: {
    marginTop: 15,
    position: "relative",
    textAlign: "center",
    width: "41vh",
  },
  screen: {
    height: 712,
    width: 336,
    borderBottomRightRadius: 40,
    borderBottomLeftRadius: 40,
  },
  screenBackground: {
    backgroundColor: palette.background.verify,
    borderRadius: 24,
    zIndex: 0,
    position: "absolute",
    transition: "background-color 1s",
  },
  content: {
    overflowY: "auto",
    zIndex: 2,
    marginTop: 28,
    height: 694,
  },
  section: {
    paddingTop: 11,
  },
  selectorGroup: {
    border: `3px solid ${palette.background.default}`,
    margin: "3vh 0vh",
  },
  row: {
    display: "inline-block",
  },
  checkbox: {
    marginLeft: 10,
  },
  checkboxLabel: {
    width: 105,
  },
  title: {
    marginTop: 12,
    marginLeft: 12,
  },
  divider: {
    margin: "18px 0px",
  },
});
