import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Typography } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import LogisticApi from "api/logistic";
import TagSensorApi from "api/TagSensor";
import codeActivationStyle from "assets/jss/components/codeActivationStyle";
import manageSKUStyles from "assets/jss/components/manageSKUStyles";
import classNames from "classnames";
import Button from "components/Button";
import Select from "components/Select";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import PapaParse from "papaparse";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";

const useSkuStyles = makeStyles(manageSKUStyles);
const useStyles = makeStyles(codeActivationStyle);

function AddList({ open, handleClose, title, data, loading, setLoading, callData }) {
  const skuClasses = useSkuStyles();
  const classes = useStyles();

  const { register, errors, control, handleSubmit, reset } = useForm();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { demo } = useSelector(_ => _.user);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [sensorList, setSensorList] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const onClose = () => {
    reset({});
    setIsEdit(false);
    handleClose();
    setCsvData([]);
    callData();
    setUploadedFiles([]);
    setLoading(false);
  };

  const saveForm = async formData => {
    setLoading(true);

    isEdit
      ? LogisticApi.update(data.id, { ...data, ...formData })
          .then(res => {
            enqueueSnackbar(res.status);
          })
          .catch(e => {
            enqueueSnackbar(e, {
              variant: "error",
            });
          })
          .finally(() => {
            onClose();
          })
      : LogisticApi.create({ ...formData, addresses: csvData })
          .then(res => {
            enqueueSnackbar(res.status);
          })
          .catch(e => {
            enqueueSnackbar(e, {
              variant: "error",
            });
          })
          .finally(() => {
            onClose();
          });
  };

  useEffect(() => {
    if (open) {
      TagSensorApi.getAllActivated()
        .then(res => {
          setSensorList(res.tags?.sort((a, b) => b.tagstarttime - a.tagstarttime));
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });
      Object.keys(data).length && setIsEdit(true);
    }
  }, [data, enqueueSnackbar, open]);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      PapaParse.parse(acceptedFiles[0], {
        header: true,
        skipEmptyLines: "greedy",
        complete: results => {
          if (results.data.length > 0) {
            setCsvData(results.data.map(i => i.Codes));
          }
        },
        transform: (val, header) => (["Codes"].includes(header) ? new URL(val).searchParams.get("a") : val),
      });
      setUploadedFiles(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: ".csv",
    multiple: false,
  });

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <form>
        <DialogTitle disableTypography>
          <Typography variant="h1">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <div className={skuClasses.grid} style={{ padding: "2% 5%" }}>
                <Typography variant="h2" className={skuClasses.specs}>
                  {t("Labels.Details")}
                </Typography>

                <Select
                  name="manufacturer_id"
                  control={control}
                  defaultValue={data?.manufacturer_id}
                  labelPlacement="left"
                  className={skuClasses.root}
                  label={t("Labels.Tag Sensor ID")}
                  error={errors.manufacturer_id}
                  helperText={t("Please select an ID")}
                  labelProps={{ style: { width: 282 } }}
                  tooltipText={
                    <img alt="TagSensor ID Location" width="100%" height="100%" src={require("assets/img/tagsensor-id-location.jpg")}></img>
                  }
                  fullWidth
                >
                  <MenuItem value={null}>&nbsp;</MenuItem>
                  {sensorList?.map(sensor => {
                    return (
                      <MenuItem key={`sensor_${sensor.tidnfc}`} value={sensor.tidnfc}>
                        <Grid container>
                          <Typography color="textPrimary">{sensor.tidnfc}</Typography>
                          <Typography color="textSecondary">
                            &nbsp; - {t("Started at")}&nbsp;
                            {new Date(sensor.tagstarttime * 1000).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </Typography>
                        </Grid>
                      </MenuItem>
                    );
                  })}
                </Select>

                <TextField
                  labelPlacement="left"
                  required
                  className={skuClasses.root}
                  inputRef={register({ required: true })}
                  name="recipient"
                  label={t("Labels.Recipient")}
                  error={errors.recipient}
                  defaultValue={data?.recipient}
                  helperText={t("Please fill in recipient Name")}
                  labelProps={{ style: { width: 282 } }}
                  fullWidth
                />

                {isEdit && (
                  <>
                    <TextField
                      labelPlacement="left"
                      required
                      className={skuClasses.root}
                      inputRef={register({ required: true })}
                      name="identifier"
                      label={t("Identifier")}
                      error={errors.identifier}
                      defaultValue={data?.identifier}
                      helperText={t("Please fill in identifier")}
                      labelProps={{ style: { width: 282 } }}
                      fullWidth
                    />

                    <TextField
                      labelPlacement="left"
                      required
                      className={skuClasses.root}
                      inputRef={register({ required: true })}
                      name="shipped_at"
                      type="date"
                      label={t("Shipped At")}
                      error={errors.shipped_at}
                      defaultValue={data?.shipped_at}
                      helperText={t("Please fill in shipped at date")}
                      labelProps={{ style: { width: 282 } }}
                      fullWidth
                    />
                  </>
                )}

                <TextField
                  labelPlacement="left"
                  className={skuClasses.root}
                  inputRef={register({ required: false })}
                  name="note"
                  label={t("Labels.Note")}
                  defaultValue={data?.note}
                  labelProps={{ style: { width: 282 } }}
                  fullWidth
                  multiline
                  rows={2}
                />

                {!isEdit && (
                  <>
                    <Grid item align="center" className={classNames(classes.action, classes.dndMargin)}>
                      <div className={classes.dragndrop} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Typography variant="h3" className={classNames(classes.bold, classes.dropzoneText)}>
                          {isDragActive ? t("Instructions.Drop it like it's hot!") : t("HelperText.Drag and Drop CSV file here")}
                        </Typography>
                        <Typography variant="h3" className={classNames(classes.bold, classes.dropzoneText)}>
                          {t("Labels.OR")}
                        </Typography>
                        <Button component="span" className={classes.button} buttonText={t("Labels.Upload")} />
                        <Typography variant="h3" className={classNames(classes.semiTrans, classes.dropzoneText)}>
                          {uploadedFiles.length > 0
                            ? uploadedFiles.map(acceptedFile => t("Labels.File selected") + ": " + acceptedFile.name)
                            : t("HelperText.Max File 10MB")}
                        </Typography>
                        {fileRejections.length > 0 && <Typography text={fileRejections[0].errors[0].message} />}
                      </div>
                    </Grid>
                    <div className={classes.positionEnd}>
                      <Alert severity="success" className={classNames(classes.halfSize, classes.dndMargin)}>
                        {csvData.length} {t("codes uploaded")}
                      </Alert>
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <div className={skuClasses.buttonGroup}>
            <Button onClick={onClose} variant="outlined" buttonText={t("Labels.Cancel")} className={skuClasses.button} />
            <Button
              type="submit"
              disabled={loading || demo}
              onClick={handleSubmit(saveForm)}
              autoFocus
              className={skuClasses.button}
              loading={loading}
              loadingText={loading && t("Messages.Saving")}
              buttonText={isEdit ? t("Labels.Update") : t("Labels.Create")}
              endIcon={<ChevronRightRounded />}
            />
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddList;
