import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { DeleteRounded, EditRounded, MoreHoriz } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import LogisticApi from "api/logistic";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import A from "components/A";
import Button from "components/Button";
import { default as ReminderDialog } from "components/Dialog/confirmation";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddListDialog from "./ListDialog";

const useToolBarStyles = makeStyles(mtoolbarstyle);
const Container = props => <Paper elevation={0} {...props} />;

const useStyles = makeStyles(({ palette }) => ({
  offline: {
    width: 10,
    height: 10,
    marginRight: 5,
    borderRadius: "50%",
    background: palette.background.dark,
  },
  online: {
    background: "#56d364",
  },
  statusGroup: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  link: {
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

function Table({ setItemsList, setTempLog }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [logisticsList, setLogisticsList] = useState([]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const toolbarClasses = useToolBarStyles();
  const { enqueueSnackbar } = useSnackbar();

  const Title = () => <Typography variant="h2">{t("Labels.List of Logistics")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData({ ...rowData, sensorId: rowData.manufacturer_id });
    event.stopPropagation();
  };

  const callData = useCallback(() => {
    setLoading(true);

    LogisticApi.getAll()
      .then(res => {
        setLogisticsList(res);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [enqueueSnackbar]);

  const onClose = () => {
    setSelectedRowData();
    setAnchorEl();
  };

  const deleteList = () => {
    setLoading(true);

    LogisticApi.remove(selectedRowData.id)
      .then(res => {
        enqueueSnackbar(res.status);
        callData();
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => onClose());
  };

  useEffect(() => {
    callData();
  }, [callData]);

  return (
    <>
      <Button
        onClick={() => {
          setAlertDialog(true);
          setSelectedRowData({});
        }}
        buttonText={t("Add Logistic")}
      />

      <MaterialTable
        options={{
          toolbar: true,
          draggable: false,
          detailPanelColumnAlignment: "left",
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
            border: "3px solid",
            borderColor: palette.background.default,
          },
          rowStyle: {
            border: "3px solid",
            borderColor: palette.background.default,
          },
          cellStyle: {
            borderBottom: 0,
          },
        }}
        components={{
          Container,
          Toolbar,
        }}
        title={Title()}
        columns={[
          {
            title: t("Labels.Date Created"),
            align: "center",
            field: "createdAt",
            render: rowData => new Date(rowData.createdAt).toLocaleDateString([], { day: "numeric", month: "short", year: "numeric" }),
          },
          {
            title: t("Labels.Post ID"),
            align: "center",
            field: "identifier",
          },
          {
            title: t("Labels.Recipient"),
            align: "center",
            field: "recipient",
          },
          {
            title: t("Labels.Note"),
            align: "center",
            field: "note",
          },
          {
            title: t("Labels.Item Quantity"),
            align: "center",
            field: "quantity",
          },
          {
            title: t("Labels.Shipped At"),
            align: "center",
            render: ({ shipped_at, createdAt }) =>
              new Date(shipped_at || createdAt).toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              }),
          },
          {
            title: t("Labels.Delivered At"),
            align: "center",
            render: ({ delivered_at }) =>
              delivered_at
                ? new Date(delivered_at).toLocaleString([], {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : t("Out for delivery"),
          },
          {
            title: t("Labels.Temperature Log"),
            field: "view",
            align: "center",
            sorting: false,
            render: rowData =>
              rowData.manufacturer_id ? (
                <span className={classes.link} onClick={() => setTempLog(rowData)}>
                  {t("Labels.View")}
                </span>
              ) : (
                <em>{t("-")}</em>
              ),
          },
          {
            title: t("Labels.List of Items"),
            field: "view",
            align: "center",
            sorting: false,
            render: rowData => (
              <span className={classes.link} onClick={() => setItemsList(rowData)}>
                {t("Labels.View")}
              </span>
            ),
          },
          {
            title: t("Labels.Action"),
            align: "center",
            field: "action",
            sorting: false,
            render: rowData => (
              <IconButton onClick={e => handleClick(e, rowData)}>
                <MoreHoriz />
              </IconButton>
            ),
          },
        ]}
        data={logisticsList}
      />

      <ReminderDialog
        header={t("Labels.Reminder")}
        image={require("assets/img/reminder.png")}
        descriptions={[
          t("Before adding your logistic, start your"),
          <>
            {t("TagSensor using")}&nbsp;
            <A href="https://tag-sensors.com/app-download/">TAG Tap</A>
            &nbsp;{t("before continue")}
          </>,
        ]}
        submitText={t("Labels.Continue")}
        cancelText={t("Labels.Cancel")}
        onClick={() => {
          setAlertDialog(false);
          setAddDialog(true);
        }}
        handleClose={() => setAlertDialog(false)}
        open={alertDialog}
      />

      <AddListDialog
        title={selectedRowData?.id ? t("Edit Logistic") : t("Add Logistic")}
        handleClose={() => setAddDialog(false)}
        open={addDialog}
        data={selectedRowData}
        loading={loading}
        setLoading={setLoading}
        callData={callData}
      />

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={onClose}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl();
            setAlertDialog(true);
          }}
        >
          <ListItemIcon className={classes.itemIcon}>
            <EditRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Labels.Edit")} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={deleteList}>
          <ListItemIcon className={classes.itemIcon}>
            <DeleteRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Delete")} />
        </MenuItem>
      </Menu>
    </>
  );
}

export default Table;
