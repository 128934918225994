import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { apiURL } from "api";
import cx from "clsx";
import DotIndicator from "components/DotIndicator";
import ParallaxSlide from "components/Parallax";
import React from "react";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  slide: {
    perspective: 1000,
    overflow: "hidden",
    position: "relative",
    paddingTop: 0,
  },
  imageContainer: {
    width: 125,
    height: 125,
  },
  image: {
    objectFit: "cover",
    overflow: "hidden",
    width: "100%",
    height: "auto",
  },
  imageBackground: {
    backgroundSize: "contain",
    position: "absolute",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
  },
  arrow: {
    display: "none",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    [breakpoints.up("sm")]: {
      display: "inline-flex",
    },
    height: "100%",
    minWidth: "auto",
  },
  arrowLeft: {
    left: 0,
  },
  arrowRight: {
    right: 0,
  },
  indicatorContainer: {
    textAlign: "center",
  },
}));

const Carousel = ({ data = [] }) => {
  const classes = useStyles();
  const renderElements = ({ index, onChangeIndex }) => (
    <>
      <Button className={cx(classes.arrow, classes.arrowLeft)} disabled={index === 0} onClick={() => onChangeIndex(index - 1)}>
        <KeyboardArrowLeft />
      </Button>
      <Button className={cx(classes.arrow, classes.arrowRight)} disabled={index === data.length - 1} onClick={() => onChangeIndex(index + 1)}>
        <KeyboardArrowRight />
      </Button>
      <div className={classes.indicatorContainer}>
        {data.map((e, i) => (
          <DotIndicator key={i} active={i === index} onClick={() => onChangeIndex(i)} />
        ))}
      </div>
    </>
  );
  const renderChildren = () =>
    data.map((e, i) => {
      const containsURL = e?.imageExternalUrl !== "" && e?.imageExternalUrl;
      return (
        <div key={i} className={classes.slide}>
          <div className={classes.imageContainer}>
            {e.hash ? (
              <div
                {...(containsURL && { onClick: () => window.open(e?.imageExternalUrl, "_blank") })}
                className={classes.imageBackground}
                style={{ backgroundImage: `url(${`${apiURL}/files/${e.hash}${e.ext}`})`, cursor: e?.imageExternalUrl ? "pointer" : "unset" }}
              />
            ) : (
              <div
                {...(containsURL && { onClick: () => window.open(e.imageExternalUrl, "_blank") })}
                className={classes.imageBackground}
                style={{
                  backgroundImage: `url(${e?.url || e})`,
                  cursor: containsURL ? "pointer" : "unset",
                }}
              />
            )}
          </div>
        </div>
      );
    });
  return <div className={classes.root}>{data && <ParallaxSlide renderElements={renderElements}>{renderChildren}</ParallaxSlide>}</div>;
};

export default Carousel;
