import { Dialog, Grid, Typography } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import Button from "components/Button";
import React from "react";
import Lottie from "react-lottie";

const usestyles = makeStyles(() => ({
  title: {
    textAlign: "center",
    padding: 25,
  },
  img: {
    height: 240,
  },
  paper: {
    padding: "24px 224px",
    textAlign: "center",
  },
  message: {
    lineHeight: "1.25",
    textAlign: "center",

    marginBottom: 10,

    padding: 10,
  },
  button: {
    height: 37,
    width: 212,
    margin: 10,
  },
}));

function Confirmation({
  header,
  image,
  descriptions,
  submitText,
  cancelText,
  onClick,
  handleClose,
  lottieAnimationData,
  enableImage = true,
  titleProps,
  primaryButtonProps,
  secondaryButtonProps,
  ...props
}) {
  const classes = usestyles();

  return (
    <Dialog maxWidth={"md"} PaperProps={{ className: classes.paper }} {...props}>
      <Grid justify="center">
        <Typography variant="h1" className={classes.title} {...titleProps}>
          {header}
        </Typography>

        {enableImage &&
          (lottieAnimationData ? (
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: lottieAnimationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height="75%"
              autoplay
              loop
              width="50%"
            />
          ) : (
            <img alt={header} className={classes.img} src={image} />
          ))}
        <Typography variant="subtitle1" className={classes.message}>
          {descriptions
            .reduce((a, b) => a.concat(b).concat(<br />), [])
            .slice(0, -1)
            .map(_ => _)}
        </Typography>
        <Grid container justify="center" xs={12}>
          <Grid xs={6}>
            <Button className={classes.button} variant="outlined" buttonText={cancelText} onClick={handleClose} {...secondaryButtonProps} />
          </Grid>
          <Grid xs={6}>
            <Button className={classes.button} buttonText={submitText} onClick={onClick} endIcon={<ChevronRightRounded />} {...primaryButtonProps} />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default Confirmation;
