import PageContent from "components/PageContent";
import React from "react";
import { useTranslation } from "react-i18next";
import Fields from "./Fields";

function Business() {
  const { t } = useTranslation();
  return (
    <PageContent header={t("Labels.Business Info Settings")}>
      <Fields />
    </PageContent>
  );
}

export default Business;
