import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import Fields from "./Fields";

function BrandCreate({ open, handleClose, title, data }) {
  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Fields onCancel={handleClose} data={data} />
      </DialogContent>
    </Dialog>
  );
}

export default BrandCreate;
