import { Card, CardContent, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import UserApi from "api/user";
import landingStyle from "assets/jss/views/landing";
import classNames from "classnames";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import RegExpPattern from "utils/index.js";
import Dialog from "./Dialog";

const useLandingStyles = makeStyles(landingStyle);

const useStyles = makeStyles({
  root: {
    margin: 150,
    padding: "40px 0px",
  },
  subheader: {
    paddingBottom: 22,
  },
  textfield: {
    marginBottom: 0,
  },
  button: {
    marginTop: 20,
  },
});

function Recover() {
  const [loading, setLoading] = useState();
  const landingClasses = useLandingStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState();
  const [email, setEmail] = useState();

  const onSubmit = data => {
    setEmail(data.email);
    setLoading(true);
    UserApi.sendRecoveryEmail(data)
      .then(() => {
        setOpen(true);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
        setLoading();
      });
  };

  return (
    <form>
      <Card variant="outlined" className={classNames(landingClasses.card, classes.root)}>
        <img alt="brandtag" src={require("assets/svg/BrandTag.svg")} width="60%" />

        <CardContent>
          <Typography className={classes.subheader}>{t("Labels.I forgot my password")}</Typography>
          <TextField
            inputRef={register({
              required: true,
              pattern: RegExpPattern.email(),
            })}
            error={errors.email}
            name="email"
            label={t("Labels.Enter Email")}
            className={classNames(landingClasses.textfield, classes.textfield)}
            helperText={t("HelperText.Please provide a valid email address")}
          />
          <Button
            loading={loading}
            loadingText={t("Messages.Sending")}
            buttonText={t("Labels.Send recovery link")}
            type="submit"
            fullWidth
            className={classes.button}
            onClick={handleSubmit(onSubmit)}
          />
          <br />
          <Divider />
          <br />
          <NavLink to="/" className={landingClasses.link}>
            <div className={landingClasses.recover}>{t("Labels.Return to Sign in")}</div>
          </NavLink>
        </CardContent>
      </Card>
      <Dialog email={email} open={open} />
    </form>
  );
}

export default Recover;
