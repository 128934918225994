import { Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SKUApi from "api/sku";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import Button from "components/Button";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useToolbarStyles = makeStyles(mtoolbarstyle);

const useStyles = makeStyles(({ palette }) => ({
  status: {
    border: `3px solid ${palette.background.default}`,
  },
}));

function Table({ setData, setSKUs }) {
  const { t } = useTranslation();
  const [skuList, setSkuList] = useState([]);
  const { palette } = useTheme();
  const toolbarClasses = useToolbarStyles();
  const classes = useStyles();
  const { demo } = useSelector(_ => _.user);

  useEffect(() => {
    if (!skuList.length) {
      SKUApi.getAll().then(res => {
        setSkuList(res);
      });
    }
  }, [skuList, demo]);

  const Title = () => <Typography variant="h2">{t("Labels.List of SKUs")}</Typography>;
  const Container = props => <Paper elevation={0} {...props} />;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  return (
    <MaterialTable
      columns={[
        { title: t("Labels.Name"), align: "center", field: "name" },
        { title: t("Labels.SKU"), align: "center", field: "sku" },
        {
          title: t("Labels.Customization"),
          align: "center",
          render: rowData => {
            const pristine = rowData?.details?.customization && Object.keys(rowData?.details?.customization).length;
            const isDraft = rowData?.details?.customization && rowData?.details?.customization.isDraft;
            return (
              <Button
                variant="outlined"
                className={pristine && classes.status}
                buttonText={isDraft ? "Resume Draft" : pristine ? t("Labels.Edit") : t("Labels.Customize me!")}
                fullWidth
                onClick={() => {
                  setData(rowData);
                  setSKUs(skuList);
                }}
              />
            );
          },
        },
      ]}
      data={skuList}
      title={Title()}
      options={{
        toolbar: true,
        draggable: false,
        emptyRowsWhenPaging: false,
        headerStyle: {
          border: "3px solid",
          borderColor: palette.background.default,
          backgroundColor: palette.background.default,
        },
        rowStyle: {
          border: "3px solid",
          borderColor: palette.background.default,
        },
        cellStyle: {
          borderBottom: 0,
        },
      }}
      components={{
        Container,
        Toolbar,
      }}
    />
  );
}

export default Table;
