import { Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { appbarHeight, bannerHeight, demoHeight } from "assets/jss";
import classNames from "classnames";
import Header from "components/Header";
import SettingsSidebar from "components/SettingsSidebar";
import Sidebar from "components/Sidebar";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import adminRouteList from "routes/admin";
import settingsRoutes from "routes/settings";
import userRouteList from "routes/user";
import { createRoutes } from "routes/utils";
import { decodeJWTPayload } from "utils";
import Announcement from "views/Announcement";
import { tempTagMerchants } from "constants.js";

const adminRoutes = createRoutes(adminRouteList);
const settingRoutes = createRoutes(settingsRoutes);

const useStyles = makeStyles({
  root: {
    display: "flex",
    overflowY: "hidden",
  },
  mainPanel: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
    marginTop: appbarHeight,
  },
  demo: {
    marginTop: appbarHeight + demoHeight,
  },
  banner: {
    marginTop: appbarHeight + bannerHeight,
  },
  main: {
    minHeight: "calc(100% - 85px)",
  },
  announcement: {
    padding: 0,
  },
  body: {
    flex: 1,
    padding: 0,
    height: `calc(100vh - ${appbarHeight}px)`,
    overflowY: "scroll",
  },
  bannerBody: {
    height: `calc(100vh - ${appbarHeight + bannerHeight}px)`,
  },
  demoBody: {
    height: `calc(100vh - ${appbarHeight + demoHeight}px)`,
  },
});

function Dashboard() {
  const [open, setOpen] = useState(true);
  const [openMobile, setOpenMobile] = useState();
  const mainEl = useRef();
  const classes = useStyles();
  const { demo, jwtToken, banner } = useSelector(_ => _.user);
  const { isAdmin, merchant } = decodeJWTPayload(jwtToken);
  const userRoutes = createRoutes(userRouteList.filter(i => (tempTagMerchants.includes(merchant) ? i : i.label !== "Labels.Temperature Tagging")));
  const settings = window.location.pathname.startsWith("/settings");
  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Header onDrawerOpen={() => setOpen(!open)} />
      </Hidden>
      <Hidden mdUp>
        <Header onDrawerOpen={() => setOpenMobile(!openMobile)} />
      </Hidden>
      {settings ? (
        <SettingsSidebar
          open={open}
          openMobile={openMobile}
          onDrawerClose={type => () => type === "mobile" ? setOpenMobile(false) : setOpen(false)}
          routes={settingsRoutes}
        />
      ) : (
        <Sidebar
          open={open}
          onDrawerOpen={() => setOpen(!open)}
          openMobile={openMobile}
          onDrawerClose={type => () => type === "mobile" ? setOpenMobile(false) : setOpen(false)}
          routes={
            isAdmin
              ? adminRouteList
              : demo
              ? userRouteList
                  .filter(i => (tempTagMerchants.includes(merchant) ? i : i.label !== "Labels.Temperature Tagging"))
                  .map(i => {
                    return {
                      ...i,
                      children: i.children?.filter(j => j.path !== "/settings"),
                    };
                  })
              : userRouteList.filter(i => (tempTagMerchants.includes(merchant) ? i : i.label !== "Labels.Temperature Tagging"))
          }
        />
      )}

      <div
        id="main-panel"
        ref={mainEl}
        className={classNames(classes.mainPanel, {
          [classes.banner]: banner,
          [classes.demo]: demo,
        })}
      >
        <div className={classes.announcement}>
          <Announcement />
        </div>
        <div
          className={classNames(classes.body, {
            [classes.bannerBody]: banner,
            [classes.demoBody]: demo,
          })}
        >
          <Switch>{settings ? settingRoutes : isAdmin ? adminRoutes : userRoutes}</Switch>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
