export default () => ({
  root: {
    borderRadius: 4,
    backgroundColor: "transparent",
  },
  input: {
    padding: 13,
  },
  multiline: {
    padding: 0,
  },
  underline: {
    "&:before": {
      border: "unset",
      content: "unset",
    },
  },
  disabled: {
    border: "unset",
  },
});
