import { Divider, Grid, Typography } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import BillingApi from "api/billing";
import InsightsApi from "api/insights";
import PayloadApi from "api/payload";
import Button from "components/Button";
import Card from "components/Card";
import LineChart from "components/Charts/LineChart";
import PageContent from "components/PageContent";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GeneratedCodeTable from "./GeneratedCodeTable";
import ActivatedCodeTable from "./ActivatedCodeTable";
import { getEpochDates } from "utils/date";

const useStyles = makeStyles({
  root: {
    padding: 24,
  },
  table: {
    paddingRight: 24,
  },
  paper: {
    padding: "14px 38px",
    paddingLeft: 0,
  },
  section: {
    marginTop: 24,
  },
  introSection: {
    marginBottom: 24,
  },
  button: {
    width: 212,
    height: 37,
  },
  title: {
    fontWeight: 600,
  },
});
function Dashboard() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [generatedCode, setGeneratedCode] = useState();
  const [activatedCode, setActivatedCode] = useState();
  const [chartData, setChartData] = useState();
  const [creditBalance, setCreditBalance] = useState();
  const { progress, demo } = useSelector(_ => _.user);
  const { businessUpdated, trademarkRegistered } = progress;

  useEffect(() => {
    const getGeneratedCode = async () => {
      try {
        const res = await BillingApi.getBalance();
        if (res) {
          setGeneratedCode(res.usedCodes);
          setCreditBalance(res.remainingCodes);
        }
      } catch (e) {
        enqueueSnackbar(e, {
          variant: "error",
        });
      }
    };

    const getActivatedCode = async () => {
      try {
        const resData = await PayloadApi.getTotalActivated();
        if (resData) {
          setActivatedCode(resData);
        }
      } catch (e) {
        enqueueSnackbar(e, {
          variant: "error",
        });
      }
    };

    if (progress?.emailConfirmed) {
      !generatedCode && getGeneratedCode();
      !activatedCode && getActivatedCode();
    }
  }, [activatedCode, enqueueSnackbar, generatedCode, progress, demo]);

  useEffect(() => {
    const lineChartData = async () => {
      try {
        const d = getEpochDates("1y");
        InsightsApi.getAll(d)
          .then(res => {
            setChartData(res);
          })
          .catch(e => {
            throw e;
          });
      } catch (e) {
        enqueueSnackbar(e, {
          variant: "error",
        });
      }
    };

    lineChartData();
  }, [enqueueSnackbar, demo]);

  return (
    <PageContent
      header={!businessUpdated || !trademarkRegistered ? t("Labels.Welcome to BrandTag") : t("Labels.Dashboard")}
      subheader={!businessUpdated || !trademarkRegistered ? t("Messages.We've assembled some links to get you started") : undefined}
    >
      {(!businessUpdated || !trademarkRegistered) && (
        <>
          <Divider />
          <Grid container className={classes.introSection}>
            {!businessUpdated && (
              <Grid item xs={12} sm={6}>
                <div className={classes.section}>
                  <Typography variant="h2" gutterBottom className={classes.title}>
                    {t("Labels.Complete Your Registration")}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("HelperText.Not registering will prevent generated codes from being verified")}
                  </Typography>
                  <Link to="/settings/business">
                    <Button className={classes.button} endIcon={<ChevronRightRounded />} buttonText={t("Labels.Register")} />
                  </Link>
                </div>
              </Grid>
            )}
            {!trademarkRegistered && (
              <Grid item xs={12} sm={6}>
                <div className={classes.section}>
                  <Typography variant="h2" gutterBottom className={classes.title}>
                    {t("Labels.Get Started")}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("HelperText.Start Brand Registration")}
                  </Typography>
                  <Link to="/brand">
                    <Button className={classes.button} endIcon={<ChevronRightRounded />} buttonText={t("Labels.Register Trademark")} />
                  </Link>
                </div>
              </Grid>
            )}
          </Grid>
          <Divider />
        </>
      )}

      <div className={(!businessUpdated || !trademarkRegistered) && classes.section}>
        <Grid item>
          <Typography variant="h2" gutterBottom className={classes.title}>
            {t("Labels.At a glance")}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6} lg={4}>
              <Link to="generatecode">
                <Card subtitle={t("Labels.Total Code Generation")} count={generatedCode} icon={require("assets/svg/GenerateCode.svg")} />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Link to="activatecode">
                <Card subtitle={t("Labels.Total Code Activation")} count={activatedCode} icon={require("assets/svg/ActivateCode.svg")} />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Link to="settings/credit">
                <Card subtitle={t("Labels.Credit Balance")} count={creditBalance} icon={require("assets/svg/CreditLimit.svg")} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </div>

      {chartData?.trend && (
        <Grid className={classes.section} item xs={12} sm={12} md={12}>
          <LineChart
            data={chartData?.trend}
            values={[
              { value: "creation", label: t("Labels.Total Code Creation") },
              { value: "generation", label: t("Labels.Total Code Generation") },
            ]}
            title={t("Labels.BrandTag activites")}
          />
        </Grid>
      )}

      <Grid container className={classes.section}>
        <Grid className={classes.table} item xs={12} sm={6} md={6}>
          <Typography variant="h2" className={classes.title}>
            {t("Labels.Code Generation Activities")}
          </Typography>
          <GeneratedCodeTable />
        </Grid>
        <Grid className={classes.table} item xs={12} sm={6} md={6}>
          <Typography variant="h2" className={classes.title}>
            {t("Labels.Code Activated Activities")}
          </Typography>
          <ActivatedCodeTable />
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default Dashboard;
