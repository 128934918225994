import api from ".";

const path = "/form";

const getAll = () => api.get(`${path}`);

const get = id => api.get(`${path}/${id}`);

const create = data => api.post(`${path}`, data);

const createSection = (id, data) => api.post(`${path}/${id}/section`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

export default {
  getAll,
  get,
  create,
  createSection,
  update,
  remove,
};
