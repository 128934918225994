import React, { useState } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CollapseCard from "components/CollapseCard";
import TextField from "components/TextField";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const useStyles = makeStyles(({ palette, typography }) => ({
  video: {
    marginTop: 12,
  },
  videoItem: {
    marginLeft: 12,
    marginRight: 12,
    alignItems: "center",
  },
  videoHeader: {
    paddingRight: 12,
  },
}));

const Videos = props => {
  const { value, onDelete, onChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(false);
  return (
    <CollapseCard onDelete={onDelete} collapse={collapse} handleCollapse={() => setCollapse(!collapse)} draggable title={t("Labels.Video")}>
      {collapse && (
        <div className={classes.video}>
          <Grid container className={classNames(classes.videoItem, classes.videoHeader)}>
            <Grid item xs={11}>
              <Typography variant="h3">{t("Labels.Video")}</Typography>
            </Grid>
          </Grid>
          <TextField
            labelPlacement="left"
            name="title"
            label={t("Labels.Video Title")}
            value={value?.title}
            placeholder={t("Labels.Video Title")}
            onChange={onChange}
          />

          <TextField
            labelPlacement="left"
            name="videoUrl"
            label={t("Labels.Video URL")}
            value={value?.videoUrl}
            placeholder={t("Labels.Paste URL")}
            onChange={onChange}
          />

          <TextField
            labelPlacement="left"
            multiline
            rows={4}
            name="description"
            label={t("Labels.Description")}
            value={value?.description}
            placeholder={t("Labels.Description")}
            onChange={onChange}
          />

          <Divider />
        </div>
      )}
    </CollapseCard>
  );
};

Videos.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
    videoUrl: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Videos;
