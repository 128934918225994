import { createMuiTheme } from "@material-ui/core/styles";

import { bnw, light } from "assets/jss";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { enUS, ruRU } from "@material-ui/core/locale";

const createTheme = (theme = "light", _primaryColor, _secondaryColor, language) => {
  let type = "light",
    primary = { main: _primaryColor ?? light.primary, light: light.primaryLight },
    secondary = { main: _secondaryColor ?? light.secondary },
    color = "#0000008a",
    backgroundColor = "#ffffff",
    defaultBackgroundColor = "#efefef";
  switch (theme) {
    case "bnw":
      primary = { main: bnw.primary };
      secondary = { main: bnw.secondary };
      break;
    case "dark":
      primary = { main: _primaryColor ?? light.primary };
      secondary = { main: _secondaryColor ?? light.secondary };
      color = "#ffffffb3";
      backgroundColor = "#263238";
      defaultBackgroundColor = "#34495e";
      type = "dark";
      break;
    default:
      break;
  }

  return createMuiTheme(
    {
      typography: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: 12,
        lineHeight: "1.5",
        letterSpacing: "normal",
        textAlign: "left",
        color,
        h1: {
          fontSize: "1.6em",
          fontWeight: "600",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.58",
          letterSpacing: "normal",
        },
        h2: {
          fontSize: "1.4em",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "2.11",
          letterSpacing: "normal",
        },
        h3: {
          fontSize: "1.2em",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.58",
          letterSpacing: "normal",
        },
        h4: {
          fontSize: "1.1em",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.58",
          letterSpacing: "normal",
          color,
        },
        h5: {
          fontSize: "1em",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.58",
          letterSpacing: "normal",
        },
        h6: {
          fontSize: ".9em",
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "1.58",
          letterSpacing: "normal",
        },
      },
      palette: {
        type,
        color,
        primary,
        secondary,
        background: {
          paper: backgroundColor,
          default: defaultBackgroundColor,
          dark: "#bdbdbd",
          verify: "#d6ecf8",
        },
        analytics: ["#344c71", "#418dca", "#8fb6d6", "#c4d0e2"],
      },
      overrides: {
        MuiAppBar: {},
        Sidebar: {
          itemText: {},
        },
        MuiListItemIcon: {
          root: {},
        },
        MuiListItem: {
          button: {
            "&:hover": {
              backgroundColor: fade(secondary.main, 0.1),
            },
          },
        },
        MuiListItemText: {
          root: {
            color,
          },
        },
        MuiSelect: {
          root: {
            "& fieldset": {},
          },
          icon: {},
        },
        MuiButton: {
          root: {
            textTransform: "unset",
            fontWeight: "bold",
            fontSize: "1em",
            minHeight: 37,
            minWidth: 100,
          },
          contained: {
            color: "inherit",
          },
          containedPrimary: {
            "&:hover": {
              backgroundColor: defaultBackgroundColor,
              color: primary.main,
            },
          },
          outlinedPrimary: {
            borderWidth: 3,
            borderColor: primary.main,
            "&:hover": {
              borderWidth: 3,
            },
          },
          endIcon: {
            // border: `solid 1px`,
            // borderRadius: 12,
          },
        },
        MuiFab: {
          root: {
            "&:hover": {
              backgroundColor: primary.main,
              "& >span>svg": {
                color: defaultBackgroundColor,
              },
            },
          },
        },
        MuiIconButton: {
          root: {
            width: 48,
            height: 48,
            padding: 0,
          },
        },
        MuiSwitch: {
          switchBase: {
            width: "auto",
            height: "auto",
          },
        },
        MuiTab: {
          textColorPrimary: {},
        },
        MuiMenuItem: {
          root: {},
        },
        MuiInput: {
          root: {},
        },
        MuiPaper: {
          elevation1: {
            boxShadow: "none",
            border: `1px solid ${fade(color, 0.2)}`,
          },
          elevation2: {
            boxShadow: "none",
            border: `1px solid ${fade(color, 0.2)}`,
          },
        },
        MuiFormLabel: {
          root: {
            "&$focused": {
              color: primary.main,
            },
          },
        },
        MuiTypography: {
          root: {},
          body1: {},
        },
        MuiDialogContentText: {
          root: {
            color: "#fff !important",
          },
        },
        MuiModal: {
          root: {},
        },
        MuiTableHeader: {
          root: {},
        },
        MuiTableCell: {
          root: {
            borderBottom: "1px solid rgba(205, 205, 205, 1)",
          },
          head: {},
          body: {},
        },
        MuiGridListTileBar: {
          title: {},
        },
        MuiChip: {
          deleteIcon: {
            color: "rgba(0, 0, 0, 0.26)",
          },
        },
        MuiTooltip: {
          tooltip: {
            backgroundColor: primary.main,
          },
        },
        MuiTablePagination: {
          select: {
            paddingRight: 32,
          },
        },
      },
    },
    language === "ruRU" ? ruRU : enUS
  );
};

export default createTheme;
