export const shortenFilename = (filename, startLength = 5, endLength = 4) => {
  // Ensure the provided filename is valid
  if (!filename || typeof filename !== "string") {
    return "";
  }

  // Find the position of the last dot to separate the extension
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex === -1) {
    return filename; // No extension found, return the original filename
  }

  const namePart = filename.slice(0, dotIndex);
  const extension = filename.slice(dotIndex);

  // Ensure the name part is longer than the start and end lengths combined
  if (namePart.length <= startLength + endLength) {
    return filename; // Filename is too short to shorten meaningfully
  }

  // Shorten the filename
  const shortened = namePart.slice(0, startLength) + "..." + namePart.slice(-endLength);

  return shortened + extension;
};

export const constructRecursionFilename = (fileName, fileNameList) => {
  const findName = fileNameList.find(name => name === fileName);
  if (!findName) {
    return fileName;
  }
  let name = findName.split(".");
  const newFileName = `${name[0]}(1).${name[1]}`;
  return constructRecursionFilename(newFileName, fileNameList);
};
