import { IconButton, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import headerStyle from "assets/jss/components/headerStyle";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setDemo, setValidationBar } from "redux/actions/user";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0%   {opacity: 100%}
  50%  {opacity: 0%}
  100% {opacity: 100%}
`;

const Value = styled.div`
  animation: ${pulse} 5s ease-in-out;
  animation-iteration-count: infinite;
`;

const useStyles = makeStyles(headerStyle);
function Banner({ trial, remainingDays, isActive = true, isAdmin, loading }) {
  const classes = useStyles();
  const { demo, banner } = useSelector(_ => _.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setValidation = () => {
    dispatch(setValidationBar(false));
  };

  const endDemo = () => {
    dispatch(setDemo(false));
  };

  useEffect(() => {
    if (!isActive && !isAdmin && !loading) {
      dispatch(setValidationBar(true));
    } else {
      dispatch(setValidationBar(false));
    }
  }, [isActive, isAdmin, dispatch, loading]);

  return demo ? (
    <div className={classNames(classes.bannerRoot, classes.demo, classes.primary)} onClick={endDemo}>
      <Typography component={"div"} className={classes.text}>
        <Value>
          <div className={classes.bold}>{t("Messages.You are in Demo-Mode, Feel free to look and click around, You can’t break anything here")}</div>
          <div className={classes.bold}>{t("Labels.Click here to exit and return to your dashboard")}</div>
        </Value>
      </Typography>
    </div>
  ) : (
    banner && (
      <div
        className={classNames(classes.bannerRoot, classes.banner, {
          // [classes.primary]: remainingDays > 10 && trial,
          // [classes.warning]: remainingDays <= 10 && remainingDays > 0,
          // [classes.error]: remainingDays <= 0,
          [classes.error]: !isActive,
        })}
      >
        <Link to="/settings/credit" className={classes.text}>
          <strong>
            {/* {trial
              ? t("Messages.Your free code trial")
              : remainingDays <= 0
              ? t("Messages.Your code has expired")
              : t("Messages.Your code is expiring")} */}
            {
              // trial
              // ? t("Messages.Your free code trial")
              !isActive ? t("Your billing account has been deactivated. Please contact support@brandtag.io for more information.") : ""
            }
          </strong>
          {/* {isActive && (
            <>
              &nbsp; ({remainingDays}&nbsp;{remainingDays === 1 ? t("Messages.day left") : t("Messages.days left")}
              ,&nbsp;
              <u>{trial ? t("Labels.Talk to us for premium") : t("Labels.Talk to us to upgrade")}</u>)
            </>
          )} */}
        </Link>
        {/* {isActive && (
          <IconButton className={classes.close} onClick={setValidation}>
            <Close />
          </IconButton>
        )} */}
      </div>
    )
  );
}

export default Banner;
