import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddCircleRounded, ChevronRightRounded } from "@material-ui/icons";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import FormSectionApi from "api/formSection";
import FormTemplateApi from "api/formTemplate";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "./Section";

const useStyles = makeStyles(({ palette, typography }) => ({
  row: {
    paddingBottom: 12,
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 5,
  },
  addButton: {
    width: "unset",
    textAlign: "center",
    border: `1px dashed ${palette.background.dark}`,
    marginLeft: 13,
    marginRight: 13,
    color: typography.color,
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      borderColor: palette.primary.main,
      color: palette.primary.main,
    },
  },
  timeline: {
    padding: 0,
    margin: 0,
  },
  addSection: {
    textAlign: "left",
    padding: 14,
    color: "inherit",
  },
  buttonGroup: {
    margin: 8,
    marginTop: 40,
    float: "right",
  },
  summary: {
    paddingTop: 12,
  },
  timelineDot: {
    padding: 6,
    margin: 0,
  },
  timelineContent: {
    paddingTop: 0,
    overflowWrap: "anywhere",
    fontSize: 14,
    fontWeight: 500,
  },
}));

function Create({ data, resetData }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [dynamicSections, setDynamicSections] = useState([]);
  const [formTitle, setFormTitle] = useState(data.name);
  const [customIdLabel, setCustomIdLabel] = useState(data.custom_id_label);
  const [activeSections, setActiveSections] = useState([]);
  const [sectionDelete, setSectionDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const defaultTitle = `Form Title`;

  const checkQuestionsTitle = () => {
    let newDynamicSections = [...dynamicSections];
    newDynamicSections.forEach((section, sectionIndex) => {
      section.questions.forEach((question, questionIndex) => {
        if (!question.name.length) newDynamicSections[sectionIndex]["questions"][questionIndex]["name"] = `Question Title #${questionIndex + 1}`;
      });
    });

    setDynamicSections(newDynamicSections);
  };

  const createForm = () => {
    setLoading(true);
    checkQuestionsTitle();
    FormTemplateApi.create({ name: formTitle || defaultTitle, custom_id_label: customIdLabel })
      .then(res => {
        dynamicSections.length
          ? dynamicSections.forEach((section, i) =>
              FormTemplateApi.createSection(res.id, {
                ...section,
                name: section.name.length ? section.name : `Section Title #${i + 1}`,
                sequence: i,
              }).then(res => {
                resetData();
              })
            )
          : resetData();
        enqueueSnackbar(res.status);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateForm = () => {
    setLoading(true);
    checkQuestionsTitle();
    FormTemplateApi.update(data.id, { name: formTitle || defaultTitle, custom_id_label: customIdLabel })
      .then(res => {
        dynamicSections.length
          ? dynamicSections.forEach((section, i) => {
              section.id
                ? FormSectionApi.update(section.id, {
                    ...section,
                    name: section.name.length ? section.name : `Section Title #${i + 1}`,
                    sensors: section.sensors.filter(sensor => sensor.id),
                    sequence: i,
                  })
                    .then(res => {
                      resetData();
                    })
                    .catch(e => {
                      enqueueSnackbar(e, {
                        variant: "error",
                      });
                    })
                : FormTemplateApi.createSection(data.id, {
                    ...section,
                    name: section.name.length ? section.name : `Section Title #${i + 1}`,
                    sequence: i,
                  }).then(res => {
                    resetData();
                  });
            })
          : resetData();
        enqueueSnackbar(res.status);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => {
        sectionDelete.forEach(form => {
          FormSectionApi.remove(form.id)
            .then(res => {
              resetData();
              enqueueSnackbar(res.message);
            })
            .catch(e => {
              enqueueSnackbar(e, {
                variant: "error",
              });
            });
        });
        setLoading(false);
      });
  };

  const onSectionDelete = i => () => {
    setSectionDelete(_ => [..._, dynamicSections[i]]);
    setDynamicSections(_ => _.filter((_, j) => j !== i));
  };

  const onValueChange = sectionIndex => (field, questionIndex) => value =>
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["questions"][questionIndex][field] = value;
      return newDynamicSections;
    });

  const onTitleChange = sectionIndex => value =>
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["name"] = value;
      return newDynamicSections;
    });

  const onSensorChange = sectionIndex => (e, i) =>
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["sensors"][i] = e
        ? e.target
          ? { ...newDynamicSections[sectionIndex]["sensors"][i], [e.target.name]: e.target?.value || e.target?.checked }
          : { ...newDynamicSections[sectionIndex]["sensors"][i], ...e }
        : [];
      return newDynamicSections;
    });

  const onDeleteQuestion = sectionIndex => questionIndex => () =>
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["questions"] = newDynamicSections[sectionIndex]["questions"].filter((_, i) => i !== questionIndex);
      return newDynamicSections;
    });

  const onDeleteSensor = sectionIndex => sensorIndex =>
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["sensors"] = newDynamicSections[sectionIndex]["sensors"].filter((_, i) => i !== sensorIndex);
      return newDynamicSections;
    });

  const activatedSections = sectionIndex => status =>
    setActiveSections(_ => (status ? [..._, sectionIndex] : _?.filter(section => section !== sectionIndex)));

  const addQuestion = sectionIndex => () => {
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["questions"] = [
        ...newDynamicSections[sectionIndex]["questions"],
        {
          name: t("Labels.Question Title") + " #" + (newDynamicSections[sectionIndex]["questions"].length + 1),
          datatype: "string",
          share: true,
          required: false,
        },
      ];
      return newDynamicSections;
    });
  };

  const addSensor = sectionIndex => () => {
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["sensors"] = [
        ...newDynamicSections[sectionIndex]["sensors"],
        {
          share: true,
        },
      ];
      return newDynamicSections;
    });
  };

  const setQuestions = sectionIndex => questions =>
    setDynamicSections(_ => {
      let newDynamicSections = [..._];
      newDynamicSections[sectionIndex]["questions"] = [...questions];
      return newDynamicSections;
    });

  const addSection = () =>
    setDynamicSections(_ => [
      ..._,
      {
        name: t("Labels.Section Title") + " #" + (_.length + 1),
        sequence: _.length,
        questions: [],
        sensors: [{ id: null, share: true }],
      },
    ]);

  useEffect(() => {
    if (data.id) {
      FormSectionApi.getByForm(data.id)
        .then(res => {
          setDynamicSections(
            res
              .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
              .map(section => (section.sensors?.length >= 0 ? section : { ...section, sensors: [] }))
          );
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });
    }
  }, [data.id, enqueueSnackbar]);

  return (
    <Grid container spacing={0}>
      <Grid item className={classes.row} xs={8}>
        <TextField
          labelPlacement="left"
          label={t("Labels.Template Name")}
          value={formTitle}
          onChange={e => setFormTitle(e.target.value)}
          placeholder={defaultTitle}
          variant={"h3"}
          required
        />
        <TextField
          labelPlacement="left"
          label={t("Labels.Identifier Label")}
          tooltipText={t("HelperText.Customize your submission ID label")}
          value={customIdLabel}
          onChange={e => setCustomIdLabel(e.target.value)}
          placeholder={"Lot Number | Batch Number"}
          variant={"h3"}
        />
        {dynamicSections?.map((section, i) => (
          <Section
            index={i}
            section={section}
            onTitleChange={onTitleChange(i)}
            onSensorChange={onSensorChange(i)}
            onSectionDelete={onSectionDelete(i)}
            onValueChange={onValueChange(i)}
            onDeleteQuestion={onDeleteQuestion(i)}
            onDeleteSensor={onDeleteSensor(i)}
            addSensor={addSensor(i)}
            addQuestion={addQuestion(i)}
            activatedSections={activatedSections(i)}
            setQuestions={setQuestions(i)}
            collapse={activeSections.includes(i)}
          />
        ))}
        <Grid container className={classes.addButton} onClick={addSection}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.addSection}>
              {t("Labels.Add section")}
            </Typography>
          </Grid>
          <Tooltip placement="right" title={t("Labels.Add section")}>
            <IconButton>
              <AddCircleRounded fontSize="large" color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <div className={classes.buttonGroup}>
          <Button
            className={classes.button}
            variant="outlined"
            buttonText={t("Labels.Cancel")}
            onClick={() => {
              resetData();
              setSectionDelete([]);
            }}
          />
          <Button
            className={classes.button}
            buttonText={t("Labels.Save")}
            type="submit"
            onClick={() => (data.id ? updateForm() : createForm())}
            endIcon={<ChevronRightRounded />}
            loading={loading}
          />
        </div>
      </Grid>
      <Grid item xs={4} className={classes.summary}>
        <Timeline className={classes.timeline}>
          {dynamicSections?.map((section, i) => (
            <TimelineItem key={i}>
              <TimelineSeparator>
                <TimelineDot color={activeSections.includes(i) ? "primary" : "grey"} className={classes.timelineDot} />
                {dynamicSections.length !== i + 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>{section.name}</TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Grid>
  );
}

export default Create;
