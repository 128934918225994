import api from ".";

const path = "/uploads";

const getAll = () => api.get(`${path}`);

const get = id => api.get(`${path}/${id}`);

const create = data => {
  const formData = new FormData();
  formData.append("file", data);
  return api.post(`${path}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const createBulk = data => {
  const formData = new FormData();
  formData.append("files", data);
  return api.post(`${path}/bulk`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const remove = id => api.delete(`${path}/${id}`);

export default {
  getAll,
  get,
  create,
  createBulk,
  remove,
};
