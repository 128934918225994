import { Grid, IconButton, isWidthUp, makeStyles, Paper, Typography, useTheme, withWidth } from "@material-ui/core";
import { ZoomOut } from "@material-ui/icons";
import React, { useState } from "react";
import { CartesianGrid, Legend, Line, LineChart, ReferenceArea, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const tomorrow = date => {
  const d = new Date(date);
  d.setDate(d.getDate() + 1);
  return d.toLocaleDateString();
};

const getHighestY = (d, k) => d.reduce((a, b) => (a[k] > b[k] ? a : b))[k];

const getLowestY = (data, k) => data.reduce((a, b) => (a[k] < b[k] ? a : b))[k];

const useStyles = makeStyles({
  paper: {
    padding: "14px 38px",
    paddingLeft: 0,
  },
  title: {
    fontWeight: 600,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

function TrendChart({ data, width, values, title, threshold, unit }) {
  const { palette } = useTheme();
  const classes = useStyles();
  const [dynamicData, setDynamicData] = useState(data);
  const [isZoom, setIsZoom] = useState();

  const defaultDataMaxY = Math.ceil(Math.max(Math.max(...values.map(v => getHighestY(data, v.value)), 0), threshold || 0));
  const defaultDataLowestY = Math.ceil(Math.min(Math.min(...values.map(v => getLowestY(data, v.value)), 0), threshold || 0));

  const chartHeight = isWidthUp("md", width) ? 500 : 300;
  const [refArea, setRefArea] = useState({ left: "", right: "" });
  const [opacity, setOpacity] = useState({
    ...values.map(v => {
      return { [v.value]: v.opacity | 1 };
    }),
  });
  const [dataMaxY, setDataMaxY] = useState(defaultDataMaxY);
  const [datalowY, setDataLowY] = useState(defaultDataLowestY);
  const handleLineLegendClick = ({ dataKey }) => setOpacity({ ...opacity, [dataKey]: opacity[dataKey] ? 0 : 1 });

  const zoom = () => {
    let { left, right } = refArea;

    if (left !== right && right !== "") {
      if (new Date(left) > new Date(right)) [left, right] = [right, left];

      const newData = data.slice(
        data.findIndex(i => i.date === left),
        data.findIndex(i => i.date === tomorrow(right))
      );
      setDynamicData(newData);
      setDataMaxY(Math.ceil(Math.max(Math.max(...values.map(v => getHighestY(newData, v.value)), 0), threshold || 0)));
      setDataLowY(Math.ceil(Math.min(Math.min(...values.map(v => getLowestY(newData, v.value)), 0), threshold || 0)));
      setIsZoom(true);
    }

    setRefArea({ left: "", right: "" });
  };

  const { left, right } = refArea;

  const zoomOut = () => {
    setDynamicData(data);
    setDataMaxY(defaultDataMaxY);
    setDataLowY(defaultDataLowestY);
    setIsZoom();
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <div className={classes.header}>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>

        <IconButton onClick={zoomOut} style={{ visibility: isZoom ? "visible" : "hidden" }}>
          <ZoomOut fontSize="large" />
        </IconButton>
      </div>

      <Paper square className={classes.paper}>
        <ResponsiveContainer height={chartHeight}>
          <LineChart
            width={800}
            height={400}
            data={dynamicData}
            onMouseDown={e => e && setRefArea({ ...refArea, left: e.activeLabel })}
            onMouseMove={e => e && left && setRefArea({ ...refArea, right: e.activeLabel })}
            onMouseUp={zoom}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis allowDataOverflow dataKey="date" domain={["dataMin", "dataMax"]} />
            <YAxis
              allowDataOverflow
              domain={[datalowY, dataMaxY]}
              type="number"
              yAxisId="1"
              ticks={[
                dataMaxY,
                Math.ceil(dataMaxY - (dataMaxY - datalowY) / 4),
                Math.ceil((dataMaxY - datalowY) / 2 + datalowY),
                Math.ceil((dataMaxY - datalowY) / 4 + datalowY),
                threshold,
                datalowY,
              ]}
            />
            <YAxis allowDataOverflow domain={[datalowY, dataMaxY]} type="number" yAxisId="2" hide />
            <YAxis allowDataOverflow domain={[datalowY, dataMaxY]} type="number" yAxisId="3" hide />
            <Legend onClick={handleLineLegendClick} align="right" verticalAlign="top" iconType="plainline" />
            <Tooltip />

            {threshold && (
              <ReferenceLine
                y={threshold}
                stroke="red"
                alwaysShow
                yAxisId="1"
                label={{ position: "top", value: `${values[0]?.label} Threshold`, fill: "red", fontSize: 14 }}
              />
            )}

            {values.map((v, i) => (
              <Line
                yAxisId={i + 1}
                type="linear"
                dataKey={v.value}
                name={unit ? `${v.label} (${unit})` : v.label}
                strokeOpacity={opacity?.value}
                stroke={palette.analytics[i]}
                strokeWidth={3}
                dot={false}
                activeDot={{ r: 5 }}
                animationDuration={300}
              />
            ))}

            {left && right && <ReferenceArea yAxisId="1" x1={left} x2={right} strokeOpacity={0.3} />}
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    </Grid>
  );
}

export default withWidth()(TrendChart);
