import { Grid, IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Paper, useTheme } from "@material-ui/core";
import { MoreHoriz, ThumbDownRounded, ThumbsUpDownRounded, ThumbUpRounded, WarningRounded } from "@material-ui/icons";
import { apiURL } from "api";
import SkuApi from "api/sku";
import A from "components/A";
import PageContent from "components/PageContent";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: 24,
  },
  row: {
    paddingBottom: 12,
  },
  detailImage: {
    maxWidth: 150,
    maxHeight: 150,
    borderRadius: 3,
    border: `solid 3px ${palette.background.default}`,
    padding: spacing(1),
    margin: spacing(1),
  },
  detailRoot: {
    padding: spacing(1),
    margin: spacing(1),
  },
  detailKey: {
    fontWeight: "bold",
  },
  itemIcon: {
    minWidth: 40,
  },
  status: {
    border: `2px solid ${palette.background.default}`,
    textAlign: "center",
    padding: 10,
  },
}));

const Container = props => <Paper elevation={0} {...props} />;

function SKU() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState();
  const [certificates, setCertificates] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState({});
  const { palette } = useTheme();

  useEffect(() => {
    SkuApi.getAll().then(res => {
      res.flatMap(i => {
        if (!i.business_name) {
          i.business_name = i.merchant;
        }
      });

      setCertificates(
        res
          .flatMap(i =>
            i.certificates?.map(certificate => {
              const { certificates, ...res } = i;
              return { certificate, ...res };
            })
          )
          .filter(i => i)
      );
    });
  }, []);

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData);
    event.stopPropagation();
  };

  const handleClose = () => setAnchorEl();

  const handleApprove = status => () => {
    const { id, certificate } = selectedRowData;
    SkuApi.updateStatus(id, { status, certificate })
      .then(res => {
        enqueueSnackbar(`${selectedRowData.certificate.title} Certificate of SKU ${selectedRowData.name} status updated!`);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => {
        setAnchorEl();
        SkuApi.getAll().then(res =>
          setCertificates(
            res
              .flatMap(i =>
                i.certificates?.map(certificate => {
                  const { certificates, ...res } = i;
                  return { certificate, ...res };
                })
              )
              .filter(i => i)
          )
        );
      });
  };

  return (
    <PageContent header={t("Manage SKU Certificates")}>
      <Grid container>
        <Grid item className={classes.row} xs={12}>
          <MaterialTable
            columns={[
              {
                title: "Business Name",
                field: "business_name",
                defaultGroupOrder: 1,
              },
              { title: "Brand", field: "trademark.registration_name" },
              { title: "SKU", field: "name", defaultGroupOrder: 2 },
              { title: "Certificate Title", field: "certificate.title" },
              {
                title: t("Certificate Status"),
                field: "certificate.status",
                render: rowData => rowData.certificate?.status || "Pending Approval",
              },
              {
                title: t("Certificate Document"),
                field: "certificate.document.title",
                render: ({ certificate }) => <A href={`${apiURL}/files/${certificate?.hash}${certificate?.ext}`}>{certificate?.name}</A>,
              },
              {
                title: t("Action"),
                field: "action",
                sorting: false,
                render: rowData => (
                  <IconButton onClick={e => handleClick(e, rowData)}>
                    <MoreHoriz />
                  </IconButton>
                ),
              },
            ]}
            data={certificates}
            title=""
            options={{
              toolbar: true,
              draggable: false,
              detailPanelColumnAlignment: "left",
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: palette.background.default,
              },
            }}
            components={{
              Container,
            }}
          />
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
          >
            {selectedRowData.status !== "Pending Approval" && (
              <MenuItem onClick={handleApprove("Pending Approval")}>
                <ListItemIcon className={classes.itemIcon}>
                  <ThumbsUpDownRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Pending Approval")} />
              </MenuItem>
            )}
            {selectedRowData.status !== "Approved" && (
              <MenuItem onClick={handleApprove("Approved")}>
                <ListItemIcon className={classes.itemIcon}>
                  <ThumbUpRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Approve")} />
              </MenuItem>
            )}
            {selectedRowData.status !== "Rejected" && (
              <MenuItem onClick={handleApprove("Rejected")}>
                <ListItemIcon className={classes.itemIcon}>
                  <ThumbDownRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Reject")} />
              </MenuItem>
            )}
            {selectedRowData.status !== "Request Changes" && (
              <MenuItem onClick={handleApprove("Request Changes")}>
                <ListItemIcon className={classes.itemIcon}>
                  <WarningRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={t("Request Change")} />
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default SKU;
