import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import OrderApi from "api/order";
import SkuApi from "api/sku";
import Carousel from "components/Carousel/withText";
import PageContent from "components/PageContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Create from "./Create";
import Table from "./Table";

const useStyles = makeStyles(({ spacing }) => ({
  imgExample: {
    margin: "auto",
    height: 180,
    display: "block",
    paddingTop: 10,
  },
  cardstyle: {
    height: "100%",
  },
  title: {
    fontWeight: 600,
    textAlign: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  text: {
    textAlign: "center",
  },
  howTo: {
    paddingLeft: 14,
  },
}));

function CodeGeneration() {
  const { t } = useTranslation();
  const classes = useStyles();

  const [loading, setLoading] = useState();
  const [orderList, setOrderList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const { demo } = useSelector(_ => _.user);

  useEffect(() => {
    if (loading) return;

    OrderApi.getAll().then(res => {
      setOrderList(res);
    });
  }, [loading, demo]);

  useEffect(() => {
    SkuApi.getAll().then(res => {
      setSkuList(res);
    });
  }, [demo]);

  return (
    <PageContent header={t("Labels.Generate Codes")}>
      <Grid container alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Create loading={loading} skuList={skuList} setLoading={setLoading} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.howTo}>
          <Card className={classes.cardstyle}>
            <Typography variant="h2" className={classes.title}>
              {t("Labels.How to")}
            </Typography>
            <Grid item xs={12}>
              <Carousel
                data={[
                  {
                    title: "Labels.Generate Code",
                    subtitle: [
                      "Instructions.Choose an existing SKU",
                      "Instructions.Insert the number of codes you wish to generate",
                      "Instructions.Click 'Generate' button",
                      "Instructions.Wait for codes to be generated",
                      "Instructions.Once completed, a record of the code generation is displayed in 'Order History'",
                    ],
                    img: require("assets/gif/generateCode.gif"),
                  },
                  {
                    title: "Labels.Download Code",
                    subtitle: [
                      "Instructions.Go to 'Order History'",
                      "Instructions.Identify the generated codes",
                      "Instructions.Click 'Download' button to save CSV file",
                      "Instructions.File is saved locally",
                    ],
                    img: require("assets/gif/downloadCode.gif"),
                  },
                  {
                    title: "Labels.Send To Printer",
                    subtitle: [
                      "Instructions.Open Microsoft Word",
                      "Instructions.Download QR4office",
                      "Instructions.Go-To insert and press My Add-ins",
                      "Instructions.Click QR4office then drop your CSV file",
                      "Instructions.Pick your own QR style then click insert",
                    ],
                    img: require("assets/gif/sendToPrinter.gif"),
                  },
                  {
                    title: "Labels.Stick",
                    subtitle: [
                      "Instructions.Print the QR code onto your product packaging",
                      "Instructions.Paste it onto your product",
                      "Instructions.Now it's ready for your customers!",
                    ],
                    img: require("assets/gif/print.gif"),
                  },
                  {
                    title: "Labels.Activate",
                    subtitle: [
                      "Instructions.Select CSV file from device",
                      "Instructions.Double-check file from preview",
                      "Instructions.Click 'Activate' button",
                    ],
                    img: require("assets/gif/activate.gif"),
                  },
                ]}
              />
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Table data={orderList} skuList={skuList} />
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default CodeGeneration;
