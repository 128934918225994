import api from ".";

const path = "/billing";

const getAll = params => api.get(`${path}`, { params });

const getBalance = m => api.get(`${path}/getBalance` + (m ? `/${m}` : ``));

const create = data => api.post(`${path}`, data);

const startTrial = data => api.post(`${path}/startTrial`, data);

const updateStatus = (status, merchant) => api.put(`${path}/updateStatus/${merchant}`, { isActive: status });

export default {
  getAll,
  create,
  startTrial,
  getBalance,
  updateStatus,
};
