import api from ".";

const path = "/sensor";

const getAll = () => api.get(`${path}`);

const get = id => api.get(`${path}/${id}`);

const create = data => api.post(`${path}`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

const getLog = id => api.get(`${path}/log/${id}`);

const getAllBySensorId = (id, params) => api.get(`${path}/${id}/log`, { params });

const createLog = (id, data) => api.post(`${path}/${id}/log`, data);

export default {
  getAll,
  get,
  create,
  update,
  remove,
  getLog,
  getAllBySensorId,
  createLog,
};
