import api from ".";

const path = "/submission";

const getAll = () => api.get(`${path}/`);

const get = id => api.get(`${path}/${id}`);

const create = ({ formId, ...data }) => api.post(`${path}/byFormId/${formId}`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

const getAnswersBySectionId = (id, sectionid) => api.get(`${path}/${id}/answers/bySectionId/${sectionid}`);

const createAnswer = (id, data) => api.post(`${path}/${id}/answers`, data);

const updateAnswer = (id, data) => api.put(`${path}/${id}/answers`, data);

const preview = id => api.get(`${path}/${id}/preview`);

export default {
  getAll,
  get,
  create,
  update,
  remove,
  getAnswersBySectionId,
  createAnswer,
  updateAnswer,
  preview,
};
