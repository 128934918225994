import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Fields from "./Fields";

function FormCreate({ open, handleClose, forms, data }) {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h1">
          {t(`Labels.${Object.keys(data).length ? "Edit" : "Create"}`)} {t("Labels.Submission")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Fields handleClose={handleClose} forms={forms} data={data} />
      </DialogContent>
    </Dialog>
  );
}

export default FormCreate;
