import { FormHelperText, Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "components/Button";
import React from "react";

const useStyles = makeStyles({
  button: {
    height: 48,
    width: "100%",
  },
  input: {
    display: "none",
  },
  root: {
    flex: 1,
    marginLeft: 12,
  },
});

const UploadButton = ({
  buttonText = "Upload",
  documentName,
  error,
  helperText,
  id = "upload",
  inputRef,
  name,
  onChange,
  onUploadChange,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Input accept="image/*" className={classes.input} id={id} multiple type="file" name={name} onChange={onChange} inputRef={inputRef} />
      <label htmlFor={id}>
        <Button component="span" className={classes.button} {...props} buttonText={buttonText} />
      </label>
      {documentName && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </div>
  );
};

export default UploadButton;
