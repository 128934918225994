import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <footer className={classes.footer}>
      <Grid container justify="center">
        <Typography className={classes.text}>
          {new Date().getFullYear()} {t("Labels.Copyright")} &copy; {t("Labels.BrandTag")}
        </Typography>
      </Grid>
    </footer>
  );
}

const useStyles = makeStyles(() => ({
  footer: {
    paddingBottom: 30,
    width: "100%",
    bottom: 0,
    position: "relative",
  },
  text: {
    height: 14,
    fontSize: 14,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "3.57",
    letterSpacing: "normal",
    textAlign: "center",
  },
}));

export default Footer;
