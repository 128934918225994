import { FilledInput, FormHelperText, Select, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/borderlessTextfield";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
  },
  left: {
    display: "flex",
  },
  leftLabel: {
    width: 150,
    alignSelf: "center",
  },
  topLabel: {
    height: 23,
    lineHeight: 1.63,
  },
  fields: {
    overflow: "hidden",
  },
  help: {
    marginLeft: 4,
  },
}));
const useSelectStyles = makeStyles(({ palette }) => ({
  outlined: {
    fontSize: "1.6em",
    color: palette.primary.main,
    paddingTop: "7px !important",
    paddingBottom: "0px !important",
  },
  icon: {
    color: palette.primary.main,
  },
}));

const useTextFieldStyles = makeStyles(textfieldStyles);

const TextField = ({ label, value, onChange, required, tooltipText, labelPlacement, helperText, children, labelProps, handleChange, ...props }) => {
  const classes = useStyles();
  const selectClasses = useSelectStyles();
  const textfieldClasses = useTextFieldStyles();

  return (
    <div
      className={classNames({
        [classes.root]: labelPlacement,
        [classes.left]: labelPlacement === "left",
      })}
    >
      {labelPlacement && (
        <Typography
          variant="h5"
          gutterBottom
          className={classNames(classes.label, {
            [classes.leftLabel]: labelPlacement === "left",
            [classes.topLabel]: labelPlacement === "top",
          })}
          {...labelProps}
        >
          {label}
          {required && "*"}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Help fontSize="small" className={classes.help} />
            </Tooltip>
          )}
        </Typography>
      )}
      <div className={classes.fields}>
        <Controller
          {...props}
          render={({ onChange, ...p }) => (
            <Select
              onChange={e => {
                onChange(e);
                handleChange(e.target.value);
              }}
              variant="outlined"
              input={<FilledInput classes={textfieldClasses} />}
              classes={selectClasses}
              {...p}
            >
              {children}
            </Select>
          )}
        />
        {props.error && <FormHelperText {...props}>{helperText}</FormHelperText>}
      </div>
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["top", "left"]),
};

export default TextField;
