import { Grid, IconButton, Step, StepButton, Stepper } from "@material-ui/core";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import FormSubmissionApi from "api/formSubmission";
import Button from "components/Button";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SectionQuestions from "./sectionQuestions";

const useStyles = makeStyles(({ palette }) => ({
  row: {
    paddingBottom: 12,
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 5,
  },
  navButton: {
    marginLeft: 5,
    height: 37,
    color: palette.background.paper,
    "&:hover": {
      color: palette.primary.main,
    },
    backgroundColor: palette.primary.main,
    borderRadius: 4,
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
  },
  addButton: {
    width: "100%",
    textAlign: "center",
  },
  stepper: {
    minWidth: "25vw",
  },
  buttonGroup: {
    margin: 12,
    textAlign: "right",
  },
  timeline: {
    padding: 0,
    margin: 0,
  },
}));

function Form({ data, resetData, setPreview }) {
  const { submission, sections } = data;
  const { id, currentSection } = submission;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(
    sections.findIndex(i => i.id === currentSection) < 0 ? 0 : sections.findIndex(i => i.id === currentSection)
  );
  const [sectionAnswers, setSectionAnswers] = useState([]);

  const steps = sections.map(section => section.name);

  const handleNext = () => setActiveSection(_ => _ + 1);

  const handleBack = () => setActiveSection(_ => _ - 1);

  const updateForm = () => {
    FormSubmissionApi.updateAnswer(id, sectionAnswers)
      .then(res => {
        setSectionAnswers(res.answers);
        enqueueSnackbar(`Submission updated!`);
      })
      .catch(e =>
        enqueueSnackbar(e, {
          variant: "error",
        })
      );
  };

  const onValueChange = (i, value) =>
    setSectionAnswers(_ => {
      let newSectionAnswers = [..._];
      const answerIndex = _.findIndex(answer => answer.questionId === i);
      answerIndex >= 0
        ? (newSectionAnswers[answerIndex]["value"] = value)
        : (newSectionAnswers = [...newSectionAnswers, { submissionId: id, questionId: i, value: value }]);
      return newSectionAnswers;
    });

  const onSensorChange = (sensorId, sectionId, field, value) =>
    setSectionAnswers(_ => {
      let newSectionAnswers = [..._];
      const answerIndex = _.findIndex(answer => answer.sensorId === sensorId && answer.sectionId === sectionId);
      answerIndex >= 0
        ? (newSectionAnswers[answerIndex][field] = value)
        : (newSectionAnswers = [...newSectionAnswers, { sensorId, sectionId, [field]: value }]);
      return newSectionAnswers;
    });

  return (
    <Grid container spacing={0}>
      <Grid item className={classes.row} xs={12}>
        <Stepper activeStep={activeSection} alternativeLabel>
          {steps.map((label, i) => (
            <Step key={label}>
              <StepButton onClick={() => setActiveSection(i)}>{label}</StepButton>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid item className={classes.row} xs={12}>
        <div className={classes.stepContent}>
          <SectionQuestions
            sectionAnswers={sectionAnswers}
            setSectionAnswers={setSectionAnswers}
            id={id}
            sectionId={sections[activeSection]?.id}
            onChange={onValueChange}
            onSensorChange={onSensorChange}
          />

          <div className={classes.buttonGroup}>
            {activeSection !== 0 && (
              <IconButton className={classes.navButton} onClick={handleBack}>
                <ChevronLeftRounded />
              </IconButton>
            )}
            <Button className={classes.button} variant="outlined" buttonText={t("Labels.Cancel")} onClick={resetData} />
            <Button
              className={classes.button}
              buttonText={t(activeSection === sections.length - 1 ? "Labels.Save & Preview" : "Labels.Save")}
              onClick={() => {
                updateForm();
                activeSection === sections.length - 1 && setPreview(data);
              }}
            />
            {activeSection !== sections.length - 1 && (
              <IconButton className={classes.navButton} onClick={handleNext}>
                <ChevronRightRounded />
              </IconButton>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default Form;
