export const badges = {
  BrandTag: {
    link: "brandtag.io",
  },
  EcoTag: {
    link: "brandtag.io",
    color: "#4f9c43",
  },
  Halal: {
    link: "",
    color: "#55b44c",
  },
  // HalalTag: {
  //   link: "halaltag.io",
  //   color: "#55b44c",
  // },
  // KosherTag: {
  //   link: "halaltag.io",
  //   color: "#f3e660",
  // },
};
