import PageContent from "components/PageContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Log from "./Log";
import Table from "./Table";

function Forms() {
  const { t } = useTranslation();
  const [data, setData] = useState();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const id = query.get("sensorId");
    id && setData({ id, name: query.get("name") });
  }, []);

  return (
    <PageContent header={`${t("Labels.View")} ${data ? `${t("Labels.Log")}: ${data.name}` : t("Labels.Sensors")}`}>
      {data ? <Log sensorId={data.id} resetData={() => setData()} /> : <Table setData={setData} />}
    </PageContent>
  );
}

export default Forms;
