import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PayloadApi from "api/payload";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import Button from "components/Button";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  row: {
    paddingBottom: 12,
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 5,
    marginTop: 15,
    marginBottom: 15,
  },
  paper: {
    padding: "14px 38px",
    paddingLeft: 0,
  },
  addButton: {
    width: "100%",
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
  timeline: {
    padding: 0,
    margin: 0,
  },
}));

const useToolBarStyles = makeStyles(mtoolbarstyle);

function ItemsList({ logisticId, resetItemsList }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [itemsList, setItemsList] = useState([]);
  const toolbarClasses = useToolBarStyles();
  const { enqueueSnackbar } = useSnackbar();

  const Container = props => <Paper elevation={0} {...props} />;
  const Title = () => <Typography variant="h2">{t("Labels.List of Items")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  useEffect(() => {
    try {
      PayloadApi.getAll({ logisticId })
        .then(res => {
          setItemsList(res);
        })
        .catch(e => {
          throw e;
        });
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, logisticId]);

  return (
    <Grid container spacing={0}>
      <Grid item className={classes.row} xs={8}>
        <MaterialTable
          options={{
            toolbar: true,
            draggable: false,
            detailPanelColumnAlignment: "left",
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: palette.background.default,
              border: "3px solid",
              borderColor: palette.background.default,
            },
            rowStyle: {
              border: "3px solid",
              borderColor: palette.background.default,
            },
            cellStyle: {
              borderBottom: 0,
            },
          }}
          components={{
            Container,
            Toolbar,
          }}
          title={Title()}
          columns={[
            {
              title: t("Labels.Serial No"),
              align: "center",
              field: "serial_no",
            },
            {
              title: t("Labels.SKU Name"),
              align: "center",
              render: ({ sku }) => sku.name,
            },
            {
              title: t("Labels.SKU"),
              align: "center",
              render: ({ sku }) => sku.sku,
            },
          ]}
          data={itemsList}
        />
        <Button className={classes.button} variant="outlined" buttonText={t("Labels.Back")} onClick={resetItemsList} />
      </Grid>
    </Grid>
  );
}

export default ItemsList;
