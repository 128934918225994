import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { apiURL } from "api";
import BusinessApi from "api/business";
import UploadApi from "api/upload";
import Button from "components/Button";
import TextField from "components/TextField";
import UploadTextField from "components/UploadTextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setProgress } from "redux/actions/user";
import RegExpPattern from "utils";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: "100%",
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 10,
  },
  buttonGroup: {
    paddingTop: 24,
  },
  uploadList: {
    marginLeft: 12,
  },
  link: {
    color: palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  name: {
    fontWeight: "bold",
  },
  businessName: {
    display: "flex",
    paddingLeft: 12,
  },
}));

function Business({ saveAndNext, saveText }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState();
  const [isUpdate, setIsUpdate] = useState(true);
  const { register, errors, handleSubmit, setValue, reset } = useForm();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const saveForm = async formData => {
    setLoading(true);
    try {
      let { registration_no, ...details } = formData;
      if (formData.documents[0]) {
        const uploadRes = await UploadApi.create(formData.documents[0]);
        const { merchant, url, ...documents } = uploadRes;
        details = { ...details, documents };
      } else {
        details = { ...details, documents: data?.details?.documents };
      }
      if (isUpdate) {
        await BusinessApi.update({
          registration_no,
          details,
        });
      } else {
        await BusinessApi.create({
          registration_no,
          details,
        });
      }
      enqueueSnackbar("Business details updated");
      if (saveAndNext) saveAndNext();
    } catch (e) {
      if (!data?.registration_no) {
        enqueueSnackbar(e, {
          variant: "error",
        });
      } else {
        if (saveAndNext) saveAndNext();
      }
    } finally {
      dispatch(setProgress(0));
      setLoading();
    }
  };

  useEffect(() => {
    const get = async () => {
      try {
        const resData = await BusinessApi.get();
        if (resData) {
          setValue("registration_no", resData.registration_no);
          resData.details && Object.keys(resData.details).map(i => i !== "documents" && setValue(i, resData.details[i]));
          setData(resData);
        }
      } catch (e) {
        if (typeof e === "string" && e.toLowerCase().includes("not found")) {
        } else {
          enqueueSnackbar(e, {
            variant: "error",
          });
        }
        setIsUpdate(false);
      }
    };

    !data && get();
  }, [data, enqueueSnackbar, setValue]);

  return (
    <form className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" gutterBottom className={classes.businessName}>
            {t("Labels.Business Name")}:&nbsp;
            <div className={classes.name}>{data?.name}</div>
          </Typography>
          <TextField
            labelPlacement="top"
            name="registration_no"
            label={t("Labels.Business Registration No")}
            inputRef={register({ required: true })}
            disabled={data?.registration_no}
            value={data?.registration_no}
            required
            error={errors.registration_no}
            helperText={t("HelperText.Please fill in registration number")}
            tooltipText={t("Tooltips.Set of numbers printed on the company’s certificate of incorporation")}
          />
          <UploadTextField
            labelPlacement="top"
            name="documents"
            inputRef={register({ required: !data?.details?.documents })}
            error={errors.documents}
            label={t("Labels.Upload Business Document")}
            required
            buttonText={t("Labels.Upload")}
            helperText={errors.documents ? t("HelperText.Please upload a document") : t("HelperText.Max File 2MB")}
            tooltipText={t("Tooltips.Company's Certificate of Incorporation")}
            linkPath={`${apiURL}/files/${data?.details?.documents?.hash}${data?.details?.documents?.ext}`}
            documentName={data?.details?.documents?.name}
          />
          <TextField
            labelPlacement="top"
            name="email"
            inputRef={register({
              pattern: RegExpPattern.email(),
            })}
            label={t("Labels.Company Email")}
            helperText={t("HelperText.Please provide a valid email address")}
            error={errors.email}
          />
          <TextField labelPlacement="top" name="contactNo" inputRef={register} label={t("Labels.Contact Number")} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField labelPlacement="top" name="address" inputRef={register} label={t("Labels.Company Address")} multiline rows={4} />
          <TextField labelPlacement="top" name="postcode" inputRef={register} label={t("Labels.Postcode")} />
          <TextField
            labelPlacement="top"
            name="country"
            inputRef={register({ required: true })}
            label={t("Labels.Country")}
            required
            error={errors.country}
            helperText={t("HelperText.Please fill in country")}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end" spacing={1} className={classes.buttonGroup}>
        <Grid item>
          <Button variant="outlined" className={classes.button} buttonText={t("Labels.Reset")} onClick={() => reset()} />
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            endIcon={<ChevronRightRounded />}
            buttonText={saveText ? saveText : isUpdate ? t("Labels.Update") : t("Labels.Create")}
            type="submit"
            onClick={handleSubmit(saveForm)}
            loading={loading}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default Business;
