import { Grid, IconButton, MenuItem, Paper, Select, Typography, useTheme, Tooltip as MuiTooltip, Box } from "@material-ui/core";
import { ChevronRight, Info } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/styles";
import GeoApi from "api/geoip";
import InsightsApi from "api/insights";
import SkuApi from "api/sku";
import TrademarkApi from "api/trademark";
import Button from "components/Button";
import Card from "components/Card/ForGrafana";
import PageContent from "components/PageContent";
import BorderlessSelect from "components/Select/Borderless";
import DateRange from "components/Select/DateRange";
import TrackTrace from "components/TrackTrace";
import WeeklyHeatmap from "components/WeeklyHeatmap";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import LineChart from "components/Charts/LineChart";
import RiskDialog from "./RiskDialog";
import { getEpochDates } from "utils/date";

const filterHeights = 31; //default is 37

const useStyles = makeStyles(({ spacing, palette }) => ({
  paper: {
    padding: 24,
    borderWidth: 2,
    margin: 4,
    textAlign: "-webkit-center",
  },
  title: {
    fontWeight: 600,
  },
  section: {
    marginTop: 24,
  },
  action: {
    paddingTop: 10,
  },
  riskButton: {
    color: "white",
    border: "solid 2px",
    width: 28,
    height: 28,
  },
  select: {
    width: "96%",
    height: filterHeights,
    marginLeft: 0,
    paddingLeft: 0,
  },
  button: {
    width: 128,
    minHeight: filterHeights,
    height: filterHeights,
    marginTop: 20,
    marginRight: spacing(1),
  },
  color: {
    color: palette.primary.main,
  },
  pieChartTitle: {
    fontSize: 16,
    justifySelf: "left",
  },
  pieChartPercentage: {
    fontSize: 22,
    justifySelf: "left",
    fontWeight: 200,
  },
  legendUl: {
    padding: 0,
    margin: 0,
    textAlign: "center",
  },
  legendLi: {
    display: "inline-block",
    marginRight: 10,
  },
  legendDiv: {
    display: "grid",
    width: 63,
  },
  legendIcon: {
    width: 29,
    height: 29,
  },
}));

function Insight() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue, getValues } = useForm();
  const [trademarkList, setTrademarkList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [geoipList, setGeoipList] = useState([]);
  const [filteredSkuList, setFilteredSkuList] = useState([]);
  const [filteredGeoipList, setFilteredGeoipList] = useState([]);
  const [openRiskDialog, setOpenRiskDialog] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [reportloading, setReportLoading] = useState();
  const [date, setDate] = useState({ from: "1w" });
  const [csvData, setCsvData] = useState("");
  const [reportName, setReportName] = useState("BrandTag Report (BrandTag.io).csv");
  const { palette } = useTheme();
  const csvRef = useRef();
  const progress = useSelector(_ => _.user.progress);

  useEffect(() => {
    const showError = e => {
      enqueueSnackbar(e, {
        variant: "error",
      });
    };
    if (progress?.emailConfirmed) {
      const d = getEpochDates("1w");
      InsightsApi.getAll(d)
        .then(res => {
          setData(res);
        })
        .catch(e => {
          showError(e);
        });
      TrademarkApi.getAll()
        .then(res => {
          setTrademarkList(res);
        })
        .catch(e => {
          showError(e);
        });
      SkuApi.getAll()
        .then(res => {
          setSkuList(res);
          setFilteredSkuList(res);
        })
        .catch(e => {
          showError(e);
        });
      GeoApi.getAll()
        .then(res => {
          setGeoipList(res);
          setFilteredGeoipList(removeLocationDuplicates(res));
        })
        .catch(e => {
          showError(e);
        });
    }
  }, [enqueueSnackbar, progress]);

  const removeLocationDuplicates = locations =>
    locations.reduce((a, i) => {
      if (!a.find(j => j.location === i.location && j.country === i.country)) {
        a.push(i);
      }
      return a;
    }, []);

  const filter = async data => {
    try {
      setLoading(true);
      const d = getEpochDates(date?.from, date?.to);
      const res = await InsightsApi.getAll({
        trademarkId: data.trademarkId ? data.trademarkId : null,
        skuId: data.skuId ? data.skuId : null,
        location: data.location ? data.location : null,
        ...d,
      });
      if (res) {
        setData(res);
      }
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
    setLoading();
  };
  const plainShortDate = d => new Date(d).toLocaleDateString().replaceAll("/", "");

  const downloadReport = async data => {
    try {
      setReportLoading(true);
      const d = getEpochDates(date?.from, date?.to);
      setReportName(plainShortDate(d?.from) + "-" + plainShortDate(d?.to) + " BrandTag Report (BrandTag.io).csv");
      const res = await InsightsApi.getReport({
        trademarkId: data.trademarkId ? data.trademarkId : null,
        skuId: data.skuId ? data.skuId : null,
        location: data.location ? data.location : null,
        ...d,
      });
      if (res) {
        setCsvData(res);
        csvRef.current.link.click();
      }
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
    setReportLoading();
  };

  const handleBrandChange = trademarkId => {
    if (trademarkId > 0) {
      const skus = skuList.filter(i => i.trademarkId === trademarkId);
      !skus.find(i => i.id === getValues("skuId")) && setValue("skuId", 0);
      setFilteredSkuList(skus);
      const locations = removeLocationDuplicates(geoipList.filter(i => i.trademarkId === trademarkId));
      !locations.find(i => i.location === getValues("location")) && setValue("location", 0);
      setFilteredGeoipList(locations);
    } else {
      setFilteredSkuList(skuList);
      setFilteredGeoipList(removeLocationDuplicates(geoipList));
    }
    filter({ trademarkId: getValues("trademarkId"), skuId: getValues("skuId"), location: getValues("location") });
  };
  const handleSkuChange = skuId => {
    if (skuId > 0) {
      const locations = removeLocationDuplicates(geoipList.filter(i => i.skuId === skuId));
      !locations.find(i => i.location === getValues("location")) && setValue("location", 0);
      setFilteredGeoipList(locations);
    } else {
      setFilteredGeoipList(removeLocationDuplicates(geoipList));
    }
  };
  const handleOpenRiskDialog = () => setOpenRiskDialog(true);
  const handleCloseRiskDialog = () => setOpenRiskDialog();
  const Section = ({ children }) => <div className={classes.section}>{children}</div>;

  const action = value => {
    const formattedValue = value.toFixed(1);
    const formattedPercentage = formattedValue.replace(/(\.0+)$/, "");
    return (
      <div className={classes.action} style={{ color: value > 0 ? "#00ff3f" : "#FF1D1D", fontWeight: "bold" }}>
        {value ? `${value > 0 ? "+" : ""}${formattedPercentage}%` : ""}
      </div>
    );
  };

  const renderLegend = ({ payload }) => {
    let total = 0;
    payload.forEach(({ payload }) => {
      total += payload.value;
    });
    return (
      <ul className={classes.legendUl}>
        {payload.map(({ payload }, index) => (
          <li key={`item-${index}`} className={classes.legendLi}>
            <div className={classes.legendDiv}>
              <div className={classes.legendIcon} style={{ backgroundColor: payload.fill }} />
              <span className={classes.pieChartTitle}>{payload.name}</span>
              <span className={classes.pieChartPercentage}>{payload.value ? ((payload.value / total) * 100).toFixed(1) : 0}%</span>
            </div>
          </li>
        ))}
      </ul>
    );
  };
  return (
    <PageContent
      header={t("Labels.Data Insights for Brand")}
      list={
        <BorderlessSelect name="trademarkId" control={control} defaultValue={0} handleChange={handleBrandChange}>
          <MenuItem value={0}>{t("Labels.All")}</MenuItem>
          {trademarkList.map((t, i) => (
            <MenuItem key={`trademark-${i}`} value={t.id}>
              {t.registration_name}
            </MenuItem>
          ))}
        </BorderlessSelect>
      }
    >
      <Grid container>
        <Grid item xs={3}>
          <Typography className={classes.title}>{t("Labels.Date Range")}</Typography>
          <DateRange
            defaultValue={date?.from}
            onChange={_ => setDate(_)}
            values={[
              { label: t("Labels.Today"), value: "1d" },
              { label: t("Labels.This Week"), value: "1w" },
              { label: t("Labels.This Month"), value: "1m" },
              { label: t("Labels.This Year"), value: "1y" },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title}>{t("Labels.SKU")}</Typography>
          <Controller
            render={({ onChange, ...p }) => (
              <Select
                onChange={e => {
                  onChange(e);
                  handleSkuChange(e.target.value);
                }}
                variant="outlined"
                className={classes.select}
                {...p}
              >
                <MenuItem value={0}>{t("Labels.All")}</MenuItem>
                {filteredSkuList?.map((s, i) => (
                  <MenuItem key={`sku-${i}`} value={s.id}>
                    {`${s.name} - ${s.sku}`}
                  </MenuItem>
                ))}
              </Select>
            )}
            control={control}
            name="skuId"
            defaultValue={0}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.title}>{t("Labels.Location")}</Typography>
          <Controller
            as={
              <Select variant="outlined" className={classes.select}>
                <MenuItem value={0}>{t("Labels.All")}</MenuItem>
                {filteredGeoipList?.map((g, i) => (
                  <MenuItem key={`location-${i}`} value={g.location}>
                    {`${g.location}, ${g.country}`}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="location"
            defaultValue={0}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            className={classes.button}
            onClick={handleSubmit(filter)}
            type="submit"
            loading={loading}
            loadingText={t("Messages.Filtering")}
            buttonText={t("Labels.Filter")}
          />
          <Button
            className={classes.button}
            onClick={handleSubmit(downloadReport)}
            loading={reportloading}
            variant="outlined"
            loadingText={t("Messages.Exporting")}
            buttonText={t("Labels.Export CSV")}
            endIcon={<GetAppIcon />}
            noCircleEndIcon
          />
          <CSVLink data={csvData} filename={reportName} ref={csvRef} target="_blank" rel="noopener noreferrer" />
        </Grid>
      </Grid>

      <Section>
        <Box display={"flex"} alignItems={"center"} style={{ gap: 5 }} mb={1}>
          <Typography variant="h2" className={classes.title}>
            {t("Labels.At a glance")}
          </Typography>
          <MuiTooltip
            title={
              <Typography variant="body2">
                {t(
                  "Trend exceeding 100% signifies substantial growth compared to the previous cycle, possibly due to increased demand, enhanced efficiency, or expanded outreach."
                )}
              </Typography>
            }
          >
            <Info style={{ cursor: "pointer" }} />
          </MuiTooltip>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={6} lg={3}>
            <Card
              style={{ backgroundColor: "#5CB5FD" }}
              subtitle={t("Labels.Total Active Code")}
              count={data?.summary.find(i => i.action === "GENERATION")?.count || 0}
              icon={require("assets/svg/TotalActive.svg")}
              action={action(data?.summary.find(i => i.action === "GENERATION")?.trend || 0)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card
              style={{ backgroundColor: "#2D94E6" }}
              subtitle={t("Labels.Total Scan")}
              count={data?.summary.find(i => i.action === "VERIFY")?.count || 0}
              icon={require("assets/svg/TotalScan.svg")}
              action={action(data?.summary.find(i => i.action === "VERIFY")?.trend || 0)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card
              style={{ backgroundColor: "#0b3253" }}
              subtitle={t("Labels.Total Redirect")}
              count={data?.summary.find(i => i.action === "REDIRECT")?.count || 0}
              icon={require("assets/svg/Redirect.svg")}
              action={action(data?.summary.find(i => i.action === "REDIRECT")?.trend || 0)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Card
              style={{ backgroundColor: "#c84040" }}
              subtitle={t("Labels.Outlier Detection")}
              count={data?.risk.length || 0}
              icon={require("assets/svg/warningDetect.svg")}
              action={
                <IconButton onClick={handleOpenRiskDialog} className={classes.riskButton}>
                  <ChevronRight color="inherit" fontSize="large" />
                </IconButton>
              }
            />
          </Grid>
        </Grid>
      </Section>
      {data?.trend && (
        <Section>
          <LineChart
            data={data?.trend}
            values={[
              { value: "creation", label: t("Labels.Total Active Code") },
              { value: "verify", label: t("Labels.Total Scan") },
              { value: "redirect", label: t("Labels.Total Redirect") },
            ]}
            title={t("Labels.Number of Scanning Activities")}
          />
        </Section>
      )}

      {data?.activityMap && (
        <Section>
          <TrackTrace data={data?.activityMap} />
        </Section>
      )}

      <Section>
        <Grid container>
          {!!data?.weeklyActivity.length && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h2" className={classes.title}>
                {t("Labels.Time of Scanning Activities")}
              </Typography>

              <Paper className={classes.paper}>
                <WeeklyHeatmap data={data?.weeklyActivity} />
              </Paper>
            </Grid>
          )}
          {data?.os && (
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="h2" className={classes.title}>
                {t("Labels.OS used for Scan")}
              </Typography>

              <Paper className={classes.paper}>
                <PieChart width={500} height={634}>
                  <Pie
                    data={
                      data?.os &&
                      Object.keys(data.os).map(k => {
                        return { name: k, value: data.os[k] };
                      })
                    }
                    innerRadius={120}
                    startAngle={90}
                    endAngle={450}
                    dataKey="value"
                  >
                    {data?.os &&
                      Object.keys(data.os)?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={palette.analytics[index % palette.analytics.length]} />
                      ))}
                  </Pie>
                  <Tooltip />
                  <Legend align="center" content={renderLegend} />
                </PieChart>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Section>
      <RiskDialog open={openRiskDialog} onClose={handleCloseRiskDialog} data={data?.risk} />
    </PageContent>
  );
}

export default Insight;
