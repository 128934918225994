import api from ".";

const path = "/insights";

const getAll = params => api.get(`${path}`, { params });

const getReport = params => api.get(`${path}/report`, { params });

export default {
  getAll,
  getReport,
};
