import { Card, CardContent, CardHeader, Collapse, IconButton } from "@material-ui/core";
import { ChevronRightRounded, Delete, DragIndicator, KeyboardArrowDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import style from "assets/jss/components/collapseCardStyle";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles(style);

const CollapseCard = ({
  children,
  collapse,
  draggable,
  handleCollapse,
  handleCollapseButton,
  onDelete,
  rootProps,
  titleTypographyProps,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div {...rootProps}>
      <Card variant="outlined" className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          classes={{ action: classes.action }}
          onClick={handleCollapse}
          action={
            <>
              {collapse && onDelete && (
                <IconButton color="primary" onClick={onDelete}>
                  <Delete fontSize="small" />
                </IconButton>
              )}
              <IconButton onClick={handleCollapseButton || handleCollapse}>{collapse ? <KeyboardArrowDown /> : <ChevronRightRounded />}</IconButton>
            </>
          }
          avatar={
            <IconButton onClick={e => e.stopPropagation()} className={classNames({ [classes.hide]: !draggable })}>
              <DragIndicator />
            </IconButton>
          }
          titleTypographyProps={{ variant: "h3", ...titleTypographyProps }}
          {...props}
        />
      </Card>
      <Collapse in={collapse} timeout="auto" unmountOnExit>
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </Collapse>
    </div>
  );
};

export default CollapseCard;
