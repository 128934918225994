export default ({ spacing, palette }) => ({
  root: {
    padding: 24,
  },
  cloudUpload: {
    width: 60,
    height: 60,
  },
  dragndrop: {
    height: "100%",
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: palette.primary.main,
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  navigation: {
    textAlign: "center",
    marginTop: -20,
  },
  activateButton: {
    marginTop: 10,
  },
  emptyPreview: {
    minHeight: 150,
  },
  imgContainer: {
    paddingTop: 10,
  },
  img: {
    width: 260,
    height: 210,
    padding: 50,
  },
  imgExample: {
    width: "100%",
    display: "block",
    paddingBottom: 10,
  },
  gridExample: {
    marginTop: spacing(1),
  },
  message: {
    lineHeight: "1.25",
    textAlign: "center",
    marginBottom: 10,
    maxWidth: 500,
    paddingBottom: 10,
  },
  exampletitle: {
    padding: 20,
    fontSize: 12,
  },
  examplelink: {
    color: palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  action: {
    backgroundColor: palette.background.default,
    padding: 30,
  },
  dndMargin: {
    margin: "12px 10px",
  },
  positionEnd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  halfSize: {
    width: "50%",
  },
  headerTitle: {
    paddingBottom: 10,
  },
  previewCount: {
    padding: 0,
  },
  activation: {
    paddingTop: 24,
  },
  bold: {
    fontWeight: 600,
  },
  semiTrans: {
    opacity: 0.5,
  },
  dropzoneText: {
    padding: 6,
  },
  text: {
    padding: 12,
  },
  title: {
    textAlign: "center",
    paddingTop: 30,
  },
  button: {
    height: 37,
    width: 212,
  },
  results: {
    paddingTop: 20,
  },
  paperStyle: {
    maxHeight: 425,
    overflowY: "auto",
  },
  codeActivated: {
    padding: "24px 180px",
  },
  activateTable: {
    paddingLeft: 60,
    paddingRight: 40,
    paddingBottom: 40,
    background: palette.background.paper,
  },
  dataPreview: {
    minHeight: "100%",
  },
  ol: {
    paddingLeft: 0,
  },
});
