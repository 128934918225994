import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import TagApi from "api/tag";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toVerifyUrl } from "utils";

const useStyles = makeStyles(({ palette }) => ({
  content: {
    border: `solid 1px ${palette.background.default}`,
    borderRadius: 4,
    padding: 0,
    margin: "8px 16px",
  },
  actions: {
    marginRight: 8,
  },
  radioGroup: {
    justifyContent: "center",
  },
  checkbox: {
    paddingLeft: 12,
  },
}));

function DownloadDialog({ open, handleClose, data, activated = 0, setCodeList, setCsvFileName, setStartDownload }) {
  const [type, setType] = useState();
  const [limit, setLimit] = useState();
  const [helperText, setHelperText] = useState();
  const [loading, setLoading] = useState();
  const [includeSN, setIncludeSN] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const { t } = useTranslation();

  const handleLimitChange = event => {
    setLimit(event.target.value);
    validate(event.target.value, type);
  };

  const handleTypeChange = event => {
    setType(event.target.value);
    validate(limit, event.target.value);
  };

  const validate = (limit, type) => {
    const { quantity } = data;
    setHelperText(() =>
      t(
        limit < 0
          ? `Invalid amount`
          : type === "Inactive" && limit > quantity - activated
          ? `Inactive amount cannot exceed ${quantity - activated}`
          : type === "Active" && limit > activated
          ? `Active amount cannot exceed ${activated}`
          : limit > quantity
          ? `Amount cannot exceed ${quantity}`
          : ""
      )
    );
  };

  const handleDownloadClick = () => {
    setLoading(true);
    const { id, order_id } = data;
    TagApi.download(id, { limit, type: type ? type : undefined })
      .then(res => {
        if (res.length > 0) {
          setCodeList(
            res.map(({ address, serial_no }) => {
              const Codes = toVerifyUrl(address);
              return includeSN ? { Codes, "Serial No": serial_no } : { Codes };
            })
          );
          setCsvFileName(`order_${order_id} (${limit ? limit : ""}${type ? " " + type : ""} code${limit === 1 ? "" : "s"}).csv`);
          setStartDownload(true);
          setLoading(false);
          handleClose();
        } else {
          enqueueSnackbar(t("Messages.Unable to find any codes to download, Please contact customer support"), {
            variant: "error",
          });
        }
      })
      .finally(() => {
        setLimit();
        setType();
        setLoading();
      });
  };

  useEffect(() => {
    if (open) {
      setLimit(data?.quantity);
    }
  }, [open, data]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle disableTypography>
        <Typography variant="h1">{t("Download Codes")}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          fullWidth
          type="number"
          labelPlacement="left"
          label={t("Amount")}
          tooltipText={t("Amount of code to be downloaded")}
          helperText={helperText}
          defaultValue={data?.quantity}
          error={helperText}
          onChange={handleLimitChange}
        />
        <RadioGroup defaultValue={""} row value={type} onChange={handleTypeChange} className={classes.radioGroup}>
          <FormControlLabel value={""} control={<Radio />} label="All" />
          <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
          <FormControlLabel value="Active" control={<Radio />} label="Active" />
        </RadioGroup>
        <FormControlLabel
          className={classes.checkbox}
          control={<Checkbox checked={includeSN} onChange={() => setIncludeSN(_ => !_)} />}
          label={t("Include Serial No.")}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" buttonText={t("Labels.Cancel")} onClick={handleClose} />
        <Button
          buttonText={t("Download")}
          type="submit"
          loading={loading}
          disabled={loading || helperText}
          onClick={handleDownloadClick}
          endIcon={<ChevronRightRounded />}
        />
      </DialogActions>
    </Dialog>
  );
}

export default DownloadDialog;
