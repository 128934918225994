import api from ".";

const path = "/logistic";

const getAll = params => api.get(`${path}`, { params });

const getById = id => api.get(`${path}/${id}`);

const create = data => api.post(`${path}`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

const getAccessToken = () => api.get(`${path}/accessToken`);

const endSensorLogging = a => api.post(`${path}/endSensorLogging`, null, { params: { a } });

const arrived = a => api.post(`${path}/arrived`, null, { params: { a } });

export default {
  getAll,
  getById,
  create,
  update,
  remove,
  getAccessToken,
  endSensorLogging,
  arrived,
};
