const environment = (
  state = {
    theme: localStorage.getItem("theme"),
    language: localStorage.getItem("i18nextLng"),
  },
  action
) => {
  switch (action.type) {
    case "CHANGE_THEME":
      localStorage.setItem("theme", action.theme);
      return {
        ...state,
        theme: action.theme,
      };
    case "CHANGE_TOOLBAR_COLOR":
      return {
        ...state,
        toolbarColor: action.toolbarColor,
      };

    case "CHANGE_PRIMARY_COLOR":
      return {
        ...state,
        primaryColor: action.primaryColor,
      };

    case "CHANGE_SECONDARY_COLOR":
      return {
        ...state,
        secondaryColor: action.secondaryColor,
      };

    case "CHANGE_LANGUAGE":
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
};

export default environment;
