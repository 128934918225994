import { makeStyles } from "@material-ui/core";
import cx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(({ palette, transitions, breakpoints }) => {
  const SIZES = {
    xs: 8,
    sm: 10,
    lg: 12,
  };
  return {
    root: {
      display: "inline-block",
      border: 0,
      background: "none",
      padding: 0,
      lineHeight: 0,
      cursor: "pointer",
      "& + $root": {
        marginLeft: SIZES.xs,
      },
      "&:hover": {
        "&:after": {
          transform: "scale(1.2)",
        },
      },
      "&:after": {
        content: '""',
        display: "inline-block",
        width: SIZES.xs,
        height: SIZES.xs,
        borderRadius: "50%",
        backgroundColor: palette.text.disabled,
        transition: transitions.create(),
      },
    },
    active: {
      "&:after": {
        backgroundColor: palette.text.primary,
      },
    },
  };
});

const DotIndicator = ({ active, className, ...props }) => {
  const classes = useStyles();
  return <button type={"button"} tabIndex={0} className={cx(className, classes.root, active && `-active ${classes.active}`)} {...props} />;
};

DotIndicator.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
};
DotIndicator.defaultProps = {
  className: "",
  active: false,
};
DotIndicator.displayName = "DotIndicator";

export default DotIndicator;
