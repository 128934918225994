import { Grid, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FormQuestionApi from "api/formQuestion";
import FormSectionApi from "api/formSection";
import FormSubmissionApi from "api/formSubmission";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ palette }) => ({
  sensorGroup: {
    borderRadius: 12,
    border: `solid 1px ${palette.background.default}`,
  },
  textGroup: {
    paddingTop: 12,
    paddingLeft: 12,
  },
}));

function Questions({ id, sectionId, onSensorChange, onChange, sectionAnswers = [], setSectionAnswers }) {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [sensors, setSensors] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (sectionId) {
      FormQuestionApi.getBySection(sectionId)
        .then(res => {
          setQuestions(res);
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });

      FormSectionApi.getSensors(sectionId)
        .then(res => {
          setSensors(res);
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });

      FormSubmissionApi.getAnswersBySectionId(id, sectionId)
        .then(res => {
          setSectionAnswers([...sectionAnswers, ...res]);
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });
    }
  }, [enqueueSnackbar, id, sectionAnswers, sectionId, setSectionAnswers]);

  return (
    <>
      {sensors.map(({ id, name, type }) => (
        <div className={classes.sensorGroup}>
          <Grid container className={classes.textGroup}>
            <Typography color="textPrimary" variant="h3">
              {type} {t("sensor capture range")}
            </Typography>
            <Tooltip placement="right" title={t("Sensor name")}>
              <Typography color="textSecondary" variant="h3">
                &nbsp; - {name}
              </Typography>
            </Tooltip>
          </Grid>
          <TextField
            labelPlacement="left"
            label={t("Labels.Start Time")}
            type="datetime"
            required
            value={sectionAnswers.find(answer => answer.sensorId === id && answer.sectionId === sectionId)?.start_time || null}
            maxDate={sectionAnswers.find(answer => answer.sensorId === id && answer.sectionId === sectionId)?.end_time}
            maxDateMessage={t("HelperText.Start Time must be before End Time")}
            onChange={e => onSensorChange(id, sectionId, "start_time", e)}
          />
          <TextField
            labelPlacement="left"
            label={"End Time"}
            type="datetime"
            required
            value={sectionAnswers.find(answer => answer.sensorId === id && answer.sectionId === sectionId)?.end_time || null}
            minDate={sectionAnswers.find(answer => answer.sensorId === id && answer.sectionId === sectionId)?.start_time}
            minDateMessage={t("HelperText.End Time must be after Start Time")}
            onChange={e => onSensorChange(id, sectionId, "end_time", e)}
          />
        </div>
      ))}
      {questions.map(({ id, datatype, name, prefix, required, suffix }) => (
        <TextField
          labelPlacement="left"
          name={name}
          label={name}
          type={datatype}
          value={sectionAnswers.find(answer => answer.questionId === id)?.value || (datatype.startsWith("date") ? null : "")}
          onChange={e => onChange(id, datatype.startsWith("date") ? e : e.target.value)}
          required={required}
          startAdornment={prefix && <InputAdornment position="start">{prefix}</InputAdornment>}
          endAdornment={suffix && <InputAdornment position="start">{suffix}</InputAdornment>}
        />
      ))}
    </>
  );
}

export default Questions;
