import { Card, CardContent, CardMedia, Grid, makeStyles } from "@material-ui/core";
import Carousel from "components/Carousel";
import ProductDetail from "components/Table/ProductDetail";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  card: {
    overflow: "auto",
    display: "flex",
  },
  root: {
    alignSelf: "center",
  },
  imagesDiv: {
    margin: 2,
    width: 180,
    textAlign: "center",
    fontStyle: "italic",
  },
  detailsDiv: {
    width: "100%",
  },
});

function Preview({ images = [], details = {} }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.card} elevation={1}>
      <CardMedia classes={{ root: classes.root }}>
        <div className={classes.imagesDiv}>{images.length ? <Carousel data={images} /> : t("No image available")}</div>
      </CardMedia>
      <CardContent className={classes.detailsDiv}>
        <Grid container justify="center">
          <ProductDetail details={details} />
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Preview;
