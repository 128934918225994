import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { decodeJWTPayload } from "utils";

const PrivateRoute = ({ component: Component, admin, ...rest }) => {
  const jwtToken = useSelector(_ => _.user.jwtToken);
  return (
    <Route
      {...rest}
      render={props =>
        tokenIsValid(jwtToken, admin) ? <Component {...props} /> : <Redirect to={{ pathname: "/error", state: { from: props.location } }} />
      }
    />
  );
};

const tokenIsValid = (token, admin) => {
  try {
    const decoded = decodeJWTPayload(token);
    return admin ? decoded.isAdmin : decoded !== null;
  } catch (e) {
    return false;
  }
};

export default PrivateRoute;
