import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { formatNumber } from "utils";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 20,
    marginRight: 6,
    width: "100%",
    height: 150 * 0.75,
    borderRadius: 10,
    border: `solid 3px ${palette.background.default}`,
  },
  icon: {
    width: 43.23,
    height: 48.44,
  },
  details: {
    position: "relative",
    paddingLeft: 12,
  },
  count: {
    fontSize: 34,
    color: "#ffffff",
    position: "absolute",
    top: -13,
  },
  subtitle: {
    fontSize: 14,
    color: "#ffffff",
    position: "absolute",
    top: 26,
    width: "max-content",
  },
  action: {
    float: "right",
  },
}));

function ForGrafana({ count, subtitle, icon, action, ...props }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} {...props}>
      <Grid container>
        <img src={icon} alt={count} className={classes.icon} />
        <div className={classes.details}>
          <Typography className={classes.count} variant={"h1"}>
            {formatNumber(count)}
          </Typography>
          <Typography variant={"subtitle1"} className={classes.subtitle}>
            {subtitle}
          </Typography>
        </div>
      </Grid>
      <div className={classes.action}>{action}</div>
    </Paper>
  );
}
export default ForGrafana;
