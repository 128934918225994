import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";

const qNA = [
  {
    question: "Tell me more about BrandTag",
    answer: [
      "To brand owners, BrandTag helps to protect their brands and products, How? Protect each product unit with a unique code, which is secured by Blockchain technology, making it non-economic for the counterfeiters to fake the products as the cost is just simply too high, Additionally, now each of their product can now act as their best salesman thanks to BrandTag, kindly contact keith@luxtag,io to find out more, To the shoppers, with BrandTag solutions, they can be assured that what they intended to purchase is what they will really get! No more buying the fakes while paying the price of the original products as they are now empowered by BrandTag to verify every tagged product registered by BrandTag,",
    ],
  },
  { question: "How do I use BrandTag?", answer: ["Sign up, verify your email, register your business, register your trademark, then create a SKU"] },
  { question: "Is BrandTag available on browsers other than Chrome?", answer: ["For the best exprience, you may use Chrome"] },
  {
    question: "How long will BrandTag take to generate or activate my codes?",
    answer: ["To generate, it will take around 28 codes per seconds, To activate, it peaks at 30 codes per seconds"],
  },
  {
    question: "How many dynamic fields can I add upon activating my codes?",
    answer: [
      "As we are securing your codes on blockchain, you can only add up to 1kb of payload, Excess payload will be trimmed and not be stored in the blockchain",
    ],
  },
  {
    question: "BrandTag solution is available to which regions/countries?",
    answer: [
      "As BrandTag is a SaaS solution, brand owners and shoppers around the world can enjoy the brand protection and marketing benefits offered by BrandTag",
    ],
  },
  {
    question: "How accurate is the location services for your data insights?",
    answer: [
      "We estimate that countries level are 95%-99% accurate, regions (states) level are 55%-80% accurate, and the cities level is 50-75% accurate",
    ],
  },
  {
    question: "How long does it take for my trademark to be approved?",
    answer: [
      "Provided you have submitted all the required documents and information, our team will require only 3 working days to verify and approve your trademark(s)",
    ],
  },
  {
    question: "How to Print my code?",
    answer: [
      "By default, you can just pass the generated codes to your printers and they will know how to convert the codes to QR codes and print them on your packaging accordingly, If you need additional support, kindly contact keith@luxtag,io",
    ],
  },
  {
    question: "Does it means that my brands/products are secured by using Bitcoin?",
    answer: ["While Bitcoin is using Blockchain technology, your brands/products are secured by blockchain technology, not Bitcoin"],
  },
  {
    question: "The counterfeiters still can copy the codes, right?",
    answer: [
      "With BrandTag solution, each product is registered with a unique code, not serialised codes where the counterfeiters can copy easily, Shall the counterfeiter decided to exploit the single code that they have chosen to copy, BrandTag is able to pick up suspicious scanning activities such as 2 or 3 scan on the same code in a different location and send the alert to brand owners to take required actions, Thus making the counterfeiting business simply uneconomic to do so, BrandTag will offer more tagging option for example NTAG 424 which is a form a NFC Tag that it is impossible to even copy the code, However, this is a more expensive version when compared to QR Codes and may be more suitable for luxury items or other products that are above a certain price point",
    ],
  },
  {
    question: "How can I ensure that my customers scan the QR codes of my products?",
    answerHeader: "To encourage your shoppers to scan the QR codes on your product, kindly ensure that the codes are:",
    answer: [
      "Position in an obvious spot of your product packaging",
      "Placed a relevant call-to-action (CTA) message beside the QR code to attract shoppers to scan it",
      "It's easy for the shoppers to scan the Qr codes as nowadays smartphone can scan it without the need to install additional apps",
      "Offer a reward/value to the shoppers after they scanned the QR codes, so that they are motivated to scan your products in the future",
    ],
  },
  {
    question: "Is there anything I need to be extra careful about within the BrandTag app?",
    answer: ["Don't worry, Most actions can be undone, and the ones which can't, have clear warning messages pop up for you to approve"],
  },
  {
    question: "Does BrandTag offer other tag options than QR? Thinking about RFID, NFC, others",
    answer: ["BrandTag will offer more tagging options in the near future, Stay tuned!"],
  },
  {
    question: "Why doesn’t LuxTag give me QR code graphics files to download?",
    answer: [
      "We learnt that packaging manufacturers or printing houses prefer to get CSV code lists and they feed this into their machines to print QRs",
    ],
  },
  {
    question: "How do I easily convert the code string into a QR so I can try out the system?",
    answer: [
      "Large production manufacturers have their own software for QR-code generation, In order to try it in test form, just type in any search engine 'QR code generation'",
    ],
  },
];

const useStyles = makeStyles(() => ({
  faqItems: {
    margin: 10,
    boxShadow: " 1.5px 2.6px 10px 0 rgba(119, 119, 119, 0.1)",
  },
  faqItem: {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    paddingTop: "none",
  },
  answerStyle: {
    color: "#818181",
    paddingTop: 0,
    fontWeight: 400,
  },
  answerHeader: {
    paddingLeft: 3,
    paddingTop: 0,
  },
}));

function FAQ() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper elevation={3} className={classes.faqItems}>
      {qNA.map(({ question, answer, answerHeader }, i) => (
        <Accordion key={i} className={classes.faqItem}>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>{t(`FAQ.Titles.${question}`)}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.answerStyle}>
              {answerHeader && <span className={classes.answerHeader}>{t`FAQ.Answers.${answerHeader}`}</span>}
              {answer && (
                <ul>
                  {answer.map(innerElement => (
                    <li>{t(`FAQ.Contents.${innerElement}`)}</li>
                  ))}
                </ul>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Paper>
  );
}

export default FAQ;
