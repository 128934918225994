import { Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BusinessApi from "api/business";
import sidebarStyle from "assets/jss/components/sidebarStyle";
import classNames from "classnames";
import preval from "preval.macro";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { setProgress } from "redux/actions/user";
import { decodeJWTPayload } from "utils";
import { version } from "../../../package.json";
import CollapseButton from "./CollapseButton";
import StatusProgress from "./Progress";
import SidebarTooltip from "./Tooltip";

const isActiveRoute = (location, path) => (location.pathname.startsWith("/admin") ? location.pathname.endsWith(path) : location.pathname === path);

const useStyles = makeStyles(sidebarStyle);
function Sidebar({ open, openMobile, onDrawerClose, routes, location, onDrawerOpen }) {
  const classes = useStyles();

  const [text, setText] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const { jwtToken, banner, demo } = useSelector(_ => _.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { email, isAdmin } = decodeJWTPayload(jwtToken);

  const handleTooltipClose = () => {
    setAnchorEl();
    setText();
  };

  const progressData = useCallback(async () => {
    const checklist = await BusinessApi.getProgress();
    dispatch(setProgress(checklist));
  }, [dispatch]);

  useEffect(() => {
    !isAdmin && progressData();
  }, [isAdmin, progressData, demo]);

  function renderList(isMobile) {
    return (
      <>
        {(!isMobile || (isMobile && openMobile)) && (
          <div className={classNames({ [classes.hidden]: !open })}>
            <Typography variant="subtitle1" gutterBottom className={classes.username}>
              <div className={classes.truncate}>{demo ? "Demo account" : email}</div>
            </Typography>
            {!isAdmin && <StatusProgress />}
          </div>
        )}
        {!isMobile && <CollapseButton onDrawerOpen={onDrawerOpen} open={open} />}
        <List
          className={classNames(classes.summaryList, {
            [classes.summaryListBanner]: banner,
            [classes.summaryListDemo]: demo,
          })}
          disablePadding
        >
          {routes.map((route, index) => {
            if (route.divider) return <Divider className={classes.divider} key={index} />;
            if (!route.label) return null;
            return listItemLink(index, isMobile, route);
          })}
        </List>
        <Tooltip title={t("Tooltips.Build At") + ": " + new Date(preval`module.exports = new Date();`).toLocaleString()}>
          <Typography variant="caption" gutterBottom className={classes.version}>
            v{version}
          </Typography>
        </Tooltip>
      </>
    );
  }

  const listItemLink = (index, isMobile, route, step = 0) => {
    const { disableRoute, children, path } = route;
    return (
      <div key={index}>
        {disableRoute ? (
          listItem(route, isMobile, step)
        ) : (
          <NavLink to={path} onClick={onDrawerClose("mobile")}>
            {listItem(route, isMobile, step)}
          </NavLink>
        )}
        {children &&
          children.length &&
          children.map((e, i) => {
            if (!e.label) return null;
            return listItemLink(i, isMobile, e, step + 1);
          })}
      </div>
    );
  };

  const showTooltip = label => e => {
    setAnchorEl(e.currentTarget);
    setText(label);
  };

  const listItem = ({ label, path, icon: Icon, disableRoute }, isMobile, step) => {
    const active = isActiveRoute(location, path);
    return (
      <ListItem
        style={open && step ? { paddingLeft: step * 40 } : {}}
        button={!disableRoute}
        onMouseEnter={!open && !isMobile && showTooltip(t(label))}
        onMouseLeave={!open && !isMobile && handleTooltipClose}
        className={classNames(classes.listItem, {
          [classes.childListItem]: !disableRoute,
          [classes.active]: active,
        })}
      >
        {Icon && (
          <ListItemIcon className={classNames({ [classes.nothing]: disableRoute && open })}>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={t(label)}
          classes={{
            primary: classNames(classes.text, {
              [classes.activeText]: active,
              [classes.hidden]: !open,
            }),
          }}
        />
      </ListItem>
    );
  };

  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.banner]: banner,
            [classes.demo]: demo,
          })}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
              [classes.banner]: banner,
              [classes.demo]: demo,
            }),
          }}
          open={open}
        >
          {renderList(false)}
        </Drawer>
        <SidebarTooltip classes={classes} open={Boolean(!open && anchorEl)} text={text} anchorEl={anchorEl} onClose={handleTooltipClose} />
      </Hidden>

      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={openMobile}
          ModalProps={{ onBackdropClick: onDrawerClose("mobile") }}
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerOpenMobile]: open,
            [classes.banner]: banner,
            [classes.demo]: demo,
          })}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerOpenMobile]: open,
              [classes.banner]: banner,
              [classes.demo]: demo,
            }),
          }}
        >
          {renderList(true)}
        </Drawer>
      </Hidden>
    </>
  );
}

Sidebar.propTypes = {
  open: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  openMobile: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  routes: PropTypes.array.isRequired,
};

export default withRouter(Sidebar);
