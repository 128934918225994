import { AppBar, Grid, Hidden, IconButton, Toolbar } from "@material-ui/core";
import { AccountBalanceWalletRounded, Menu } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import BillingApi from "api/billing";
import headerStyle from "assets/jss/components/headerStyle";
import classNames from "classnames";
import Banner from "components/Banner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setCredit } from "redux/actions/user";
import { decodeJWTPayload, formatNumber } from "utils";

const useStyles = makeStyles(headerStyle);
function Header({ onDrawerOpen }) {
  const classes = useStyles();
  const toolbarColor = useSelector(_ => _.environment.toolbarColor);
  const { demo, credit, jwtToken } = useSelector(_ => _.user);
  const [remainingDays, setRemainingDays] = useState(90);
  const [trial, setTrial] = useState();
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAdmin, emailConfirmed, merchant } = decodeJWTPayload(jwtToken);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setLoading(true);
      !isAdmin &&
        emailConfirmed &&
        BillingApi.getBalance()
          .then(res => {
            setRemainingDays(res.remainingDays);
            setTrial(res.trial);
            setIsActive(res.isActive);
            if (res.remainingCodes) {
              dispatch(setCredit(res.remainingCodes));
            }
          })
          .finally(() => setLoading(false));
    } catch (e) {}
  }, [demo, dispatch, emailConfirmed, isAdmin]);

  const renderButton = () => (
    <IconButton className={classes.menuButton} onClick={onDrawerOpen} color="inherit" aria-label="Open drawer">
      <Menu />
    </IconButton>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color={toolbarColor ?? "inherit"} className={classes.appBar}>
        <Banner trial={trial} remainingDays={remainingDays} isActive={isActive} isAdmin={isAdmin} loading={loading} />

        <Toolbar className={classes.toolbar}>
          <div>{<Hidden mdUp>{renderButton()}</Hidden>}</div>
          <Link to="/" className={classes.logo}>
            {merchant !== "haltrace" ? (
              <img alt="brandtag" src={require("assets/svg/BrandTag.svg")} width="206" />
            ) : (
              <img alt="haltrace" src={require("assets/img/HalTrace.png")} width="206" />
            )}
          </Link>
          <div className={classes.grow} />
          {!isAdmin && (
            <div className={classes.wallet}>
              <Hidden smDown>
                <AccountBalanceWalletRounded fontSize="large" className={classes.walletIcon} />
              </Hidden>
              <Grid item className={classes.walletDetails}>
                <div className={classes.cb}>{t("Labels.Credit Balance")}</div>
                <div
                  className={classNames(classes.cbv, {
                    [classes.cbve]: remainingDays <= 0,
                  })}
                >
                  {formatNumber(credit)}
                </div>
              </Grid>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
