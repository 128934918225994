const user = (
  state = {
    jwtToken: localStorage.getItem("jwtToken"),
    credit: 0,
    progress: {},
    demo: false,
    banner: false,
  },
  action
) => {
  switch (action.type) {
    case "SET_TOKEN":
      localStorage.setItem("jwtToken", action.jwtToken);
      return {
        ...state,
        jwtToken: action.jwtToken,
      };
    case "SET_LOGISTIC_SENSOR_TOKEN":
      return {
        ...state,
        logisticSensorToken: action.logisticSensorToken,
      };
    case "SET_CREDIT":
      return {
        ...state,
        credit: action.credit,
      };
    case "SET_PROGRESS":
      return {
        ...state,
        progress: action.progress,
      };
    case "SIGNOUT":
      localStorage.setItem("jwtToken", "");
      return {
        ...state,
        jwtToken: "",
      };
    case "SET_DEMO":
      return {
        ...state,
        demo: action.demo,
      };
    case "SET_BANNER":
      return {
        ...state,
        banner: action.banner,
      };
    default:
      return state;
  }
};

export default user;
