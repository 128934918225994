import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles(({ palette }) => ({
  link: {
    color: palette.primary.main,
    fontWeight: "bold",
    display: "inline-flex",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function A({ className, ...props }) {
  const classes = useStyles();
  return (
    <a className={classNames(classes.link, className)} target="_blank" rel="noopener noreferrer" {...props}>
      {props.children}
    </a>
  );
}

export default A;
