import { makeStyles, Paper, useTheme } from "@material-ui/core";
import PayloadApi from "api/payload";
import format from "date-fns/format";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles(({ palette }) => ({
  status: {
    border: `2px solid ${palette.background.default}`,
    textAlign: "center",
    padding: 10,
  },
}));

function ATable() {
  const { t } = useTranslation();
  const classes = useStyles();
  const Container = props => <Paper elevation={0} {...props} />;
  const Toolbar = () => false;
  const { palette } = useTheme();

  const [activatedList, setActivatedList] = useState();
  const { demo } = useSelector(_ => _.user);

  useEffect(() => {
    if (!activatedList) {
      PayloadApi.activateBatch().then(res => {
        setActivatedList(res);
      });
    }
  }, [activatedList, demo]);

  return (
    <React.Fragment>
      <MaterialTable
        options={{
          draggable: false,
          search: false,
          sorting: false,
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
            border: "3px solid",
            borderColor: palette.background.default,
          },
          rowStyle: {
            border: "3px solid",
            borderColor: palette.background.default,
          },
          cellStyle: {
            borderBottom: 0,
          },
        }}
        components={{
          Container,
          Toolbar,
        }}
        columns={[
          {
            title: t("Labels.Date Created"),
            align: "center",
            field: "createdAt",
            grouping: false,
            defaultSort: "desc",
            render: rowData => <span title={format(new Date(rowData.createdAt), "PPppp")}>{format(new Date(rowData.createdAt), "PP")}</span>,
          },
          {
            title: t("Labels.Batch ID"),
            align: "center",
            field: "identifier",
          },
          { title: t("Labels.Quantity"), align: "center", field: "quantity", grouping: false },
          {
            title: t("Labels.Status"),
            align: "center",
            render: ({ status }) => <div className={classes.status}>{t(status)}</div>,
          },
        ]}
        data={activatedList}
      />
    </React.Fragment>
  );
}

export default ATable;
