import UserApi from "api/user";
import PageContent from "components/PageContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "utils";
import AddUser from "./AddUser";
import UserTable from "./UserTable";

function UserManagement() {
  const { t } = useTranslation();

  const [userDatas, setUserDatas] = useState([]);

  //GET all user data and update the userdata state
  function getUserData() {
    UserApi.getAll().then(res => {
      var data = res.map(obj => {
        var status = obj.blocked ? "Inactive" : "Active";
        let confirmed = obj.confirmed ? obj.confirmed : false;
        var roleCapital = capitalizeFirstLetter(obj.role.type);

        return {
          id: obj.id,
          name: obj.username,
          role: roleCapital,
          email: obj.email,
          businessName: obj.role.description,
          status: status,
          confirmed: confirmed,
        };
      });

      setUserDatas(data);
    });
  }

  useEffect(() => {
    getUserData();
  }, []);

  //onUpdate will be called every time changes are made to the database
  return (
    <PageContent header={t("Labels.Manage Users")}>
      <AddUser onUpdate={getUserData} />
      <UserTable tableData={userDatas} onUpdate={getUserData} />
    </PageContent>
  );
}

export default UserManagement;
