import { Button, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CustomDateRange from "components/Picker/DateRange";
import React, { useState } from "react";

const filterHeights = 31;

const useStyles = makeStyles({
  paper: {
    padding: 8,
    width: 336,
  },
  button: {
    margin: 4,
    height: filterHeights,
  },
  select: {
    width: "96%",
    height: filterHeights,
    marginLeft: 0,
    paddingLeft: 0,
  },
  buttonGroup: {
    display: "grid",
  },
});

function DateRange({ values = [], defaultValue, onChange, ...props }) {
  const classes = useStyles();
  const [text, setText] = useState(values.find(i => i.value === defaultValue)?.label);
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const [from, setFrom] = useState(now);
  const [to, setTo] = useState(now);

  const handleFromChange = d => {
    setText("custom");
    setFrom(d);
    onChange({ from: d.getTime(), to: to.getTime() });
  };
  const handleToChange = d => {
    setText("custom");
    setTo(d);
    onChange({ from: from.getTime(), to: d.getTime() });
  };

  return (
    <Select
      className={classes.select}
      variant="outlined"
      value={text}
      renderValue={v => (v === "custom" ? `${from.toLocaleDateString()} ↔ ${to.toLocaleDateString()}` : v)}
      {...props}
    >
      <CustomDateRange from={from} to={to} onFromChange={handleFromChange} onToChange={handleToChange} />
      <div className={classes.buttonGroup}>
        {values.map((e, i) => (
          <Button
            key={i}
            className={classes.button}
            variant="outlined"
            onClick={() => {
              setText(e.label);
              onChange({ from: e.value });
            }}
          >
            {e.label}
          </Button>
        ))}
      </div>
    </Select>
  );
}
export default DateRange;
