import api from ".";

const path = "/section";

const getByForm = id => api.get(`${path}/byFormId/${id}`);

const get = id => api.get(`${path}/${id}`);

const getSensors = id => api.get(`${path}/${id}/sensor`);

const update = (id, data) => api.put(`${path}/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

export default {
  getByForm,
  get,
  getSensors,
  update,
  remove,
};
