import { Avatar, makeStyles, Typography } from "@material-ui/core";
import { Phone, Telegram } from "@material-ui/icons";
import A from "components/A";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    padding: 8,
    marginRight: 74,
  },
  details: {
    paddingLeft: 14,
  },
  large: {
    width: spacing(7),
    height: spacing(7),
  },
  title: {
    fontWeight: "bold",
  },
  email: {
    position: "relative",
    bottom: 6,
  },
  link: {
    color: palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  telegram: {
    position: "absolute",
  },
  other: {
    position: "relative",
    bottom: 10,
  },
}));

const Personnel = ({ name, email, phoneNo, telegram, imgLink }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Avatar className={classes.large} alt={name} src={imgLink} />
      <div className={classes.details}>
        <Typography className={classes.title} variant="h3">
          {name}
        </Typography>
        <Typography className={classes.email} variant="subtitle1">
          <a
            className={classes.link}
            href={`mailto:${email}?subject=${t("Labels.BrandTag Credit Top Up")}&body=Hello ${name}, I would like to top up my credit.`}
          >
            {email}
          </a>
        </Typography>
        {phoneNo && (
          <Typography className={classes.other} variant="subtitle2">
            <Phone fontSize="small" />
            {phoneNo}
          </Typography>
        )}
        {telegram && (
          <Typography className={classes.other} variant="subtitle2">
            <Telegram fontSize="small" />
            <A className={classes.telegram} href={`//t.me/${telegram}`}>
              {"@" + telegram}
            </A>
          </Typography>
        )}
      </div>
    </div>
  );
};

export default Personnel;
