import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import SensorApi from "api/sensor";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import Button from "components/Button";
import LineChart from "components/Charts/LineChart";
import DateRange from "components/Select/DateRange";
import MaterialTable, { MTableToolbar } from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEpochDates } from "utils/date";

const useStyles = makeStyles(() => ({
  row: {
    paddingBottom: 24,
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 5,
    marginTop: 15,
    marginBottom: 15,
  },
  addButton: {
    width: "100%",
    textAlign: "center",
  },
  text: {
    textAlign: "center",
  },
  timeline: {
    padding: 0,
    margin: 0,
  },
  threshold: {
    color: "red",
  },
}));

const useToolBarStyles = makeStyles(mtoolbarstyle);

function Log({ sensorId, resetData }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [logsList, setLogsList] = useState([]);
  const [sensor, setSensor] = useState();
  const [date, setDate] = useState({ from: "1w" });
  const toolbarClasses = useToolBarStyles();

  const Container = props => <Paper elevation={0} {...props} />;
  const Title = () => <Typography variant="h2">{t("Labels.Logs")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  useEffect(() => {
    const d = getEpochDates(date?.from, date?.to);

    SensorApi.getAllBySensorId(sensorId, { startTime: d?.from, endTime: d?.to }).then(res => {
      const { logs, ...sensor } = res;
      setLogsList(
        logs.map(log => ({
          ...log,
          date: new Date(log.createdAt).toLocaleDateString([], {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
        }))
      );
      setSensor(sensor);
    });
  }, [sensorId, date]);

  return (
    <Grid container spacing={0}>
      <Grid item className={classes.row} xs={6} md={3}>
        <Typography variant="h4">
          {t("Labels.Date Range")}
          <DateRange
            defaultValue={date?.from}
            onChange={_ => setDate(_)}
            values={[
              { label: t("Labels.Today"), value: "1d" },
              { label: t("Labels.This Week"), value: "1w" },
              { label: t("Labels.This Month"), value: "1m" },
              { label: t("Labels.This Year"), value: "1y" },
            ]}
          />
        </Typography>
      </Grid>
      <Grid item className={classes.row} xs={12}>
        {logsList.length ? (
          <LineChart
            title={t(sensor?.type) + " Log"}
            data={logsList}
            values={[{ value: "value", label: t(sensor?.type) }]}
            threshold={sensor?.threshold}
            unit={sensor?.unit}
          />
        ) : (
          <Typography variant="h4" className={classes.text}>
            {t("No records to display")}
          </Typography>
        )}
      </Grid>

      <Grid item className={classes.row} xs={8}>
        <MaterialTable
          options={{
            toolbar: true,
            draggable: false,
            detailPanelColumnAlignment: "left",
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: palette.background.default,
              border: "3px solid",
              borderColor: palette.background.default,
            },
            rowStyle: {
              border: "3px solid",
              borderColor: palette.background.default,
            },
            cellStyle: {
              borderBottom: 0,
            },
          }}
          components={{
            Container,
            Toolbar,
          }}
          title={Title()}
          columns={[
            {
              title: t("Labels.Date"),
              align: "center",
              field: "date",
              render: rowData => new Date(rowData.createdAt).toLocaleDateString([], { day: "numeric", month: "long", year: "numeric" }),
            },
            {
              title: t("Labels.Time"),
              align: "center",
              field: "time",
              render: rowData => new Date(rowData.createdAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }),
            },
            {
              title: t(`Labels.${t(sensor?.type)}`) + ` (${sensor?.unit})`,
              align: "center",
              field: "value",
              render: rowData => (rowData.value >= sensor?.threshold ? <div className={classes.threshold}>{rowData.value}</div> : rowData.value),
            },
          ]}
          data={logsList}
        />
        <Button className={classes.button} variant="outlined" buttonText={t("Labels.Back")} onClick={resetData} />
      </Grid>
    </Grid>
  );
}

export default Log;
