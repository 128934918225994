import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import TrademarkApi from "api/trademark";
import UploadApi from "api/upload";
import Buttons from "components/Button";
import TextField from "components/TextField";
import UploadTextField from "components/UploadTextField";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  textfield: {
    margin: 0,
  },
  progressWrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: `-${theme.typography.fontSize}px`,
    marginLeft: `-${theme.typography.fontSize}px`,
  },
  fileLabel: {
    padding: 12,
  },
  topLabel: {
    height: 23,
    fontSize: 16,
    lineHeight: 1.63,
  },
  buttonGroup: {
    paddingRight: 12,
    marginBottom: 6,
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 10,
  },
  aic: {
    marginLeft: 0,
  },
}));

function Fields({ onCancel, handleBack, data = {}, saveAndNext, saveText, cancelText }) {
  const classes = useStyles();
  const { register, errors, handleSubmit, setValue } = useForm();
  const { t } = useTranslation();
  const [aic, setAIC] = useState();
  const [loading, setLoading] = useState(0);
  const { demo } = useSelector(_ => _.user);
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState();

  const formatData = ({ registration_name, registration_no, applicant_name, applicant_address, country, file, ...details }) => {
    return { upload: file[0], trademark: { registration_name, registration_no, applicant_name, applicant_address, country, details } };
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      setIsEdit(true);
      Object.keys(data)
        .filter(k => !["id", "createdAt", "status", "tableData", "updatedAt", "merchant"].includes(k))
        .forEach(i => setValue(i, data[i]));
      data.details &&
        Object.keys(data.details)
          .filter(k => !["file", "id", "createdAt", "status", "tableData", "updatedAt", "merchant"].includes(k))
          .forEach(i => setValue(i, data.details[i]));
    }
  }, [data, setValue]);

  const saveForm = async formData => {
    if (!aic) {
      enqueueSnackbar(t("HelperText.Please tick the checkbox if you want to proceed"), {
        variant: "error",
      });
      return;
    }

    try {
      const { upload, trademark } = formatData(formData);
      if (upload) {
        setLoading(1);
        const { name, hash, ext, mime, size } = await UploadApi.create(upload);
        trademark.details.file = { name, hash, ext, mime, size };
      } else {
        trademark.details.file = data.details.file;
      }
      setLoading(2);
      if (isEdit) {
        await TrademarkApi.update(data.id, { ...trademark, acknowledgeInfoCorrect: aic });
      } else {
        trademark.details.active = true;
        await TrademarkApi.create({ ...trademark, acknowledgeInfoCorrect: aic });
      }
      onCancel && onCancel();
      saveAndNext && saveAndNext();
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading(0);
    }
  };

  return (
    <form className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <TextField
            labelPlacement="top"
            name="registration_name"
            required
            className={classes.textfield}
            inputRef={register({ required: true })}
            label={t("Labels.Trademark Name")}
            error={errors.registration_name}
            helperText={t("HelperText.Please fill in trademark name")}
            tooltipText={t("Tooltips.Your brand's name")}
          />
          <TextField
            labelPlacement="top"
            name="registration_no"
            required
            className={classes.textfield}
            inputRef={register({ required: true })}
            label={t("Labels.Trademark No")}
            error={errors.registration_no}
            helperText={t("HelperText.Please fill in trademark number")}
            tooltipText={t("Tooltips.The serial number of your registered trademark")}
          />
          <TextField
            labelPlacement="top"
            name="class"
            required
            className={classes.textfield}
            inputRef={register({ required: true })}
            label={t("Labels.Trademark Class")}
            error={errors.class}
            helperText={t("HelperText.Please fill in trademark class")}
            tooltipText={t("Tooltips.The classification group for your goods and services")}
          />
          <TextField
            labelPlacement="top"
            name="applicant_name"
            required
            className={classes.textfield}
            inputRef={register({ required: true })}
            label={t("Labels.Applicant Name")}
            error={errors.applicant_name}
            helperText={t("HelperText.Please fill in applicant name")}
          />
          <UploadTextField
            labelPlacement="top"
            name="file"
            inputRef={register({ required: !data?.details?.file })}
            label={t("Labels.Pleasse provide a supporting document")}
            buttonText={t("Labels.Upload")}
            helperText={errors.file ? t("HelperText.Please upload trademark document") : t("HelperText.Max File 2MB")}
            error={errors.file}
            accept="image/*"
            required
            type="file"
            linkPath={`${apiURL}/files/${data?.details?.file?.hash}${data?.details?.file?.ext}`}
            documentName={data?.details?.file?.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            labelPlacement="top"
            name="applicant_address"
            required
            className={classes.textfield}
            inputRef={register({ required: true })}
            label={t("Labels.Applicant Address")}
            error={errors.applicant_address}
            helperText={t("HelperText.Please fill in applicant address")}
            multiline
            rows={4}
            tooltipText={t("Tooltips.The registered address in your certificate of incorporation")}
          />
          <TextField
            labelPlacement="top"
            name="postcode"
            required
            className={classes.textfield}
            inputRef={register({ required: true })}
            label={t("Labels.Postcode")}
            error={errors.postcode}
            helperText={t("HelperText.Please fill in application postcode")}
          />
          <TextField
            labelPlacement="top"
            name="country"
            required
            className={classes.textfield}
            inputRef={register({ required: true })}
            label={t("Labels.Country")}
            error={errors.country}
            helperText={t("HelperText.Please fill in application country")}
          />
          <FormControlLabel
            className={classes.aic}
            control={<Checkbox color="primary" checked={aic} onChange={() => setAIC(!aic)} />}
            label={t("Messages.I hereby acknowledge that the information I have enclosed is correct and legit for the company to review")}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
          <Buttons
            variant="outlined"
            buttonText={cancelText ? cancelText : t("Labels.Cancel")}
            className={classes.button}
            onClick={onCancel || handleBack}
          />
        </Grid>
        <Grid item>
          <Buttons
            loading={loading > 0}
            loadingText={loading === 1 ? t("Messages.Uploading image") : t("Messages.Saving")}
            buttonText={saveText ? saveText : isEdit ? t("Labels.Update") : t("Labels.Create")}
            type="submit"
            disabled={demo}
            className={classes.button}
            onClick={handleSubmit(saveForm)}
            endIcon={!loading && <ChevronRightRounded />}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default Fields;
