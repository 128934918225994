import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { signOut } from "redux/actions/user";

function SignOut() {
  const dispatch = useDispatch();
  dispatch(signOut());
  return <Redirect exact to="/" />;
}

export default SignOut;
