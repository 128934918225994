import { Grid, makeStyles, Step, StepLabel, Stepper } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import classNames from "classnames";
import Button from "components/Button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Brand from "views/Brand/Fields";
import Business from "views/Business/Fields";
import Finish from "./Finish";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  button: {
    width: 212,
    height: 42,
    marginRight: 10,
  },
  backButtonFirst: {
    display: "none",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function GettingStarted({ finish }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <Business saveAndNext={handleNext} saveText={t("Labels.Save and next")} />;
      case 1:
        return <Brand handleBack={handleBack} saveAndNext={handleNext} saveText={t("Labels.Save and next")} cancelText={t("Labels.Back")} />;
      case 2:
        return <Finish />;
      default:
        return "Unknown stepIndex";
    }
  };
  const steps = [t("Labels.Business Registration Info"), t("Labels.Brand Registration Info"), t("Labels.Finish")];
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  useEffect(() => {
    if (activeStep === steps.length - 1) {
      finish();
    }
  }, [activeStep, finish, steps.length]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid container justify="flex-end" className={classes.buttonGroup}>
        {getStepContent(activeStep)}
        {activeStep !== steps.length - 1 && activeStep === 2 && (
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classNames(classes.button, { [classes.backButtonFirst]: activeStep === 0 })}
              buttonText={t("Labels.Back")}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              endIcon={<ChevronRightRounded />}
              buttonText={activeStep === steps.length - 1 ? t("Labels.Finish") : t("Labels.Save and next")}
            />
          </div>
        )}
      </Grid>
    </div>
  );
}
