import { makeStyles, Paper, useTheme } from "@material-ui/core";
import OrderApi from "api/order";
import PayloadApi from "api/payload";
import SkuApi from "api/sku";
import format from "date-fns/format";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles(({ palette }) => ({
  titleDiv: {
    paddingLeft: 0,
  },
  status: {
    border: `2px solid ${palette.background.default}`,
    textAlign: "center",
    padding: 10,
  },
}));

function CodeGeneratedTable() {
  const { t } = useTranslation();
  const classes = useStyles();
  const Container = props => <Paper elevation={0} {...props} />;
  const Toolbar = () => false;
  const { palette } = useTheme();

  const [orderList, setOrderList] = useState([]);
  const { demo } = useSelector(_ => _.user);
  const [skuList, setSkuList] = useState([]);
  const [activatedList, setActivatedList] = useState([]);

  useEffect(() => {
    OrderApi.getAll({ limit: 5 }).then(res => {
      setOrderList(res);
    });

    PayloadApi.activated().then(res => {
      setActivatedList(res);
    });

    SkuApi.getAll().then(res => {
      setSkuList(res);
    });
  }, [demo]);

  return (
    <MaterialTable
      options={{
        draggable: false,
        paging: false,
        search: false,
        sorting: false,
        emptyRowsWhenPaging: false,
        headerStyle: {
          backgroundColor: palette.background.default,
          border: "3px solid",
          borderColor: palette.background.default,
        },
        rowStyle: {
          border: "3px solid",
          borderColor: palette.background.default,
        },
        cellStyle: {
          borderBottom: 0,
        },
      }}
      components={{
        Container,
        Toolbar,
      }}
      columns={[
        {
          title: t("Labels.Date Created"),
          align: "center",
          field: "createdAt",
          grouping: false,
          defaultSort: "desc",
          render: rowData => <span title={format(new Date(rowData.createdAt), "PPppp")}>{format(new Date(rowData.createdAt), "PP")}</span>,
        },
        {
          title: t("Labels.SKU"),
          align: "center",
          field: "skuId",
          lookup: skuList.reduce((obj, sku) => {
            obj[sku.id] = `${sku.name} - ${sku.sku}`;
            return obj;
          }, {}),
        },
        { title: t("Labels.Quantity"), align: "center", field: "quantity", grouping: false },
        {
          title: t("Labels.Codes Used"),
          align: "center",
          field: "id",
          searchable: false,
          grouping: false,
          render: ({ id, quantity }) => (
            <div className={classes.status}>{`${activatedList.find(i => i.orderId === id)?.activated || 0}/${quantity}`}</div>
          ),
        },
      ]}
      data={orderList}
    />
  );
}

export default CodeGeneratedTable;
