import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ChevronRightRounded } from "@material-ui/icons";
import classNames from "classnames";
import Button from "components/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setDemo } from "redux/actions/user";

const useStyles = makeStyles({
  welcome: {
    fontSize: 42,
  },
  title: {
    fontWeight: "bold",
  },
  img: {
    padding: 64,
  },
  message: {
    lineHeight: "1.25",
    textAlign: "center",
    marginBottom: 40,
    maxWidth: "70%",
  },
  button: {
    width: 264,
    height: 55,
    marginLeft: 10,
  },
});

function Welcome({ next }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid container justify="center">
        <Typography className={classes.welcome}>{t("Labels.Welcome to")}&nbsp;</Typography>
        <Typography className={classNames(classes.welcome, classes.title)}>{t("Labels.BrandTag")}</Typography>
      </Grid>
      <Grid container justify="center" className={classes.img}>
        <img alt="welcome" src={require("assets/svg/welcome.svg")} />
      </Grid>
      <Grid container justify="center">
        <Typography className={classes.message}>
          {t("Messages.Register or try demo mode, Uncompleted registration will unable generated codes from containing any information")}
        </Typography>
      </Grid>
      <Grid container justify="center" className={classes.buttonGroup}>
        <Grid item>
          <Button variant="outlined" className={classes.button} onClick={next} buttonText={t("Labels.Continue Registration")} />
        </Grid>
        <Grid item>
          <Link to="/">
            <Button
              className={classes.button}
              endIcon={<ChevronRightRounded />}
              onClick={() => dispatch(setDemo(true))}
              buttonText={t("Labels.Go to Demo Mode")}
            />
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Welcome;
