import { makeStyles, Tooltip, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ palette }) => ({
  cell: {
    backgroundColor: palette.primary.main,
    width: 62,
    height: 21,
    padding: 4,
  },
  spectrum: {
    width: 62 * 1.75,
    height: 21,
    padding: 4,
  },
  legend: {
    marginTop: 12,
  },
  spectrumEmpty: {
    height: 21,
  },
  day: {
    paddingTop: 4,
  },
  time: {
    paddingLeft: 8,
  },
}));

const DayCell = ({ value }) => {
  const classes = useStyles();

  return (
    <td className={classes.day} align="center">
      {value}
    </td>
  );
};

const SpectrumCell = ({ value, className, style }) => (
  <td>
    <div className={className} style={style} />
    {value}
  </td>
);

export default function WeeklyHeat({ data }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const hex2rgba = (alpha = 1, hex = palette.primary.main) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  const flatVal = data.map(e => Object.keys(e).map(i => e[i])).flat();
  const min = Math.min(...flatVal);
  const max = Math.max(...flatVal);
  const mid = Math.round((min + max) / 2);
  const q1 = Math.round((min + mid) / 2);
  const q3 = Math.round((mid + max) / 2);

  const DataCell = ({ value }) => {
    const bootstrap = makeStyles({
      arrow: {
        color: "black",
      },
      tooltip: {
        backgroundColor: "black",
      },
      popper: {
        top: "-12px !important",
      },
    })();

    return (
      <Tooltip arrow classes={bootstrap} title={value}>
        <td className={classes.cell} style={{ opacity: value / max + 0.2 }} align="center" />
      </Tooltip>
    );
  };

  return (
    <>
      <table>
        <tbody>
          {data.map((row, i) => (
            <tr key={i}>
              <DataCell value={row.Sun} />
              <DataCell value={row.Mon} />
              <DataCell value={row.Tue} />
              <DataCell value={row.Wed} />
              <DataCell value={row.Thu} />
              <DataCell value={row.Fri} />
              <DataCell value={row.Sat} />
              <td className={classes.time} align="right">
                {i % 2 === 0 ? (i < 13 ? (i === 0 ? "12am" : i + (i === 12 ? "pm" : "am")) : i - 12 + "pm") : ""}
              </td>
            </tr>
          ))}
          <tr>
            <DayCell value={t("Labels.Sun")} />
            <DayCell value={t("Labels.Mon")} />
            <DayCell value={t("Labels.Tue")} />
            <DayCell value={t("Labels.Wed")} />
            <DayCell value={t("Labels.Thu")} />
            <DayCell value={t("Labels.Fri")} />
            <DayCell value={t("Labels.Sat")} />
          </tr>
        </tbody>
      </table>
      <table className={classes.legend}>
        <tbody>
          <tr>
            <SpectrumCell
              className={classes.spectrum}
              value={min}
              style={{ background: `linear-gradient(to right, ${hex2rgba(0.2)},${hex2rgba(0.4)})` }}
            />
            <SpectrumCell
              className={classes.spectrum}
              value={q1}
              style={{ background: `linear-gradient(to right, ${hex2rgba(0.4)},${hex2rgba(0.6)})` }}
            />
            <SpectrumCell
              className={classes.spectrum}
              value={mid}
              style={{ background: `linear-gradient(to right, ${hex2rgba(0.6)},${hex2rgba(0.8)})` }}
            />
            <SpectrumCell
              className={classes.spectrum}
              value={q3}
              style={{ background: `linear-gradient(to right, ${hex2rgba(0.8)},${hex2rgba()})` }}
            />
            <SpectrumCell className={classes.spectrumEmpty} value={max} />
          </tr>
        </tbody>
      </table>
    </>
  );
}
