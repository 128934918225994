import { Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import cx from "clsx";
import DotIndicator from "components/DotIndicator";
import ParallaxSlide from "components/Parallax";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    position: "relative",
    width: "100%",
  },
  slide: {
    perspective: 1000,
    overflow: "hidden",
    position: "relative",
    paddingTop: 0,
  },
  textContainer: {
    width: "50%",
  },
  imageContainer: {
    width: "50%",
    height: 125,
  },
  image: {
    objectFit: "cover",
    overflow: "hidden",
    width: "100%",
    height: "auto",
  },
  imageBackground: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
  },
  arrow: {
    display: "none",
    position: "absolute",
    top: "45%",
    transform: "translateY(-50%)",
    [breakpoints.up("sm")]: {
      display: "inline-flex",
    },
  },
  arrowLeft: {
    left: 0,
  },
  arrowRight: {
    right: 0,
  },
  indicatorContainer: {
    textAlign: "center",
  },
  container: {
    marginLeft: 40,
    marginRight: 40,
    width: "unset",
  },
  subtitle_ol: {
    paddingLeft: 5,
    fontWeight: 400,
    paddingRight: 5,
  },
}));

const Carousel = ({ data = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const renderElements = ({ index, onChangeIndex }) => (
    <>
      <IconButton className={cx(classes.arrow, classes.arrowLeft)} disabled={index === 0} onClick={() => onChangeIndex(index - 1)}>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton className={cx(classes.arrow, classes.arrowRight)} disabled={index === data.length - 1} onClick={() => onChangeIndex(index + 1)}>
        <KeyboardArrowRight />
      </IconButton>
      <div className={classes.indicatorContainer}>
        {data.map((e, i) => (
          <DotIndicator key={i} active={i === index} onClick={() => onChangeIndex(i)} />
        ))}
      </div>
    </>
  );
  const renderChildren = () =>
    data.map(({ img, title, subtitle }, i) => (
      <div key={i} className={classes.slide}>
        <Grid container alignItems="stretch" className={classes.container}>
          <Grid item xs={6}>
            <Typography variant="h2" className={classes.title}>
              {t(title)}
            </Typography>
            <Typography variant="subtitle2" className={classes.subtitle}>
              <ol className={classes.subtitle_ol}>
                {subtitle.map(innerElement => (
                  <li>{t(innerElement)}</li>
                ))}
              </ol>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.imageBackground} style={{ backgroundImage: `url(${img})` }} />
          </Grid>
        </Grid>
      </div>
    ));
  return <div className={classes.root}>{data && <ParallaxSlide renderElements={renderElements}>{renderChildren}</ParallaxSlide>}</div>;
};

export default Carousel;
