import { Grid, Hidden, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import SignUp from "./SignUp";
import SignIn from "./SignIn";

function Landing(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid container justify="center" className={classes.mainGrid}>
          <Grid item xs={12} sm={12} md={7} className={classes.leftGrid}>
            <Hidden smDown>
              <img alt="brandtag" src={require("assets/svg/BrandTag.svg")} className={classes.logo} />
              <Typography className={classes.subtitle}>{t("Labels.Consumer confidence and engagement made easy")}</Typography>
              <img alt="brandtag" src={require("assets/img/man-shopping.png")} className={classes.img} />
            </Hidden>
          </Grid>
          <Grid item xs={12} sm={12} md={5} className={classes.rightGrid}>
            <Paper elevation={0} className={classes.form}>
              <Hidden mdUp>
                <img alt="brandtag" src={require("assets/svg/BrandTag.svg")} className={classes.smallLogo} />
              </Hidden>
              <SignIn {...props} />
              <div className={classes.or}>
                <div className={classes.divider}></div>
                <Typography className={classes.text}>{t("Labels.OR")}</Typography>
                <div className={classes.divider}></div>
              </div>
              <SignUp {...props} />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  root: {
    height: "100vh",
    display: "grid",
    placeItems: "center",
    overflowY: "auto",
    background: palette.background.paper,
  },
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    "@media (min-width: 576px)": {
      maxWidth: 540,
    },
    "@media (min-width: 768px)": {
      maxWidth: 720,
    },
    "@media (min-width: 992px)": {
      maxWidth: 960,
    },
    "@media (min-width: 1200px)": {
      maxWidth: 1140,
    },
  },
  mainGrid: {
    height: "100vh",
    maxHeight: 800,
  },
  leftGrid: {
    alignSelf: "flex-end",
  },
  rightGrid: {
    alignSelf: "center",
  },
  logo: {
    maxWidth: "50%",
  },
  subtitle: {
    fontSize: 30,
    lineHeight: 1.1,
    paddingTop: 18,
    paddingBottom: 12,
    maxWidth: "70%",
  },
  img: {
    width: "100%",
  },
  smallLogo: {
    maxWidth: "64%",
    margin: "auto",
    marginTop: 12,
    display: "block",
  },
  form: {
    padding: "8px 24px",
    margin: "26px 15px",
    boxShadow: "0px 4px 20px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: 8,
  },
  or: {
    display: "flex",
    width: "100%",
    height: 16,
    marginLeft: 18,
    marginRight: 18,
  },
  divider: {
    borderTop: `3px solid #000000`,
    width: "40%",
  },
  text: {
    color: "#000000",
    bottom: 16,
    fontWeight: "bold",
    padding: 8,
    position: "relative",
  },
}));

export default Landing;
