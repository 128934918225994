import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/index.css";
import App from "./App";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "redux/store";
import * as Sentry from "@sentry/browser";
import * as FullStory from "@fullstory/browser";

Sentry.init({ dsn: "https://80bf6752ccb543baa9e420bb9ebd625e@sentry.luxtag.io/28" });
FullStory.init({ orgId: "XPS7W", devMode: process.env.NODE_ENV === "development" });

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
export default store;
