import * as FullStory from "@fullstory/browser";
import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as Sentry from "@sentry/browser";
import BillingApi from "api/billing";
import UserApi from "api/user";
import cardHeaderStyle from "assets/jss/components/cardHeader";
import landingStyle from "assets/jss/views/landing";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setToken, setValidationBar } from "redux/actions/user";
import { decodeJWTPayload } from "utils";

const useLandingStyles = makeStyles(landingStyle);
const useCardHeaderStyles = makeStyles(cardHeaderStyle);

function SignIn({ location, history }) {
  const { from } = location.state || { from: { pathname: "/" } };
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState();
  const [ksi, setKSI] = useState();
  const [loading, setLoading] = useState();
  const landingClasses = useLandingStyles();
  const cardHeaderClasses = useCardHeaderStyles();
  const { t } = useTranslation();
  const { register, errors, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = data => {
    setLoading(true);
    UserApi.signIn(data)
      .then(res => {
        dispatch(setToken(res.jwt));
        Sentry.configureScope(function (scope) {
          scope.setUser({
            id: res?.user?.id,
            email: res?.user?.email,
            merchant: res?.user?.role?.merchant_code,
          });
        });
        FullStory.identify(res?.user?.email, {
          email: res?.user?.email,
          merchant: res?.user?.role?.merchant_code,
        });
        const { isAdmin, emailConfirmed } = decodeJWTPayload(res.jwt);
        if (isAdmin) {
          history.replace({ pathname: "/" });
        } else {
          history.replace(from);
          emailConfirmed &&
            BillingApi.getBalance().then(res => {
              if (res.trial || res.remainingDays <= 10) {
                dispatch(setValidationBar(true));
              }
            });
        }
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
        setLoading();
      });
  };

  return (
    <form>
      <Card variant="outlined" className={landingClasses.card}>
        <CardHeader
          title={t("Labels.Sign In")}
          className={landingClasses.title}
          titleTypographyProps={{
            variant: "h4",
          }}
          classes={cardHeaderClasses}
        />
        <CardContent>
          <TextField
            inputRef={register({ required: true })}
            error={errors.identifier}
            name="identifier"
            label={t("Labels.Email")}
            className={landingClasses.textfield}
          />
          <TextField
            inputRef={register({ required: true })}
            name="password"
            label={t("Labels.Password")}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
              </InputAdornment>
            }
            className={landingClasses.textfieldLast}
          />
          <FormControlLabel
            className={landingClasses.ksi}
            control={<Checkbox checked={ksi} onChange={() => setKSI(!ksi)} color="primary" />}
            label={t("Labels.Keep me signed in")}
          />
          <Button loading={loading} buttonText={t("Labels.Sign In")} type="submit" fullWidth onClick={handleSubmit(onSubmit)} />
          <NavLink to="/recover-password" className={landingClasses.link}>
            <div className={landingClasses.recover}>{t("Labels.Can't sign in?")}</div>
          </NavLink>
        </CardContent>
      </Card>
    </form>
  );
}

export default SignIn;
