import { Avatar, Grid, IconButton, Typography, Box, Tooltip as MuiTooltip } from "@material-ui/core";
import { AddAPhoto, Cancel, Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import CollapseCard from "components/CollapseCard";
import TextField from "components/TextField";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { constructRecursionFilename, shortenFilename } from "./helper";

const useStyles = makeStyles(({ palette, spacing }) => ({
  cloudUpload: {
    color: palette.primary.main,
    height: 60,
    width: 60,
  },
  colorLabel: {
    width: 150,
  },
  dragndrop: {
    borderRadius: 4,
    alignItems: "center",
    backgroundColor: palette.background.default,
    display: "flex",
    flexDirection: "column",
    padding: 20,
    transition: "border .24s ease-in-out",
    width: "100%",
  },
  formDiv: {
    display: "flex",
    // flexDirection: "column",
    padding: 12,
  },
  uploadImage: {
    height: "auto",
    width: "auto",
  },
  uploadImageClose: {
    position: "absolute",
    right: -12,
    top: -13,
  },
  uploadImageContainer: {
    marginTop: spacing(1),
  },
  uploadImageContainerItem: {
    marginTop: 5,
    padding: 10,
    position: "relative",
    overflowWrap: "anywhere",
  },
}));

const Image = ({ imageValue, descriptionValue, onImageAdd, onImageRemove, onTitleChange, onDelete, onHandleImagesExternalUrlChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState();

  const { register, control, reset } = useForm();

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "images",
  });

  useEffect(() => {
    imageValue &&
      reset({
        images: imageValue.map(file => {
          return {
            name: file.name,
            preview: file.hash ? `${apiURL}/files/${file.hash}${file.ext}` : URL.createObjectURL(file),
            file,
          };
        }),
      });
  }, [imageValue, reset]);

  const onDrop = useCallback(
    acceptedFiles => {
      let fileNames = imageValue.map(file => file.name);
      let newFiles = acceptedFiles.map(file => {
        const fileName = constructRecursionFilename(file.name, fileNames);
        if (fileNames.includes(file.name)) {
          return new File([file], fileName, { type: file.type });
        }
        return file;
      });
      newFiles.forEach(async file => {
        append({
          name: file.name,
          preview: URL.createObjectURL(file),
          file,
        });
        onImageAdd(file);
      });
    },
    [append, imageValue, onImageAdd]
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop,
    accept: "image/*",
    minSize: 0,
    maxSize: 1048576,
    multiple: true,
  });

  const onRemove = (event, id) => {
    onImageRemove(id);
    remove(id);
    event.stopPropagation();
  };

  return (
    <CollapseCard onDelete={onDelete} collapse={collapse} handleCollapse={() => setCollapse(!collapse)} draggable title={t("Labels.Image")}>
      <TextField
        labelPlacement="left"
        name="description"
        inputRef={register}
        label={t("Description")}
        value={descriptionValue}
        placeholder={descriptionValue}
        onChange={onTitleChange}
      />

      <div className={classes.formDiv}>
        <Typography variant="h5" gutterBottom className={classes.colorLabel}></Typography>

        <div className={classes.dragndrop} {...getRootProps()}>
          <AddAPhoto className={classes.cloudUpload} />
          <input {...getInputProps()} />
          {!isDragActive && (
            <Box textAlign={"center"}>
              <Typography>{t("Click here or drop a file to upload!")}</Typography>
              <Typography>JPG, PNG, JPEG only up to 10MB</Typography>
            </Box>
          )}
          {isDragActive && !isDragReject && t("Drop it like it's hot!")}
          {isDragReject && t("File type not accepted, sorry!")}
          {fileRejections.length > 0 && <div>{fileRejections[0].errors[0].message}</div>}
          <Grid container alignItems="stretch" className={classes.uploadImageContainer}>
            {/* Exisiting Uploads */}
            {fields.map(
              (field, index) =>
                !field.preview && (
                  <Grid className={classes.uploadImageContainerItem} item xs={6} key={`uploadImage_${index}`}>
                    <Avatar
                      variant="square"
                      className={classes.uploadImage}
                      alt={field.name}
                      src={`${apiURL}/files/${field.hash}${field.ext}`}
                    ></Avatar>
                    <IconButton size="small" className={classes.uploadImageClose} onClick={e => onRemove(e, index)}>
                      <Cancel />
                    </IconButton>
                    {field.name}
                    {/* <input type="hidden" key={field.id} name={`file[${index}].file`} ref={register()} defaultValue={field.file} /> */}
                  </Grid>
                )
            )}

            {/* New Uploads */}
            {fields.map(
              (field, index) =>
                field.preview && (
                  <Grid className={classes.uploadImageContainerItem} item xs={6} key={`uploadImage_${index}`}>
                    <Avatar variant="square" className={classes.uploadImage} alt={field.name} src={field.preview}></Avatar>
                    <IconButton size="small" className={classes.uploadImageClose} onClick={e => onRemove(e, index)}>
                      <Cancel />
                    </IconButton>
                    {field.name}
                    {/* <input type="hidden" key={field.id} name={`file[${index}].file`} ref={register()} defaultValue={field.file} /> */}
                  </Grid>
                )
            )}
          </Grid>
        </div>
      </div>
      {imageValue && imageValue.length > 0 && (
        <>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ gap: 4 }} py={2}>
            <Typography style={{ fontWeight: "bold", fontSize: ".875rem" }}>{t("Image External URL")}</Typography>
            <MuiTooltip
              title={
                <Typography variant="body2">
                  {t(
                    'You can attach an external URL to the image to link it to a specific webpage. If you don"t want to link the image to a webpage, simply leave this field empty.'
                  )}
                </Typography>
              }
            >
              <Info style={{ cursor: "pointer" }} />
            </MuiTooltip>
          </Box>
          {imageValue.map(field => (
            <TextField
              labelPlacement="left"
              name={field.name}
              inputRef={register}
              label={shortenFilename(field.name)}
              value={field.imageExternalUrl}
              placeholder={field.imageExternalUrl || ""}
              onChange={onHandleImagesExternalUrlChange}
            />
          ))}
        </>
      )}
    </CollapseCard>
  );
};

Image.propTypes = {
  onDelete: PropTypes.func,
};

export default Image;
