import CollapseCard from "components/CollapseCard";
import { EditorState } from "draft-js";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Text = ({ text, onChange, onDelete }) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState();

  return (
    <CollapseCard collapse={collapse} handleCollapse={() => setCollapse(!collapse)} onDelete={onDelete} draggable title={t("Labels.Text")}>
      <Editor
        editorState={text || EditorState.createEmpty()}
        onEditorStateChange={onChange}
        toolbar={{
          options: ["inline", "blockType", "fontSize", "list", "textAlign", "emoji", "history"],
        }}
      />
    </CollapseCard>
  );
};

Text.propTypes = {
  onDelete: PropTypes.func,
};

export default Text;
