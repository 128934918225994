import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { setDemo } from "redux/actions/user";

function Demo() {
  const dispatch = useDispatch();
  dispatch(setDemo(true));
  return <Redirect exact to="/" />;
}

export default Demo;
