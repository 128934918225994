import { Dialog, Grid, IconButton, InputAdornment, Tooltip, Typography } from "@material-ui/core";
import { Send, VerifiedUser } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import UserApi from "api/user";
import Button from "components/Button";
import PageContent from "components/PageContent";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { decodeJWTPayload } from "utils";
import ChangePassword from "./ChangePassword";

const useStyles = makeStyles(({ palette }) => ({
  passwordSection: {
    display: "flex",
  },
  cp: {
    padding: 12,
  },
  buttonGroup: {
    paddingTop: 22,
    justifyContent: "center",
  },
  button: {
    width: 160,
    height: 37,
    marginLeft: 10,
  },
}));

function Settings() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState();
  const { jwtToken, progress } = useSelector(_ => _.user);
  const { enqueueSnackbar } = useSnackbar();
  const { email } = decodeJWTPayload(jwtToken);

  const handleClose = () => setOpen();
  const resendEmail = () => {
    UserApi.sendConfirmationEmail({ email })
      .then(res => {
        enqueueSnackbar(t(`Confirmation email has been sent to ${email} sucessfully.`));
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      });
  };
  return (
    <PageContent header={t("Labels.General Account Settings")}>
      <Grid container>
        <Grid item md={6}>
          <Grid item md={8}>
            <TextField
              labelPlacement="top"
              label={t("Labels.Business Email")}
              disabled
              value={email}
              endAdornment={
                progress?.emailConfirmed ? (
                  <Tooltip title={t("Tooltips.Email verified!")} placement="top">
                    <VerifiedUser />
                  </Tooltip>
                ) : (
                  <Tooltip title={t("HelperText.Resend confirmation email")} placement="top">
                    <InputAdornment position="end">
                      <IconButton onClick={resendEmail} color="primary">
                        <Send />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                )
              }
            />
          </Grid>
          {progress?.emailConfirmed && (
            <>
              <div className={classes.cp}>
                <Typography variant="h5" gutterBottom>
                  {t("Labels.Change Password")}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {t("HelperText.It’s a good idea to use a strong password that you’re not using elsewhere")}
                </Typography>
              </div>
              <Button className={classes.button} onClick={() => setOpen(true)} buttonText={t("Labels.Change Password")} />
            </>
          )}
        </Grid>
      </Grid>
      <Dialog maxWidth={"sm"} open={open} onClose={handleClose}>
        <ChangePassword onClose={handleClose} />
      </Dialog>
    </PageContent>
  );
}

export default Settings;
