const divider = true;
const routes = [
  {
    path: "",
    label: "Labels.General Settings",
    component: require("views/Settings").default,
  },
  {
    path: "/business",
    label: "Labels.Business Info",
    component: require("views/Business").default,
  },
  {
    path: "/billing-history",
    label: "Labels.Billing History",
    component: require("views/BillingHistory").default,
  },
  {
    path: "/demo",
    label: "Labels.Switch to Demo Account",
    component: require("views/Demo").default,
  },
  {
    divider,
  },
  {
    path: "/credit",
    label: "Labels.Credit Top Up",
    component: require("views/Credit").default,
  },
  {
    path: "/preferences",
    label: "Labels.Preferences",
    component: require("views/Preferences").default,
  },
  {
    divider,
  },
  {
    path: "/signout",
    label: "Labels.Sign Out",
    component: require("views/SignOut").default,
  },
];

const updateRoute = r => {
  return r.map(route => {
    return {
      ...route,
      children: route.children && updateRoute(route.children),
      private: true,
      exact: true,
      path: "/settings" + route.path,
    };
  });
};

export default updateRoute(routes);
