import { Dialog, DialogActions, DialogContent, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PlanApi from "api/plan";
import Button from "components/Button";
import Select from "components/Select";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: 0,
  },
  button: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  dragndrop: {
    height: "100%",
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: palette.color.primary,
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  bold: {
    fontWeight: 600,
  },
  semiTrans: {
    opacity: 0.5,
  },
}));

function ManagePlanDialog({
  onUpdate,
  validityUnits,
  handleValidityUnitChange,
  selectedPlanData,
  dialogOpen,
  setDialogOpen,
  submittingForm,
  setSubmittingForm,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { register, errors, handleSubmit, control } = useForm();

  const submitForm = async data => {
    setSubmittingForm(true);
    data.price = data.price || undefined;
    data.promo_price = data.promo_price || undefined;

    try {
      if (selectedPlanData.id) {
        await PlanApi.update(selectedPlanData.id, data);
        enqueueSnackbar("Plan Updated!");
      } else {
        await PlanApi.create(data);
        enqueueSnackbar("Plan Added!");
      }
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
    setSubmittingForm();
    setDialogOpen();
    onUpdate();
  };

  return (
    <Dialog fullWidth open={dialogOpen}>
      <DialogContent>
        <Typography variant="h1" className={classes.cardNo}>
          {!selectedPlanData.id ? t("Labels.Add Plan") : t("Labels.Edit Plan")}
        </Typography>

        <form>
          <TextField
            labelPlacement="left"
            name="title"
            required
            inputRef={register({ required: true })}
            defaultValue={selectedPlanData.title}
            label={t("Labels.Plan Name")}
            error={errors.title}
            helperText={t("HelperText.Plan.Please fill in Plan Name")}
          />
          <TextField
            labelPlacement="left"
            name="price"
            type="number"
            step="1.00"
            defaultValue={selectedPlanData.price}
            inputRef={register}
            label={t("Labels.Price")}
            error={errors.price}
            helperText={t("HelperText.Plan.Please fill in Price")}
          />
          <TextField
            labelPlacement="left"
            name="promo_price"
            type="number"
            step="1.00"
            defaultValue={selectedPlanData.promo_price}
            inputRef={register}
            label={t("Labels.Promo Price")}
            error={errors.promo_price}
            helperText={t("HelperText.Plan.Please fill in Promo Price")}
          />
          {/* <TextField
            labelPlacement="left"
            name="validity"
            type="number"
            required
            defaultValue={selectedPlanData.validity}
            inputRef={register({ required: true })}
            label={t("Labels.Validity")}
            error={errors.validity}
            helperText={t("HelperText.Plan.Please fill in Validity")}
          />
          <Select
            labelPlacement="left"
            name="validity_unit"
            required
            defaultValue={selectedPlanData.validity_unit}
            control={control}
            rules={{ required: true }}
            label={t("Labels.Validity Unit")}
            error={errors.validity_unit}
            helperText={t("HelperText.Plan.Please fill in Validity Unit")}
            onChange={handleValidityUnitChange}
            fullWidth
          >
            {validityUnits?.map(validity_unit => (
              <MenuItem key={validity_unit} value={validity_unit}>
                {validity_unit}
              </MenuItem>
            ))}
          </Select> */}
          <TextField
            labelPlacement="left"
            name="codes"
            required
            type="number"
            defaultValue={selectedPlanData.codes}
            inputRef={register({ required: true })}
            label={t("Labels.Codes")}
            error={errors.codes}
            helperText={t("HelperText.Plan.Please fill in Codes")}
          />
          <TextField
            labelPlacement="left"
            name="description"
            defaultValue={selectedPlanData.description}
            inputRef={register}
            label={t("Labels.Description")}
            error={errors.description}
            helperText={t("HelperText.Plan.Please fill in Description")}
          />

          <DialogActions>
            <Button buttonText={t("Labels.Cancel")} onClick={() => setDialogOpen()} variant="outlined" />

            <Button loading={submittingForm} buttonText={t("Labels.Submit")} type="submit" onClick={handleSubmit(submitForm)} />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ManagePlanDialog;
