import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import React from "react";

const useStyles = makeStyles(({ palette }) => ({
  timeline: {
    padding: 0,
    margin: 0,
    marginTop: 6 * 3,
  },
  timelineDot: {
    padding: 6,
    margin: 0,
  },
  oppositeContent: {
    paddingTop: 0,
  },
  timelineContent: {
    overflowWrap: "anywhere",
    flex: 2,
    paddingTop: 0,
  },
  timelineContentTitle: {
    fontWeight: 600,
  },
  seeMore: {
    color: palette.primary.main,
    cursor: "pointer",
  },
}));

export default function SensorTimeline({ data, onClick, expanded }) {
  const classes = useStyles();
  return (
    <Timeline className={classes.timeline}>
      {data.map(({ type, unit, createdAt, value, name }, i) => (
        <TimelineItem>
          <TimelineOppositeContent className={classes.oppositeContent}>
            <Typography color="textSecondary">{new Date(createdAt).toLocaleDateString()}</Typography>
            <Typography color="textSecondary">{new Date(createdAt).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={classes.timelineDot} />
            {i < data.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent className={classes.timelineContent}>
            <Typography align="left" className={classes.timelineContentTitle}>
              {name}
            </Typography>
            <Typography align="left" variant="subtitle2">
              {type}:&nbsp;{value}
              {unit}
            </Typography>
            {data[i - 1]?.name !== name && (
              <Typography align="left" variant="body2" className={classes.seeMore} onClick={onClick(name)}>
                See {expanded[name] ? "less" : "more"}
              </Typography>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
}
