const mainRoutes = [
  {
    path: "/verify",
    component: require("views/Verify").default,
    layout: require("layouts/Verify").default,
  },
  {
    path: "/onboarding",
    component: require("views/Onboarding").default,
    layout: require("layouts/Default").default,
    private: true,
  },
  {
    path: "/email-confirmation",
    component: require("views/EmailConfirmation").default,
  },
  {
    path: "/experience",
    component: require("views/Customization/Customize").default,
    layout: require("layouts/Default").default,
  },
  {
    path: "/recover-password",
    component: require("views/Recover").default,
    layout: require("layouts/Default").default,
  },
  {
    path: "/reset-password",
    component: require("views/ResetPassword").default,
    layout: require("layouts/Default").default,
  },
  {
    path: "/settings",
    component: require("layouts/Dashboard").default,
    private: true,
  },
  {
    path: "/error",
    component: require("views/Error").default,
  },
  {
    path: "/",
    component: require("layouts/Dashboard").default,
  },
  {
    path: "*",
    component: require("views/Error").default,
  },
];
export default mainRoutes;
