import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import UserApi from "api/user";
import Button from "components/Button";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "34px 64px",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "-webkit-center",
  },
  button: {
    width: 212,
    height: 37,
    margin: "0px 5px",
  },
  buttonGroup: {
    padding: 14,
  },
}));

function UpdatePassword({ onClose }) {
  const classes = useStyles();
  const { register, errors, handleSubmit, watch } = useForm();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");

  const saveForm = async data => {
    setLoading(true);
    try {
      await UserApi.changePassword(data);
      onClose();
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={classes.root}>
      <Typography className={classes.title}>{t("Labels.Change Password")}</Typography>
      <TextField
        type="password"
        labelPlacement="top"
        inputRef={register({ required: true })}
        name="oldPassword"
        label={t("Labels.Current Password")}
        error={errors.oldPassword}
        helperText={t("HelperText.Please fill in current password")}
      />
      <TextField
        type="password"
        labelPlacement="top"
        inputRef={register({ required: true })}
        name="newPassword"
        label={t("Labels.New Password")}
        error={errors.newPassword}
        helperText={t("HelperText.Please fill in new password")}
      />
      <TextField
        type="password"
        labelPlacement="top"
        inputRef={register({ required: true, validate: value => value === newPassword.current || t("Messages.The passwords do not match") })}
        name="newPasswordConfirm"
        label={t("Labels.Re-Type New Password")}
        error={errors.newPasswordConfirm}
        helperText={errors.newPasswordConfirm?.message || t("HelperText.Please fill in retype password")}
      />
      <Grid container justify="center" className={classes.buttonGroup}>
        <Grid item>
          <Link to="/recover-password">
            <Button className={classes.button} buttonText={t("Labels.Forgot Password")} variant="outlined" />
          </Link>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            buttonText={t("Labels.Save Changes")}
            loading={loading}
            loadingText={t("Messages.Saving")}
            type="submit"
            onClick={handleSubmit(saveForm)}
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default UpdatePassword;
