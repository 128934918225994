import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import FormSubmissionApi from "api/formSubmission";
import classNames from "classnames";
import Button from "components/Button";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isISODate } from "utils/date";

const useStyles = makeStyles(({ palette }) => ({
  title: {
    textAlign: "center",
  },
  content: {
    alignSelf: "center",
  },
  question: {
    paddingRight: 24,
  },
  actions: {
    justifyContent: "center",
  },
  button: {
    height: 37,
    width: 212,
    marginLeft: 5,
  },
  even: {
    backgroundColor: palette.background.verify,
  },
  sublabel: {
    fontWeight: 300,
    textAlign: "end",
  },
}));

function Preview({ open, handleClose, handleEdit, title, id }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (id) {
      FormSubmissionApi.preview(id)
        .then(res => {
          setData({ ...res, completed: res?.sensorAnswers?.map(i => Object.values(i).length === 3).reduce((a, b) => a && b, true) });
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });
    }
  }, [enqueueSnackbar, id]);

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose(data.completed)}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h1">{title}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <table>
          <tbody>
            {data.answers?.map(({ question, answer }, i) => {
              return (
                <tr className={classNames({ [classes.even]: ++i % 2 === 0 })}>
                  <td>
                    <Typography className={classes.question}>
                      <b>{question}</b>
                    </Typography>
                  </td>
                  <td>
                    <Typography>{isISODate(answer) ? new Date(answer).toLocaleString() : answer}</Typography>
                  </td>
                </tr>
              );
            })}
            <br />
            {!!data.sensorAnswers?.length && (
              <tr>
                <Typography variant="h2">{t("Sensors")}</Typography>
              </tr>
            )}
            {data.sensorAnswers?.map(({ section_name, start_time, end_time }, i) => {
              return (
                <tr className={classNames({ [classes.even]: ++i % 2 === 0 })}>
                  <td>
                    <Typography className={classes.question}>
                      <b>{section_name}</b>
                    </Typography>
                  </td>
                  <td>
                    <Typography>
                      <tr>
                        <td className={classes.sublabel}>{t("Start Time")}:</td>
                        <td>{start_time && new Date(start_time).toLocaleString()}</td>
                      </tr>
                      <tr>
                        <td className={classes.sublabel}>{t("End Time")}:</td>
                        <td>{end_time && new Date(end_time).toLocaleString()}</td>
                      </tr>
                    </Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {data.completed ? (
          <Link
            to={{
              pathname: "activatecode",
              search: `submissionId=${id}`,
            }}
          >
            <Button className={classes.button} buttonText={t("Labels.Activate")} />
          </Link>
        ) : (
          <Button className={classes.button} disabled={!data.completed} buttonText={t("Labels.Activate")} />
        )}
        <Button className={classes.button} variant="outlined" buttonText={t("Labels.Edit")} onClick={handleEdit} />
      </DialogActions>
    </Dialog>
  );
}

export default Preview;
