import DateFnsUtils from "@date-io/date-fns";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Height } from "@material-ui/icons";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { useTranslation } from "react-i18next";
import { getLocaleDateFormat } from "utils/date";

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
    align: "left",
  },
  datePicker: {
    width: 155,
    margin: 0,
  },
  section: {
    padding: 4,
  },
  arrow: {
    transform: "rotate(90deg)",
  },
  arrowDiv: {
    paddingTop: 36,
  },
  container: {
    justifyContent: "space-between",
  },
});
const useInputStyles = makeStyles({
  input: {
    padding: 14,
    paddingLeft: 0,
    paddingRight: 18,
  },
  adornedStart: {
    paddingLeft: 0,
  },
});

const CustomKeyboardDatePicker = ({ from, to, onFromChange, onToChange }) => {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container className={classes.container}>
        <div className={classes.section}>
          <Typography className={classes.title}>{t("Labels.From")}</Typography>
          <KeyboardDatePicker
            className={classes.datePicker}
            InputProps={{ classes: inputClasses }}
            disableToolbar
            disableFuture
            autoOk
            variant="inline"
            inputVariant="outlined"
            format={getLocaleDateFormat()}
            InputAdornmentProps={{ position: "start" }}
            value={from}
            maxDate={to}
            onChange={d => onFromChange(d)}
          />
        </div>

        <div className={classes.arrowDiv}>
          <Height className={classes.arrow} />
        </div>

        <div className={classes.section}>
          <Typography className={classes.title}>{t("Labels.To")}</Typography>
          <KeyboardDatePicker
            className={classes.datePicker}
            InputProps={{ classes: inputClasses }}
            disableToolbar
            disableFuture
            autoOk
            variant="inline"
            inputVariant="outlined"
            format={getLocaleDateFormat()}
            InputAdornmentProps={{ position: "start" }}
            value={to}
            onChange={d => onToChange(d)}
          />
        </div>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default CustomKeyboardDatePicker;
