import { Dialog, DialogActions, DialogContent, Grid, IconButton, ListItemText, Menu, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import PlanApi from "api/plan";
import PageContent from "components/PageContent";
import Button from "components/Button";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import ManagePlanDialog from "./ManagePlanDialog";
import { formatNumber } from "utils";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: 24,
  },
  row: {
    paddingBottom: 12,
  },
  detailKey: {
    fontWeight: "bold",
  },
  itemIcon: {
    minWidth: 40,
  },
  status: {
    border: `2px solid ${palette.background.default}`,
    textAlign: "center",
    padding: 10,
  },
  button: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
}));

const Container = props => <Paper elevation={0} {...props} />;

function Plan() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState();
  const [plan, setPlan] = useState([]);
  const [removeDialogOpen, setRemoveDialogOpen] = useState();
  const [selectedPlanData, setSelectedPlanData] = useState({});
  const [dialogOpen, setDialogOpen] = useState();
  const [submittingForm, setSubmittingForm] = useState();
  const { palette } = useTheme();
  const validityUnits = ["year", "month", "day"];

  function getPlanData() {
    PlanApi.getAll().then(res => {
      res.map(obj => {
        return {
          id: obj.id,
          name: obj.title,
          price: obj.price,
          validity: obj.validity,
        };
      });
      setPlan(res);
    });
  }

  useEffect(() => {
    PlanApi.getAll().then(res => {
      setPlan(res);
      getPlanData();
    });
  }, []);

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedPlanData(rowData);
    event.stopPropagation();
  };

  async function handleRemove() {
    try {
      await PlanApi.remove(selectedPlanData.id);
      getPlanData();
      closeRemoveDialog();
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  }

  const handleClose = () => setAnchorEl();

  const handleValidityUnitChange = event => {
    let newPlanData = selectedPlanData;
    newPlanData.validity_unit = event.target.value;
    setSelectedPlanData(newPlanData);
  };

  function openRemoveDialog() {
    handleClose();
    setRemoveDialogOpen(true);
  }

  function closeRemoveDialog() {
    setRemoveDialogOpen();
  }

  function handleOpenDialog() {
    setDialogOpen(true);
  }

  return (
    <PageContent header={t("Manage Plans")}>
      <Grid container spacing={3}>
        <Grid item lg={2} xl={2}>
          <Button
            onClick={() => {
              setSelectedPlanData({});
              handleOpenDialog();
            }}
            className={classes.button}
            buttonText={t("Labels.Add Plan")}
            fullWidth
          />
        </Grid>
      </Grid>

      <MaterialTable
        columns={[
          { title: t("Labels.Plan Name"), field: "title" },
          { title: t("Labels.Price"), render: ({ price }) => formatNumber(price) },
          // {
          //   title: t("Labels.Validity"),
          //   render: ({ validity, validity_unit }) => `${formatNumber(validity)} ${validity_unit}${validity !== 1 ? "s" : ""}`,
          // },
          { title: t("Labels.Codes"), render: ({ codes }) => formatNumber(codes) },
          {
            title: t("Labels.Action"),
            field: "action",
            sorting: false,
            render: rowData => (
              <IconButton onClick={e => handleClick(e, rowData)}>
                <MoreHoriz />
              </IconButton>
            ),
          },
        ]}
        data={plan}
        options={{
          showTitle: false,
          toolbar: true,
          draggable: false,
          detailPanelColumnAlignment: "left",
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
          },
        }}
        components={{
          Container,
        }}
      />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleOpenDialog()}>
          <ListItemText primary={t("Labels.Edit")} />
        </MenuItem>
        <MenuItem onClick={openRemoveDialog}>
          <ListItemText primary={t("Labels.Remove")} />
        </MenuItem>
      </Menu>

      <ManagePlanDialog
        submittingForm={submittingForm}
        setSubmittingForm={setSubmittingForm}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onUpdate={getPlanData}
        validityUnits={validityUnits}
        handleValidityUnitChange={handleValidityUnitChange}
        selectedPlanData={selectedPlanData}
      />

      <Dialog fullWidth open={removeDialogOpen}>
        <DialogContent>
          <Typography variant="h1" className={classes.cardNo}>
            {t(`Delete Plan ${selectedPlanData.title}?`)}
          </Typography>

          <DialogActions>
            <Button className={classes.button} buttonText={t("Labels.No")} onClick={closeRemoveDialog} variant="outlined" />

            <Button className={classes.button} loading={submittingForm} buttonText={t("Labels.Yes")} onClick={handleRemove} />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </PageContent>
  );
}

export default Plan;
