import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "material-table";
import React from "react";
import { useTranslation } from "react-i18next";

function Insight({ open, onClose, data }) {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const Container = props => <Paper elevation={0} {...props} />;

  return (
    <Dialog maxWidth={"lg"} open={open} scroll="body" onClose={onClose}>
      <DialogTitle disableTypography>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h2">{t("Labels.Detection of Risk")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton disableRipple disableFocusRipple size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent width="800px">
        <MaterialTable
          options={{
            showTitle: false,
            filtering: false,
            toolbar: false,
            headerStyle: {
              backgroundColor: palette.background.default,
              border: "3px solid",
              borderColor: palette.background.default,
            },
            rowStyle: {
              border: "3px solid",
              borderColor: palette.background.default,
            },
          }}
          data={data}
          components={{
            Container,
          }}
          columns={[
            {
              title: t("Labels.Order ID"),
              field: "order_id",
            },
            {
              title: t("Labels.SKU"),
              field: "sku",
            },
            { title: t("Labels.Unusual Activities"), field: "description" },
          ]}
        />
      </DialogContent>
    </Dialog>
  );
}

export default Insight;
