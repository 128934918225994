import { FilledInput, Grid, IconButton, InputAdornment } from "@material-ui/core";
import { ClearRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/textfield";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
    display: "flex",
  },
  key: {
    paddingRight: 8,
  },
}));

const useTextFieldStyles = makeStyles(textfieldStyles);

const KeyValueTextField = ({ keyValue, value, keyPlaceholder, valuePlaceholder, onKeyChange, onValueChange, onDelete }) => {
  const classes = useStyles();
  const textfieldClasses = useTextFieldStyles();

  return (
    <div className={classes.root}>
      <Grid item md={4} className={classes.key}>
        <FilledInput classes={textfieldClasses} fullWidth placeholder={keyPlaceholder} value={keyValue} onChange={onKeyChange} />
      </Grid>
      <Grid item md={8}>
        <FilledInput
          classes={textfieldClasses}
          fullWidth
          placeholder={valuePlaceholder}
          onChange={onValueChange}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton color="primary" onClick={onDelete}>
                {<ClearRounded />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
    </div>
  );
};

KeyValueTextField.propTypes = {
  onDelete: PropTypes.func,
};

export default KeyValueTextField;
