import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import UserApi from "api/user";
import Button from "components/Button";
import TextField from "components/TextField";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RegExpPattern from "utils";

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    paddingBottom: 12,
  },
  button: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
}));

const Container = props => <Paper elevation={0} {...props} />;

function UserTable({ tableData, onUpdate }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { register, errors, handleSubmit, watch } = useForm();

  const [anchorEl, setAnchorEl] = useState();
  const [editDialogOpen, setEditDialogOpen] = useState();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState();
  const [action, setAction] = useState();
  const [selectedUserData, setSelectedUserData] = useState({});
  const password = useRef({});
  password.current = watch("password", "");
  const [submittingForm, setSubmittingForm] = useState();

  const submitEditForm = async data => {
    setSubmittingForm(true);

    if (data.password === "") {
      delete data["password"];
    }

    try {
      await UserApi.update(selectedUserData.id, data);
      enqueueSnackbar("User Updated!");
      setSubmittingForm();
      closeEditDialog();

      onUpdate();
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      setSubmittingForm();
    }
  };

  function handleClick(event, rowData) {
    setAnchorEl(event.currentTarget);
    setSelectedUserData(rowData);
    event.stopPropagation();
  }

  const handleClose = () => setAnchorEl();

  //Open and close edit dialog

  function openEditDialog() {
    handleClose();
    setEditDialogOpen(true);
  }

  function closeEditDialog() {
    setEditDialogOpen(false);
  }

  //Open and close confirm dialog

  const handleOpenConfirmationDialog = action => () => {
    setAction(action);
    handleClose();
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog();
  };

  async function switchActivation() {
    //Api call to switch blocked boolean
    try {
      await UserApi.blockedSwitch(selectedUserData.id);
      enqueueSnackbar(`User successfully ${selectedUserData.status === "Active" ? "de" : ""}activated!`);
      setSubmittingForm();
      handleCloseConfirmationDialog();

      //reload page and update the table with new data
      onUpdate();
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      setSubmittingForm();
    }
  }
  async function switchConfirmation() {
    //Api call to switch confirmation boolean
    const data = {
      confirmed: !selectedUserData.confirmed,
    };
    try {
      await UserApi.confirmedSwitch(selectedUserData.id, data);
      enqueueSnackbar(`User successfully ${selectedUserData.confirmed ? "cancel" : "confirm"}ed!`);
      setSubmittingForm();
      handleCloseConfirmationDialog();

      //reload page and update the table with new data
      onUpdate();
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
      setSubmittingForm();
    }
  }

  return (
    <div>
      <Grid container>
        <Grid item className={classes.row} xs={12}>
          <MaterialTable
            columns={[
              { title: t("Labels.Name"), field: "name" },
              { title: t("Labels.Role"), field: "role" },
              { title: t("Labels.Email"), field: "email" },
              { title: t("Labels.Business Name"), field: "businessName", defaultGroupOrder: 1 },
              { title: t("Labels.Status"), field: "status" },
              {
                title: t("Labels.Action"),
                field: "action",
                sorting: false,
                render: rowData => (
                  <IconButton onClick={e => handleClick(e, rowData)}>
                    <MoreHoriz />
                  </IconButton>
                ),
              },
            ]}
            data={tableData}
            options={{
              toolbar: true,
              draggable: false,
              detailPanelColumnAlignment: "left",
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: palette.background.default,
              },
            }}
            components={{
              Container,
            }}
            title=""
          />

          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
          >
            <MenuItem onClick={openEditDialog}>
              <ListItemText primary={t("Labels.Edit")} />
            </MenuItem>
            <MenuItem onClick={handleOpenConfirmationDialog("status")}>
              <ListItemText primary={selectedUserData.status === "Active" ? t("Labels.Deactivate") : t("Labels.Activate")} />
            </MenuItem>
            <MenuItem onClick={handleOpenConfirmationDialog("confirmation")}>
              <ListItemText primary={selectedUserData.confirmed ? t("Labels.Cancel") : t("Labels.Confirm")} />
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>

      <Dialog fullWidth open={editDialogOpen}>
        <DialogContent>
          <Typography variant="h1" className={classes.cardNo}>
            {t("Labels.Edit User Details")}
          </Typography>

          <form>
            <TextField
              labelPlacement="left"
              name="username"
              defaultValue={selectedUserData.name}
              required
              inputRef={register({ required: true })}
              label={t("Labels.Name")}
              error={errors.name}
              helperText={t("HelperText.User.Please fill in name")}
            />

            <TextField
              labelPlacement="left"
              name="email"
              defaultValue={selectedUserData.email}
              type="email"
              required
              inputRef={register({
                required: true,
                pattern: RegExpPattern.email(),
              })}
              label={t("Labels.Email")}
              error={errors.email}
              helperText={t("HelperText.User.Please fill in valid email")}
            />

            <TextField
              labelPlacement="left"
              name="password"
              type="password"
              required
              inputRef={register({
                required: false,
              })}
              label={t("Labels.Password")}
              error={errors.email}
              helperText={t("HelperText.User.Please fill in valid email")}
            />

            <DialogActions>
              <Button className={classes.button} buttonText={t("Labels.Cancel")} variant="outlined" onClick={closeEditDialog} />

              <Button
                className={classes.button}
                loading={submittingForm}
                buttonText={t("Labels.Submit")}
                type="submit"
                onClick={handleSubmit(submitEditForm)}
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog fullWidth open={openConfirmationDialog}>
        <DialogTitle>
          <Typography variant="h1" className={classes.cardNo}>
            {t("Are you sure?")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h2" className={classes.cardNo}>
            {`${
              action === "status"
                ? selectedUserData.status === "Active"
                  ? t(`Deactivate`)
                  : t(`Activate`)
                : selectedUserData.confirmed
                ? t(`Cancel`)
                : t(`Confirm`)
            } user ${selectedUserData.name}?`}
          </Typography>

          <DialogActions>
            <Button className={classes.button} buttonText={t("Labels.No")} onClick={handleCloseConfirmationDialog} variant="outlined" />

            <Button
              className={classes.button}
              loading={submittingForm}
              buttonText={t("Labels.Yes")}
              onClick={action === "status" ? switchActivation : switchConfirmation}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UserTable;
