import { Checkbox, Grid, IconButton, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { AddCircleRounded, Help } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import FormQuestionApi from "api/formQuestion";
import FormSectionApi from "api/formSection";
import SensorApi from "api/sensor";
import classNames from "classnames";
import CollapseCard from "components/CollapseCard";
import Select from "components/Select";
import TextField from "components/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormQuestion from "views/Form/Create/FormQuestion";

const useStyles = makeStyles(({ palette, typography }) => ({
  addButton: {
    "&:hover": {
      borderColor: palette.primary.main,
      color: palette.primary.main,
      cursor: "pointer",
    },
    border: `1px dashed ${palette.background.dark}`,
    borderRadius: 4,
    color: typography.color,
    textAlign: "center",
    width: "unset",
  },
  addQuestion: {
    color: "inherit",
    padding: 14,
    textAlign: "left",
  },
  flex: {
    display: "flex",
  },
  help: {
    fontSize: 12,
  },
  key: {
    paddingRight: 8,
  },
  root: {
    margin: 12,
  },
  section: {
    paddingLeft: 15,
  },
  sensor: {
    marginBottom: 10,
  },
  subsection: {
    fontSize: 14,
    marginBottom: 14,
    marginTop: 14,
  },
}));

function Section({
  index,
  section,
  setQuestions,
  onSensorChange,
  onSectionDelete,
  onTitleChange,
  onValueChange,
  onDeleteQuestion,
  addQuestion,
  activatedSections,
  collapse,
  enqueueSnackbar,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [sensorsList, setSensorsList] = useState([]);

  useEffect(() => {
    SensorApi.getAll().then(res => {
      setSensorsList(res);
    });
    if (section.id) {
      FormQuestionApi.getBySection(section.id)
        .then(res => {
          setQuestions(res.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0)));
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });

      FormSectionApi.getSensors(section.id)
        .then(res => {
          res.length
            ? res.forEach(_ => onSensorChange(_, section.id))
            : onSensorChange(
                {
                  share: true,
                },
                section.id
              );
        })
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error",
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CollapseCard
      onDelete={onSectionDelete}
      collapse={collapse}
      handleCollapse={() => !collapse && activatedSections(true)}
      handleCollapseButton={() => activatedSections(!collapse)}
      title={
        collapse ? (
          <TextField
            value={section.name}
            onChange={e => onTitleChange(e.target.value)}
            name="name"
            required
            fullWidth={false}
            transparent
            placeholder={t("Labels.Section Title") + " #" + (index + 1)}
          />
        ) : (
          section.name
        )
      }
      titleTypographyProps={collapse ? {} : { className: classes.section }}
      style={{ cursor: !collapse && "pointer" }}
      rootProps={{ className: classes.root }}
    >
      <Grid item>
        <Grid container spacing={0} className={classes.sensor}>
          <Grid item xs={10} className={classNames(classes.key, classes.subsection)}>
            {t("Labels.Sensor")}
          </Grid>
          <Grid item xs={2} className={classNames(classes.key, classes.subsection)}>
            {t("Share")}&nbsp;
            <Tooltip placement="right" title={"Include this information in verify page."}>
              <Help fontSize="small" className={classes.help} />
            </Tooltip>
          </Grid>
          {section.sensors?.map((sensor, i) => (
            <>
              <Grid item xs={10} className={classes.key}>
                <Select key={i} name="id" fullWidth defaultValue={sensor.id} value={sensor.id} onChange={e => onSensorChange(e, i)}>
                  <MenuItem value={null}>&nbsp;</MenuItem>
                  {sensorsList.map(({ id, name, type }, j) => (
                    <MenuItem key={j} value={id}>
                      <Grid container>
                        <Typography color="textPrimary">{name}</Typography>
                        <Typography color="textSecondary">
                          &nbsp; - {type} {t("sensor")}
                        </Typography>
                      </Grid>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2}>
                <Checkbox name="share" color="primary" size="medium" checked={sensor.share} onChange={e => onSensorChange(e, i)} />
              </Grid>
              {/* <Grid item xs={1}>
                <IconButton color="primary" onClick={() => onDeleteSensor(i)}>    //////// Add sensors feature for future uses
                  {<ClearRounded />}
                </IconButton>
              </Grid> */}
            </>
          ))}
        </Grid>
        {/* <Grid container className={classes.addButton} onClick={addSensor}>    //////// Add sensors feature for future uses
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.addQuestion}>
              {t("Add sensor")}
            </Typography>
          </Grid>
          <Tooltip placement="right" title={t("Add question")}>
            <IconButton>
              <AddCircleRounded fontSize="large" color="primary" />
            </IconButton>
          </Tooltip>
        </Grid> */}

        <Grid container spacing={0}>
          <Grid item xs={5} className={classNames(classes.key, classes.subsection)}>
            {t("Labels.Question")}
          </Grid>
          <Grid item xs={5} className={classNames(classes.key, classes.subsection)}>
            {t("Labels.Data Type")}
          </Grid>
          <Grid item xs={1} className={classNames(classes.key, classes.subsection, classes.flex)}>
            {t("Labels.Share")}&nbsp;
            <Tooltip placement="right" title={t("HelperText.Include this information in verify page")}>
              <Help fontSize="small" className={classes.help} />
            </Tooltip>
          </Grid>
          <Grid item xs={1} className={classNames(classes.key, classes.subsection)}></Grid>
        </Grid>
        <Grid container spacing={0}>
          {section.questions
            ?.filter(question => question.active !== false)
            .map(({ datatype, name, share }, i) => (
              <FormQuestion
                index={i}
                title={name}
                onTitleChange={onValueChange("name", i)}
                datatype={datatype}
                onDatatypeChange={onValueChange("datatype", i)}
                share={share}
                onShareChange={onValueChange("share", i)}
                onDelete={onDeleteQuestion(i)}
              />
            ))}
        </Grid>
        <Grid container className={classes.addButton} onClick={addQuestion}>
          <Grid item xs={5}>
            <Typography variant="h4" className={classes.addQuestion}>
              {t("Labels.Add question")}
            </Typography>
          </Grid>
          <Tooltip placement="right" title={t("Labels.Add question")}>
            <IconButton>
              <AddCircleRounded fontSize="large" color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </CollapseCard>
  );
}

export default Section;
