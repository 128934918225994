export default ({ palette, typography }) => ({
  link: {
    color: palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card: {
    textAlign: "center",
    border: "unset",
  },
  progressWrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: `-${typography.fontSize}px`,
    marginLeft: `-${typography.fontSize}px`,
  },
  ksi: {
    display: "flex",
  },
  button: {
    marginBottom: 8,
  },
  recover: {
    paddingTop: 2,
  },
  textfield: {
    marginBottom: 20,
    padding: 0,
  },
  textfieldLast: {
    padding: 0,
  },
});
