import { makeStyles } from "@material-ui/styles";
import React, { Fragment, useState } from "react";
import { Circle, GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

const useStyles = makeStyles(() => ({
  loadingElement: {
    height: "100%",
  },
  containerElement: {
    height: 500,
  },
}));

const CustomSkinMap = withScriptjs(
  withGoogleMap(({ children, places, circleProps, handleMouseOver, handleMouseExit, showInfoWindow, onPointClick, ...rest }) => {
    return (
      <GoogleMap
        options={{
          scrollwheel: false,
          zoomControl: true,
          disableDefaultUI: true,
          styles: [
            {
              featureType: "administrative",
              elementType: "all",
              stylers: [
                {
                  saturation: "-100",
                },
              ],
            },
            {
              featureType: "administrative.province",
              elementType: "all",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [
                {
                  saturation: -100,
                },
                {
                  lightness: 65,
                },
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [
                {
                  saturation: -100,
                },
                {
                  lightness: "50",
                },
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [
                {
                  saturation: "-100",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "all",
              stylers: [
                {
                  lightness: "30",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "all",
              stylers: [
                {
                  lightness: "40",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [
                {
                  saturation: -100,
                },
                {
                  visibility: "simplified",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  hue: "#ffff00",
                },
                {
                  lightness: -25,
                },
                {
                  saturation: -97,
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels",
              stylers: [
                {
                  lightness: -25,
                },
                {
                  saturation: -100,
                },
              ],
            },
          ],
        }}
        {...rest}
      >
        {circleProps ? (
          places?.map((place, id) => {
            return (
              <Fragment key={id}>
                <Circle
                  defaultCenter={{
                    lat: parseFloat(place.lat),
                    lng: parseFloat(place.lng),
                  }}
                  radius={circleProps.radius * 0.65}
                  options={{ ...circleProps.options, fillOpacity: 0.8 }}
                />
                <Circle
                  defaultCenter={{
                    lat: parseFloat(place.lat),
                    lng: parseFloat(place.lng),
                  }}
                  radius={circleProps.radius}
                  options={circleProps.options}
                  onClick={() => onPointClick(place)}
                  onMouseOver={handleMouseOver(id)}
                  onMouseOut={handleMouseExit}
                />
                {showInfoWindow === id && (
                  <InfoWindow
                    position={{
                      lat: parseFloat(place.lat),
                      lng: parseFloat(place.lng),
                    }}
                  >
                    <>
                      <h5>{place.Location}</h5>
                      <h2>{place.count}</h2>
                    </>
                  </InfoWindow>
                )}
              </Fragment>
            );
          })
        ) : (
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
            {places?.map((place, id) => {
              return (
                <Fragment key={id}>
                  <Marker
                    position={{
                      lat: parseFloat(place.lat),
                      lng: parseFloat(place.lng),
                    }}
                    onMouseOver={handleMouseOver(id)}
                    onMouseOut={handleMouseExit}
                  >
                    {showInfoWindow === id && (
                      <InfoWindow>
                        <h4>
                          {place.Location}, {place.Country}
                        </h4>
                      </InfoWindow>
                    )}
                  </Marker>
                </Fragment>
              );
            })}
          </MarkerClusterer>
        )}
      </GoogleMap>
    );
  })
);

function Maps(props) {
  const classes = useStyles();
  const [showInfoWindow, setShowInfoWindow] = useState();
  return (
    <CustomSkinMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
      loadingElement={<div className={classes.loadingElement} />}
      containerElement={<div className={classes.containerElement} />}
      mapElement={<div className={classes.loadingElement} />}
      showInfoWindow={showInfoWindow}
      handleMouseOver={id => () => setShowInfoWindow(id)}
      handleMouseExit={() => setShowInfoWindow()}
      {...props}
    />
  );
}

export default Maps;
