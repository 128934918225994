import api from ".";

const path = "/sku";

const getAll = () => api.get(`${path}`);

const get = id => api.get(`${path}/${id}`);

const getCustomization = id => api.get(`${path}/customization/${id}`);

const create = data => api.post(`${path}`, data);

const createBulk = data => api.post(`${path}/bulk`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const updateCustomization = (id, data) => api.put(`${path}/customization/${id}`, data);

const removeCustomization = id => api.put(`${path}/customization/remove/${id}`);

const updateStatus = (id, data) => api.put(`${path}/status/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

const getDemo = params => api.get(`${path}/demo`, { params });

export default {
  getAll,
  get,
  getCustomization,
  create,
  createBulk,
  update,
  updateCustomization,
  updateStatus,
  remove,
  getDemo,
  removeCustomization,
};
