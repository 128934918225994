import api from ".";

const path = "/geoip";

const getAll = () => api.get(`${path}`);

const get = id => api.get(`${path}/${id}`);

const count = () => api.get(`${path}/count`);

export default {
  getAll,
  get,
  count,
};
