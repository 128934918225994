import { Dialog, Grid, IconButton, MenuItem, Paper, Typography } from "@material-ui/core";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import Submission from "api/formSubmission";
import Form from "api/formTemplate";
import PayloadApi from "api/payload";
import TagApi from "api/tag";
import codeActivationStyle from "assets/jss/components/codeActivationStyle";
import classNames from "classnames";
import Button from "components/Button";
import { default as ActivatingDialog, default as ReviewDialog } from "components/Dialog/confirmation";
import ValidationDialog from "components/Dialog/info";
import PageContent from "components/PageContent";
import Select from "components/Select";
import { useSnackbar } from "notistack";
import PapaParse from "papaparse";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { asyncForEach, decodeJWTPayload } from "utils";
import Preview from "./Preview";
import Table from "./Table";
import { useHistory } from "react-router-dom";
import { tempTagMerchants } from "constants.js";
import { AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(codeActivationStyle);

function CodeActivation() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState();
  const [skuDetails, setSkuDetails] = useState();
  const [openReviewDialog, setReviewDialog] = useState();
  const [openValidationDialog] = useState();
  const [openActivatingDialog, setActivatingDialog] = useState();
  const [payload, setPayload] = useState([]);
  const [submissionId, setSubmissionId] = useState(new URLSearchParams(window.location.search).get("submissionId"));
  const [selectedPayload, setSelectedPayload] = useState(1);
  const [invalidRows, setInvalidRows] = useState();
  const [activateLoadingDialog, setActivateLoadingDialog] = useState();
  const [loading, setLoading] = useState();
  const [forms, setForms] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [refreshTable, setRefreshTable] = useState();
  const { demo } = useSelector(_ => _.user);
  const jwtToken = useSelector(_ => _.user.jwtToken);
  const merchant = decodeJWTPayload(jwtToken).merchant;
  const messagesEndRef = useRef(null);
  const history = useHistory();
  const handleContactSupport = useCallback(() => history.push("/settings/credit"), [history]);

  const refreshDataPreview = ({ merchant, tag, address, ...extraDetails }) => {
    TagApi.preview(address)
      .then(res => {
        const { sku } = res.order;
        if (sku.details.images) {
          const { hash, ext } = sku.details.images;
          if (hash) setPreviewImages([`${apiURL}/files/${hash}${ext}`]);
          else {
            setPreviewImages(sku.details.images.map(({ hash, ext }) => `${apiURL}/files/${hash}${ext}`));
          }
        }

        let skuDetails = {};
        if (sku.details.sku_details) {
          skuDetails = sku.details.sku_details;
        }
        setSkuDetails(
          Object.assign(
            {
              Name: sku.name,
              SKU: sku.sku,
              Brand: sku.trademark.registration_name,
              Status: sku.trademark.status,
            },
            skuDetails,
            extraDetails
          )
        );
      })
      .catch(() => {
        setPreviewImages([require(`assets/img/sampleSKU.png`)]);
        setSkuDetails({
          Error: "Invalid code",
        });
      });
  };

  const resetForm = () => {
    setSkuDetails();
    setPreviewImages();
    setInvalidRows();
    setUploadedFiles([]);
    setPayload([]);
  };

  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length > 0) {
        setSkuDetails();
        setPreviewImages();
        setInvalidRows();
        PapaParse.parse(acceptedFiles[0], {
          header: true,
          skipEmptyLines: "greedy",
          complete: results => {
            if (results.data.length > 0) {
              if (results.data.length <= 3000) {
                setPayload(results.data);
                setSelectedPayload(1);
                setUploadedFiles(acceptedFiles);
              } else {
                setReviewDialog(true);
              }
            }
          },
          transform: (val, header) => (["Codes"].includes(header) ? { address: new URL(val).searchParams.get("a") } : val),
        });
      }
    },
    [setPayload]
  );

  const onClickActivate = event => {
    setLoading(true);
    setActivatingDialog();
    TagApi.activate({ certificates: payload, submissionId })
      .then(() => {
        setActivateLoadingDialog(true);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading();
      });
    event.stopPropagation();
  };

  useEffect(() => {
    if (payload.length < 1) return;
    if (selectedPayload > payload.length) return setSelectedPayload(payload.length);
    if (selectedPayload < 1) return setSelectedPayload(1);
    const { Codes, ...extraDetails } = payload[selectedPayload - 1];
    refreshDataPreview({
      ...Codes,
      ...extraDetails,
    });
  }, [selectedPayload, payload]);

  useEffect(() => {
    const addressList = payload.map(_ => _?.Codes?.address);
    PayloadApi.check(addressList).then(res => {
      if (!res.ok) setInvalidRows(res);
    });
  }, [setPayload, payload]);

  useEffect(() => {
    Submission.getAll().then(async res => {
      const completedList = [];
      await asyncForEach(res, async i => {
        const { sensorAnswers, answers } = await Submission.preview(i.id);
        const completed = sensorAnswers.map(i => Object.values(i).length === 3).reduce((a, b) => a && b, true);
        completed && completedList.push({ ...i, answers });
      });
      setSubmissions(completedList);
    });
    Form.getAll().then(res => {
      setForms(res);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept: ".csv",
    multiple: false,
  });
  const handleClose = () => {
    setLoading();
    setActivatingDialog();

    resetForm();
  };
  const handleOnClick = () => {
    setActivatingDialog(true);

    setLoading(true);
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <PageContent header={t("Labels.Activate Codes")} subheaderProps={{ className: classes.activation }}>
            <Grid item align="center" className={classes.action}>
              {tempTagMerchants.includes(merchant) && (
                <>
                  <Typography variant="h3" className={classes.text}>
                    {t("Select Submission")}
                  </Typography>
                  <Select name="submissionId" value={submissionId} onChange={event => setSubmissionId(event.target.value)} fullWidth>
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    {submissions.map(({ id, date, formId, custom_id }) => (
                      <MenuItem value={id}>
                        <Grid container>
                          <Grid item xs={6} sm={6}>
                            {new Date(date).toLocaleString([], {
                              weekday: "long",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                            })}
                          </Grid>
                          <Grid item xs={1} sm={1}>
                            |
                          </Grid>
                          <Grid item xs={5} sm={5}>
                            {forms?.find(i => i.id === formId)?.custom_id_label}: <b>{custom_id}</b>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="h3" className={classes.text}>
                    {t("And")}
                  </Typography>
                </>
              )}
              <div className={classes.dragndrop} {...getRootProps()}>
                <input {...getInputProps()} />
                <Typography variant="h3" className={classNames(classes.bold, classes.dropzoneText)}>
                  {isDragActive ? t("Instructions.Drop it like it's hot!") : t("HelperText.Drag and Drop CSV file here")}
                </Typography>
                <Typography variant="h3" className={classNames(classes.bold, classes.dropzoneText)}>
                  {t("Labels.OR")}
                </Typography>
                <Button component="span" className={classes.button} buttonText={t("Labels.Upload")} />
                <Typography variant="h3" className={classNames(classes.semiTrans, classes.dropzoneText)}>
                  {uploadedFiles.length > 0
                    ? uploadedFiles.map(acceptedFile => t("Labels.File selected") + ": " + acceptedFile.name)
                    : t("HelperText.Max File 10MB")}
                </Typography>
                {fileRejections.length > 0 && <Typography text={fileRejections[0].errors[0].message} />}
              </div>
              <Typography variant="h4" className={classes.exampletitle}>
                {t("Labels.Example of CSV format")}
              </Typography>
              <img alt="activation-example" className={classes.imgExample} src={require("assets/gif/activationExcel.gif")} />
            </Grid>
          </PageContent>
        </Grid>
        <Grid item xs={12} md={6}>
          <PageContent header={t("Labels.Data Preview")} className={classes.dataPreview}>
            <Grid container>
              <Grid item className={classes.navigation} xs={12}>
                {payload.length > 0 && (
                  <>
                    <IconButton disabled={selectedPayload <= 1} onClick={() => setSelectedPayload(selectedPayload - 1)}>
                      <ChevronLeftRounded />
                    </IconButton>
                    <Typography variant="button">
                      {selectedPayload} of {payload.length}
                    </Typography>
                    <IconButton disabled={selectedPayload === payload.length} onClick={() => setSelectedPayload(selectedPayload + 1)}>
                      <ChevronRightRounded />
                    </IconButton>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={1}>
                  {payload.length > 0 ? (
                    <Preview
                      images={previewImages}
                      details={{
                        ...skuDetails,
                        ...submissions
                          .find(i => i.id === submissionId)
                          ?.answers?.reduce((acc, { question, answer }) => ({ ...acc, [question]: answer }), {}),
                      }}
                    />
                  ) : (
                    <div className={classes.emptyPreview}>
                      <Typography>{t("HelperText.No codes available for preview")}</Typography>
                    </div>
                  )}
                </Paper>
              </Grid>
            </Grid>
            {payload.length > 0 && (
              <div className={classes.results}>
                <Typography variant="h1" className={classes.headerTitle}>
                  {t("Data Validation Results")}
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Paper className={classes.paperStyle} elevation={1}>
                      {invalidRows?.length ? (
                        <Alert severity="error">
                          <AlertTitle>
                            {`${t("Labels.Error")}, ${invalidRows.length} code${invalidRows.length > 1 ? "s" : ""} ${
                              invalidRows[0].type === "Active" ? "has been activated" : `do${invalidRows.length > 1 ? "" : "es"} not exists`
                            }`}
                          </AlertTitle>
                          <ol className={classes.ol}>
                            {invalidRows.map(e => (
                              <li>{e.address}</li>
                            ))}
                          </ol>
                        </Alert>
                      ) : (
                        <Alert severity="success">{`${payload.length} ${t("Messages.codes available for activation")}`}</Alert>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item className={classes.activateButton} xs={12}>
                    <Grid container justify="flex-end" spacing={1}>
                      <Grid item>
                        <Button variant="outlined" onClick={resetForm} buttonText={t("Labels.Cancel")} />
                      </Grid>
                      <Grid item>
                        <Button
                          loading={loading}
                          onClick={handleOnClick}
                          disabled={loading || demo || (invalidRows && invalidRows.length > 0)}
                          buttonText={t("Labels.Activate Codes")}
                          endIcon={<ChevronRightRounded />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
          </PageContent>
        </Grid>
        <Grid item xs={12} md={12} className={classes.activateTable}>
          <Table refresh={refreshTable} />
        </Grid>
        <div ref={messagesEndRef} />
      </Grid>

      <ReviewDialog
        header={t("Labels.Information")}
        image={require("assets/svg/girl-on-computer.svg")}
        descriptions={[
          t("Please do not activate more than 3000 codes in a single activation to prevent code activation errors."),
          t("Example for 6000 codes, separate them into 2 CSV files for activation."),
        ]}
        submitText={t("Labels.Contact Support")}
        cancelText={t("Labels.Yes, I understand")}
        handleClose={() => setReviewDialog()}
        onClick={handleContactSupport}
        open={openReviewDialog}
      />
      <ValidationDialog
        header={t("Labels.Validation Results")}
        image={require("assets/svg/backpack-man.svg")}
        descriptions={[t("Messages.Invalid value for")]}
        buttonText={t("Labels.Fix Data")}
        handleClose={handleClose}
        open={openValidationDialog}
      />
      <ActivatingDialog
        header={t("Labels.Activating Codes")}
        image={require("assets/img/activationLoading.png")}
        descriptions={[t("Messages.Are you sure to continue?")]}
        submitText={t("Labels.Yes")}
        cancelText={t("Labels.No")}
        handleClose={handleClose}
        onClick={onClickActivate}
        open={openActivatingDialog}
      />
      <Dialog maxWidth={"md"} open={activateLoadingDialog}>
        <div className={classes.codeActivated}>
          <Grid container justify="center">
            <Typography variant="h1" className={classes.title}>
              {t("Messages.Your codes are being activated")}
            </Typography>
          </Grid>
          <Grid container justify="center">
            <img alt="activation-loading" className={classes.img} src={require("assets/img/activationLoading.png")} />
          </Grid>
          <Grid container justify="center">
            <Typography className={classes.message}>{t("Messages.BlockchainAnnouncmentMessage")}</Typography>
          </Grid>
          <Grid container justify="center" className={classes.buttonGroup}>
            <Button
              color="primary"
              className={classes.button}
              buttonText={t("Labels.Continue")}
              onClick={() => {
                setRefreshTable(_ => !_);
                setActivateLoadingDialog();
                scrollToBottom();
              }}
              endIcon={<ChevronRightRounded />}
            />
          </Grid>
        </div>
      </Dialog>
    </>
  );
}

export default CodeActivation;
