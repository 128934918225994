export default ({ palette, breakpoints }) => ({
  logoDiv: {
    overflow: "hidden",
    background: palette.background.paper,
    alignSelf: "center",
    width: 164,
    height: 164,
    borderRadius: 164,
  },
  logoBorder: {
    borderRadius: 175,
    width: 175,
    height: 175,
    bottom: -50,
    position: "relative",
    backgroundColor: palette.background.verify,
    transition: "background-color 1s",
    display: "grid",
    placeItems: "center",
  },
  eventsMargin: {
    marginBottom: 25,
  },
  eventsTitle: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  eventTitle: {
    paddingBottom: 15,
    textAlign: "left",
  },
  description: {
    whiteSpace: "pre-wrap",
    paddingTop: 10,
    textAlign: "initial",
    fontSize: 12,
  },
  links: {
    wordBreak: "break-all",
    fontSize: 12,
    fontWeight: 400,
  },
  img: {
    width: "80%",
  },
  withlogo: {
    width: 172,
    height: 172,
    borderRadius: 172,
  },
  logoRoot: {
    bottom: -50,
    position: "relative",
  },
  withoutlogo: {
    paddingTop: 50,
  },
  button: {
    marginTop: 5,
    bottom: 0,
    backgroundColor: palette.primary.main,
    color: palette.background.paper,
    // maxWidth: 320,
    // [breakpoints.down("xs")]: {
    //   maxWidth: 288,
    // },
  },
  redirectButton: {
    justifyContent: "space-between",
  },
  details: {
    // padding: "0px 46px",
    // [breakpoints.down("md")]: {
    //   padding: "0px 8px",
    // },
  },
  detailLabel: {
    paddingTop: 14,
    paddingBottom: 4,
    fontWeight: 600,
  },
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    height: "100%",
  },
  logoDefault: {
    top: 26,
    position: "relative",
  },
  paper: {
    marginTop: 14,
    borderRadius: 18,
    padding: 25,
    paddingTop: 64,
    border: "unset",
  },
  verificationPaper: {
    marginTop: 0,
  },
  defaultPaper: {
    padding: 12,
    border: "unset",
    borderRadius: 18,
    marginTop: 5,
  },
  eventPaper: {
    padding: 0,
    paddingTop: 12,
  },
  root: {
    textAlign: "center",
    margin: "auto",
    maxWidth: "90%",
  },
  buttonLink: {
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  carousel: {
    border: `${palette.background.verify} solid 2px`,
    borderRadius: 12,
    transition: "border-color 1s",
    margin: 2,
    marginTop: 8,
    width: 300,
    maxHeight: 196,
    // [breakpoints.down("xs")]: {
    //   width: 250,
    // },
    overflow: "hidden",
  },
  result: {
    fontWeight: 600,
    lineHeight: "unset",
    color: "initial",
  },
  a: {
    "&:hover": {
      textDecoration: "unset",
    },
  },
  buttonText: {
    textAlign: "center",
  },
  text: {
    textAlign: "left",
  },
  logohere: {
    opacity: 0.5,
    fontSize: 26,
    lineHeight: 1,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  logoherediv: {
    width: "min-content",
    padding: "52px 47px",
  },
  circularProgress: {
    border: `solid 2px`,
    borderRadius: 12,
    padding: 1,
  },
  logoSection: {
    backgroundColor: "transparent",
  },
  certificateLogo: {
    height: 35,
    margin: "2px 5px",
  },
  timelineContent: {
    padding: 0,
    paddingLeft: 6,
    paddingTop: 3,
  },
  timelineOppositeContent: {
    flex: 0,
    padding: 0,
  },
  defaultVerifyColor: {
    backgroundColor: palette.background.verify,
  },
});
