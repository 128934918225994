import { Grid, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import A from "components/A";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isISODate } from "utils/date";

const useStyles = makeStyles(() => ({
  key: {
    borderBottom: "unset",
    padding: "3px 0",
    paddingLeft: 20,
    fontWeight: 600,
    verticalAlign: "top",
    width: "30%",
    lineHeight: "1.2",
  },
  value: {
    borderBottom: "unset",
    paddingLeft: 20,
    paddingRight: 20,
    padding: "3px 0",
    whiteSpace: "pre-wrap",
    verticalAlign: "top",
    width: "70%",
    lineHeight: "1.2",
  },
  documentHeader: {
    paddingLeft: 0,
    paddingTop: 10,
  },
  documentTitle: {
    wordBreak: "break-word",
  },
}));

function ProductDetail({ details, document, customization = {} }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [orderedDetails, setOrderedDetails] = useState();
  const { order, customSKUName, customSKU, breakLineChar } = customization;
  useEffect(() => {
    if (order) {
      const initOrder = ["Name", "SKU"].concat(order);
      const noOrderField = Object.keys(details).filter(i => !initOrder.includes(i));
      setOrderedDetails(initOrder.concat(noOrderField));
    }
  }, [order, details]);

  const getArray = () => orderedDetails || Object.keys(details);

  return (
    <>
      <Table>
        <TableBody>
          {details &&
            getArray().map(
              (e, i) =>
                typeof details[e] === "string" && (
                  <TableRow key={i}>
                    <TableCell className={classes.key}>
                      {e === "Name" && customSKUName ? customSKUName : e === "SKU" && customSKU ? customSKU : e}
                    </TableCell>
                    <TableCell className={classes.value}>
                      {isISODate(details[e])
                        ? new Date(details[e]).toLocaleString([], {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        : breakLineChar
                        ? details[e]
                            .split(breakLineChar)
                            .reduce((acc, cur, i, arr) => acc.concat(cur + (i < arr.length - 1 ? breakLineChar : "")).concat(<br />), [])
                            .slice(0, -1)
                        : details[e]}
                    </TableCell>
                  </TableRow>
                )
            )}
        </TableBody>
      </Table>
      {document?.length &&
        document.map(({ name, link }) => (
          <>
            <div>
              <Typography className={classes.documentHeader} variant="subtitle2">
                {t("Labels.Certificates")}
              </Typography>
            </div>
            <Grid xs={12}>
              <Typography className={classes.documentTitle} variant="caption">
                <A href={link}>{name}</A>
              </Typography>
            </Grid>
          </>
        ))}
    </>
  );
}

export default ProductDetail;
