import PlanApi from "api/plan";
import BillingTable from "components/BillingTable";
import PageContent from "components/PageContent";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function BillingHistory() {
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    PlanApi.getAll().then(res => {
      setPlans(res);
    });
  }, []);

  return (
    <PageContent header={t("Labels.Billing History")}>
      <BillingTable plans={plans} />
    </PageContent>
  );
}

export default BillingHistory;
