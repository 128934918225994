import api from ".";

const path = "/payloads";

const getAll = params => api.get(`${path}`, { params });

const activated = () => api.get(`${path}/activated`);

const activateBatch = params => api.get(`${path}/activateBatch`, { params });

const activateBatchById = id => api.get(`${path}/activateBatch/${id}`);

const getTotalActivated = () => api.get(`${path}/totalActivated`);

const check = addressList => api.post(`${path}/check`, { addressList });

const byLogisticId = logisticId => api.get(`${path}/byLogisticId/${logisticId}`);

export default {
  getAll,
  activated,
  activateBatch,
  activateBatchById,
  getTotalActivated,
  check,
  byLogisticId,
};
