import { Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Typography } from "@material-ui/core";
import { AddCircleRounded, Cancel, ChevronRightRounded } from "@material-ui/icons";
import lottieAnimationData from "assets/json/upload.json";
import Lottie from "react-lottie";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import SKUApi from "api/sku";
import TrademarkApi from "api/trademark";
import UploadApi from "api/upload";
import manageSKUStyles from "assets/jss/components/manageSKUStyles";
import classNames from "classnames";
import Button from "components/Button";
import SelectUpload from "components/Select/Upload";
import KeyValueTextField from "components/KeyValueTextField";
import Select from "components/Select";
import TextField from "components/TextField";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { certificates } from "constants.js";

const useStyles = makeStyles(manageSKUStyles);

function BrandCreate({ open, handleClose, title, data }) {
  const classes = useStyles();
  const { register, errors, control, handleSubmit, unregister, setValue, reset } = useForm();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "file",
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(0);
  const [dynamicDetails, setDynamicDetails] = useState([{}]);
  const [dynamicCertificates, setDynamicCertificates] = useState([{}]);
  const { enqueueSnackbar } = useSnackbar();
  const [isEdit, setIsEdit] = useState();
  const { demo } = useSelector(_ => _.user);

  const [trademarkList, setTrademarkList] = useState([]);
  const formatData = ({ trademarkId, name, sku, images, document, ...details }) => {
    return { trademarkId, name, sku, details };
  };
  const { emailConfirmed } = useSelector(_ => _.user.progress);

  const onClose = () => {
    reset({});
    setDynamicDetails([{}]);
    setDynamicCertificates([{}]);
    setIsEdit();
    handleClose();
  };

  const saveForm = async formData => {
    try {
      if (!emailConfirmed) {
        throw t("HelperText.Please verify your email");
      }
      setLoading(1);
      const sku = formatData(formData);
      if (fields.length) {
        const uploadRes = await Promise.all(fields.filter(f => !f.existing).map(f => UploadApi.create(f.file)));
        sku.details.images = [
          ...fields.filter(f => f.existing).flatMap(f => f.file),
          ...uploadRes.map(res => {
            const { name, hash, ext, mime, size } = res;
            return { name, hash, ext, mime, size };
          }),
        ];
      }
      if (dynamicCertificates.length) {
        const uploadRes = await Promise.all(
          dynamicCertificates.map(async f => {
            if (f.newDocument) {
              const res = await UploadApi.create(f.newDocument);
              const { name, hash, ext, mime, size } = res;
              return { type: f.type, name, hash, ext, mime, size };
            }
            return f;
          })
        );
        sku.certificates = uploadRes.filter(f => f.ext);
      }
      if (formData.document?.length > 0) {
        const { name, hash, ext, mime, size } = await UploadApi.create(formData.document[0]);
        sku.details.document = { name, hash, ext, mime, size };
      }
      sku.details.sku_details = Object.assign({}, ...dynamicDetails.filter(_ => _ !== { "": "" }));
      sku.details.sku_details_order = dynamicDetails.map(i => Object.keys(i)[0]).filter(_ => _);
      sku.details.customization = data.details?.customization;
      setLoading(2);
      if (isEdit) {
        await SKUApi.update(data.id, sku);
      } else {
        await SKUApi.create(sku);
      }
      onClose();
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setLoading(0);
    }
  };

  useEffect(() => {
    TrademarkApi.getAll().then(res => {
      setTrademarkList(res);
    });
  }, [register, unregister]);

  useEffect(() => {
    const getArray = () => data?.details?.sku_details_order || Object.keys(data.details.sku_details);
    if (open && Object.keys(data).length) {
      setIsEdit(true);
      data?.details?.sku_details && setDynamicDetails(getArray().map(i => ({ [i]: data.details.sku_details[i] })));
      data?.certificates && setDynamicCertificates(data.certificates);
      data?.details.images &&
        reset({
          file: data.details.images.map(file => {
            return {
              name: file.name,
              preview: `${apiURL}/files/${file.hash}${file.ext}`,
              existing: true,
              file,
            };
          }),
        });
      Object.keys(data.details)
        .filter(k => !["id", "createdAt", "active", "tableData", "updatedAt", "merchant", "document"].includes(k))
        .forEach(i => setValue(i, data.details[i]));
    }
  }, [open, data, reset, setValue]);

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        append({
          name: file.name,
          preview: URL.createObjectURL(file),
          file: file,
        });
      });
    },
    [append]
  );

  const onRemove = (event, id) => {
    remove(id);
    event.stopPropagation();
  };

  const maxFileSize = 1048576;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop,
    accept: "image/*",
    minSize: 0,
    maxSize: `${maxFileSize}`,
    multiple: true,
  });

  const onDeleteDynamicDetails = i => () => setDynamicDetails(dynamicDetails.filter((_, j) => j !== i));

  const onKeyChange =
    i =>
    ({ currentTarget }) => {
      const newDynamicDetails = [...dynamicDetails];
      const obj = Object.keys(newDynamicDetails[i]);
      newDynamicDetails[i] = { [currentTarget.value]: obj.length ? newDynamicDetails[i][obj[0]] : "" };
      setDynamicDetails(newDynamicDetails);
    };
  const onValueChange =
    i =>
    ({ currentTarget }) => {
      const newDynamicDetails = [...dynamicDetails];
      const obj = Object.keys(newDynamicDetails[i]);
      newDynamicDetails[i] = { [obj.length ? obj[0] : ""]: currentTarget.value };
      setDynamicDetails(newDynamicDetails);
    };

  const onDeleteDynamicCertificates = i => () => setDynamicCertificates(dynamicCertificates.filter((_, j) => j !== i));

  const onTypeChange = i => e => {
    const newDynamicCertificates = [...dynamicCertificates];
    newDynamicCertificates[i] = {
      ...newDynamicCertificates[i],
      [e.target.name]: e.target.value,
    };
    setDynamicCertificates(newDynamicCertificates);
  };

  const onDocumentsChange = i => data => {
    const newDynamicCertificates = [...dynamicCertificates];
    newDynamicCertificates[i] = {
      ...newDynamicCertificates[i],
      newDocument: data,
    };
    setDynamicCertificates(newDynamicCertificates);
  };

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <form>
        <DialogTitle disableTypography>
          <Typography variant="h1">{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <div className={classes.grid}>
                <TextField
                  labelPlacement="left"
                  required
                  className={classes.root}
                  inputRef={register({ required: true })}
                  name="name"
                  label={t("Labels.Name")}
                  error={errors.name}
                  defaultValue={data?.name}
                  helperText={t("HelperText.Please fill in SKU Name")}
                  labelProps={{ style: { width: 282 } }}
                  fullWidth
                />
                <TextField
                  labelPlacement="left"
                  required
                  className={classes.root}
                  inputRef={register({ required: true })}
                  name="sku"
                  label={t("Labels.SKU")}
                  error={errors.sku}
                  defaultValue={data?.sku}
                  helperText={t("HelperText.Please fill in SKU Number")}
                  labelProps={{ style: { width: 282 } }}
                  fullWidth
                />
                <Select
                  name="trademarkId"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={data?.trademarkId}
                  labelPlacement="left"
                  required
                  className={classes.root}
                  label={t("Labels.Trademark")}
                  error={errors.trademarkId}
                  helperText={t("HelperText.Please select Brand")}
                  labelProps={{ style: { width: 282 } }}
                  fullWidth
                >
                  <MenuItem value={null}>&nbsp;</MenuItem>
                  {trademarkList.map(trademark => {
                    return (
                      <MenuItem
                        key={`trademark_name_${trademark.id}`}
                        value={trademark.id}
                      >{`${trademark.registration_name} - ${trademark.status}`}</MenuItem>
                    );
                  })}
                </Select>
                <Typography variant="h5" className={classes.specs}>
                  {t("Labels.SKU Specifications")}
                </Typography>
                {dynamicDetails.map((e, i) => (
                  <KeyValueTextField
                    key={i}
                    keyValue={Object.keys(e).length ? Object.keys(e)[0] : ""}
                    value={Object.keys(e).length ? e[Object.keys(e)[0]] : ""}
                    onDelete={onDeleteDynamicDetails(i)}
                    keyPlaceholder={t("Labels.Feature")}
                    valuePlaceholder={t("Labels.Details")}
                    onKeyChange={onKeyChange(i)}
                    onValueChange={onValueChange(i)}
                  />
                ))}
                <div className={classes.addButton}>
                  <IconButton onClick={() => setDynamicDetails([...dynamicDetails, {}])}>
                    <AddCircleRounded fontSize="large" color="primary" />
                  </IconButton>
                </div>
              </div>
              <div className={classNames(classes.grid, classes.certificateSection)}>
                <Typography variant="h3" className={classes.specs}>
                  {t("Labels.Supporting Certificates")}
                </Typography>
                {dynamicCertificates.map((e, i) => (
                  <SelectUpload
                    key={i}
                    index={i}
                    labelPlacement="left"
                    label={t("Labels.Certificate") + " #" + (i + 1)}
                    name="type"
                    file={e?.document}
                    documentName={e?.newDocument?.name}
                    linkPath={`${apiURL}/files/${e.hash}${e.ext}`}
                    selectOptions={certificates}
                    value={e?.type}
                    onDelete={onDeleteDynamicCertificates(i)}
                    onChange={onTypeChange(i)}
                    onUpload={onDocumentsChange}
                  />
                ))}
                <div className={classes.addButton}>
                  <IconButton onClick={() => setDynamicCertificates([...dynamicCertificates, {}])}>
                    <AddCircleRounded fontSize="large" color="primary" />
                  </IconButton>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div className={classNames(classes.grid, classes.fullHeight)}>
                <div className={classes.div}>
                  <div className={classes.dragndrop} {...getRootProps()}>
                    <div className={classes.lottieDiv}>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: lottieAnimationData,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        height="100%"
                        autoplay
                        loop
                        width="100%"
                      />
                    </div>
                    <input {...getInputProps()} />
                    {!isDragActive && t("HelperText.Click here or drop a file to upload SKU image!")}
                    {isDragActive && !isDragReject && t("Instructions.Drop it like it's hot!")}
                    {isDragReject && t("Messages.File type not accepted, sorry!")}
                    {fileRejections.length > 0 && <div>{fileRejections[0].errors[0].message}</div>}
                    <Grid container alignItems="stretch" className={classes.uploadImageContainer}>
                      {fields.map(
                        (field, index) =>
                          !field.preview && (
                            <Grid className={classes.uploadImageContainerItem} item xs={6} key={`uploadImage_${index}`}>
                              <Avatar
                                variant="square"
                                className={classes.uploadImage}
                                alt={field.name}
                                src={`${apiURL}/files/${field.hash}${field.ext}`}
                              ></Avatar>
                              <IconButton className={classes.uploadImageClose} onClick={e => onRemove(e, index)}>
                                <Cancel />
                              </IconButton>
                              {field.name}
                            </Grid>
                          )
                      )}

                      {fields.map(
                        (field, index) =>
                          field.preview && (
                            <Grid className={classes.uploadImageContainerItem} item xs={6} key={`uploadImage_${index}`}>
                              <Avatar variant="square" className={classes.uploadImage} alt={field.name} src={field.preview}></Avatar>
                              <IconButton size="small" className={classes.uploadImageClose} onClick={e => onRemove(e, index)}>
                                <Cancel />
                              </IconButton>
                              {field.name}
                            </Grid>
                          )
                      )}
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonGroup}>
            <Button onClick={onClose} variant="outlined" buttonText={t("Labels.Cancel")} className={classes.button} />
            <Button
              type="submit"
              disabled={loading > 0 || demo}
              onClick={handleSubmit(saveForm)}
              autoFocus
              className={classes.button}
              loading={loading}
              loadingText={loading === 1 ? t("Messages.Uploading image") : t("Messages.Saving")}
              buttonText={isEdit ? t("Labels.Update") : t("Labels.Create")}
              endIcon={<ChevronRightRounded />}
            />
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default BrandCreate;
