import { Divider, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { ChevronRightRounded, DeleteRounded, EditRounded, MoreHoriz } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import BusinessApi from "api/business";
import TrademarkApi from "api/trademark";
import mtoolbarStyle from "assets/jss/components/MTableToolbar";
import Button from "components/Button";
import VerifyEmailDialog from "components/Dialog/info";
import PageContent from "components/PageContent";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "redux/actions/user";
import BrandCreate from "./Create";
import A from "components/A";

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    padding: 24,
  },
  row: {
    paddingBottom: 12,
  },
  detailImage: {
    width: "100%",
    borderRadius: 3,
    border: `solid 3px ${palette.background.default}`,
    padding: spacing(1),
    margin: spacing(1),
  },
  detailRoot: {
    padding: spacing(1),
    margin: spacing(1),
  },
  detailKey: {
    fontWeight: "bold",
  },
  itemIcon: {
    minWidth: 40,
  },
  status: {
    border: `2px solid ${palette.background.default}`,
    textAlign: "center",
    padding: 10,
  },
  subtitle: {
    paddingBottom: 10,
  },
}));

const useToolbarStyles = makeStyles(mtoolbarStyle);

const keyToDisplayMap = {
  registration_name: "Trademark Name",
  registration_no: "Trademark No.",
  applicant_name: "Applicant Name",
  applicant_address: "Applicant Address",
  document: "Supporting Document",
  country: "Country",
  trademarkStatus: "Status",
  class: "Trademark Class",
  postcode: "Postcode",
};

const Container = props => <Paper elevation={0} {...props} />;

function Brand() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState();
  const [openCreate, setOpenCreate] = useState();
  const [trademarkList, setTrademarkList] = useState([]);
  const [openVerifyEmailDialog, setOpenVerifyEmailDialog] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();
  const toolbarClasses = useToolbarStyles();
  const dispatch = useDispatch();
  const { progress, demo } = useSelector(_ => _.user);

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl();
    setOpenVerifyEmailDialog();
  };

  const handleEdit = () => {
    setOpenCreate(true);
    handleClose();
  };

  const handleActivation = async () => {
    handleClose();
    try {
      const details = { ...selectedRowData.details };
      const active = !selectedRowData.details?.active;
      details.active = !selectedRowData.details?.active;
      await TrademarkApi.update(selectedRowData.id, { ...selectedRowData, acknowledgeInfoCorrect: true, details });
      enqueueSnackbar(
        `${t("Labels.Trademark")} ${selectedRowData.registration_name} (${selectedRowData.registration_no}) ${active ? "activated" : "deactivated"}.`
      );
      trademarkList[trademarkList.findIndex(i => i.id === selectedRowData.id)].details = details;
      setTrademarkList(trademarkList);
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    }
  };

  const progressData = useCallback(async () => {
    const checklist = await BusinessApi.getProgress();
    dispatch(setProgress(checklist));
  }, [dispatch]);

  useEffect(() => {
    if (openCreate) return;
    !demo && setOpenVerifyEmailDialog(!progress?.emailConfirmed);
    TrademarkApi.getAll().then(res => {
      setTrademarkList(res);
    });
  }, [progress, openCreate, demo]);

  const Title = () => <Typography variant="h2">{t("Labels.List of Brands")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  return (
    <PageContent header={t("Labels.Manage Trademarks")} subheader={t("Labels.Register your brand here")}>
      <Grid item className={classes.row} xs={12}>
        <Button
          onClick={() => {
            setOpenCreate(true);
            setSelectedRowData({});
          }}
          disabled={!progress?.emailConfirmed}
          buttonText={t("Labels.Register Brand")}
          endIcon={<ChevronRightRounded />}
        />
      </Grid>
      <Grid item className={classes.row} xs={12}>
        <MaterialTable
          columns={[
            { title: t("Labels.Trademark Name"), field: "registration_name" },
            { title: t("Labels.Trademark No"), field: "registration_no" },
            { title: t("Labels.Trademark Class"), field: "details.class" },
            { title: t("Labels.Country"), field: "country" },
            { title: t("Labels.Status"), field: "status", render: rowData => <div className={classes.status}>{t(`Labels.${rowData.status}`)}</div> },
            {
              title: t("Labels.Action"),
              field: "action",
              sorting: false,
              render: rowData => (
                <IconButton onClick={e => handleClick(e, rowData)}>
                  <MoreHoriz />
                </IconButton>
              ),
            },
          ]}
          data={trademarkList}
          title={Title()}
          options={{
            toolbar: true,
            draggable: false,
            detailPanelColumnAlignment: "left",
            emptyRowsWhenPaging: false,
            headerStyle: {
              backgroundColor: palette.background.default,
              border: "3px solid",
              borderColor: palette.background.default,
            },
            rowStyle: {
              border: "3px solid",
              borderColor: palette.background.default,
            },
            cellStyle: {
              borderBottom: 0,
            },
          }}
          components={{
            Container,
            Toolbar,
          }}
          detailPanel={[
            {
              render: rowData => (
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div className={classes.detailRoot}>
                      {Object.keys(rowData.details).map((d, i) =>
                        d === "file" ? (
                          <>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography className={classes.detailKey}>{t(keyToDisplayMap.document)}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography>
                                  <A href={`${apiURL}/files/${rowData.details.file?.hash}${rowData.details.file?.ext}`}>
                                    {rowData.details.file.name}
                                  </A>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography className={classes.detailKey}>{t(keyToDisplayMap.applicant_name)}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography>{rowData.applicant_name}</Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={3}>
                                <Typography className={classes.detailKey}>{t(keyToDisplayMap.applicant_address)}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography>{rowData.applicant_address}</Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          d !== "class" && (
                            <Grid container key={i}>
                              <Grid item xs={3}>
                                <Typography className={classes.detailKey}>{t(keyToDisplayMap[d])}</Typography>
                              </Grid>
                              <Grid item xs={9}>
                                <Typography>{rowData.details[d]}</Typography>
                              </Grid>
                            </Grid>
                          )
                        )
                      )}
                    </div>
                  </Grid>
                </Grid>
              ),
            },
          ]}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleClose}
        >
          <MenuItem onClick={handleEdit}>
            <ListItemIcon className={classes.itemIcon}>
              <EditRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Labels.Edit")} />
          </MenuItem>
          <Divider />
          <MenuItem disabled onClick={handleActivation}>
            <ListItemIcon className={classes.itemIcon}>
              <DeleteRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={selectedRowData.details?.active ? t("Labels.Deactivate") : t("Labels.Activate")} />
          </MenuItem>
        </Menu>
        <BrandCreate
          title={selectedRowData.details ? t("Labels.Brand Edit") : t("Labels.Register Brand")}
          handleClose={() => {
            setOpenCreate();
            TrademarkApi.getAll().then(res => {
              setTrademarkList(res);
              res.length > 0 && progressData();
            });
          }}
          open={openCreate}
          data={selectedRowData}
        />
        <VerifyEmailDialog
          header={t("Messages.Verify Your Email")}
          image={require("assets/img/emailVerification.png")}
          descriptions={[t("Messages.To ensure your product security we need your email verified before you can register your brand")]}
          buttonText={t("Labels.Continue")}
          handleClose={handleClose}
          open={openVerifyEmailDialog}
        />
      </Grid>
    </PageContent>
  );
}

export default Brand;
