import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { appbarHeight, bannerHeight, dashboardPaddingTop, demoHeight } from "assets/jss";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    padding: dashboardPaddingTop,
    paddingLeft: 60,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4),
    },
    border: "unset",
    minHeight: `calc(100vh - ${appbarHeight}px)`,
  },
  banner: {
    minHeight: `calc(100vh - ${appbarHeight + bannerHeight}px)`,
  },
  demo: {
    minHeight: `calc(100vh - ${appbarHeight + demoHeight}px)`,
  },
  settings: {
    padding: dashboardPaddingTop + 34,
  },
  subtitle: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  list: {
    display: "flex",
  },
}));

function PageContent({ header, list, subheader, children, subheaderProps, className, ...props }) {
  const classes = useStyles();
  const { banner, demo } = useSelector(_ => _.user);
  const settings = window.location.pathname.startsWith("/settings");

  return (
    <Paper
      className={classNames(classes.root, className, {
        [classes.settings]: settings,
        [classes.demo]: demo,
        [classes.banner]: banner,
      })}
      {...props}
    >
      <Typography
        variant="h1"
        className={classNames({
          [classes.list]: list,
        })}
      >
        {header}
        {list && ": "}
        {list}
      </Typography>

      <Typography variant="subtitle1" className={classes.subtitle} {...subheaderProps}>
        {subheader}
      </Typography>

      {children}
    </Paper>
  );
}

export default PageContent;
