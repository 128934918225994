import { makeStyles } from "@material-ui/styles";
import Footer from "components/Footer";
import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ChevronRightRounded, WarningTwoTone } from "@material-ui/icons";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

function Error() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Grid item sm={12} md={6} className={classes.gridItem}>
          <Typography className={classes.error}>{t("Labels.Error")}</Typography>
          <Typography className={classes.code}>404</Typography>
          <Grid container className={classes.warning}>
            <WarningTwoTone />
            &nbsp;
            <Typography className={classes.sorry}>{t("Messages.Sorry page not found")}</Typography>
          </Grid>
          <Typography>{t("Messages.We could not find the page you were looking for")}</Typography>
          <Typography>{t("Messages.Meanwhile, you may return to dashboard")}</Typography>
          <NavLink to="/">
            <Button className={classes.button} endIcon={<ChevronRightRounded />} buttonText={t("Labels.Go to Dashboard")} />
          </NavLink>
        </Grid>
        <Hidden smDown>
          <Grid sm={12} md={6}>
            <img alt="broken-link" src={require("assets/img/brokenLink.png")} />
          </Grid>
        </Hidden>
      </div>
      <Footer />
    </div>
  );
}

const useStyles = makeStyles(({ palette }) => ({
  root: {
    height: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
  },
  container: {
    marginRight: "auto",
    marginLeft: "auto",
    "@media (min-width: 576px)": {
      maxWidth: 540,
    },
    "@media (min-width: 768px)": {
      maxWidth: 720,
    },
    "@media (min-width: 992px)": {
      maxWidth: 960,
    },
    "@media (min-width: 1200px)": {
      maxWidth: 1140,
    },
    paddingTop: "14vh",
    paddingBottom: 100,
    display: "flex",
  },
  gridItem: {
    paddingTop: 160,
    textAlign: "center",
  },
  title: {
    fontSize: 42,
    fontWeight: "bold",
  },
  link: {
    color: palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card: {
    textAlign: "center",
  },
  code: {
    height: 128,
    fontSize: 200,
    fontWeight: 600,
    lineHeight: "0.26",
  },
  sorry: {
    fontWeight: "bold",
  },
  button: {
    marginTop: 58,
  },
  warning: {
    justifyContent: "center",
    paddingBottom: 14,
  },
  error: {
    fontSize: 50,
    fontWeight: 600,
    lineHeight: 1.04,
    bottom: 64,
    position: "relative",
  },
}));

export default Error;
