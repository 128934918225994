import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    background: palette.primary.light,
    height: "100vh",
    display: "grid",
    placeContent: "center",
    overflowY: "auto",
  },
  experience: {
    background: palette.background.paper,
    display: "block",
  },
}));

export default function Default({ children }) {
  const classes = useStyles();
  const experience = window.location.pathname.startsWith("/experience");

  return <div className={classNames(classes.root, { [classes.experience]: experience })}>{children}</div>;
}
