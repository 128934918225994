import api from ".";

const path = "/user";

const getAll = () => api.get(`${path}`);

const get = id => api.get(`${path}/${id}`);

const getByName = params => api.get(path, { params });

const create = data => api.post(`${path}`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

const me = () => api.get(`${path}/me`);

const signIn = data => api.post(`/auth/local`, data);

const sendRecoveryEmail = data => api.post(`/auth/forgot-password`, data);

const resetPassword = data => api.post(`/auth/reset-password`, data);

const registerMerchant = data => api.post(`/merchant/register`, data);

const changePassword = data => api.post(`/auth/change-password`, data);

const confirmEmail = code => api.post(`/auth/email-confirmation?confirmation=${code}`);

const sendConfirmationEmail = params => api.get(`/auth/send-email-confirmation`, { params });

const register = (type, data) => api.post(`${type}/register`, data);

const blockedSwitch = id => api.put(`${path}/blockedswitch/${id}`);

const confirmedSwitch = (id, data) => api.put(`${path}/${id}`, data);

export default {
  getAll,
  get,
  create,
  update,
  remove,
  me,
  getByName,
  signIn,
  sendRecoveryEmail,
  resetPassword,
  registerMerchant,
  changePassword,
  confirmEmail,
  sendConfirmationEmail,
  register,
  blockedSwitch,
  confirmedSwitch,
};
