import { FilledInput, FormControl, FormHelperText, Grid, Popover, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/textfield";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { SketchPicker } from "react-color";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 12,
  },
  left: {
    display: "flex",
  },
  leftLabel: {
    width: 150,
    alignSelf: "center",
  },
  topLabel: {
    height: 23,
    lineHeight: 1.63,
  },
  help: {
    marginLeft: 4,
  },
  multiline: {
    alignSelf: "unset",
    paddingTop: 12,
  },
  colorBox: {
    marginLeft: 12,
    height: 48,
    borderRadius: 4,
    border: `1px solid ${palette.background.default}`,
    cursor: "pointer",
  },
  popover: {
    width: 223,
  },
}));

const useTextFieldStyles = makeStyles(textfieldStyles);

const ColorPickerTextField = ({
  label,
  name,
  required,
  tooltipText,
  labelPlacement,
  helperText,
  multiline,
  error,
  labelProps,
  bgColor,
  value,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  const textfieldClasses = useTextFieldStyles();
  const [anchorEl, setAnchorEl] = useState();

  return (
    <div
      className={classNames({
        [classes.root]: labelPlacement,
        [classes.left]: labelPlacement === "left",
      })}
    >
      {labelPlacement && (
        <Typography
          variant="h5"
          gutterBottom
          className={classNames({
            [classes.leftLabel]: labelPlacement === "left",
            [classes.multiline]: multiline && labelPlacement === "left",
            [classes.topLabel]: labelPlacement === "top",
          })}
          {...labelProps}
        >
          {label}
          {required && "*"}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Help fontSize="small" className={classes.help} />
            </Tooltip>
          )}
        </Typography>
      )}
      <FormControl fullWidth>
        <Grid container>
          <Grid item xs={8}>
            <FilledInput
              placeholder={labelPlacement ? "" : label}
              classes={textfieldClasses}
              error={!!error}
              multiline={multiline}
              fullWidth
              value={value}
              onChange={onChange}
              {...props}
            />
            {error && <FormHelperText error>{helperText}</FormHelperText>}
          </Grid>
          <Grid item xs={4}>
            <div className={classes.colorBox} style={{ backgroundColor: value }} onClick={e => setAnchorEl(e.currentTarget)} />

            <Popover
              classes={{ paper: classes.popover }}
              open={anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl()}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <SketchPicker color={value} disableAlpha width="unset" presetColors={[]} onChange={onChange} />
            </Popover>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
};

ColorPickerTextField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["top", "left"]),
};

export default ColorPickerTextField;
