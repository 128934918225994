export default ({ spacing, palette, typography }) => ({
  root: {
    margin: 0,
  },
  div: {
    margin: 12,
    height: "calc(100% - 34px)",
  },
  cloudUpload: {
    width: 60,
    height: 60,
  },
  dragndrop: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
    color: typography.color,
    cursor: "pointer",
  },
  buttonGroup: {
    paddingRight: 20,
  },
  addButton: {
    width: "100%",
    textAlign: "center",
  },
  button: {
    marginLeft: 10,
  },
  specs: {
    margin: 12,
  },
  grid: {
    border: `solid 1px ${palette.background.default}`,
    borderRadius: 4,
    marginLeft: 4,
    marginRight: 4,
  },
  certificateSection: {
    marginTop: 14,
  },
  fullHeight: {
    height: "100%",
  },
  uploadImageClose: {
    position: "absolute",
    top: -10,
    right: -10,
  },
  uploadImageContainer: {
    marginTop: spacing(1),
  },
  uploadImageContainerItem: {
    position: "relative",
    padding: 10,
    marginTop: 5,
    overflowWrap: "anywhere",
  },
  uploadImage: {
    width: "unset",
    height: "unset",
  },
  lottieDiv: {
    width: "60%",
  },
});
