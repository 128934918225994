import { Divider, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddCircleRounded, Delete } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import CollapseCard from "components/CollapseCard";
import ColorPickerTextField from "components/ColorPickerTextField";
import TextField from "components/TextField";
import UploadTextField from "components/UploadTextField";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const useStyles = makeStyles(({ palette, typography }) => ({
  addButton: {
    width: "unset",
    textAlign: "center",
    border: `1px dashed ${palette.background.dark}`,
    marginTop: 13,
    marginBottom: 13,
    color: typography.color,
    borderRadius: 4,
    "&:hover": {
      cursor: "pointer",
      borderColor: palette.primary.main,
      color: palette.primary.main,
    },
  },
  addText: {
    textAlign: "left",
    padding: 14,
    color: "inherit",
  },
  event: {
    marginTop: 12,
  },
  eventItem: {
    marginLeft: 12,
    marginRight: 12,
    alignItems: "center",
  },
  document: {
    marginLeft: 130,
    marginRight: 12,
  },
  eventHeader: {
    paddingRight: 12,
  },
}));

const Events = ({
  id,
  title,
  timeline_color,
  defaultColor,
  events,
  onChange,
  onColorChange,
  onEventChange,
  onMediaChange,
  onDocumentChange,
  onDocumentDescChange,
  onDelete,
  onDeleteEvent,
  onDeleteDocument,
  onMediaPaste,
  addDocument,
  addEvent,
}) => {
  const { t } = useTranslation();
  const { errors, register } = useForm();
  const classes = useStyles();
  const [collapse, setCollapse] = useState();

  return (
    <CollapseCard onDelete={onDelete} collapse={collapse} handleCollapse={() => setCollapse(!collapse)} draggable title={t("Labels.Events")}>
      <TextField
        labelPlacement="left"
        name="title"
        inputRef={register}
        label={t("Labels.Heading Title")}
        value={title}
        placeholder={"Events"}
        onChange={onChange}
      />

      <ColorPickerTextField
        labelPlacement="left"
        name="timeline_color"
        inputRef={register}
        label={t("Labels.Timeline Color")}
        value={timeline_color}
        placeholder={defaultColor}
        onChange={onColorChange("timeline_color")}
      />
      <Divider />

      {events.map((event, i) => (
        <div className={classes.event}>
          <Grid container className={classNames(classes.eventItem, classes.eventHeader)}>
            <Grid item xs={11}>
              <Typography variant="h3">{event.title || `${t("Labels.Event")} ${i + 1}`}</Typography>
            </Grid>

            <Grid item xs={1}>
              <IconButton color="primary" onClick={() => onDeleteEvent(i)}>
                <Delete fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>

          <TextField
            labelPlacement="left"
            name="title"
            inputRef={register}
            label={t("Labels.Event Title")}
            value={event.title}
            placeholder={t("Labels.Event Title")}
            onChange={e => onEventChange(i, e)}
          />

          <UploadTextField
            id={`media-${id}-${i}`}
            name="media"
            inputRef={register}
            label={t("Labels.Media Option")}
            buttonText={t("Labels.Upload")}
            helperText={errors.media ? t("HelperText.Please upload a document") : t("HelperText.JPG or PNG formats | Size must be 512px X 512px")}
            labelPlacement="left"
            accept="image/*"
            type="file"
            linkPath={`${apiURL}/files/${event.media?.hash}${event.media?.ext}`}
            documentName={event.media?.name}
            onChange={onMediaChange(i)}
            enable
            placeholder={t("Paste URL or Upload File")}
            onInputChange={onMediaPaste(i)}
            inputValue={event.media}
          />

          <TextField
            labelPlacement="left"
            multiline
            rows={4}
            name="description"
            inputRef={register}
            label={t("Labels.Description")}
            value={event.description}
            placeholder={t("Labels.Description")}
            onChange={e => onEventChange(i, e)}
          />

          {event.documents.map((document, y) => (
            <>
              {/* <TextField
                id={`document${i}-${y}-desc`}
                label={t("Labels.Document Description")}
                labelPlacement="left"
                name={`description${i}-${y}`}
                inputRef={register}
                value={document.description}
                placeholder={`${t("Labels.Document")} #${y + 1}`}
                onChange={e => onDocumentDescChange(i, y, e)}
              /> */}
              <UploadTextField
                name="logo"
                inputRef={register}
                label={document.description}
                id={`document-${id}-${i}-${y}`}
                onChange={onDocumentChange(i, y)}
                buttonText={t("Labels.Upload")}
                helperText={errors.media ? t("HelperText.Please upload a document") : t("HelperText.Max File 2MB")}
                labelPlacement="left"
                linkPath={`${apiURL}/files/${document.hash}${document.ext}`}
                documentName={document.name}
                onDelete={() => onDeleteDocument(i, y)}
                editableLabel
                onLabelChange={e => onDocumentDescChange(i, y, e)}
                labelPlaceholder={`${t("Labels.Document")} #${y + 1}`}
                enable
                placeholder={t("Paste URL or Upload File")}
                onInputChange={onDocumentChange(i, y)}
                inputValue={document}
              />
              {/* <Divider className={classes.document} /> */}
            </>
          ))}

          <Grid container className={classNames(classes.addButton, classes.document)} onClick={() => addDocument(i)}>
            <Grid item xs={5}>
              <Typography variant="h4" className={classes.addText}>
                {t("Labels.Add Document")}
              </Typography>
            </Grid>
            <Tooltip placement="right" title={t("Labels.Add Document")}>
              <IconButton>
                <AddCircleRounded fontSize="large" color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Divider />
        </div>
      ))}

      <Grid container className={classes.addButton} onClick={() => addEvent()}>
        <Grid item xs={5}>
          <Typography variant="h4" className={classes.addText}>
            {t("Labels.Add Event")}
          </Typography>
        </Grid>
        <Tooltip placement="right" title={t("Labels.Add Event")}>
          <IconButton>
            <AddCircleRounded fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
      </Grid>
    </CollapseCard>
  );
};

Events.propTypes = {
  onDelete: PropTypes.func,
  onDeleteEvent: PropTypes.func,
};

export default Events;
