import { makeStyles, Box } from "@material-ui/core";
import verifyStyle from "assets/jss/components/verifyStyle";
import React from "react";
import Default from "./Default";
import Header from "./Header";
import Sensor from "./Sensor";
import Footer from "./Footer";

const useStyles = makeStyles(verifyStyle);

function Verify({ verified, verifiedBusiness, verifiedTrademark, delivered, productJourney, logEnded, customization, expired, ...props }) {
  const classes = useStyles();

  return (
    <>
      <Header
        customization={customization}
        delivered={delivered}
        logEnded={logEnded}
        verifiedBusiness={verifiedBusiness}
        verifiedTrademark={verifiedTrademark}
        verified={verified}
        expired={expired}
      />

      <Box className={classes.root}>
        <Default
          verified={verified}
          verifiedBusiness={verifiedBusiness}
          verifiedTrademark={verifiedTrademark}
          delivered={delivered}
          logEnded={logEnded}
          customization={customization}
          expired={expired}
          {...props}
        />
        {!expired && !!(productJourney?.sensorsData?.length || Object.keys(productJourney?.logisticSensorData || {})?.length) && (
          <Sensor {...productJourney} delivered={delivered} />
        )}
        <Footer />
      </Box>
    </>
  );
}

export default Verify;
