const footerStyle = ({ palette }) => ({
  a: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  footer: {
    size: 21,
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    height: 20,
    width: 20,
  },
  brand: {
    color: "black",
    fontWeight: 600,
  },
  tag: {
    color: "black",
    fontWeight: 300,
  },
  paper: {
    marginTop: 14,
    marginBottom: 14,
    borderRadius: 18,
    padding: 12,
    border: "unset",
  },
});
export default footerStyle;
