import { combineReducers, createStore } from "redux";
import announcement from "./reducers/announcement";
import environment from "./reducers/environment";
import user from "./reducers/user";

const store = createStore(
  combineReducers({ announcement, environment, user }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
