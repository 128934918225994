import api from ".";

const path = "/order";

const getAll = params => api.get(`${path}`, { params });

const get = id => api.get(`${path}/${id}`);

const getTotal = () => api.get(`${path}/total`);

const create = data => api.post(`${path}`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

export default {
  getAll,
  getTotal,
  get,
  create,
  update,
  remove,
};
