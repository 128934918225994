import { IconButton, makeStyles, Paper, Snackbar } from "@material-ui/core";
import { CheckRounded, ClearRounded } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import BillingApi from "api/billing";
import classNames from "classnames";
import Button from "components/Button";
import Summary from "components/Card/BillingSummary";
import MaterialTable from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { decodeJWTPayload, formatNumber } from "utils";

const Container = props => <Paper elevation={0} {...props} />;

const useStyles = makeStyles(() => ({
  emptyDataDiv: {
    padding: 12,
  },
  trialButton: {
    padding: 3,
    paddingBottom: 5,
    minWidth: 74,
  },
  alert: {
    alignItems: "center",
    textAlign: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
}));

function BillingTable({ plans, merchant }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [billings, setBillings] = useState([]);
  const [refresh, setRefresh] = useState();
  const [showAlert, setShowAlert] = useState();
  const jwtToken = useSelector(_ => _.user.jwtToken);
  const { isAdmin } = decodeJWTPayload(jwtToken);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    BillingApi.getAll(merchant && { merchant }).then(res => {
      setBillings(res);
    });
  }, [merchant, refresh]);

  const handleConfirmation = () => setShowAlert(true);

  const onConfirm = () => {
    BillingApi.startTrial({ merchant }).then(res => {
      setRefresh(!refresh);
      setShowAlert();
    });
  };
  const onCancel = () => setShowAlert();

  const onRowAdd = newData =>
    new Promise((resolve, reject) =>
      BillingApi.create({ ...newData, merchant })
        .then(res => {
          resolve();
          setRefresh(!refresh);
        })
        .catch(e => {
          reject();
          enqueueSnackbar(e, {
            variant: "error",
          });
        })
    );

  const calculateValidity = (startedAt, planId) => {
    const { validity_unit, validity } = plans.find(i => i.id === planId) || {};
    const deadline =
      validity_unit === "day"
        ? new Date(startedAt.getFullYear(), startedAt.getMonth(), startedAt.getDate() + validity)
        : validity_unit === "month"
        ? new Date(startedAt.getFullYear(), startedAt.getMonth() + validity, startedAt.getDate())
        : validity_unit === "year"
        ? new Date(startedAt.getFullYear() + validity, startedAt.getMonth(), startedAt.getDate())
        : new Date();
    return deadline.toLocaleDateString([], { day: "numeric", month: "short", year: "numeric" });
  };

  return (
    <>
      <Summary
        merchant={merchant}
        expiryDate={calculateValidity(new Date(billings[0]?.createdAt), billings[0]?.planId)}
        totalCodes={billings.reduce((a, { planId }) => (plans.find(i => i.id === planId)?.codes || 0) + a, 0)}
        hasSwitch
      ></Summary>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={showAlert}>
        <Alert
          classes={{ root: classNames(classes.alert) }}
          variant="filled"
          action={
            <>
              <IconButton color="inherit" size="small" onClick={onConfirm}>
                <CheckRounded />
              </IconButton>
              <IconButton color="inherit" size="small" onClick={onCancel}>
                <ClearRounded />
              </IconButton>
            </>
          }
        >
          {t(`Are you sure you want to start trial for merchant ${merchant}?`)}
        </Alert>
      </Snackbar>

      <MaterialTable
        options={{
          filter: true,
          showTitle: false,
          toolbar: true,
          emptyRowsWhenPaging: false,
          rowStyle: {
            fontSize: "12px",
          },
          actionsColumnIndex: -1,
          cellStyle: {
            borderBottom: 0,
          },
        }}
        localization={{
          body: {
            emptyDataSourceMessage: isAdmin ? (
              <div className={classes.emptyDataDiv}>
                {t("Looks like there are no billing history for this merchant yet.")}
                <Button
                  align="center"
                  onClick={handleConfirmation}
                  className={classes.trialButton}
                  variant={null}
                  buttonText="Click here"
                  size="small"
                />
                {t("to start trial.")}
              </div>
            ) : (
              t("Looks like you have no billing history yet.")
            ),
          },
        }}
        components={{
          Container,
        }}
        columns={[
          {
            title: t("Labels.Plan Name"),
            align: "center",
            field: "planId",
            lookup: plans.reduce((acc, { id, title }) => {
              acc[id] = title;
              return acc;
            }, {}),
            render: ({ planId }) => plans.find(i => i.id === planId)?.title,
          },
          {
            title: t("Labels.Code Amount"),
            align: "center",
            field: "codes",
            editComponent: ({ rowData }) => formatNumber(plans.find(i => i.id === Number(rowData?.planId))?.codes || 0),
            render: ({ planId }) => formatNumber(plans.find(i => i.id === planId)?.codes),
          },
          {
            title: t("Labels.Currency"),
            align: "center",
            field: "currency",
          },
          {
            title: t("Labels.Price"),
            field: "price_create",
            align: "center",
            render: ({ price_create }) => formatNumber(price_create),
          },
          // {
          //   title: t("Labels.Valid From"),
          //   align: "center",
          //   field: "validFrom",
          //   editComponent: () => new Date().toLocaleDateString([], { day: "numeric", month: "short", year: "numeric" }),
          //   render: ({ createdAt }) => new Date(createdAt).toLocaleDateString([], { day: "numeric", month: "short", year: "numeric" }),
          // },
          // {
          //   title: t("Labels.Valid Until"),
          //   align: "center",
          //   field: "validUntil",
          //   editComponent: ({ rowData }) => calculateValidity(new Date(), Number(rowData?.planId)),
          //   render: ({ createdAt, planId }) => calculateValidity(new Date(createdAt), planId),
          // },
          {
            title: t("Labels.Description"),
            align: "center",
            field: "description",
          },
        ]}
        data={billings}
        editable={
          isAdmin && {
            onRowAdd,
          }
        }
      />
    </>
  );
}

export default BillingTable;
