import PageContent from "components/PageContent";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ItemsList from "./ItemsList";
import Table from "./Table";
import TempLog from "./TempLog";

function Forms() {
  const { t } = useTranslation();
  const [itemsList, setItemsList] = useState();
  const [tempLog, setTempLog] = useState();

  return (
    <PageContent
      header={itemsList ? t("Labels.List of Items") : tempLog ? `${t("Labels.View Log")}: ${tempLog.manufacturer_id}` : t("Labels.Logistics")}
    >
      {itemsList ? (
        <ItemsList logisticId={itemsList.id} resetItemsList={() => setItemsList()} />
      ) : tempLog ? (
        <TempLog sensorId={tempLog.manufacturer_id} resetTempLog={() => setTempLog()} />
      ) : (
        <Table setItemsList={setItemsList} setTempLog={setTempLog} />
      )}
    </PageContent>
  );
}

export default Forms;
