import { Checkbox, FormControlLabel, Grid, Typography } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles } from "@material-ui/styles";
import { light } from "assets/jss";
import Button from "components/Button";
import PageContent from "components/PageContent";
import React, { useState } from "react";
import { CirclePicker as ColorPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, changePrimaryColor, changeSecondaryColor, changeTheme, changeToolbarColor } from "redux/actions/environment";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    position: "fixed",
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  paper: {
    boxShadow: theme.shadows[3],
    top: 140,
    height: 480,
    border: "1px solid " + theme.palette.divider,
    overflowY: "hidden",
    maxHeight: "60vh",
    [theme.breakpoints.down("sm")]: {
      overflowY: "hidden",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    padding: 24,
  },
}));

function Preferences() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [darkBl0ckCh41n, setDarkBl0ckCh41n] = useState(true);
  const { primaryColor, secondaryColor } = light;
  const {
    theme: _theme,
    toolbarColor,
    primaryColor: _primaryColor = primaryColor,
    secondaryColor: _secondaryColor = secondaryColor,
  } = useSelector(_ => _.environment);
  const { t, i18n } = useTranslation();

  return (
    <PageContent header={t("Labels.Preferences")}>
      <div className={classes.container}>
        <Grid item>
          {process.env.NODE_ENV === "development" && (
            <Grid item className={classes.item}>
              <Typography variant="subtitle1">{t("Labels.Theme")}</Typography>
              <div>
                <ToggleButtonGroup value={_theme} exclusive onChange={(e, theme) => dispatch(changeTheme(theme))}>
                  <ToggleButton value="bnw">{t("Labels.B & W")}</ToggleButton>
                  <ToggleButton value="light">{t("Labels.Light")}</ToggleButton>
                  <ToggleButton value="dark">{t("Labels.Dark")}</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </Grid>
          )}
          <Grid item className={classes.item}>
            <Typography variant="subtitle1">{t("Labels.Language")}</Typography>
            <div>
              <ToggleButtonGroup
                value={i18n.language}
                exclusive
                onChange={(e, lang) => {
                  i18n.changeLanguage(lang);
                  dispatch(changeLanguage(lang));
                }}
              >
                <ToggleButton value="enUS">English</ToggleButton>
                <ToggleButton value="ruRU">русский язык</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
          {process.env.NODE_ENV === "development" && (
            <>
              <Grid item className={classes.item}>
                <Typography variant="subtitle1">{t("Labels.App Bar Color")}</Typography>
                <div>
                  <ToggleButtonGroup value={toolbarColor} exclusive onChange={(e, toolbarColor) => dispatch(changeToolbarColor(toolbarColor))}>
                    <ToggleButton value="inherit">{t("Labels.inherit")}</ToggleButton>
                    <ToggleButton value="primary">{t("Labels.primary")}</ToggleButton>
                    <ToggleButton value="secondary">{t("Labels.Secondary")}</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Grid>
              <Grid item className={classes.item}>
                <Typography variant="subtitle1">{t("Labels.Primary Color")}</Typography>
                <ColorPicker
                  color={_primaryColor}
                  onChange={({ hex }) => {
                    dispatch(changePrimaryColor(hex));
                  }}
                />
              </Grid>
              <Grid item className={classes.item}>
                <Typography variant="subtitle1">{t("Labels.Secondary Color")}</Typography>
                <ColorPicker
                  color={_secondaryColor}
                  onChange={({ hex }) => {
                    dispatch(changeSecondaryColor(hex));
                  }}
                />
              </Grid>
              <Grid item className={classes.item}>
                <Button
                  onClick={e => {
                    dispatch(changeTheme());
                    dispatch(changeToolbarColor());
                    dispatch(changePrimaryColor());
                    dispatch(changeSecondaryColor());
                  }}
                  buttonText={t("Labels.Reset")}
                />
              </Grid>
              <Grid item className={classes.item}>
                {_theme === "dark" && (
                  <FormControlLabel
                    control={<Checkbox checked={darkBl0ckCh41n} onChange={() => setDarkBl0ckCh41n(!darkBl0ckCh41n)} color="primary" />}
                    label="Dark Blockchain"
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </PageContent>
  );
}

export default Preferences;
