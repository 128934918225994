const headerStyle = ({ zIndex, transitions, mixins }) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: zIndex.drawer + 1,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
    transition: transitions.create(["width", "margin"], {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    width: "100%",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 12px",
    ...mixins.toolbar,
  },
  logo: {
    height: 40,
    width: 40,
  },
  logoDiv: {
    display: "contents",
    fontSize: 22,
    color: "inherit",
    "&:hover": {
      textDecoration: "unset",
    },
  },
  brand: {
    fontWeight: 600,
  },
  tag: {
    fontWeight: 300,
  },
});

export default headerStyle;
