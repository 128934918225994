const announcement = (
  state = {
    list: [
    ]
  },
  action
) => {
  switch (action.type) {
    case "ADD_ANNOUNCEMENT":
      return {
        ...state,
        list: [...state.list, action.announcement],
      };
    default:
      return state;
  }
};

export default announcement;
