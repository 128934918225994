import { fade } from "@material-ui/core/styles/colorManipulator";
import { bannerHeight, demoHeight } from "..";
const headerStyle = ({ zIndex, transitions, mixins, breakpoints, shape, palette, spacing }) => {
  return {
    root: {
      zIndex: zIndex.drawer + 1,
    },
    grow: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: zIndex.drawer + 1,
      boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)",
      transition: transitions.create(["width", "margin"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.leavingScreen,
      }),
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 12px",
      ...mixins.toolbar,
    },
    menuButton: {
      marginLeft: 2,
      marginRight: 30,
      [breakpoints.down("sm")]: {
        marginRight: 2,
        width: 50,
      },
    },
    hide: {
      display: "block",
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    title: {
      fontSize: 42,
      fontWeight: "bold",
      [breakpoints.down("sm")]: {
        fontSize: 32,
      },
    },
    search: {
      position: "relative",
      borderRadius: shape.borderRadius,
      backgroundColor: fade(palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(palette.common.white, 0.25),
      },
      marginRight: spacing(2),
      marginLeft: 0,
      width: "100%",
      [breakpoints.up("sm")]: {
        marginLeft: spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      width: spacing(9),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      paddingTop: spacing(1),
      paddingRight: spacing(1),
      paddingBottom: spacing(1),
      paddingLeft: spacing(10),
      transition: transitions.create("width"),
      width: "100%",
      [breakpoints.up("md")]: {
        width: 200,
      },
    },
    sectionDesktop: {
      display: "none",
      [breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [breakpoints.up("md")]: {
        display: "none",
      },
    },
    avatar: {
      width: 32,
      height: 32,
    },
    menuItem: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    walletDetails: {
      marginLeft: 8,
    },
    hiddenWallet: {
      display: "none",
    },
    logo: {
      paddingLeft: 28,
    },
    bannerRoot: {
      cursor: "pointer",
      display: "grid",
      placeItems: "center",
      textAlign: "center",
    },
    banner: {
      height: bannerHeight,
    },
    demo: {
      height: demoHeight,
    },
    text: {
      color: palette.background.paper,
      fontSize: 16,
    },
    div: {
      flex: 1,
      padding: 0,
    },
    error: {
      backgroundColor: palette.error.dark,
    },
    warning: {
      backgroundColor: palette.warning.dark,
    },
    primary: {
      backgroundColor: palette.primary.main,
    },
    close: {
      color: palette.background.paper,
      position: "absolute",
      right: "1%",
    },
    walletIcon: {
      fontSize: "2.5rem",
    },
    wallet: {
      display: "flex",
      marginRight: 32,
    },
    cb: {
      fontSize: 12,
    },
    cbv: {
      fontSize: 16,
      fontWeight: "bold",
    },
    cbve: {
      color: palette.error.main,
    },
    bold: {
      fontWeight: "bold",
    },
  };
};

export default headerStyle;
