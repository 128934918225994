import { Grid, makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { setDemo } from "redux/actions/user";
import GettingStarted from "./GettingStarted";
import Welcome from "./Welcome";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 40,
    width: 800,
  },
  link: {
    padding: 10,
    color: palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

function Onboarding() {
  const classes = useStyles();
  const [welcome, setWelcome] = useState(true);
  const [finish, setFinish] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <Paper className={classes.root}>
        {welcome ? <Welcome next={() => setWelcome(false)} /> : <GettingStarted finish={() => setFinish(true)} />}
      </Paper>
      {!welcome && !finish && (
        <Grid container justify="center">
          <NavLink to="/" className={classes.link} onClick={() => dispatch(setDemo(true))}>
            <div>{t("Labels.Go to Demo Mode")}</div>
          </NavLink>
          <NavLink to="/" className={classes.link}>
            <div>{t("Labels.Skip this first")}</div>
          </NavLink>
        </Grid>
      )}
    </>
  );
}

export default Onboarding;
