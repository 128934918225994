import { useTheme } from "@material-ui/core";
import React from "react";

export default function Logo({ color, ...props }) {
  const { palette } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="99.539" height="100.26" viewBox="0 0 99.539 100.26" {...props}>
      <path
        d="M20.918 28.21l-.057-.775.7-.335c1.515-.727 3.08-1.409 4.655-2.026a76.931 76.931 0 0 1 21.5-5.116V1.773a83.227 83.227 0 0 0-22.659 4.246 93.046 93.046 0 0 0-19.512 9.524c-1.085.671-1.886 1.209-2.334 1.516a92.031 92.031 0 0 0 1.025 24.37 83.285 83.285 0 0 0 7.4 21.834l15.853-8.909a76.373 76.373 0 0 1-6.066-21.242c-.21-1.556-.377-3.159-.505-4.902z"
        transform="translate(-2.831 -1.773) translate(2.831 1.773) translate(-2.831 -1.773)"
      />
      <path
        fill={color || palette.primary.main}
        d="M96.45 17.061c-.462-.315-1.3-.871-2.409-1.565l-.072-.045-.029-.018-.035-.022A92.633 92.633 0 0 0 74.6 6.019a83.232 83.232 0 0 0-22.661-4.246v18.184a76.918 76.918 0 0 1 21.5 5.116c1.527.6 3.094 1.278 4.656 2.028l.7.334-.056.775c-.125 1.725-.292 3.327-.51 4.9a76.383 76.383 0 0 1-6.065 21.243l15.856 8.91a83.3 83.3 0 0 0 7.4-21.834 92.1 92.1 0 0 0 1.03-24.368z"
        transform="translate(-2.831 -1.773) translate(2.831 1.773) translate(2.711 -1.773)"
      />
      <path
        d="M55.895 71.991c-1.305 1.038-2.683 2.049-4.09 3l-.632.427-.637-.424c-1.315-.877-2.659-1.857-3.992-2.912a76.649 76.649 0 0 1-15.271-15.971l-15.7 9.189a83.685 83.685 0 0 0 15.111 17.424A92.257 92.257 0 0 0 51.2 95.911c.512-.244 1.431-.692 2.619-1.325l.44-.225a92.136 92.136 0 0 0 17.508-11.739A83.024 83.024 0 0 0 86.868 65.2l-15.7-9.183a76.619 76.619 0 0 1-15.273 15.974z"
        transform="translate(-2.831 -1.773) translate(2.831 1.773) translate(-1.393 4.349)"
      />
    </svg>
  );
}
