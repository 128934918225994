import { FilledInput, Grid, IconButton, Typography } from "@material-ui/core";
import { Delete, Help } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/textfield";
import { Tooltip } from "chart.js";
import classNames from "classnames";
import UploadButton from "components/UploadButton";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

export const truncateFilename = (fileName, config) => {
  const { maxLength = 30 } = config || {};
  if (fileName && fileName.length > maxLength) {
    const startLength = Math.floor((maxLength - 3) / 2);
    const endLength = Math.ceil((maxLength - 3) / 2);
    const truncatedName = fileName.substring(0, startLength) + "..." + fileName.substring(fileName.length - endLength);
    return truncatedName;
  }

  return fileName;
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    padding: 12,
  },
  bold: {
    fontWeight: "bold",
  },
  crossed: {
    textDecoration: "line-through",
  },
  left: {
    display: "flex",
  },
  leftLabel: {
    width: 152,
    alignSelf: "center",
  },
  topLabel: {
    height: 23,
    lineHeight: 1.63,
  },
  action: {
    display: "flex",
  },
}));

const UploadSelect = ({
  buttonText,
  selectOptions,
  documentName,
  error,
  helperText,
  index,
  label,
  labelPlacement,
  linkPath,
  onDelete,
  onUpload,
  onChange,
  required,
  tooltipText,
  value,
  id,
  ...props
}) => {
  const classes = useStyles();
  const useTextFieldStyles = makeStyles(textfieldStyles);
  const textfieldClasses = useTextFieldStyles();
  const onUploadChange = event => {
    if (onUpload) onUpload(index)(event.target.files[0]);
  };
  return (
    <div
      className={classNames({
        [classes.root]: labelPlacement,
        [classes.left]: labelPlacement === "left",
      })}
    >
      <Typography
        variant="h5"
        gutterBottom
        className={classNames(classes.label, {
          [classes.leftLabel]: labelPlacement === "left",
          [classes.topLabel]: labelPlacement === "top",
        })}
      >
        {label}
        {required && "*"}
        {tooltipText && (
          <Tooltip placement="right" title={tooltipText}>
            <Help fontSize="small" className={classes.help} />
          </Tooltip>
        )}
      </Typography>
      <Grid container>
        <Grid item sm={8}>
          {/* <Select
            fullWidth
            value={value}
            defaultValue={value}
            onChange={onChange}
            variant="filled"
            input={<FilledInput classes={textfieldClasses} />}
            {...props}
          >
            {selectOptions?.map(o => (
              <MenuItem value={o}>{o}</MenuItem>
            ))}
          </Select> */}
          <FilledInput classes={textfieldClasses} disabled fullWidth value={truncateFilename(documentName) || ""} />
          {/* {documentName ? (
            <FormHelperText>
              {(file ? t("Replace document") : t("Uploaded document")) + `: `}
              <A
                className={{
                  [classes.crossed]: file,
                }}
                href={linkPath}
              >
                {documentName}
              </A>
              {file && <span className={classes.bold}>&nbsp;→&nbsp;{file}</span>}
            </FormHelperText>
          ) : file ? (
            <FormHelperText>
              {t("New document") + ": "} <span className={classes.bold}>{file}</span>
            </FormHelperText>
          ) : (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )} */}
        </Grid>
        <Grid item sm={3}>
          <UploadButton
            id={id || "upload" + index}
            buttonText={buttonText}
            documentName={documentName}
            error={error}
            helperText={helperText}
            onChange={onUploadChange}
            required
            index={index}
          />
        </Grid>
        <Grid item sm={1}>
          <IconButton color="primary" onClick={onDelete}>
            <Delete />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadSelect;
