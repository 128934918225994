import DateFnsUtils from "@date-io/date-fns";
import { FilledInput, FormControl, FormHelperText, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/textfield";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { getLocaleDateFormat } from "utils/date";

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
  },
  left: {
    display: "flex",
  },
  leftLabel: {
    width: 150,
    alignSelf: "center",
  },
  topLabel: {
    height: 23,
    lineHeight: 1.63,
  },
  help: {
    marginLeft: 4,
  },
  multiline: {
    alignSelf: "unset",
    paddingTop: 12,
  },
  transparentRoot: {
    borderRadius: 4,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&$focused": {
      backgroundColor: "transparent",
    },
  },
  transparent: {
    backgroundColor: "transparent",
  },
}));

const useTextFieldStyles = makeStyles(textfieldStyles);

const TextField = ({
  label,
  required,
  tooltipText,
  labelPlacement,
  helperText,
  multiline,
  error,
  labelProps,
  variant,
  transparent,
  type,
  ...props
}) => {
  const classes = useStyles();
  const textfieldClasses = useTextFieldStyles();

  return (
    <div
      className={classNames({
        [classes.root]: labelPlacement,
        [classes.left]: labelPlacement === "left",
      })}
    >
      {labelPlacement && (
        <Typography
          variant={variant || "h5"}
          gutterBottom
          className={classNames(classes.label, {
            [classes.leftLabel]: labelPlacement === "left",
            [classes.multiline]: multiline && labelPlacement === "left",
            [classes.topLabel]: labelPlacement === "top",
          })}
          {...labelProps}
        >
          {label}
          {required && "*"}
          {tooltipText && (
            <Tooltip placement="right" title={tooltipText}>
              <Help fontSize="small" className={classes.help} />
            </Tooltip>
          )}
        </Typography>
      )}
      <FormControl fullWidth>
        {type === "datetime" ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              InputProps={{ classes: textfieldClasses }}
              autoOk
              variant="inline"
              inputVariant="filled"
              format={getLocaleDateFormat() + " HH:mm"}
              InputAdornmentProps={{ position: "end" }}
              ampm={false}
              {...props}
            />
          </MuiPickersUtilsProvider>
        ) : type === "date" ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              InputProps={{ classes: textfieldClasses }}
              autoOk
              variant="inline"
              inputVariant="filled"
              format={getLocaleDateFormat()}
              InputAdornmentProps={{ position: "end" }}
              ampm={false}
              {...props}
            />
          </MuiPickersUtilsProvider>
        ) : (
          <FilledInput
            placeholder={labelPlacement ? "" : label}
            classes={{
              ...textfieldClasses,
              root: transparent ? classes.transparentRoot : textfieldClasses.root,
              focused: transparent && classes.focused,
            }}
            fullWidth
            error={!!error}
            multiline={multiline}
            type={type}
            {...props}
          />
        )}
        {error && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.oneOf(["top", "left"]),
};

export default TextField;
