import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography, useTheme } from "@material-ui/core";
import { DeleteRounded, EditRounded, MoreHoriz } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import FormTemplateApi from "api/formTemplate";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  itemIcon: {
    minWidth: 40,
  },
}));

const useToolBarStyles = makeStyles(mtoolbarstyle);
const Container = props => <Paper elevation={0} {...props} />;

function Table({ setData }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const [formsList, setFormsList] = useState([]);
  const [anchorEl, setAnchorEl] = useState();
  const [selectedRowData, setSelectedRowData] = useState({});
  const toolbarClasses = useToolBarStyles();
  const { enqueueSnackbar } = useSnackbar();

  const Title = () => <Typography variant="h2">{t("Labels.List of Forms")}</Typography>;
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;

  const handleClick = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData);
    event.stopPropagation();
  };

  const handleClose = () => setAnchorEl();

  const callData = useCallback(() => {
    FormTemplateApi.getAll()
      .then(res => {
        setFormsList(res);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      });
  }, [enqueueSnackbar]);

  const handleDelete = () => {
    FormTemplateApi.remove(selectedRowData.id)
      .then(res => {
        callData();
        setAnchorEl();
        enqueueSnackbar(res.message);
      })
      .catch(e => {
        enqueueSnackbar(e, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    callData();
  }, [callData]);

  return (
    <>
      <MaterialTable
        options={{
          toolbar: true,
          draggable: false,
          detailPanelColumnAlignment: "left",
          emptyRowsWhenPaging: false,
          headerStyle: {
            backgroundColor: palette.background.default,
            border: "3px solid",
            borderColor: palette.background.default,
          },
          rowStyle: {
            border: "3px solid",
            borderColor: palette.background.default,
          },
          cellStyle: {
            borderBottom: 0,
          },
        }}
        components={{
          Container,
          Toolbar,
        }}
        title={Title()}
        columns={[
          {
            title: t("Labels.Date Created"),
            align: "center",
            field: "createdAt",
            render: rowData =>
              new Date(rowData.createdAt).toLocaleDateString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
              }),
          },
          {
            title: t("Labels.Template Name"),
            align: "center",
            field: "name",
          },
          {
            title: t("Labels.Action"),
            field: "action",
            sorting: false,
            render: rowData => (
              <IconButton onClick={e => handleClick(e, rowData)}>
                <MoreHoriz />
              </IconButton>
            ),
          },
        ]}
        data={formsList}
      />
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setData(selectedRowData)}>
          <ListItemIcon className={classes.itemIcon}>
            <EditRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Labels.Edit")} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDelete}>
          <ListItemIcon className={classes.itemIcon}>
            <DeleteRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t("Labels.Delete")} />
        </MenuItem>
      </Menu>
    </>
  );
}

export default Table;
