import api from ".";

const path = "/trademark";

const getAll = () => api.get(`${path}`);

const get = id => api.get(`${path}/${id}`);

const create = data => api.post(`${path}`, data);

const update = (id, data) => api.put(`${path}/${id}`, data);

const updateStatus = (id, data) => api.put(`${path}/status/${id}`, data);

const remove = id => api.delete(`${path}/${id}`);

const count = () => api.get(`${path}/count`);

export default {
  getAll,
  get,
  create,
  update,
  updateStatus,
  remove,
  count,
};
