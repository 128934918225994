import { CircularProgress, Grid, Typography, Box } from "@material-ui/core";
import PageContent from "components/PageContent";
import React from "react";
import HubspotForm from "react-hubspot-form";
import { useTranslation } from "react-i18next";
import Personnel from "./Personnel";

const personnel = [
  {
    name: "BrandTag Support",
    email: "support@brandtag.io",
    phoneNo: "",
    // imgLink: "https://api.hubspot.com/userpreferences/v1/avatar/c936490fef2be977703aaa7b70825a10/100",
    imgLink: "https://i0.wp.com/www.luxtag.io/wp-content/uploads/2020/01/luxtag-single-logo.png?resize=306%2C306",
    // telegram: "keithliew",
  },
  // {
  //   name: "Imane Mourassilo",
  //   email: "imane@brandtag.io",
  //   phoneNo: "",
  //   imgLink: "https://api.hubspot.com/userpreferences/v1/avatar/e0a37147225af39ef2b97e771c4498e4/100",
  //   telegram: "imanemour",
  // },
];

function Credit() {
  const { t } = useTranslation();

  return (
    <PageContent header={t("Labels.Credit Top Up")}>
      <Grid item>
        <Typography variant="h5" gutterBottom style={{ marginBottom: "15px" }}>
          {t("Labels.Talk to our customer experience representative")} :
        </Typography>
        <Grid container>
          {personnel.map((e, i) => (
            <Personnel key={i} {...e} />
          ))}
        </Grid>
        <Box mt={2}>
          <HubspotForm portalId="6182373" formId="ae369a68-968c-4b1f-82b3-f89585d1a5cd" loading={<CircularProgress />} />
        </Box>
      </Grid>
    </PageContent>
  );
}

export default Credit;
