import { Button, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { apiURL } from "api";
import UploadApi from "api/upload";
import mtoolbarstyle from "assets/jss/components/MTableToolbar";
import PageContent from "components/PageContent";
import MaterialTable, { MTableToolbar } from "material-table";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { asyncForEach } from "utils";

const useStyles = makeStyles({
  row: {
    paddingBottom: 12,
  },
  input: {
    display: "none",
  },
});

const useToolBarStyles = makeStyles(mtoolbarstyle);
const Container = props => <Paper elevation={0} {...props} />;

function Media() {
  const classes = useStyles();

  const [mediaList, setMediaList] = useState([]);
  const [uploading, setUploading] = useState();
  const { t } = useTranslation();
  const toolbarClasses = useToolBarStyles();
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;
  const Title = () => <Typography variant="h2">{t("Labels.List of Media")}</Typography>;

  useEffect(() => {
    !mediaList.length && UploadApi.getAll().then(res => setMediaList(res));
  }, [mediaList]);

  const onMediaChange = async event => {
    try {
      const files = event.target.files;
      await asyncForEach(files, async (f, i) => {
        setUploading(t("Uploading") + ` ${i + 1}/${files.length}`);
        await UploadApi.create(f);
      });
    } catch (e) {
      enqueueSnackbar(e, {
        variant: "error",
      });
    } finally {
      setUploading();
    }
  };

  const acceptTypes = ["image/*", "application/pdf"].join(",");
  return (
    <PageContent header={t("Labels.Media")} subheader={t("Labels.Add your product image here")}>
      <Grid container>
        <Grid item className={classes.row}>
          <input accept={acceptTypes} className={classes.input} id="contained-button-file" multiple type="file" onChange={onMediaChange} />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span" disabled={uploading}>
              {uploading || t("Upload Media")}
            </Button>
          </label>
        </Grid>
        <Grid item className={classes.row} xs={12}>
          <MaterialTable
            columns={[
              {
                title: t("Labels.Image"),
                field: "url",
                render: ({ hash, ext }) =>
                  ext === ".pdf" ? (
                    <embed width="100%" height="100%" src={`${apiURL}/files/${hash}${ext}#toolbar=0&navpanes=0&scrollbar=0`} />
                  ) : (
                    <img src={`${apiURL}/files/${hash}${ext}`} alt={hash} width="100%" />
                  ),
              },
              { title: t("Labels.Name"), field: "name", render: item => item.name.split(".").slice(0, -1).join(".") },
              { title: t("Labels.Type"), field: "ext" },
              {
                title: t("Labels.Date"),
                field: "updatedAt",
                render: ({ createdAt }) => new Date(createdAt).toLocaleDateString([], { day: "numeric", month: "short", year: "numeric" }),
              },
            ]}
            data={mediaList}
            title={Title()}
            options={{
              toolbar: true,
              draggable: false,
              detailPanelColumnAlignment: "left",
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: palette.background.default,
                border: "3px solid",
                borderColor: palette.background.default,
              },
              rowStyle: {
                border: "3px solid",
                borderColor: palette.background.default,
              },
              cellStyle: {
                borderBottom: 0,
              },
            }}
            components={{
              Container,
              Toolbar,
            }}
          />
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default Media;
