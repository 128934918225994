import { Box, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import { VerifiedUser } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import sidebarStyle from "assets/jss/components/sidebarStyle";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { calculateProgress } from "utils";

const useStyles = makeStyles(sidebarStyle);

function StatusProgress() {
  const classes = useStyles();
  const progress = useSelector(_ => _.user.progress);
  const { t } = useTranslation();
  const [status, setStatus] = useState();
  const [link, setLink] = useState();
  const { emailConfirmed, businessUpdated, trademarkRegistered, businessApproved, trademarkApproved } = progress;

  useEffect(() => {
    setStatus(() => {
      if (!progress.emailConfirmed) {
        setLink("settings");
        return t("Messages.Verify Your Email");
      } else if (!progress.businessUpdated) {
        setLink("settings/business");
        return t("Messages.Register Your Business");
      } else if (!progress.trademarkRegistered) {
        setLink("brand");
        return t("Messages.Register Your First Brand");
      } else if (!progress.businessApproved) {
        setLink();
        return t("Messages.Waiting Approval");
      } else if (!progress.trademarkApproved) {
        setLink();
        return t("Messages.Waiting Approval");
      } else return "verified";
    });
  }, [progress, t]);

  const progressTooltip = () => (
    <>
      <div className={classes.progressTooltipTitle}>{t("Messages.How to get verified")}</div>
      <div className={classNames({ [classes.done]: emailConfirmed })}>{t("Messages.Email")}</div>
      <div className={classNames({ [classes.done]: businessUpdated })}>{t("Messages.Business Reg")}</div>
      <div className={classNames({ [classes.done]: trademarkRegistered })}>{t("Messages.Brand")}</div>
      <div className={classNames({ [classes.done]: businessApproved })}>{t("Messages.Business Approval")}</div>
      <div className={classNames({ [classes.done]: trademarkApproved })}>{t("Messages.Wait for brand approval")}</div>
      <div>{t("Messages.That's it, verified badge is yours!")}</div>
    </>
  );

  const CircularProgressWithLabel = props => (
    <Tooltip placement="right" title={progressTooltip()}>
      <Box position="relative" display="inline-flex">
        <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress variant="static" size={30} value={100} className={classes.baseProgress} />
        </Box>
        <CircularProgress variant="static" size={30} {...props} />
        <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );

  return (
    <div className={classes.status}>
      {status !== "verified" ? (
        <>
          {link ? (
            <Link className={classes.link} to={link}>
              <div className={classes.verifyText}>{status}</div>
            </Link>
          ) : (
            <div className={classes.verifyText}>{status}</div>
          )}
          <CircularProgressWithLabel value={calculateProgress(progress)} />
        </>
      ) : (
        <>
          {t("Messages.Verified")}&nbsp;
          <VerifiedUser fontSize="small" color="primary" />
        </>
      )}
    </div>
  );
}

StatusProgress.propTypes = {
  open: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  openMobile: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  routes: PropTypes.array.isRequired,
};

export default withRouter(StatusProgress);
