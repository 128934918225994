import React from "react";
import { makeStyles } from "@material-ui/styles";
import footerStyle from "assets/jss/components/verificationFooter";
import A from "components/A";
import Logo from "components/BrandTagLogo";
import { badges } from "./badges";
import { convertToExternalLink } from "utils";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles(footerStyle);

const tag = badges.BrandTag;

function Footer() {
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.footer}>
          Powered by &nbsp;
          <A href={tag.link && convertToExternalLink(tag.link)} className={classes.a}>
            <Logo className={classes.logo} />
            &nbsp;
            <span className={classes.brand}>Brand</span>
            <span className={classes.tag}>Tag</span>
          </A>
        </div>
      </Paper>
    </>
  );
}
export default Footer;
