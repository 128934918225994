import { makeStyles, Paper, useTheme } from "@material-ui/core";
import mtoolbarStyle from "assets/jss/components/MTableToolbar";
import MaterialTable, { MTableToolbar } from "material-table";
import React from "react";
import { useTranslation } from "react-i18next";

const useToolbarStyle = makeStyles(mtoolbarStyle);
const Container = props => <Paper elevation={0} {...props} />;

function DetailTable({ data, title, onRowClick }) {
  const { t } = useTranslation();
  const toolbarClasses = useToolbarStyle();
  const Toolbar = props => <MTableToolbar classes={toolbarClasses} {...props} />;
  const { palette } = useTheme();

  return (
    <MaterialTable
      onRowClick={(e, d) => onRowClick({ lat: d.lat, lng: d.lng })}
      title={title()}
      options={{
        pageSizeOptions: [6],
        search: true,
        pageSize: 6,
        headerStyle: {
          backgroundColor: palette.background.default,
        },
      }}
      components={{
        Container,
        Toolbar,
      }}
      columns={[
        {
          title: t("Labels.SKU"),
          field: "SKU",
        },
        {
          title: t("Labels.Activity"),
          field: "Action",
          grouping: false,
          lookup: { VERIFY: t("Labels.Scan"), CREATION: t("Labels.Activated"), REDIRECT: t("Labels.Redirect") },
        },
        {
          title: t("Labels.Location"),
          field: "Location",
        },
        {
          title: t("Labels.Country"),
          field: "Country",
        },
        { title: t("Labels.Time"), field: "Time", grouping: false },
      ]}
      data={data}
    />
  );
}

export default DetailTable;
