import { Checkbox, Grid, IconButton, MenuItem } from "@material-ui/core";
import { ClearRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import textfieldStyles from "assets/jss/components/textfield";
import TextField from "components/TextField";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../../components/Select";

const useStyles = makeStyles(() => ({
  root: {
    padding: 12,
    display: "flex",
  },
  key: {
    paddingRight: 8,
  },
}));

const useTextFieldStyles = makeStyles(textfieldStyles);
const dataTypes = [
  { label: "Text", value: "string" },
  { label: "Number", value: "number" },
  { label: "Date", value: "date" },
  { label: "Unique No.", value: "unique" },
];

const FormQuestion = ({ index, title, onTitleChange, datatype, onDatatypeChange, share, onShareChange, required, onRequiredChange, onDelete }) => {
  const classes = useStyles();
  const textfieldClasses = useTextFieldStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={5} className={classes.key}>
        <TextField
          classes={textfieldClasses}
          fullWidth
          value={title}
          onChange={e => onTitleChange(e.target.value)}
          name="name"
          placeholder={t("Labels.Question Title") + " #" + (index + 1)}
        />
      </Grid>
      <Grid item xs={5} className={classes.key}>
        <Select onChange={e => onDatatypeChange(e.target.value)} fullWidth defaultValue={datatype}>
          {dataTypes.map(({ label, value }, i) => (
            <MenuItem key={i} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={1}>
        <Checkbox name="share" color="primary" size="medium" checked={share} onChange={e => onShareChange(e.target.checked)} />
      </Grid>
      <Grid item xs={1}>
        <IconButton color="primary" onClick={onDelete}>
          <ClearRounded />
        </IconButton>
      </Grid>
    </>
  );
};

FormQuestion.propTypes = {
  onDelete: PropTypes.func,
};

export default FormQuestion;
