import { Fade, Paper, Popper, Typography } from "@material-ui/core";
import React from "react";

function SidebarTooltip({ classes, open, anchorEl, text }) {
  return (
    <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement={"right"} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={classes.popperPaper} square>
            <Typography className={classes.typography}>{text}</Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

export default SidebarTooltip;
