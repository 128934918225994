import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  resources: {
    enUS: {
      translations: require("assets/locales/en-US.json"),
    },
    ruRU: {
      translations: require("assets/locales/ru-RU.json"),
    },
  },
  fallbackLng: "enUS",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  react: {
    wait: true,
    useSuspense: true,
  },
});

export default i18n;
