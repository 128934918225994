import CollapseCard from "components/CollapseCard";
import ColorPickerTextField from "components/ColorPickerTextField";
import TextField from "components/TextField";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Button = ({ onChange, urlValue, buttonTextValue, bgColorValue, textColorValue, borderColorValue, onColorChange, onDelete }) => {
  const { t } = useTranslation();

  const { register } = useForm();

  const [collapse, setCollapse] = useState();

  return (
    <CollapseCard onDelete={onDelete} collapse={collapse} handleCollapse={() => setCollapse(!collapse)} draggable title={t("Labels.Button")}>
      <TextField
        labelPlacement="left"
        name="website"
        inputRef={register}
        label={t("URL Link")}
        value={urlValue}
        placeholder={"www.brandtag.io"}
        onChange={onChange}
      />

      <TextField
        labelPlacement="left"
        name="button_text"
        inputRef={register}
        label={t("Button Text")}
        value={buttonTextValue}
        placeholder={t("Find Out More")}
        onChange={onChange}
      />

      <ColorPickerTextField
        labelPlacement="left"
        name="button_color"
        inputRef={register}
        label={t("Button Color")}
        value={bgColorValue}
        onChange={onColorChange("button_color")}
      />

      <ColorPickerTextField
        labelPlacement="left"
        name="button_text_color"
        inputRef={register}
        label={t("Button Text Color")}
        value={textColorValue}
        onChange={onColorChange("button_text_color")}
      />

      <ColorPickerTextField
        labelPlacement="left"
        name="button_border_color"
        inputRef={register}
        label={t("Button Border Color")}
        value={borderColorValue}
        onChange={onColorChange("button_border_color")}
      />
    </CollapseCard>
  );
};

Button.propTypes = {
  onDelete: PropTypes.func,
};

export default Button;
