import { Divider, Drawer, Fade, Hidden, List, ListItem, ListItemIcon, ListItemText, Paper, Popper, Typography } from "@material-ui/core";
import { MeetingRoom } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import sidebarStyle from "assets/jss/components/sidebarStyle";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { version } from "../../../package.json";

const isActiveRoute = (location, path) => {
  return location.pathname.endsWith(path);
};

function SidebarTooltip({ classes, open, anchorEl, text }) {
  return (
    <Popper className={classes.popper} open={open} anchorEl={anchorEl} placement={"right"} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={classes.popperPaper} square>
            <Typography className={classes.typography}>{text}</Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

const useStyles = makeStyles(sidebarStyle);

function Sidebar({ open, openMobile, onDrawerClose, routes, location }) {
  const classes = useStyles();

  const [text, setText] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const handleTooltipClose = () => {
    setAnchorEl();
    setText();
  };
  const { t } = useTranslation();
  const { banner } = useSelector(state => state.user);

  const renderList = isMobile => {
    return (
      <>
        <NavLink to="/" className={classes.navlink}>
          <MeetingRoom />
          <Typography className={classes.dashboard}>{t("Labels.Back to Dashboard")}</Typography>
        </NavLink>
        <List disablePadding>
          {routes.map((route, index) => {
            if (route.divider) return <Divider key={index} className={classes.divider} />;
            if (!route.label) return null;
            return sidebarItem(index, isMobile, route);
          })}
        </List>
        <Typography variant="caption" gutterBottom className={classes.version}>
          v{version}
        </Typography>
      </>
    );
  };

  const showTooltip = label => e => {
    setAnchorEl(e.currentTarget);
    setText(label);
  };

  const sidebarItem = (index, isMobile, route, step = 0) => {
    const { label, path, icon: Icon, children } = route;
    const active = isActiveRoute(location, path);

    return (
      <div key={index}>
        <NavLink key={index} to={path} onClick={onDrawerClose("mobile")}>
          <ListItem
            style={open && step ? { paddingLeft: step * 16 + 24 - (active ? 5 : 0) } : {}}
            button
            onMouseEnter={isMobile ? undefined : showTooltip(t(label))}
            onMouseLeave={isMobile ? undefined : handleTooltipClose}
            className={classNames(classes.listItem, classes.settingsListItem)}
          >
            {Icon && (
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText
              primary={t(label)}
              classes={{
                primary: classNames({
                  [classes.activeText]: active,
                }),
              }}
            />
          </ListItem>
        </NavLink>
        {children &&
          children.length &&
          children.map((e, i) => {
            if (!e.label) return null;
            return sidebarItem(i, isMobile, e, step + 1);
          })}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
            [classes.banner]: banner,
          })}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
              [classes.banner]: banner,
            }),
          }}
          open
        >
          {renderList(false)}
        </Drawer>
        <SidebarTooltip classes={classes} open={Boolean(!open && anchorEl)} text={text} anchorEl={anchorEl} onClose={handleTooltipClose} />
      </Hidden>

      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={openMobile}
          onClose={onDrawerClose("mobile")}
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerOpenMobile]: open,
            [classes.banner]: banner,
          })}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerOpen]: open,
              [classes.drawerOpenMobile]: open,
              [classes.banner]: banner,
            }),
          }}
        >
          <div className={classes.toolbar}></div>
          {renderList(true)}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
}

Sidebar.propTypes = {
  open: PropTypes.bool,
  openMobile: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  routes: PropTypes.array.isRequired,
};

export default withRouter(Sidebar);
